import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type DppJson = TransactionItemJson<'DPP'>;

export default class Dpp extends Item<'DPP'> {
  constructor(store: ItemStore, json: DppJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get status() {
    return this.kindItem?.status;
  }

  @computed
  get orderDppFlowId() {
    return this.kindItem?.orderDppFlowId;
  }
}
