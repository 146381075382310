import getDisclosuresTaskBackData from '../utils/get-disclosures-task-back-data';
import {
  CANCEL_NHD_ORDER,
  COMPLETE_TASK,
  REORDER_NHD_WIZARD,
  START_ORDER_NHD_WIZARD,
  VIEW_TASK,
} from '../actions/kinds';
import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';
import { startOrderNhdWizard } from '../actions/start-order-nhd-wizard';

export default {
  actions: (task) => {
    const nhd = task.members.find((m) => m.kind === 'NHD') || {
      status: 'NOT_STARTED',
    };

    if (nhd.status === 'SENT_TO_SELLER') {
      return [VIEW_TASK];
    }

    return [START_ORDER_NHD_WIZARD, COMPLETE_TASK];
  },
  getStartLabel: () => 'Place an Order',
  getBackData: getDisclosuresTaskBackData,
  listOnlyActions: [VIEW_TASK],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  actionsVisible: {
    COMPLETE_TASK: 'canCompleteTask',
  },
  label: '',
  onClick: startOrderNhdWizard,
  spotIcon: () => import('src/images/spot-icons/workflows/spot-nhd.svg'),
  getStatusMeta: (task) => {
    const nhd = task.members.find((m) => m.kind === 'NHD') || {
      status: 'NOT_STARTED',
    };
    if (!nhd.status || nhd.status === 'NOT_STARTED') {
      const message = nhd.isSellerSelectionMode
        ? 'Set up NHD ordering for your seller'
        : 'Order your NHD report from top providers';
      return {
        message,
      };
    } else if (
      nhd.status === 'ORDER_PLACED' ||
      nhd.status === 'ORDER_DELAYED'
    ) {
      return {
        message: 'Order sent to NHD provider and waiting for report.',
        actions: [CANCEL_NHD_ORDER],
        primaryActions: [CANCEL_NHD_ORDER],
      };
    } else if (nhd.status === 'COMPLETED') {
      return {
        message: 'Report has been added to your documents.',
      };
    } else if (nhd.status === 'NOT_INTERESTED') {
      return {
        message: 'You did not order an NHD for this transaction.',
      };
    } else if (nhd.status === 'SENT_TO_SELLER') {
      return {
        message: 'Order sent to seller',
      };
    } else if (nhd.status === 'CANCELLED') {
      return {
        primaryActions: [REORDER_NHD_WIZARD],
        actions: [REORDER_NHD_WIZARD],
        message: 'Your order was cancelled',
      };
    }

    return {};
  },
};
