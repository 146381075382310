

import React, {
  FC,
  useState,
  type ComponentProps,
  type ReactNode,
  type CSSProperties,
} from 'react';
import { FolderTwoTone, PlusOutlined } from '@ant-design/icons';
import { Col, Row, Tree } from 'antd';
import type { TreeProps } from 'antd/lib/tree';
import classNames from 'classnames';
import Fuse from 'fuse.js';
import debounce from 'lodash/debounce';
import isFunction from 'lodash/isFunction';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import without from 'lodash/without';
import { inject, observer } from 'mobx-react';
import type { TreeNodeProps } from 'rc-tree/lib/index';
import AnchorButton from 'src/components/common/anchor-button';
import AppFiltersPanel from 'src/components/common/app-filters-panel';
import OverflowLabel from 'src/components/common/overflow-label';
import RowSpinner from 'src/components/common/row-spinner';
import SearchInput from 'src/components/common/search-input';
import PdfIcon from 'src/components/documents/pdf-icon';
import ZfxIcon from 'src/components/documents/zfx-icon';
import type FeaturesStore from 'src/stores/features-store';
import EntitlementAlertIndicator from './entitlement-alert-indicator';

const { TreeNode } = Tree;

const clsPrefix = 'app-external-documents-list';

const NodeType = {
  FOLDER: 'FOLDER',
} as const;

export function getFlatDocumentNodes(documents: DocumentNode[]) {
  // Accumulator as we recursively get all the document nodes.
  const res: DocumentNode[] = [];

  function pushRecursively(children: DocumentNode[]) {
    if (!children || !children.length) {
      return;
    }
    children.forEach((d: DocumentNode) => {
      res.push(d);
      if (d.children) {
        pushRecursively(d.children);
      }
    });
  }

  pushRecursively(documents);
  return res;
}

interface FilterSelectedDocumentsParams {
  documents: DocumentNode[];
  selectedDocuments: string[];
}

export function filterSelectedDocuments({
  documents,
  selectedDocuments,
}: FilterSelectedDocumentsParams) {
  const selectedNodes = getFlatDocumentNodes(documents).filter(
    (d) => selectedDocuments.includes(d.id) && d.contentType !== NodeType.FOLDER
  );
  return uniqBy(selectedNodes, 'id');
}

const NODE_PATH_SEPARATOR = '/';

function getNodeIdFromNodeKey(nodeKey: string): string {
  return nodeKey.split(NODE_PATH_SEPARATOR).pop() || nodeKey;
}

function deepMap(
  docs: DocumentNode[],
  transform: (doc: DocumentNode, path: DocumentNode[]) => DocumentNode,
  _path: DocumentNode[] = []
): DocumentNode[] {
  return docs.map((doc) => ({
    ...transform(doc, _path),
    children: doc.children && deepMap(doc.children, transform, [..._path, doc]),
  }));
}

// https://fusejs.io/api/options.html#includescore
// "A score of 0 indicates a perfect match, while a score of 1 indicates a complete mismatch."
const MAX_FUZZY_SEARCH_SCORE = 0.2;
// https://fusejs.io/examples.html#weighted-search
const FUZZY_WEIGHT_NAME = 0.9;
const FUZZY_WEIGHT_TAGS = 0.1;

const FUSE_SEARCH_OPTIONS = {
  includeScore: true,
  shouldSort: true,
  ignoreLocation: true,
  useExtendedSearch: true,
  // Ignore length of fields: https://fusejs.io/concepts/scoring-theory.html#field-length-norm
  ignoreFieldNorm: true,
  keys: [
    {
      name: 'name',
      weight: FUZZY_WEIGHT_NAME,
    },
    {
      name: 'tags',
      weight: FUZZY_WEIGHT_TAGS,
    },
  ],
};

/**
 * A frontend constructed data structure for form tree selecting, presenting both libraries
 * (or folders) and forms.
 */
export interface DocumentNode {
  /**
   * Original id of a library (e.g. `"l-173"`) or a form (e.g. `'12471'`).
   * As a form the id could be duplicated inside a tree, when a document belongs to multiple libraries.
   */
  id: string;
  /** attached, unique key for TreeNode, built from full path with library id, e.g. `"l-173/12471"` */
  key?: string;
  /** attached, ancestor node list, not including itself */
  path?: DocumentNode[];
  name: string;
  contentType: string;
  /** As a form library, children holds its associated forms */
  children?: DocumentNode[];
  meta?: {
    // docusign
    envelopeId: string;
    url: string;
  };
  // library fields
  entitled?: boolean;
  active?: boolean;
  qaActive?: boolean;
  // from ExternalDocumentNode
  signed?: boolean;
  // form fields
  tags?: Set<string>;
  canApplyTo?: string[];
  isPropertyDoc?: boolean;
  sourceLibraryId?: string;
}

export interface Props {
  // Represents all documents passed down to this component before any filtering/searching.
  documents: DocumentNode[];
  breadcrumb?: ReactNode;
  searchPlaceHolder?: string;
  emptyMsg?: ReactNode;
  loading?: boolean;
  /** Selected document IDs */
  selectedDocuments: string[];
  onChange: (selectedDocIds: string[]) => void;
  className?: string;
  scrollable?: boolean;
  pendingDocuments?: string[];
  addOtherForms?: ReactNode;
  showCheckAll?: boolean;
  getFolderProps?: () => Partial<TreeNodeProps>;
  getDocumentProps?: (node: DocumentNode) => Partial<TreeNodeProps>;
  defaultExpandAll?: boolean;
  applyFilter?: (docs: DocumentNode[]) => DocumentNode[];
  filterProps?: Partial<ComponentProps<typeof AppFiltersPanel>>;
  hideAssociations?: boolean;
  showAssociationsModal?: (visible: boolean) => void;
}

interface SearchDocument {
  id: string;
  name: string;
  tags: Set<string>;
}

interface InjectedProps extends Props {
  features: FeaturesStore;
}

const ExternalDocumentsList: FC<InjectedProps> = inject('features')(
  observer((props: InjectedProps) => {
    const {
      addOtherForms,
      documents: sourceDocuments = [],
      applyFilter = (docs: DocumentNode[]) => docs, // no-op as default case
      className,
      breadcrumb,
      defaultExpandAll = true,
      emptyMsg,
      features,
      filterProps,
      getDocumentProps = () => ({}),
      getFolderProps = () => ({}),
      hideAssociations,
      loading,
      onChange,
      pendingDocuments,
      searchPlaceHolder,
      scrollable = true,
      selectedDocuments = [],
      showAssociationsModal,
    } = props;

    const [query, setQuery] = useState<string>('');
    /** Documents index with id as key */
    const documentsIndex: Record<string, DocumentNode[]> = {};
    const filteredDocs = applyFilter?.(sourceDocuments) || [];
    const documents = deepMap(filteredDocs, (node, path) => {
      const transformedDoc: DocumentNode = {
        ...node,
        key: [...path, node].map((child) => child.id).join(NODE_PATH_SEPARATOR),
        path,
      };
      documentsIndex[node.id] ??= [];
      documentsIndex[node.id].push(transformedDoc);
      return transformedDoc;
    });

    const searchDocuments = documents
      .map((i) => (i.children ? i : { children: [i] }))
      .reduce((acc, lib) => {
        acc.push(
          ...(lib.children || []).map(({ id, name, tags }) => ({
            id,
            name,
            tags: tags || new Set(),
          }))
        );
        return acc;
      }, [] as SearchDocument[]);

    const fuzzySearch = new Fuse(searchDocuments, FUSE_SEARCH_OPTIONS);

    const applyQueryFilter = (): DocumentNode[] => {
      if (!query || !fuzzySearch) {
        return documents;
      }

      let matchFn: (d: DocumentNode) => DocumentNode | null;
      let sortFn: (
        a: SearchDocument | DocumentNode,
        b: SearchDocument | DocumentNode
      ) => number = () => 0;

      matchFn = (d) => (d.name.toLowerCase().indexOf(query) !== -1 ? d : null);

      const mapNode = (d: DocumentNode): DocumentNode | null =>
        d.contentType === NodeType.FOLDER
          ? {
              ...d,
              children: (
                (d.children || [])
                  .map(mapNode)
                  .filter((c) => c) as DocumentNode[]
              ).sort(sortFn),
            }
          : matchFn(d);

      return documents
        .map(mapNode)
        .filter(
          (d) =>
            d && (d.contentType === NodeType.FOLDER ? d.children?.length : d)
        ) as DocumentNode[];
    };

    const onCheck: TreeProps['onCheck'] = (_checkedKeys, { node, checked }) => {
      /*
      Documented arguments such as checked and checkedNodes cannot be used
      because of the filtering we are doing of the tree elements when
      search is used: in those cases the tree only gets the elements that pass
      the filter and, for example, if the search leaves one leaf and you click
      it the whole folder gets selected.
    */
      const checkedKeys = _checkedKeys as string[];
      const checkedNodeIds = uniq(checkedKeys.map(getNodeIdFromNodeKey));
      const key = node.key as string;
      const nodeId = getNodeIdFromNodeKey(key);

      // Clicked documents are toggled from the selected ones
      let toRemove: string[] = [];

      if (checked === false) {
        if (node.isLeaf) {
          // It's a leaf, just use the node id
          toRemove = [nodeId];
        } else {
          // It's a folder, remove all
          toRemove = without(selectedDocuments, ...checkedNodeIds);
        }
      }
      const newSelectedDocuments = without(
        uniq(selectedDocuments.concat(checkedNodeIds)),
        ...toRemove
      );

      onChange(newSelectedDocuments);
    };

    const renderEmptyMessage = () => {
      if (isFunction(emptyMsg)) {
        return (
          <div className={`${clsPrefix}__empty-msg`}>{emptyMsg(query)}</div>
        );
      }
      return (
        <div className={`${clsPrefix}__empty-msg`}>
          {query ? `No results found for ${query}` : emptyMsg}
        </div>
      );
    };

    const getSelectedDocumentKeys = () => {
      return selectedDocuments.flatMap(
        (nodeId) => documentsIndex[nodeId]?.map((d) => d.key!) ?? []
      );
    };

    const renderDocumentNode = (node: DocumentNode) => {
      const pending = pendingDocuments && pendingDocuments.includes(node.id);
      if (node.contentType === NodeType.FOLDER) {
        const toRender = (node.children || [])
          .map((child) => renderDocumentNode(child))
          .filter((c) => c);

        return (
          <TreeNode
            isLeaf={false}
            key={node.key}
            title={
              <div className={`${clsPrefix}__library-folder`}>
                {node.name}
                {!node.entitled && features.membershipVerificationEnabled && (
                  <EntitlementAlertIndicator
                    className={`${clsPrefix}__library-folder__entitled-error`}
                  />
                )}
              </div>
            }
            selectable={false}
            icon={<FolderTwoTone className={`${clsPrefix}__folder-icon`} />}
            {...getFolderProps?.()}
          >
            {toRender}
          </TreeNode>
        );
      }

      const docIconStyle: CSSProperties = {
        position: 'relative',
        top: -2,
        display: 'inline-block',
        width: 17,
        height: 22,
        marginRight: '5px',
      };

      return (
        <TreeNode
          isLeaf
          key={node.key}
          disabled={pending}
          title={
            <OverflowLabel className={`${clsPrefix}__filename`}>
              {node.signed ? (
                <span className={`${clsPrefix}__filename-signed`}>
                  {' '}
                  [Signed]{' '}
                </span>
              ) : null}
              {pending ? (
                <span className={`${clsPrefix}__filename-pending`}>
                  {' '}
                  Pending{' '}
                </span>
              ) : null}
              {node.name}
            </OverflowLabel>
          }
          selectable={false}
          icon={
            node.contentType === 'FORM' ? (
              <ZfxIcon style={docIconStyle} />
            ) : (
              <PdfIcon style={docIconStyle} />
            )
          }
          {...getDocumentProps?.(node)}
        />
      );
    };

    const renderContent = () => {
      if (loading) {
        return (
          <div className={`${clsPrefix}__loader margin-top margin-bottom`}>
            <RowSpinner style={{}} />
          </div>
        );
      }

      const filteredDocuments = query ? applyQueryFilter() : documents || [];

      const toRender =
        filteredDocuments.length > 0 &&
        filteredDocuments.map(renderDocumentNode).filter((c) => c);

      return toRender && toRender.length > 0 ? (
        <Tree
          className={`${clsPrefix}__docs-tree`}
          checkable
          showIcon
          blockNode
          defaultExpandAll={defaultExpandAll}
          checkedKeys={getSelectedDocumentKeys()}
          onCheck={onCheck}
        >
          {toRender}
        </Tree>
      ) : (
        renderEmptyMessage()
      );
    };

    const handleSearch = (q: string) => {
      setQuery(q.toLowerCase());
    };

    const showAssociations =
      !hideAssociations && features.membershipVerificationEnabled;

    return (
      <div className={`${clsPrefix} ${className}`}>
        {breadcrumb && (
          <div className={`${clsPrefix}__breadcrumb-wrap`}>
            <div className={`${clsPrefix}__breadcrumb`}>{breadcrumb}</div>
          </div>
        )}
        <div className={`${clsPrefix}__search-wrap`}>
          <div className={`${clsPrefix}__search-wrap-top`}>
            {filterProps && (
              <div className={`${clsPrefix}__search-filter`}>
                <AppFiltersPanel
                  {...filterProps}
                  modalProps={{
                    // HACK: for some reason the filterProps.value prop won't pass correctly
                    // to an ExternalDocumentsList that is inside of a modal (like in the
                    // add forms modal) when the modal is not visible due to another modal
                    // being on top of it in the stack.
                    registerModalOnStack: false,
                    ...filterProps.modalProps,
                  }}
                />
              </div>
            )}
            <div className={`${clsPrefix}__search`}>
              <SearchInput
                placeholder={searchPlaceHolder}
                onSearch={debounce((q) => handleSearch(q), 200)}
                autoFocus
              />
            </div>
          </div>
          <Row className={`${clsPrefix}__libraries-detail`}>
            <Col xs={8} sm={12} className={`${clsPrefix}__libraries-count`}>
              {sourceDocuments.length} librar
              {sourceDocuments.length === 1 ? 'y' : 'ies'}
            </Col>
            {showAssociations && (
              <Col
                xs={16}
                sm={12}
                className={`${clsPrefix}__connect-association`}
              >
                <AnchorButton onClick={showAssociationsModal}>
                  <PlusOutlined /> Connect an association
                </AnchorButton>
              </Col>
            )}
          </Row>
          {addOtherForms ? (
            <div className={`${clsPrefix}__search-actions`}>
              {' '}
              {addOtherForms}{' '}
            </div>
          ) : null}
        </div>
        <div
          className={classNames(`${clsPrefix}__content`, {
            [`${clsPrefix}__scrollable`]: scrollable,
          })}
        >
          {renderContent()}
        </div>
      </div>
    );
  })
);

export default ExternalDocumentsList as FC<Props>;
