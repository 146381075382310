import get from 'lodash/get';
import intersection from 'lodash/intersection';
import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import {
  Clause as ClauseJson,
  ClauseClauseVisibility,
} from 'src/types/proto/clauses';
import formatFullTimestamp from 'src/utils/format-full-timestamp';
import getFullNameOrEmail from 'src/utils/get-full-name-or-email';

interface TeamsCan {
  offices: {
    id: string;
  }[];
  groups: {
    id: string;
  }[];
}

export interface ClauseStore {
  parent: {
    account: {
      user: {
        id: string;
      };
    };
    transactionTemplates: {
      teamsCanView(): TeamsCan;
      teamsCanManage(): TeamsCan;
    };
  };
}

const VISIBILITY_LABELS: Record<keyof typeof ClauseClauseVisibility, string> = {
  UNKNOWN: 'Unknown',
  PERSONAL: 'Just me',
  TEAMS: 'Specific Offices & Groups',
  BROKERAGE: 'Everyone',
};

export const OPS = {
  VIEW: 'VIEW',
  MANAGE: 'MANAGE',
};

export default class Clause extends Model<ClauseJson> {
  private store: ClauseStore;

  constructor(store: ClauseStore, json: ClauseJson) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get teamIds() {
    return this.data.teamIds;
  }

  @computed
  get user() {
    return this.data.user;
  }

  @computed
  get owned() {
    return this.data.userId === get(this.store.parent, 'account.user.id');
  }

  can(op: keyof typeof OPS) {
    if (!OPS[op]) {
      throw new Error(`Unknown op: "${op}"`);
    }
    if (this.owned) {
      return true;
    }
    const clauseTeamsIds = this.teamIds;
    let teamsCanOp: TeamsCan = {
      offices: [],
      groups: [],
    };
    if (op === OPS.VIEW) {
      teamsCanOp = this.store.parent.transactionTemplates.teamsCanView();
    } else if (op === OPS.MANAGE) {
      teamsCanOp = this.store.parent.transactionTemplates.teamsCanManage();
    }
    const teamsIds = teamsCanOp.offices
      .concat(teamsCanOp.groups)
      .map((t) => t.id);
    return intersection(clauseTeamsIds, teamsIds).length > 0;
  }

  @computed
  get canView() {
    return this.can('VIEW');
  }

  @computed
  get canManage() {
    return this.can('MANAGE');
  }

  @computed
  get title() {
    return this.data.title;
  }

  @computed
  get description() {
    return this.data.description;
  }

  @computed
  get visibility() {
    return this.data.visibility;
  }

  @computed
  get visibilityLabel() {
    return VISIBILITY_LABELS[this.visibility];
  }

  @computed
  get createdAt() {
    return this.data.createdAt;
  }

  @computed
  get updatedAt() {
    return this.data.updatedAt;
  }

  @computed
  get updatedBy() {
    return this.data.updatedBy;
  }

  @computed
  get creatorName() {
    if (!get(this.user, 'contact', false)) {
      return '';
    }

    return getFullNameOrEmail(this.user?.contact);
  }

  @computed
  get creatorAndTimestamp() {
    const parts = [];
    if (this.creatorName) {
      parts.push(this.creatorName);
    }

    parts.push(
      formatFullTimestamp(
        parseInt(this.createdAt as unknown as string, 10),
        undefined,
        'M/D/YY hh:mm A'
      )
    );

    return parts.join(', ');
  }
}

export type { ClauseJson };
