type Options = {
  locale?: string | string[];
  precision?: number;
  currency?: string;
  empty?: string;
};

export default function formatCurrency(
  value?: number | string,
  options: Options = {}
) {
  const {
    locale = 'en-US',
    precision = 2,
    currency = 'USD',
    empty = '-',
  } = options;
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  return !Number.isNaN(parseFloat(value as string))
    ? formatter.format(value as number)
    : empty;
}

export const currencyToNumber = (formattedValue?: string) => {
  const cleanValue = (formattedValue || '').replace(/[$|,| ]/g, '');
  return !Number.isNaN(parseFloat(cleanValue)) ? parseFloat(cleanValue) : null;
};
