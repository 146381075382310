import BaseTermDelegate from './base';
import moment from 'moment';
import { computed, makeObservable, override } from 'mobx';

const INNER_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const TIME_ONLY_OUT_FORMAT = 'hh:mm a';
const OUT_FORMAT = `MM/DD/YYYY ${TIME_ONLY_OUT_FORMAT}`;

export default class DatetimeTermDelegate extends BaseTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  @computed
  get timeOnly() {
    return Boolean(this.term.kindData.timeOnly);
  }

  @computed
  get period() {
    return this.term.kindData.period?.radio
      ? this.term.kindData.period
      : undefined;
  }

  @computed
  get periodAmValue() {
    return this.period?.amValue;
  }

  @computed
  get periodPmValue() {
    return this.period?.pmValue;
  }

  @computed
  get datePlaceholder() {
    return this.term.kindData.datePlaceholder;
  }

  @computed
  get timePlaceholder() {
    return this.term.kindData.timePlaceholder;
  }

  @override
  get mainFieldKey() {
    return 'time';
  }

  @override
  get boundOutputs() {
    return ['time', !this.timeOnly && 'date', Boolean(this.radio) && 'radio']
      .filter(Boolean)
      .map((k) => this.getBoundOutput(k))
      .filter(Boolean);
  }

  get dateFormat() {
    return this.timeOnly ? 'YYYY-MM-DD' : 'MM/DD/YYYY';
  }

  get timeFormat() {
    return this.period ? 'hh:mm' : 'hh:mm a';
  }

  get fullFormat() {
    return `${this.dateFormat}T${this.timeFormat}:ss${this.period ? ' a' : ''}`;
  }

  getPeriod(periodValue) {
    if (!this.period || !periodValue) {
      return '';
    }

    return (
      {
        [this.periodAmValue]: 'am',
        [this.periodPmValue]: 'pm',
      }[periodValue] || ''
    );
  }

  getMoment(value) {
    const mom = moment(value, this.fullFormat);
    return mom?.isValid() ? mom : undefined;
  }

  getValue(value) {
    const mom = this.getMoment(value);
    return mom && mom.format(INNER_FORMAT);
  }

  renderReadOnlyValue() {
    const currentValue = this.getCurrentValue();
    const mom = currentValue ? moment(currentValue, INNER_FORMAT) : undefined;
    return mom?.isValid()
      ? mom.format(this.timeOnly ? TIME_ONLY_OUT_FORMAT : OUT_FORMAT)
      : '';
  }

  getValuesByFormFieldId(value) {
    const momValue = value && moment(value).isValid() ? moment(value) : null;
    const date = momValue ? momValue.format(this.dateFormat) : null;
    const time = momValue ? momValue.format(this.timeFormat) : null;
    const rawPeriod = momValue ? momValue.format('a') : null;
    let period = null;
    if (rawPeriod && this.period) {
      period =
        rawPeriod.toLowerCase() === 'pm'
          ? this.periodPmValue
          : this.periodAmValue;
    }
    return {
      [this.term.getFieldId('time')]: time,
      ...(!this.timeOnly
        ? {
            [this.term.getFieldId('date')]: date,
          }
        : {}),
      ...(this.period
        ? {
            [this.term.getFieldId('period')]: period,
          }
        : {}),
    };
  }

  getCurrentValue() {
    const time = this.getBoundOutput('time').getFieldValue('this');
    const date = !this.timeOnly
      ? this.getBoundOutput('date').getFieldValue('this')
      : moment().format(this.dateFormat);

    if (!date || !time) {
      return undefined;
    }

    const period = this.period
      ? this.getPeriod(this.getBoundOutput('period').getFieldValue('this'))
      : undefined;
    const dateTime = [date, time && `${time}:00`].filter(Boolean).join('T');
    const value = dateTime ? `${dateTime} ${period || ''}`.trim() : undefined;

    return value ? this.getValue(value) : undefined;
  }
}
