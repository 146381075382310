import Ajv, { ErrorObject } from 'ajv';
import { FormConfig } from 'src/types/proto/reform';
import schema from './json-schema/form_config.json';

const ajv = new Ajv({
  strict: false,
  removeAdditional: 'all',
  allErrors: true,
});

const validate = ajv.compile<FormConfig>(schema);

/**
 * Validate form config, removing additional properties and return any errors,
 * return nothing if no errors are found
 */
export function validateFormConfig(data: any) {
  validate(data);
  return validate.errors;
}

export type FormConfigValidationErrors = null | ErrorObject[];
