import getDisclosuresTaskBackData from '../utils/get-disclosures-task-back-data';
import {
  ARCHIVE,
  DELETE,
  DOWNLOAD_GFP,
  EDIT_REMINDERS,
  GENERATE_GFP,
  GFP_ACCESS_IN_PERSON,
  GFP_ADD_FORMS,
  GFP_PREVIEW_PDFS,
  LOCK_GFP,
  RESEND_GFP_INVITES,
  SIGN_GFP,
  VIEW_GFP,
  VIEW_GFP_AS_CLIENT,
} from '../actions/kinds';
import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';
import { viewTask } from '../actions/view-gfp';
import { getColor } from 'src/utils/get-color';

const actions = [
  GFP_ACCESS_IN_PERSON,
  VIEW_GFP,
  VIEW_GFP_AS_CLIENT,
  RESEND_GFP_INVITES,
  GFP_ADD_FORMS,
  GENERATE_GFP,
  GFP_PREVIEW_PDFS,
  DOWNLOAD_GFP,
  SIGN_GFP,
  EDIT_REMINDERS,
  LOCK_GFP,
  ARCHIVE,
  DELETE,
];

export default {
  actions: (task) => {
    const gfp = task.members.find((i) => i.kind === 'GUIDED_FORM_PACKET');
    if (!gfp) {
      return [VIEW_GFP, DELETE];
    }
    return actions;
  },
  getStartLabel: () => 'Invite',
  getBackData: getDisclosuresTaskBackData,
  actionsVisible: {
    VIEW_GFP_AS_CLIENT: 'actAsAdminAndClient',
    EDIT_TRANSACTION_DETAILS: 'actAsAdmin',
    EDIT_TRANSACTION_PARTIES: 'actAsAdmin',
    RESEND_GFP_INVITES: 'actAsAdmin',
    GENERATE_GFP: 'actAsAdmin',
    SIGN_GFP: 'actAsAdmin',
    LOCK_GFP: 'actAsAdmin',
    ARCHIVE: 'actAsAdmin',
    DELETE: 'canDelete',
    GFP_ADD_FORMS: 'actAsAdmin',
    GFP_ACCESS_IN_PERSON: 'accessInPerson',
    EDIT_REMINDERS: 'canAddReminders',
  },
  sections: [...DEFAULT_SECTIONS],
  mainActions: [VIEW_GFP_AS_CLIENT, SIGN_GFP],
  listOnlyActions: [VIEW_GFP],
  vitals: [...DEFAULT_VITALS],
  label: 'Seller Disclosures',
  onClick: viewTask,
  canAssign: false,
  canEditStatus: false,
  showClientLabel: true,
  style: {
    color: getColor('green-8'),
    backgroundColor: '#F3FBFE',
    border: getColor('green-8'),
  },
  spotIcon: () => import('src/images/spot-icons/workflows/spot-sellerdisc.svg'),
  getStatusMeta: (task) => {
    const gfp = task.members.find((i) => i.kind === 'GUIDED_FORM_PACKET') || {
      status: 'NOT_STARTED',
    };
    const fillAction = task.can('actAsClient')
      ? VIEW_GFP_AS_CLIENT
      : RESEND_GFP_INVITES;

    const isSale = task.transaction.isSale;

    const clientsNoun = `${isSale ? 'Sellers' : 'Buyers'}`;

    if (gfp.status === 'NOT_STARTED') {
      return {
        message: isSale
          ? 'Start by sending an invitation to the Sellers to complete Seller Disclosures.'
          : 'Start by sending an invitation to the Buyers.',
        actions: [],
        primaryActions: [],
      };
    }
    if (gfp.status === 'DRAFT') {
      return {
        message: `Disclosures have not been sent to the ${clientsNoun} yet. Complete the invitation.`,
        actions: [],
        primaryActions: [],
      };
    }
    if (gfp.status === 'CREATED') {
      return {
        message: `Disclosures have been sent but ${clientsNoun} have not started filling them.`,
        actions: [fillAction],
        primaryActions: [fillAction],
      };
    }
    if (gfp.status === 'STARTED') {
      return {
        message: `${clientsNoun} are in the process of filling out the Disclosures.`,
        actions: [fillAction, GFP_PREVIEW_PDFS],
        primaryActions: [fillAction],
      };
    }
    if (gfp.status === 'FLOWS_COMPLETED') {
      return {
        message: `The ${clientsNoun.toLowerCase()} haven't submitted their forms yet but they are filled out.`,
        actions: [GENERATE_GFP, fillAction, GFP_PREVIEW_PDFS],
        primaryActions: [GENERATE_GFP, fillAction],
      };
    }
    if (gfp.status === 'SUBMITTED') {
      return {
        message: 'Ready to be reviewed and generated.',
        actions: [GENERATE_GFP, fillAction, GFP_PREVIEW_PDFS],
        primaryActions: [GENERATE_GFP, fillAction],
      };
    }
    if (gfp.status === 'FLOWS_EDITED') {
      return {
        message:
          'Form answers have changed since last submit. Check with client before generating.',
        actions: [GENERATE_GFP, fillAction, GFP_PREVIEW_PDFS],
        primaryActions: [GENERATE_GFP, fillAction],
      };
    }
    if (gfp.status === 'FLOWS_EDITED_INCOMPLETE') {
      return {
        message:
          'Form answers have changed since last submit and forms are now incomplete. Check with client before generating.',
        actions: [fillAction, GFP_PREVIEW_PDFS],
        primaryActions: [fillAction],
      };
    }
    if (gfp.status === 'COMPLETED') {
      return {
        message:
          'PDFs have been generated. Clients can log in at any time to make changes.',
        actions: [SIGN_GFP, fillAction, DOWNLOAD_GFP, LOCK_GFP],
        primaryActions: [SIGN_GFP, fillAction],
      };
    }
    if (gfp.status === 'LOCKED') {
      return {
        message:
          'Forms cannot be generated and answers cannot be changed while locked.',
        actions: [LOCK_GFP],
      };
    }
    return {};
  },
};
