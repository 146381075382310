

import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const clsPrefix = 'app-actions-row';

export default function ActionsRow({
  className,
  children,
  align,
  ...otherProps
}) {
  return (
    <div
      className={classNames(clsPrefix, `${clsPrefix}--${align}`, className)}
      {...otherProps}
    >
      {children}
    </div>
  );
}

ActionsRow.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  align: PropTypes.oneOf(['left', 'center', 'right']),
};
