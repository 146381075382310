import keyBy from 'lodash/keyBy';
import { action, makeObservable, runInAction } from 'mobx';

/**
 * Store for fetching all packets for a user, not just for a
 * single transction.
 */
export default class PacketStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.packets;
  }

  get transactions() {
    return this.parent.transactions;
  }

  /**
   * @param  {...[type]} args Query parameters to send to the backend endpoint.
   * @return {transactions_pb2.ItemList}
   */
  @action
  fetchList = async (...args) => {
    const { data } = await this.api.list(...args);
    const itemsById = keyBy(data.data, 'id');
    const transactionsById = keyBy(
      data.data.map((json) => json.trans).filter((json) => json && json.id),
      'id'
    );
    runInAction(() => {
      this.transactions.updateItemsById(itemsById);
      this.transactions.updateTransactionsById(transactionsById);
    });
    return {
      ...data,
      data: data.data.map((json) => this.transactions.itemsById.get(json.id)),
    };
  };
}
