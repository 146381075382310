


import React, { forwardRef } from 'react';
import classNames from 'classnames';
import ReactQuill from 'react-quill';

const clsPrefix = 'app-app-rich-text-editor';

interface Props extends React.ComponentProps<typeof ReactQuill> {}

const AppRitchTextEditor = forwardRef<ReactQuill, Props>((props, ref) => {
  const { className, onChange, value, ...otherProps } = props;

  return (
    <div>
      <ReactQuill
        className={classNames(clsPrefix, className)}
        value={value ?? ''}
        theme="snow"
        onChange={onChange}
        ref={ref}
        {...otherProps}
      />
    </div>
  );
});

export default AppRitchTextEditor;
