

import React, { Children, Component, Fragment, createRef } from 'react';
import { findDOMNode } from 'react-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import classNames from 'classnames';
import { chain } from 'lodash';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import partial from 'lodash/partial';
import pickBy from 'lodash/pickBy';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AnchorButton from 'src/components/common/anchor-button';
import AppButton from 'src/components/common/app-button';
import AppIcon from 'src/components/common/app-icon';
import AppModal from 'src/components/common/app-modal';
import { openProvidersModal } from 'src/entries/app/transactions/transaction/documents/document/associations';
import { augmentChildren } from 'src/utils/augment-children';
import { getColor } from 'src/utils/get-color';
import AppLabel from '../app-label';
import SelectFilter from './select-filter';

const clsPrefix = 'app-filters-panel';

const MODES = {
  INLINE: 'inline',
  DROPDOWN: 'dropdown',
};

const COLORS = {
  ORANGE: ['#FFF3E9', '#D77322'],
  YELLOW: ['#FFF7E5', '#CBA02E'],
  GREEN: [getColor('green-0'), '#7BA924'],
  LIGHTBLUE: [getColor('gray-0'), getColor('app-tertiary-color')],
  BLUE: ['#E8F2F9', getColor('blue-6')],
  PURPLE: ['#FBEEFF', '#7D328F'],
  PINK: ['#FFF0FC', '#A5267B'],
  __default: ['#F7F8F9', '#0009'],
};
Object.entries(COLORS).forEach(([k, v], idx) => {
  if (k !== '__default') {
    COLORS[idx] = v;
  }
});

const APPLY = {
  IMMEDIATE: 'immediate',
  ON_CLOSE: 'onClose',
  BUTTON: 'button',
};

const FILTERS_COMPONENTS = {
  Select: SelectFilter,
  MultipleSelect: ({ ...props }) => <SelectFilter {...props} multiple />,
};

const FILTER_WIDTH = 250;
const FILTERS_GUTTER = 5;

const Pill = ({ className, backgroundColor, color, title, children }) => (
  <Tooltip title={title}>
    <span
      className={classNames(`${clsPrefix}__pill`, className)}
      style={{
        backgroundColor,
        color,
      }}
    >
      {children}
    </span>
  </Tooltip>
);
Pill.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.any,
  children: PropTypes.any,
};
Pill.defaultProps = {
  backgroundColor: COLORS.__default[0],
  color: COLORS.__default[1],
};

export function isFilterValueEmpty(filterValue) {
  return (
    Object.keys(pickBy(filterValue || {}, (v) => v?.length > 0)).length === 0
  );
}

@inject('ui', 'features', 'account')
@observer
class AppFiltersPanel extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    features: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
    className: PropTypes.string,
    mode: PropTypes.oneOf(Object.values(MODES)),
    trigger: PropTypes.array,
    visible: PropTypes.bool,
    onVisibleChange: PropTypes.func,
    dropdownButtonLabel: PropTypes.string,
    dropdownButtonProps: PropTypes.object,
    title: PropTypes.any,
    titleWrapProps: PropTypes.object,
    filtersPanelProps: PropTypes.object,
    children: PropTypes.any,
    initialValue: PropTypes.object,
    value: PropTypes.object,
    onChange: PropTypes.func,
    apply: PropTypes.oneOf(Object.values(APPLY)),
    allowClear: PropTypes.bool,
    showAppliedCount: PropTypes.bool,
    // showAppliedValues will show up to showAppliedValues values, or all of them if set to true
    showAppliedValues: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    modalProps: PropTypes.object,
  };
  static defaultProps = {
    mode: MODES.DROPDOWN,
    trigger: ['click'],
    dropdownButtonLabel: 'Filter',
    title: 'Filters',
    apply: APPLY.IMMEDIATE,
    allowClear: true,
    showAppliedCount: true,
  };
  static COLORS = COLORS;

  static getDerivedStateFromProps(props, state) {
    if (state.value === null) {
      return {
        value: props.initialValue || null,
      };
    }

    return null;
  }

  state = {
    width: 0,
    left: undefined,
    visible: false,
    value: null,
    unappliedChanges: null,
  };
  dropdownButtonRef = createRef();
  assignedColors = {};

  componentDidMount() {
    window.addEventListener('resize', this.adjustPanelSize);
    this.adjustPanelSize();
  }

  componentDidUpdate() {
    this.adjustPanelSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustPanelSize);
  }

  adjustPanelSize_ = () => {
    const { children, ui } = this.props;
    const dropdownButton = this.dropdownButtonRef.current
      ? findDOMNode(this.dropdownButtonRef.current) // eslint-disable-line react/no-find-dom-node
      : null;
    if (this.isDropdown && dropdownButton) {
      let width = null;

      if (!ui.isMobileSize) {
        const padding = 20;
        let count = 0;
        Children.forEach(children, (child) => {
          count += this.isValidFilterNode(child) ? 1 : 0;
        });
        width = FILTER_WIDTH * count + FILTERS_GUTTER * (count - 1) + padding;
        const reduceOneFilterWidth = FILTER_WIDTH + FILTERS_GUTTER;
        const minWidth = FILTER_WIDTH + padding;
        const maxWidth = window.innerWidth - 24 - dropdownButton.offsetLeft; // 24px left for right padding
        while (width > maxWidth) {
          if (width - reduceOneFilterWidth < minWidth) {
            break;
          }

          width -= reduceOneFilterWidth;
        }
      }

      if (width !== this.state.width) {
        this.setState({
          width,
        });
      }
    }
  };
  adjustPanelSize = debounce(this.adjustPanelSize_, 150);

  get isInline() {
    return this.props.mode === MODES.INLINE;
  }

  get isDropdown() {
    return this.props.mode === MODES.DROPDOWN;
  }

  get controlledValue() {
    return this.props.value !== undefined;
  }

  get applyImmediately() {
    // ON_CLOSE is invalid for non-dropdown filter panels, it is silently converted to IMMEDIATE
    return (
      this.props.apply === APPLY.IMMEDIATE ||
      (!this.isDropdown && this.props.apply === APPLY.ON_CLOSE)
    );
  }

  get applyOnClose() {
    return this.isDropdown && this.props.apply === APPLY.ON_CLOSE;
  }

  get applyWithButton() {
    return this.props.apply === APPLY.BUTTON;
  }

  get value() {
    if (this.controlledValue) {
      return this.props.value;
    }

    return this.state.value;
  }

  get fieldKeys() {
    return Children.map(this.props.children, (child) =>
      get(child, 'props.fieldKey')
    ).filter((fieldKey) => fieldKey);
  }

  isValidValue = (value) => {
    if (isObject(value)) {
      return Boolean(Object.keys(value).length);
    }

    if (isArray(value)) {
      return Boolean(value.length);
    }

    return value !== undefined && value !== null;
  };

  getClearValue = (values) => {
    const fieldKeys = this.fieldKeys;
    return Object.entries(values || {})
      .filter(([k, v]) => fieldKeys.includes(k) && this.isValidValue(v))
      .reduce(
        (cleanedValues, [k, v]) => ({
          ...cleanedValues,
          [k]: v,
        }),
        {}
      );
  };

  get hasValues() {
    return Boolean(Object.values(this.getClearValue(toJS(this.value))).length);
  }

  get dirtyValue() {
    return {
      ...this.value,
      ...this.state.unappliedChanges,
    };
  }

  get isDirty() {
    return Boolean(Object.keys(this.state.unappliedChanges || {}).length);
  }

  onChange = (values, apply = false, close = false) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        unappliedChanges: {
          ...prevState.unappliedChanges,
          ...values,
        },
      }),
      apply || this.applyImmediately ? () => this.apply(close) : undefined
    );
  };
  onFieldChange = (fieldKey, value, apply = false, close = false) => {
    this.onChange(
      {
        [fieldKey]: value,
      },
      apply,
      close
    );
  };

  apply = (close = false, forceValue = null) => {
    const { onChange } = this.props;
    const newValue = this.getClearValue(
      forceValue === null ? this.dirtyValue : forceValue
    );

    const callback = () => {
      if (this.isDropdown && close) {
        this.onVisibleChange(false);
      }
      if (!this.controlledValue && onChange) {
        onChange(newValue);
      }
    };

    if (this.controlledValue && onChange) {
      onChange(newValue);
      this.setState(
        {
          unappliedChanges: null,
        },
        callback
      );
    } else if (!this.controlledValue) {
      this.setState(
        {
          value: newValue,
          unappliedChanges: null,
        },
        callback
      );
    }
  };
  applyAndClose = () => this.apply(true);

  clearValues = (close = false) => {
    this.apply(close, {});
  };
  clearAndClose = () => this.clearValues(true);

  cancel = (close = false) => {
    const callback =
      this.isDropdown && close ? () => this.onVisibleChange(false) : undefined;
    this.setState(
      {
        unappliedChanges: null,
      },
      callback
    );
  };
  cancelAndClose = () => this.cancel(true);

  getColors = (filterNodes) => {
    const colors = {};
    const newColors = {};
    const colorsCount = Object.keys(COLORS).filter(
      (k) => !isNumber(k) && k !== '__default'
    ).length;

    Children.forEach(filterNodes, (filterNode) => {
      if (!this.isValidFilterNode(filterNode)) {
        return;
      }
      const key = filterNode.props.fieldKey;
      if (filterNode.props.titleColor) {
        colors[key] = filterNode.props.titleColor;
      } else if (this.assignedColors[key]) {
        colors[key] = this.assignedColors[key];
      }

      if (!colors[key]) {
        const colorIdx = Object.keys(this.assignedColors).length % colorsCount;
        newColors[key] = COLORS[colorIdx];
      }
    });

    if (Object.keys(newColors).length) {
      this.assignedColors = {
        ...this.assignedColors,
        ...newColors,
      };
    }

    return {
      ...colors,
      ...newColors,
    };
  };

  getTitle = (title, [backgroundColor, color]) => (
    <Pill
      className={`${clsPrefix}__filter-title`}
      {...{
        backgroundColor,
        color,
      }}
    >
      {title}
    </Pill>
  );

  isValidFilterNode = (node) => {
    // TODO show error if not undefined but has no fieldKey/is not a valid component
    return Boolean(get(node, 'props.fieldKey'));
  };

  handleAddFormLibrary = () => {
    const { ui, features, account } = this.props;
    openProvidersModal({
      ui,
      features,
      account,
      analyticsContext: { productPath: 'Form Library Modal' },
    });
  };

  get appliedCount() {
    const { showAppliedCount, mode } = this.props;
    if (!showAppliedCount) {
      return null;
    }

    const value = this.value;
    const count = this.fieldKeys.reduce((totalCount, fieldKey) => {
      const v = toJS(value?.[fieldKey]);
      if (!v) {
        return totalCount;
      }

      if (isArray(v)) {
        return totalCount + v.length;
      }

      return totalCount + 1;
    }, 0);

    return (
      Boolean(count) && (
        <AppLabel
          className={`${clsPrefix}__applied-count ${clsPrefix}__applied-count--${mode}`}
          color={AppLabel.COLORS.PRIMARY}
        >
          {count}
        </AppLabel>
      )
    );
  }

  renderPanelTitle({ showApplyWithButton = true } = {}) {
    const {
      title,
      titleWrapProps,
      allowClear,
      ui,
      hideAssociations,
      features,
    } = this.props;
    const showAddFormLibrary =
      ui.isEmbedded &&
      !hideAssociations &&
      features.membershipVerificationEnabled;

    return (
      <div
        {...titleWrapProps}
        className={classNames(
          `${clsPrefix}__title-wrap`,
          get(titleWrapProps, 'className')
        )}
      >
        <div className={`${clsPrefix}__flex-wrap`}>
          <div className={`${clsPrefix}__title-container`}>
            <div className={`${clsPrefix}__title`}>{title}</div>
            {(this.isInline || (this.isDropdown && ui.isMobileSize)) &&
              this.appliedCount}
            {this.hasValues && allowClear && (
              <AnchorButton
                className={`${clsPrefix}__clear-all`}
                onClick={this.clearAndClose}
                type="primary"
              >
                Clear All
              </AnchorButton>
            )}
            {this.isInline && this.appliedValues}
          </div>
          {showApplyWithButton && this.applyWithButton && this.isDirty && (
            <div className={`${clsPrefix}__apply-btn-container`}>
              <AnchorButton
                className={`${clsPrefix}__cancel`}
                type="danger"
                onClick={this.cancelAndClose}
              >
                Cancel
              </AnchorButton>
              <AppButton
                className={`${clsPrefix}__apply`}
                type="primary"
                size="small"
                onClick={this.applyAndClose}
              >
                Apply
              </AppButton>
            </div>
          )}
        </div>

        {showAddFormLibrary && (
          <AppButton
            className={`${clsPrefix}__add-form-library`}
            onClick={this.handleAddFormLibrary}
          >
            <PlusOutlined /> Add form library
          </AppButton>
        )}
      </div>
    );
  }

  renderPanelBody() {
    const { filtersPanelProps, children } = this.props;
    const colors = this.getColors(children);
    return (
      <div
        {...filtersPanelProps}
        className={classNames(
          `${clsPrefix}__filters-panel`,
          get(filtersPanelProps, 'className')
        )}
      >
        {Children.map(children, (child) => {
          return (
            this.isValidFilterNode(child) && (
              <div
                key={child.props.fieldKey}
                className={`${clsPrefix}__filter-container`}
              >
                {augmentChildren(child, {
                  title: this.getTitle(
                    child.props.title,
                    colors[child.props.fieldKey]
                  ),
                  titleColor: undefined,
                  onChange: partial(this.onFieldChange, child.props.fieldKey),
                  value: this.dirtyValue[child.props.fieldKey] || null,
                  context: this.dirtyValue,
                })}
              </div>
            )
          );
        })}
      </div>
    );
  }

  renderPanel() {
    const { className, mode } = this.props;
    const { width } = this.state;

    return (
      <div
        className={classNames(
          clsPrefix,
          `${clsPrefix}--${mode.toLowerCase()}`,
          className
        )}
        style={
          this.isDropdown && width
            ? {
                width: `${width}px`,
              }
            : undefined
        }
      >
        {this.renderPanelTitle()}
        {this.renderPanelBody()}
      </div>
    );
  }

  decodeFieldTitle = (fieldKey) => {
    const filter = Children.map(
      this.props.children,
      (child) => get(child, 'props.fieldKey') === fieldKey && child
    ).find((c) => c);
    if (filter && filter.props.title) {
      return filter.props.title;
    }
    return fieldKey;
  };

  decodeValue = (fieldKey, value) => {
    const filter = Children.map(
      this.props.children,
      (child) => get(child, 'props.fieldKey') === fieldKey && child
    ).find((c) => c);
    if (filter) {
      if (filter.props.options) {
        const options = isFunction(filter.props.options)
          ? filter.props.options(this.value)
          : filter.props.options;
        const valueMeta = options.reduce(
          (optionsByVal, opt, idx) => ({
            ...optionsByVal,
            [opt.value]: {
              label: opt.label,
              sort: idx,
            },
          }),
          {}
        )[value];
        if (valueMeta) {
          return valueMeta;
        }
      }
    }

    return {
      label: value,
      sort: 0,
    };
  };

  get showAppliedValues() {
    const { showAppliedValues, ui } = this.props;
    if (showAppliedValues !== undefined) {
      return showAppliedValues;
    }

    // Dynamic default value
    return [
      [ui.isMobileSize, 2],
      [ui.isSmallerThanMdSize, 3],
      [ui.isSmallerThanLgSize, 4],
      [true, 5],
    ].find(([match]) => match)[1];
  }

  get appliedValues() {
    const showAppliedValues = this.showAppliedValues;
    if (!showAppliedValues || !this.hasValues) {
      return null;
    }

    const value = this.getClearValue(this.value);
    const colors = this.getColors(this.props.children);

    const values = chain(
      Object.entries(value)
        .map(([fieldKey, fieldValues], idx) => {
          const [backgroundColor, color] = colors[fieldKey];
          const title = this.decodeFieldTitle(fieldKey);
          return (isArray(fieldValues) ? fieldValues : [fieldValues]).map(
            (val) => {
              const { label, sort: valueSort } = this.decodeValue(
                fieldKey,
                val
              );
              return {
                key: `${fieldKey}:${val}`,
                title: `${title}: ${label}`,
                label,
                backgroundColor,
                color,
                sortKey: idx * 10000 + valueSort,
              };
            }
          );
        })
        .flat()
    )
      .sortBy('label')
      .sortBy('sortKey')
      .value();

    const cap = isNumber(+showAppliedValues) && +showAppliedValues;
    const capped = cap && values.length > cap;

    return values.length ? (
      <div className={`${clsPrefix}__applied-values`}>
        {values
          .slice(0, capped ? cap - 1 : undefined)
          .concat(
            capped
              ? [
                  {
                    key: 'more',
                    title: (
                      <div>
                        {values.slice(cap - 1).map(({ key, title }) => (
                          <div key={key}>{title}</div>
                        ))}
                      </div>
                    ),
                    label: `+${values.length - cap + 1} more`,
                    backgroundColor: COLORS.__default[0],
                    color: COLORS.__default[1],
                  },
                ]
              : []
          )
          .map(({ key, title, label, backgroundColor, color }) => (
            <Pill
              key={key}
              title={title}
              className={`${clsPrefix}__applied-value-pill`}
              {...{
                backgroundColor,
                color,
              }}
            >
              {label}
            </Pill>
          ))}
      </div>
    ) : null;
  }

  renderDropdownButton = () => {
    const { dropdownButtonProps = {}, dropdownButtonLabel } = this.props;
    return (
      <div className={`${clsPrefix}__dropdown-button-container`}>
        <AppButton
          {...dropdownButtonProps}
          className={classNames(
            `${clsPrefix}__dropdown-button`,
            get(dropdownButtonProps, 'className'),
            {
              [`${clsPrefix}__dropdown-button--active`]: this.visible,
            }
          )}
          innerRef={this.dropdownButtonRef}
          onClick={(event) => {
            if (dropdownButtonProps.onClick) {
              dropdownButtonProps.onClick(event);
            }
            this.onVisibleChange(!this.visible);
          }}
        >
          <AppIcon
            className={classNames(`${clsPrefix}__icon`, {
              [`${clsPrefix}__icon--with-label`]: Boolean(dropdownButtonLabel),
            })}
            name="sliders"
          />
          {dropdownButtonLabel}
          {this.appliedCount}
        </AppButton>
        {this.appliedValues}
      </div>
    );
  };

  get visibilityControlled() {
    return this.props.visible !== undefined;
  }

  get visible() {
    if (!this.isDropdown) {
      return true;
    }

    if (this.visibilityControlled) {
      return Boolean(this.props.visible);
    }

    return this.state.visible;
  }

  onVisibleChange = (visible) => {
    const { onVisibleChange } = this.props;
    if (this.visibilityControlled && onVisibleChange) {
      onVisibleChange(visible);
    }
    if (!this.visibilityControlled) {
      this.setState(
        {
          visible,
        },
        onVisibleChange ? () => onVisibleChange(visible) : undefined
      );
    }
    if (!visible && this.isDropdown && this.isDirty && this.applyOnClose) {
      this.apply();
    }
  };

  renderModal() {
    return (
      <AppModal
        wrapClassName={`${clsPrefix}__modal`}
        visible={this.visible}
        showCancelButton={this.applyWithButton && this.isDirty}
        okText={this.applyWithButton && this.isDirty ? 'Apply' : 'Done'}
        onCancel={() =>
          this.applyWithButton && this.isDirty
            ? this.cancelAndClose()
            : this.onVisibleChange(false)
        }
        onOk={() =>
          this.applyWithButton && this.isDirty
            ? this.applyAndClose()
            : this.onVisibleChange(false)
        }
        {...this.props.modalProps}
      >
        {this.renderPanelTitle({
          showApplyWithButton: false,
        })}
        {this.renderPanelBody()}
      </AppModal>
    );
  }

  render() {
    const { mode, trigger, ui } = this.props;

    if (this.isInline) {
      return this.renderPanel();
    }

    if (this.isDropdown) {
      if (ui.isMobileSize) {
        return (
          <Fragment>
            {this.renderDropdownButton()}
            {this.renderModal()}
          </Fragment>
        );
      }
      return (
        <Dropdown
          className={`${clsPrefix}__dropdown`}
          trigger={trigger}
          overlay={this.renderPanel()}
          visible={this.visible}
          onVisibleChange={this.onVisibleChange}
          placement="bottomLeft"
        >
          {this.renderDropdownButton()}
        </Dropdown>
      );
    }

    throw new Error(`Unknown <AppFiltersPanel /> mode: ${mode}.`);
  }
}

Object.entries(FILTERS_COMPONENTS).forEach(([k, filterComp]) => {
  AppFiltersPanel[k] = filterComp;
});

export default AppFiltersPanel;
