import Avid from './avid';
import Default from './default';
import DefaultSection from './default-section';
import Esign from './esign';
import Gfp from './gfp';
import OrderNhd from './order-nhd';
import SharePacketDeprecated from './share-packet-deprecated';
import SignatureRequest from './signature-request';
import StartPacketSharing from './start-packet-sharing';
import ZipformDocs from './zipform-docs';
import analytics from 'src/analytics';

const TASK_TYPES = {
  DEFAULT: Default,
  DEFAULT_SECTION: DefaultSection,
  GFP: Gfp,
  SIGNATURE_REQUEST: SignatureRequest,
  SHARE_PACKET: SharePacketDeprecated,
  AVID: Avid,
  IMPORT_ZIPFORM_DOCS: ZipformDocs,
  ESIGN: Esign,
  START_PACKET_SHARING: StartPacketSharing,
  ORDER_NHD: OrderNhd,
};

const getTaskType = (type) => TASK_TYPES[type] || TASK_TYPES.DEFAULT;

export default getTaskType;

export const handleOnClick = ({
  task,
  defaultAction,
  event,
  router,
  transactions,
  mode,
  ui,
  store,
}) => {
  const type = getTaskType(task.type);
  analytics().track('Clicked Task', {
    id: task.id,
    type: task.type,
  });
  if (type.onClick) {
    return type.onClick({
      task,
      transactions,
      event,
      router,
      mode,
      ui,
      store,
      transaction: task.transaction,
    });
  }
  return defaultAction();
};
