import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type GuidedFormPacketFlowJson =
  TransactionItemJson<'GUIDED_FORM_PACKET_FLOW'>;

export default class GuidedFormPacketFlow extends Item<'GUIDED_FORM_PACKET_FLOW'> {
  constructor(
    store: ItemStore,
    json: TransactionItemJson<'GUIDED_FORM_PACKET_FLOW'>
  ) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get flowId() {
    return this.kindItem.flowId;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get document() {
    return this.kindItem.generatedDocument;
  }

  @computed
  get tdId() {
    return this.kindItem.tdId;
  }

  @computed
  get tdvId() {
    return this.kindItem.tdvId;
  }

  @computed
  get flow() {
    return this.kindItem.flow;
  }

  @computed
  get order() {
    return this.kindItem.order;
  }

  @computed
  get gfpId() {
    return this.inEdges
      .filter((e) => e.kind === 'GFP_HAS_GFPF')
      .map((e) => e.item1Id)[0];
  }

  @computed
  get gfp() {
    return this.store.itemsById.get(this.gfpId);
  }

  @computed
  get hasSubmissionErrors() {
    return !isEmpty(this.kindItem.submissionErrors);
  }

  @computed
  get clientStatus() {
    const CLIENT_TAG_BY_STATUS = {
      CREATED: '',
      STARTED: '',
      FLOW_COMPLETED: 'Edit',
      SUBMITTED: 'Edit',
      FLOW_EDITED: 'Edit',
      FLOW_EDITED_INCOMPLETE: 'Edit',
    };
    type ClientTagByStatusKeys = keyof typeof CLIENT_TAG_BY_STATUS;
    return CLIENT_TAG_BY_STATUS[this.status as ClientTagByStatusKeys] || '';
  }

  @computed
  get adminStatus() {
    const AGENT_TAG_BY_STATUS = {
      CREATED: 'SENT',
      STARTED: 'STARTED',
      FLOW_COMPLETED: 'COMPLETED',
      SUBMITTED: 'SUBMITTED',
      FLOW_EDITED: 'STARTED',
      FLOW_EDITED_INCOMPLETE: 'STARTED',
    };
    type AgentTagByStatusKeys = keyof typeof AGENT_TAG_BY_STATUS;
    return AGENT_TAG_BY_STATUS[this.status as AgentTagByStatusKeys] || '';
  }

  @computed
  get clientStatusColor() {
    const COLOR_BY_STATUS = {
      CREATED: undefined,
      STARTED: undefined,
      FLOWS_COMPLETED: undefined,
      FLOWS_EDITED: undefined,
      SUBMITTED: 'blue',
    };
    type ColorByStatusKeys = keyof typeof COLOR_BY_STATUS;
    return COLOR_BY_STATUS[this.status as ColorByStatusKeys];
  }

  @computed
  get adminStatusColor() {
    return this.clientStatusColor;
  }

  can(op: string) {
    if (op === 'agent_download') {
      return !!this.document;
    }
    if (op === 'client_download') {
      if (this.gfp.creationMode === 'CLIENT') {
        return false;
      }
      return !!(
        (this.status === 'FLOW_COMPLETED' || this.status === 'SUBMITTED') &&
        this.document
      );
    }
    if (op === 'update_response') {
      return !!(
        this.status === 'FLOW_COMPLETED' ||
        this.status === 'SUBMITTED' ||
        this.status === 'FLOW_EDITED'
      );
    }
    if (op === 'submit') {
      return this.status === 'FLOW_EDITED' || this.status === 'FLOW_COMPLETED';
    }
    if (op === 'preview') {
      return !get(this.kindItem, 'formFlowConfig.params.formless');
    }
    return false;
  }

  // leaving this property as is, this is hard to name as can operation
  // without reducing readability by a lot
  @computed
  get isFormComplete() {
    return !(
      this.status === 'CREATED' ||
      this.status === 'STARTED' ||
      this.status === 'FLOW_EDITED_INCOMPLETE'
    );
  }

  @computed
  get isPremium() {
    return this.kindItem.isPremium;
  }
}
