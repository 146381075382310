import { FC, SVGProps } from 'react';
import * as icons from './icons';
import type { TreeNodeInterface } from './tree-node';
import {
  NodeComponentType,
  TreeNodeCategoryType,
  TreeNodeTypeByComponentType,
} from './types';

export interface ComponentCategoryItem {
  name: string;
  category: TreeNodeCategoryType;
  hiddenInLibrary?: boolean;
}

export const componentCategoryItems: ComponentCategoryItem[] = [
  {
    name: 'Form Structure',
    category: 'container',
  },
  {
    name: 'PDF Component',
    category: 'field',
  },
  {
    name: 'Other Components',
    category: 'other',
  },
];

export interface ComponentConfigItemType<T extends NodeComponentType = any> {
  type: T;
  name: string;
  category: TreeNodeCategoryType;
  acceptableChildTypes?: NodeComponentType[];
  icon: FC<SVGProps<SVGElement>> | string;
  schema: TreeNodeInterface;
  template: TreeNodeTypeByComponentType<T>;
  hiddenInLibrary?: boolean;
}

export const componentConfigItems: ComponentConfigItemType[] = [
  {
    type: 'Form',
    name: 'Form',
    category: 'container',
    acceptableChildTypes: ['Section'],
    icon: icons.Form,
    schema: {
      type: 'void',
    },
    template: {
      component: 'Form',
      componentProps: {
        title: 'Form',
      },
      children: [],
    },
    hiddenInLibrary: true,
  },

  {
    type: 'Section',
    name: 'Section',
    category: 'container',
    acceptableChildTypes: ['Term'],
    icon: icons.Section,
    schema: {
      type: 'void',
    },
    template: {
      component: 'Section',
      componentProps: {
        title: 'Section',
      },
      children: [],
    },
  },

  {
    type: 'Term',
    name: 'Term',
    category: 'container',
    acceptableChildTypes: [
      'ShortAnswer',
      'LongAnswer',
      'SingleCheckbox',
      'MultiCheckbox',
      'RadioGroup',
      'DynamicExplanation',
      'SmartList',
      'Description',
      'TitlePage',
      'PageBreak',
      'LegalDisclaimer',
      'Currency',
      'Numeric',
    ],
    icon: icons.Term,
    schema: {
      type: 'void',
    },
    template: {
      component: 'Term',
      componentProps: {
        title: 'Term',
      },
      children: [],
    },
  },

  {
    type: 'ShortAnswer',
    name: 'Short answer',
    category: 'field',
    icon: icons.ShortAnswer,
    schema: {
      type: 'void',
    },
    template: {
      component: 'ShortAnswer',
      componentProps: {
        title: 'Short Answer',
      },
    },
  },

  {
    type: 'LongAnswer',
    name: 'Long answer',
    category: 'field',
    icon: icons.LongAnswer,
    schema: {
      type: 'void',
    },
    template: {
      component: 'LongAnswer',
      componentProps: {
        title: 'Long Answer',
      },
    },
  },

  {
    type: 'SingleCheckbox',
    name: 'Single checkbox',
    category: 'field',
    icon: icons.SingleCheckbox,
    schema: {
      type: 'void',
    },
    template: {
      component: 'SingleCheckbox',
      componentProps: {
        title: 'Single Checkbox',
        checkedOption: 'Yes',
        uncheckedOption: 'No',
      },
    },
  },

  {
    type: 'MultiCheckbox',
    name: 'Multi-checkbox',
    category: 'field',
    icon: icons.MultiCheckbox,
    schema: {
      type: 'void',
    },
    template: {
      component: 'MultiCheckbox',
      componentProps: {
        title: 'Multi-Checkbox',
        options: [
          {
            label: 'Option 1',
          },
          {
            label: 'Option 2',
          },
        ],
      },
    },
  },

  {
    type: 'RadioGroup',
    name: 'Radio group',
    category: 'field',
    icon: icons.RadioGroup,
    schema: {
      type: 'void',
    },
    template: {
      component: 'RadioGroup',
      componentProps: {
        title: 'Radio Group',
        options: [
          {
            label: 'Option 1',
          },
          {
            label: 'Option 2',
          },
        ],
      },
    },
  },

  {
    type: 'DynamicExplanation',
    name: 'Dynamic explanation',
    category: 'field',
    icon: icons.DynamicExplanation,
    schema: {
      type: 'void',
    },
    template: {
      component: 'DynamicExplanation',
      componentProps: {
        title: 'Dynamic Explanation',
        links: [],
      },
    },
  },

  {
    type: 'Currency',
    name: 'Currency',
    category: 'field',
    icon: icons.Currency,
    schema: {
      type: 'void',
    },
    template: {
      component: 'Currency',
      componentProps: {
        title: 'Currency',
      },
    },
  },

  {
    type: 'Numeric',
    name: 'Numeric',
    category: 'field',
    icon: icons.Numeric,
    schema: {
      type: 'void',
    },
    template: {
      component: 'Numeric',
      componentProps: {
        title: 'Numeric',
        subType: 'INTEGER',
      },
    },
  },

  {
    type: 'SmartList',
    name: 'Smart list',
    category: 'field',
    icon: icons.SmartList,
    schema: {
      type: 'void',
    },
    template: {
      component: 'SmartList',
      componentProps: {
        title: 'Smart List',
      },
    },
    hiddenInLibrary: true,
  },

  {
    type: 'Description',
    name: 'Description',
    category: 'other',
    icon: icons.Description,
    schema: {
      type: 'void',
    },
    template: {
      component: 'Description',
      componentProps: {
        title: 'Description',
      },
    },
  },

  {
    type: 'TitlePage',
    name: 'Title page',
    category: 'other',
    icon: icons.TitlePage,
    schema: {
      type: 'void',
    },
    template: {
      component: 'TitlePage',
      componentProps: {
        title: 'Title Page',
        subTitle: '',
      },
    },
  },

  {
    type: 'PageBreak',
    name: 'Page break',
    category: 'other',
    icon: icons.PageBreak,
    schema: {
      type: 'void',
    },
    template: {
      component: 'PageBreak',
      componentProps: {
        title: 'Page break',
      },
    },
  },

  {
    type: 'LegalDisclaimer',
    name: 'Legal Text',
    category: 'other',
    icon: icons.LegalDisclaimer,
    schema: {
      type: 'void',
    },
    template: {
      component: 'LegalDisclaimer',
      componentProps: {
        title: 'Legal Text',
        header: '',
        description: '',
        disclaimer: '',
        footer: '',
      },
    },
  },
];

export const componentConfig: Record<
  NodeComponentType,
  ComponentConfigItemType
> = componentConfigItems.reduce(
  (config, item) => ({
    ...config,
    [item.type]: item,
  }),
  {} as any
);

export function getComponentConfigItemsByCategory(
  category: TreeNodeCategoryType
): ComponentConfigItemType[] {
  return componentConfigItems.filter(
    (item) => !item.hiddenInLibrary && item.category === category
  );
}
