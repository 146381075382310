import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskAction from './action';
import navigateClick from 'src/utils/navigate-click';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import { createGfpCreationFlow } from 'src/models/transactions/intents';
import { inject, observer } from 'mobx-react';

export const viewTask = async ({
  event,
  router,
  task,
  transactions,
  ui,
  ffcIds,
  path,
}) => {
  const gfp = task.members.find((i) => i.kind === 'GUIDED_FORM_PACKET');
  if (!gfp) {
    let flowId;
    if (task.task.gfp && task.task.gfp.flowId) {
      flowId = task.task.gfp.flowId;
    } else {
      let resp;
      try {
        resp = await transactions.dispatch(
          task.transactionId,
          createGfpCreationFlow({
            taskId: task.id,
            ffcIds,
            redirectToRoute: ui.isEmbedded ? router.route.name : undefined,
          })
        );
      } catch (err) {
        ui.wentWrong(err);
        return;
      }

      flowId = resp.result.flow_id;
    }
    navigateToFlow(router, flowId, { path, search: { ffc_ids: ffcIds } });
    return;
  }

  if (
    task.isClientVisibleItem &&
    task.can('actAsClient') &&
    !task.can('actAsAdmin') &&
    task.type === 'GFP'
  ) {
    navigateClick(event, router, 'transactions.transaction.packets.packet', {
      transactionId: task.transactionId,
      packetId: gfp.packetId,
    });
  } else {
    const navigateToRoute = `transactions.transaction.${
      ui.isEmbedded ? 'documents.task' : 'items.item'
    }`;
    navigateClick(event, router, navigateToRoute, {
      transactionId: task.transactionId,
      taskId: task.id,
    });
  }
};

@inject('router', 'ui')
@observer
export default class ViewGfp extends Component {
  static propTypes = {
    task: PropTypes.object,
    transactions: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    ui: PropTypes.object.isRequired,
  };

  handleClick = async () => {
    const { task, router, transactions, ui } = this.props;
    viewTask({
      router,
      transactions,
      task,
      ui,
    });
  };

  render() {
    const { task } = this.props;
    const gfp = task.members.find((i) => i.kind === 'GUIDED_FORM_PACKET');
    let cta;
    if (gfp) {
      cta = 'View';
    } else if (task.task.gfp.status === 'NOT_STARTED') {
      cta = 'Start';
    } else {
      cta = 'Continue';
    }
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        {cta}
      </TaskAction>
    );
  }
}
