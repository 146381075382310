import React, { Component } from 'react';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import { getOrCreateInitialAvidFlow } from 'src/models/transactions/intents';
import navigateClick from 'src/utils/navigate-click';
import TaskAction from './action';

export const viewTask = async ({ event, router, task, transactions }) => {
  if (!get(task, 'task.avid.avidId')) {
    let flowId = task.task.avid && task.task.avid.preFlowId;

    if (!flowId) {
      const resp = await transactions.dispatch(
        task.transactionId,
        getOrCreateInitialAvidFlow({
          taskId: task.id,
        })
      );
      flowId = resp.result.flow_id;
    }
    navigateToFlow(router, flowId);
  } else {
    navigateClick(event, router, 'transactions.transaction.items.item', {
      transactionId: task.transactionId,
      taskId: task.id,
    });
  }
};

@inject('router')
@observer
export default class ViewAvid extends Component {
  static propTypes = {
    task: PropTypes.object,
    transactions: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  handleClick = async () => {
    const { task, router, transactions } = this.props;

    viewTask({
      router,
      transactions,
      task,
    });
  };

  render() {
    const { task } = this.props;
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        {task.isNotStarted ? 'Start' : 'View'}
      </TaskAction>
    );
  }
}
