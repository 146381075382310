import type {
  AnswerSubType,
  NumericSubType,
} from 'src/stores/form-builder-store/types';
import {
  type Annotation,
  AnnotationKind,
  type Field,
} from 'src/types/proto/reform';

export interface AnnotationNameOptions {
  kind?: boolean;
  name?: boolean;
  exportValue?: boolean;
}

export function getAnnotationLabel(
  annotation: Annotation,
  options: AnnotationNameOptions = {}
): string {
  const { kind = true, name = true, exportValue = true } = options;
  const label: string[] = [];
  if (kind) {
    label.push(annotation.kind);
  }
  if (name) {
    label.push(annotation.name || annotation.fieldId || annotation.id);
  }
  if (exportValue && annotation.exportValue) {
    label.push(annotation.exportValue as any);
  }
  return label.join('_');
}

/**
 * Annotation kinds can be used in form builder, unused kinds are commented out
 * in case some of them can be used in the future
 */
const formBuilderAnnotationKinds: AnnotationKind[] = [
  AnnotationKind.TEXT,
  AnnotationKind.CHECKBOX,
  AnnotationKind.RADIO,
  AnnotationKind.SECTION_ZONE,
];

export function filterAnnotationList(
  list: Annotation[],
  kind?: AnnotationKind | AnnotationKind[]
): Annotation[] {
  return list.filter((item) => {
    if (!kind) {
      return formBuilderAnnotationKinds.includes(item.kind);
    }
    if (Array.isArray(kind)) {
      return kind.some((k) => k === item.kind);
    }
    return kind === item.kind;
  });
}

type IdCache = Map<string, Annotation>;

const caches = new WeakMap<Annotation[], IdCache>();

export function getAnnotationCache(list: Annotation[]): IdCache {
  if (!caches.has(list)) {
    const cache = new Map();
    list.forEach((item) => {
      cache.set(item.id, item);
    });
    caches.set(list, cache);
  }
  return caches.get(list) as IdCache;
}

export function findAnnotationById(
  list: Annotation[],
  id?: string
): Annotation | undefined {
  if (!id) {
    return undefined;
  }
  const cache = getAnnotationCache(list);
  return cache.get(id);
}

export function getAnswerSubtype(field?: Field): AnswerSubType | undefined {
  if (!field) {
    return undefined;
  }
  switch (field.kind) {
    case 'date':
      return 'DATE';
    case 'time':
      return 'TIME';
    default:
      return 'TEXT';
  }
}

export function getNumericSubtype(field?: Field): NumericSubType | undefined {
  if (!field) {
    return undefined;
  }
  if (field.kind.includes('integer')) {
    return 'INTEGER';
  }
  return 'DECIMAL';
}

export function getRadioAnnotationByExportValue(
  list: Annotation[],
  fieldId?: string,
  exportValue?: string
) {
  return list.find(
    (a) => a.fieldId === fieldId && a.exportValue === exportValue
  );
}
