import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import { type UserContact as UserContactJson } from 'src/types/proto/auth';
import getFullName from 'src/utils/get-full-name';

export default class UserContact extends Model<UserContactJson> {
  constructor(json: UserContactJson) {
    super(json);

    makeObservable(this);
  }

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get email() {
    return this.data.contact?.email || '';
  }

  @computed
  get firstName() {
    return this.data.contact?.firstName || '';
  }

  @computed
  get lastName() {
    return this.data.contact?.lastName || '';
  }

  @computed
  get fullName() {
    return getFullName({
      firstName: this.firstName,
      lastName: this.lastName,
    });
  }
}
