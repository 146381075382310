import { computed, makeObservable } from 'mobx';
import Item, {
  type ItemStore as CoverSheetStore,
  type TransactionItemJson,
} from './item';

export type CoverSheetJson = TransactionItemJson<'COVER_SHEET'>;

export default class CoverSheet extends Item<'COVER_SHEET'> {
  constructor(store: CoverSheetStore, json: CoverSheetJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get useBrokerageEditionLogo() {
    return this.kindItem.useBrokerageEditionLogo;
  }

  @computed
  get instructions() {
    return this.kindItem.instructions;
  }

  @computed
  get signatures() {
    return this.kindItem.signatures;
  }

  @computed
  /** @deprecated never used */
  get content() {
    return this.kindItem.content;
  }

  @computed
  get includeEscrow() {
    return this.kindItem.includeEscrow;
  }

  @computed
  get includeCoverPhoto() {
    return this.kindItem.includeCoverPhoto;
  }
}
