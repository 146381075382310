import { computed, makeObservable } from 'mobx';
import type EnvelopeStore from 'src/stores/envelope-store';
import Item, { EnvelopeItemJson } from './item';

export type EnvelopeRecipientJson = EnvelopeItemJson<'ENVELOPE_RECIPIENT'>;

export default class EnvelopeRecipient extends Item<'ENVELOPE_RECIPIENT'> {
  constructor(store: EnvelopeStore, json: EnvelopeRecipientJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get contact() {
    return this.kindItem.contact;
  }

  @computed
  get index() {
    return this.kindItem.index;
  }

  @computed
  get email() {
    return this.contact?.email;
  }
}
