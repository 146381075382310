
import keyBy from 'lodash/keyBy';
import { action, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
import { getFetch } from 'src/utils/get-fetch';

export default class ZipformTransactionsStore {
  DEFAULT_CACHE_VALID_SECONDS = 300; // By default transaction data is considered valid for 5 minutes
  @observable transactionsById = {};

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.integrations;
  }

  @action
  initialize() {}

  @action
  updateTransactionsById(jsonTransactionsById) {
    const __fetchedAt = moment();
    this.transactionsById = {
      ...this.transactionsById,
      ...Object.entries(jsonTransactionsById).reduce(
        (all, [id, txn]) => ({
          ...all,
          [id]: {
            ...txn,
            __fetchedAt,
          },
        }),
        {}
      ),
    };
  }

  @action
  getOrFetchTransaction = async (transactionId, tokenId, options) => {
    const { force, maxAge = this.DEFAULT_CACHE_VALID_SECONDS } = options || {};
    let transaction = this.getFetchTransaction.get({
      transactionId,
    });
    if (
      transaction &&
      !force &&
      moment().diff(transaction.__fetchedAt, 'second') <= maxAge
    ) {
      return transaction;
    }
    transaction = await this.getFetchTransaction.fetch({
      transactionId,
      tokenId,
    });

    return transaction;
  };

  @action
  getOrFetchTransactionsList = async (tokenId, filterBy) => {
    const { data: transactions } = await this.api.zipformZfTransactionsList({
      tokenId,
      filterBy,
    });
    runInAction(() => {
      this.updateTransactionsById(keyBy(transactions, 'id'));
    });
    return transactions;
  };

  getFetchTransaction = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId }) => transactionId,
    getter: ({ transactionId }) => this.transactionsById[transactionId],
    fetcher: async ({ transactionId, tokenId }) => {
      const { data: transaction } = await this.api.zipformGetZfTransaction({
        tokenId,
        transactionId,
      });
      runInAction(() => {
        this.updateTransactionsById({
          [transaction.id]: transaction,
        });
      });
    },
  });

  @action
  create = async (transactionData) => {
    const { data: transaction } = await this.api.zipformZfTransactionsCreate(
      transactionData
    );
    runInAction(() => {
      this.updateTransactionsById({
        [transaction.id]: transaction,
      });
    });
    return transaction;
  };
}
