
import invariant from 'invariant';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import {
  action,
  computed,
  extendObservable,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';

const DELIM = '::::';

const BLANK_FIELD_KEY = '__BLANK_FIELD_KEY__';

function getKeyFnByFields(fields) {
  return function keyByFieldsFn(value) {
    return [
      fields.map((f) => (value[f] ? value[f] : BLANK_FIELD_KEY)).join(DELIM),
      pick(value, fields),
    ];
  };
}

class IndexIdListRecord {
  constructor(valueById, options, indexValues) {
    makeObservable(this);
    this.valueById = valueById;
    this._ids = observable(new Map());
    this.options = options;
    this.indexValues = indexValues;

    if (this.options.computed) {
      extendObservable(this, this.options.computed);
    }
  }

  @computed
  get all() {
    const values = map([...this._ids.keys()], (id) => this.valueById.get(id));
    if (!this.options.orderBy) {
      return values;
    }
    return sortBy(values, this.options.orderBy);
  }

  add(id) {
    if (this._ids.has(id)) {
      return false;
    }
    this._ids.set(id, true);
    return true;
  }

  remove(id) {
    if (!this._ids.has(id)) {
      return false;
    }

    this._ids.delete(id);
    return true;
  }
}

class Index {
  constructor(valueById, options) {
    makeObservable(this);
    this.valueById = valueById;
    this.idListByKey = observable(new Map());
    this.sawKeys = new Set();
    this.options = {
      orderBy: null,
        keyBy: null,
        computed: null,
      ...options
    };

    invariant(this.options.keyBy, 'keyBy option is required.');
    if (isArray(this.options.keyBy) && this.options.keyBy.length) {
      this.getKey = getKeyFnByFields(this.options.keyBy);
    } else if (isFunction(this.options.keyBy)) {
      this.getKey = (v) => [this.options.keyBy(v), {}];
    } else {
      throw new Error('Invalid keyBy option.');
    }
  }

  @action
  setIdListRecordAtKey(key, record) {
    this.idListByKey.set(key, record);
  }

  getOrCreateIdListRecordAtKey(key, indexValues) {
    if (!this.sawKeys.has(key)) {
      // We maintain a non-observable Set() to record which index records we've actually
      // created to avoid rerenders if this method is called in a tracked context.
      this.sawKeys.add(key);
      this.setIdListRecordAtKey(
        key,
        new IndexIdListRecord(this.valueById, this.options, indexValues)
      );
    }

    return this.idListByKey.get(key);
  }

  add(key, indexValues, id) {
    return this.getOrCreateIdListRecordAtKey(key, indexValues).add(id);
  }

  remove(key, indexValues, id) {
    return this.getOrCreateIdListRecordAtKey(key, indexValues).remove(id);
  }
}

export default class IndexedObservableMap {
  constructor(indicesOptions = {}) {
    makeObservable(this);
    this.valueById = observable(new Map());
    this.indices = new Map(
      map(indicesOptions, (options, k) => [
        k,
        new Index(this.valueById, options),
      ])
    );
  }

  indexed(indexName, keyValues) {
    const index = this.indices.get(indexName);
    return index.getOrCreateIdListRecordAtKey(...index.getKey(keyValues));
  }

  @action
  updateValue(id, updater) {
    const oldValue = this.valueById.get(id);
    let oldKeys;
    if (oldValue) {
      oldKeys = [];
      this.indices.forEach((index) => {
        oldKeys.push(index.getKey(oldValue));
      });
    }

    updater(oldValue);

    const newValue = this.valueById.get(id);
    let i = 0;
    this.indices.forEach((index) => {
      const [newKey, newValues] = newValue
        ? index.getKey(newValue)
        : [undefined, undefined];
      const [oldKey, oldValues] = (oldKeys && oldKeys[i]) || [
        undefined,
        undefined,
      ];

      i += 1;

      if (newKey === oldKey) {
        return;
      }

      if (oldKey) {
        index.remove(oldKey, oldValues, id);
      }

      if (newKey) {
        index.add(newKey, newValues, id);
      }
    });
  }

  has(id) {
    return this.valueById.has(id);
  }

  @action
  set(id, value) {
    let res;
    this.updateValue(id, () => {
      res = this.valueById.set(id, value);
    });
    return res;
  }

  @action
  delete(id) {
    let res;
    this.updateValue(id, () => {
      res = this.valueById.delete(id);
    });
    return res;
  }

  get(id) {
    return this.valueById.get(id);
  }

  keys() {
    return this.valueById.keys();
  }

  values() {
    return this.valueById.values();
  }

  entries() {
    return this.valueById.entries();
  }

  forEach(callback, thisArg) {
    return this.valueById.forEach(callback, thisArg);
  }

  clear() {
    runInAction(() => {
      this.keys().forEach(this.delete, this);
    });
  }

  get size() {
    return this.valueById.size;
  }

  toJS() {
    return this.valueById.toJS();
  }

  toJSON() {
    return this.valueById.toJSON();
  }
}
