import LayoutTermDelegate from './layout';
import { computed, makeObservable, override } from 'mobx';
import omit from 'lodash/omit';

class ExpressionEvaluator {
  constructor(value, expression) {
    this.value = value;
    this.expression = expression;
    this.REGEX = /^(?<op>[A-Z]+)\((?<args>.*)\)$/;
    this.EXPRESSION_OPS = {
      NONE: this.none,
      ALL: this.all,
      IN: this.in_,
      NOT: this.not,
      AND: this.and,
      OR: this.or,
    };
  }

  getArgValue = (arg) => {
    const isStringConst = arg.startsWith('"') && arg.endsWith('"');
    const isNumericConst = !Number.isNaN(+arg);
    const isBoolConst = ['true', 'false'].includes(arg.toLowerCase());
    const isNullConst = arg.toLowerCase() === 'null';
    const isUndefinedConst = arg.toLowerCase() === 'undefined';
    const isVariable = Object.keys(this.value).includes(arg.split('.')[0]);

    if (isStringConst) {
      return arg.slice(1, -1);
    } if (isNumericConst) {
      return +arg;
    } if (isBoolConst) {
      return arg.toLowerCase() === 'true';
    } if (isNullConst) {
      return null;
    } if (isUndefinedConst) {
      return undefined;
    } if (isVariable) {
      const parts = arg.split('.');
      let v = this.value[parts[0]];
      parts.slice(1).forEach((p) => {
        v = v?.[p];
      });
      return v;
    } if (this.parseExpression(arg)) {
      return new ExpressionEvaluator(this.value, arg).eval();
    }

    console.error(`Could not process arg: ${arg} with values `, this.value);
    return null;
  };

  none = (...args) => {
    return args.filter((a) => Boolean(this.getArgValue(a))).length === 0;
  };

  all = (...args) => {
    return (
      args.filter((a) => Boolean(this.getArgValue(a))).length === args.length
    );
  };

  in_ = (searched, ...args) => {
    if (searched === undefined) {
      return false;
    }
    const targets = new Set(args.map((a) => this.getArgValue(a)));
    return targets.has(this.getArgValue(searched));
  };

  not = (arg) => {
    return !this.getArgValue(arg);
  };

  and = (...args) => {
    return args.map((a) => this.getArgValue(a)).every((a) => a);
  };

  or = (...args) => {
    return args.map((a) => this.getArgValue(a)).some((a) => a);
  };

  parseExpression = (expression) => {
    try {
      const { op, args: args_ } = this.REGEX.exec(
        expression === undefined ? this.expression : expression
      ).groups;

      // Doesn't support escaping quotes
      const args = [];
      let parenthesisCount = 0;
      let inQuotes = false;
      let currArg = '';
      args_.split('').forEach((c, idx) => {
        if (c === '"') {
          inQuotes = !inQuotes;
        }

        if (!inQuotes && c === '(') {
          parenthesisCount += 1;
        } else if (!inQuotes && c === ')') {
          parenthesisCount -= 1;
        }

        if (parenthesisCount < 0) {
          console.error(
            `Unbalanced parentheses in expression: ${this.expression}.`
          );
        }

        if (inQuotes || parenthesisCount || c !== ',') {
          currArg += c;
        }
        if (
          !inQuotes &&
          !parenthesisCount &&
          (c === ',' || idx === args_.length - 1)
        ) {
          if (currArg && currArg.trim()) {
            args.push(currArg.trim());
          }
          currArg = '';
        }
      });

      return {
        op,
        args,
      };
    } catch (_err) {
      return {};
    }
  };

  eval = () => {
    const { op, args } = this.parseExpression();
    if (!op) {
      console.error(`Could not parse expression ${this.expression}.`);
      return null;
    } if (!this.EXPRESSION_OPS[op]) {
      console.error(`Unknown op: "${op}" in expression ${this.expression}.`);
      return null;
    }

    return this.EXPRESSION_OPS[op](...args);
  };
}

export default class ComboTermDelegate extends LayoutTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  evalExpression(expression, value_) {
    const value =
      (value_ !== undefined ? value_ : this.getCurrentValue()) || {};
    return new ExpressionEvaluator(value, expression).eval();
  }

  @computed
  get baseTermsWithData_() {
    const terms = this.term.terms;
    return this.term.kindData.terms.map((termData, idx) => [
      omit(termData, ['term']),
      terms[idx],
    ]);
  }

  getTermsWithData(v) {
    const terms = this.term.terms;
    const value = this.getValue(v, true);
    return this.term.kindData.terms.map((termData, idx) => [
      {
        ...omit(termData, [
          'isUnsetExpression',
          'isNaExpression',
          'isVisibleExpression',
        ]),
        isUnset: termData.isUnsetExpression
          ? this.evalExpression(termData.isUnsetExpression, value)
          : false,
        isNa: termData.isNaExpression
          ? this.evalExpression(termData.isNaExpression, value)
          : false,
        isVisible: termData.isVisibleExpression
          ? this.evalExpression(termData.isVisibleExpression, value)
          : true,
      },
      terms[idx],
    ]);
  }

  @computed
  get termsWithData() {
    return this.getTermsWithData(this.getCurrentValue());
  }

  @computed
  get termsByKey() {
    return this.termsWithData.reduce(
      (all, [{ key }, term]) => ({
        ...all,
        [key]: term,
      }),
      {}
    );
  }

  getTermByKey(key) {
    return this.termsByKey[key];
  }

  getTermWithData(key) {
    return this.termsWithData.find(([{ key: termKey }]) => termKey === key);
  }

  @override
  get mainFieldKey() {
    const firstTerm = this.baseTermsWithData_[0];
    if (!firstTerm) {
      return '';
    }

    const [{ key }, term] = firstTerm;
    return [key, term.delegate.mainFieldKey].filter(Boolean).join('.');
  }

  @override
  get boundOutputs() {
    return this.term.terms.map((t) => t.delegate.boundOutputs || []).flat();
  }

  renderReadOnlyValue() {
    return this.termsWithData
      .filter(
        ([{ isVisible, isNa, isUnset }, t]) =>
          isVisible &&
          !isNa &&
          !isUnset &&
          [t.renderReadOnlyValue()].flat().filter(Boolean).length
      )
      .map(([, t]) => {
        const readOnly = [t.renderReadOnlyValue()].flat().filter(Boolean);
        return [t.title, ...readOnly].filter(Boolean);
      });
  }

  getTermValue(key, term, value, getEvalValue = false) {
    const rawVal =
      term.isStringFormat && term.delegate.isContact
        ? term.value
        : (value || {})[key];
    if (getEvalValue) {
      return term.delegate.getEvalValue(rawVal);
    }

    return rawVal;
  }

  getValuesByFormFieldId(v) {
    const val = this.getTermsWithData(v).reduce(
      (value, [{ key, isVisible, isNa }, term]) => {
        const active = isVisible && !isNa;
        // Avoid directly staging values into string-format terms (since those can have
        // custom logic to process inputs that's tied to their own components), unless the
        // value has already been modified from current combo value, which means the
        // internal is kept inside of value object.
        const termVal =
          term.isStringFormat &&
          active &&
          !(v.__modifiedKeys || []).includes(key)
            ? {}
            : term.delegate.getValuesByFormFieldId(
                this.getTermValue(key, term, v)
              );
        return {
          ...value,
          ...Object.entries(termVal).reduce(
            (allTermValuesByFieldId, [termFieldId, termFieldValue]) => ({
              ...allTermValuesByFieldId,
              [termFieldId]: active ? termFieldValue : null,
            }),
            {}
          ),
        };
      },
      {}
    );
    return val;
  }

  getValue(value, getEvalValue = false) {
    // If getEvalValue == true, then get all terms' eval value instead of "raw" value
    return this.baseTermsWithData_.reduce(
      (v, [{ key }, term]) => ({
        ...v,
        [key]: this.getTermValue(key, term, value, getEvalValue),
      }),
      {
        __modifiedKeys: value.__modifiedKeys || [],
      }
    );
  }

  getCurrentValue() {
    return this.baseTermsWithData_.reduce(
      (all, [{ key }, term]) => ({
        ...all,
        [key]: term.value,
      }),
      {
        __modifiedKeys: [],
      }
    );
  }

  getEvalValue(value) {
    return Object.entries(this.getValue(value, true)).reduce(
      (outValue, [k, v]) => {
        if (v) {
          return {
            ...outValue,
            [k]: v,
          };
        }

        return outValue;
      },
      null
    );
  }

  runValidations(value) {
    return this.getTermsWithData(value)
      .filter(
        ([{ isNa, isVisible, isUnset }]) => !isNa && !isUnset && isVisible
      )
      .map(async ([{ key }, term]) => {
        const errors = await term.delegate.runValidations(value[key]);
        const firstError = (errors || []).find(Boolean);
        return firstError ? `${term.title || key}: ${firstError}` : null;
      });
  }
}
