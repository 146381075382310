import { ItemTaskChecklistItem } from 'src/types/proto/transactions';

export type BaseTaskType = {
  id: string;
  title: string;
  typeItem: ItemTaskChecklistItem;
  [key: string]: unknown;
};

export default class TaskTypeBase {
  task: BaseTaskType;

  constructor(task: BaseTaskType) {
    this.task = task;
  }

  get id() {
    return this.task.id;
  }

  get title() {
    return this.task.title;
  }

  get typeItem() {
    return this.task.typeItem;
  }
}
