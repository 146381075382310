import sortBy from 'lodash/sortBy';
import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type PurchaseInfoJson = TransactionItemJson<'PURCHASE_INFO'>;

export default class PurchaseInfo extends Item<'PURCHASE_INFO'> {
  resolvedItems = ['keyDates'];

  constructor(store: ItemStore, json: PurchaseInfoJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get purchasePrice() {
    return this.kindItem?.purchasePrice;
  }

  @computed
  get escrow() {
    return this.kindItem?.escrow;
  }

  @computed
  get escrowNumber() {
    return this.escrow && this.escrow.number;
  }

  @computed
  get amountFinanced() {
    return this.kindItem?.amountFinanced;
  }

  @computed
  get transferFees() {
    return this.kindItem?.transferFees;
  }

  @computed
  get deposit1() {
    return this.kindItem?.deposit1;
  }

  @computed
  get deposit2() {
    return this.kindItem?.deposit2;
  }

  @computed
  get deposit3() {
    return this.kindItem?.deposit3;
  }

  @computed
  get deposit4() {
    return this.kindItem?.deposit4;
  }

  @computed
  get loan1() {
    return this.kindItem?.loan1;
  }

  @computed
  get loan2() {
    return this.kindItem?.loan2;
  }

  @computed
  get loan3() {
    return this.kindItem?.loan3;
  }

  @computed
  get loan4() {
    return this.kindItem?.loan4;
  }

  @computed
  get sellerFinancing() {
    return this.kindItem?.sellerFinancing;
  }

  @computed
  get otherFinancing1() {
    return this.kindItem?.otherFinancing1;
  }

  @computed
  get otherFinancing2() {
    return this.kindItem?.otherFinancing2;
  }

  @computed
  get otherFinancing3() {
    return this.kindItem?.otherFinancing3;
  }

  @computed
  get otherFinancing4() {
    return this.kindItem?.otherFinancing4;
  }

  @computed
  get acceptanceDate() {
    return this.kindItem?.acceptanceDate;
  }

  @computed
  get closingDate() {
    return this.kindItem?.closingDate;
  }

  @computed
  get allContingenciesRemovalDate() {
    const task = this.keyDates.find(
      (t) => t.keyDate === 'ALL_CONTINGENCIES_REMOVAL'
    );
    return task && task.date;
  }

  @computed
  get possessionDate() {
    return this.kindItem?.possessionDate;
  }

  @computed
  get offerDate() {
    return this.kindItem?.offerDate;
  }

  @computed
  get contractDate() {
    return this.kindItem?.contractDate;
  }

  @computed
  get purchaseAgreementDate() {
    return this.kindItem?.purchaseAgreementDate;
  }

  @computed
  get inspectionContingency() {
    return this.kindItem?.inspectionContingency;
  }

  @computed
  get inspectionContingencyDate() {
    return this.kindItem?.inspectionContingencyDate;
  }

  @computed
  get financingContingency() {
    return this.kindItem?.financingContingency;
  }

  @computed
  get financingContingencyDate() {
    return this.kindItem?.financingContingencyDate;
  }

  @computed
  get appraisalContingency() {
    return this.kindItem?.appraisalContingency;
  }

  @computed
  get appraisalContingencyDate() {
    return this.kindItem?.appraisalContingencyDate;
  }

  @computed
  get appraisalContingencyMin() {
    return this.kindItem?.appraisalContingencyMin;
  }

  @computed
  get disclosureContingency() {
    return this.kindItem?.disclosureContingency;
  }

  @computed
  get disclosureContingencyDate() {
    return this.kindItem?.disclosureContingencyDate;
  }

  @computed
  get saleOfPropertyContingency() {
    return this.kindItem?.saleOfPropertyContingency;
  }

  @computed
  get remarks() {
    return this.kindItem?.remarks;
  }

  @computed
  get listingCommissionAmt() {
    return this.kindItem?.listingCommissionAmt;
  }

  @computed
  get listingCommissionPct() {
    return this.kindItem?.listingCommissionPct;
  }

  @computed
  get saleCommissionAmt() {
    return this.kindItem?.saleCommissionAmt;
  }

  @computed
  get saleCommissionPct() {
    return this.kindItem?.saleCommissionPct;
  }

  @computed
  get otherDeductions() {
    return this.kindItem?.otherDeductions;
  }

  @computed
  get commissionBreakdown() {
    return this.kindItem?.commissionBreakdown;
  }

  @computed
  get keyDates() {
    return sortBy(
      this.outEdges
        .filter((e) => e.kind === 'PURCHASE_INFO_HAS_KEY_DATE')
        .map((e) => this.store.itemsById.get(e.item2Id))
        .filter((x) => x),
      'dateTimestamp'
    );
  }

  @computed
  get hasContingencies() {
    // TODO: this should be computed on the server.
    return (
      this.inspectionContingency ||
      this.financingContingency ||
      this.appraisalContingency ||
      this.disclosureContingency ||
      this.saleOfPropertyContingency
    );
  }
}
