import type { TreeNode } from 'src/stores/form-builder-store/tree-node';
import {
  Annotation,
  FormConfig,
  FormConfigItem,
  FormConfigItemKind,
  FormConfigItemStringChoice,
  FormConfigItemStringOption,
  FormConfigItemStringOptionStringVariant,
} from 'src/types/proto/reform';
import { findAnnotationById } from '../../../components/form-builder/utils/annotation';
import {
  AnswerSubType,
  CurrencyNodeData,
  FieldNode,
  NodeComponentType,
  NumericNodeData,
  SectionNode,
  SingleCheckboxNodeData,
  TermNode,
  TreeNodeType,
  TreeNodeTypeByComponentType,
} from '../types';

export const formConfigKindMap: Record<
  FormConfigItemKind,
  NodeComponentType | undefined
> = {
  UNKNOWN: undefined,
  SECTION: 'Section',
  TERM: 'Term',
  STRING: 'ShortAnswer',
  BOOLEAN: 'SingleCheckbox',
  _OUTDATED_SINGLE_SELECT: undefined,
  MULTIPLE_SELECT: 'MultiCheckbox',
  PAGE_TITLE: 'TitlePage',
  PAGE_BREAK: 'PageBreak',
  DESCRIPTION: 'Description',
  DYNAMIC_EXPLANATION: 'DynamicExplanation',
  ADDRESS: 'ShortAnswer',
  PARTY: 'ShortAnswer',
  DATE: 'ShortAnswer',
  PHONE_NUMBER: 'ShortAnswer',
  STRING_CHOICE: 'RadioGroup',
  TIME: 'ShortAnswer',
  LEGAL_DISCLAIMER: 'LegalDisclaimer',
  CURRENCY: 'Currency',
  NUMERIC: 'Numeric',
  PARTY_FULL_NAME: undefined,
};

export function decodeFormConfigItem<T extends NodeComponentType = any>(
  item: FormConfigItem,
  annotations: Annotation[] = []
): TreeNodeTypeByComponentType<T> {
  const node = {
    id: item.id,
    type: 'object',
    component: formConfigKindMap[item.kind],
    componentProps: {
      title: item.title,
      description: item.description,
      annotationId: item.annotationId,
      fieldId: item.fieldId,
      num: item.num,
      help: item.helpText,
      outlineExclusive: item.outlineExclusive,
      legalDescription: item.legalDescription,
      optional: item.optional,
    },
  } as TreeNodeTypeByComponentType<T>;
  switch (node.component) {
    case 'Section':
      node.componentProps.num = item.num ?? '';
      node.children = (item.section.items ?? []).map((childItem) =>
        decodeFormConfigItem(childItem, annotations)
      ) as TermNode[];
      break;

    case 'Term':
      node.componentProps.num = item.num ?? '';
      node.children = (
        (item.term.items ?? []).map((childItem) =>
          decodeFormConfigItem(childItem, annotations)
        ) as FieldNode[]
      ).filter((child) => child.component);
      break;

    case 'ShortAnswer':
    case 'LongAnswer':
      let stringOption: FormConfigItemStringOption;
      let subType: AnswerSubType;

      switch (item.kind) {
        case FormConfigItemKind.DATE:
          stringOption = item.date;
          subType = 'DATE';
          break;
        case FormConfigItemKind.TIME:
          stringOption = item.time;
          subType = 'TIME';
          break;
        case FormConfigItemKind.ADDRESS:
          stringOption = item.address;
          subType = 'ADDRESS';
          break;
        case FormConfigItemKind.PARTY:
          stringOption = item.party;
          subType = 'PARTY';
          break;
        case FormConfigItemKind.PHONE_NUMBER:
          stringOption = item.phoneNumber;
          subType = 'PHONE_NUMBER';
          break;
        case FormConfigItemKind.STRING:
        default:
          stringOption = item.string;
          subType = 'TEXT';
          break;
      }

      node.component =
        stringOption.variant ===
        FormConfigItemStringOptionStringVariant.SHORT_ANSWER
          ? 'ShortAnswer'
          : 'LongAnswer';

      node.componentProps = {
        ...node.componentProps,
        label: stringOption.textLabel,
        needAttachment: stringOption.needAttachment,
        subType,
      };
      break;

    case 'SingleCheckbox':
      node.componentProps = {
        ...node.componentProps,
        checkedOption: item.boolean.yesLabel,
        uncheckedOption: item.boolean.noLabel,
        needAttachment: item.boolean.needAttachment,
      };
      break;

    case 'RadioGroup':
    case 'MultiCheckbox':
      const options =
        (node.component === 'RadioGroup' ? item.stringChoice : item.multiSelect)
          ?.options ?? [];

      node.componentProps = {
        ...node.componentProps,
        options: options.map((o) => ({
          label: o.label,
          value: o.value,
          annotationId: o.annotationId,
          textAnnotationId: o.textAnnotationId,
          help: o.helpText,
          isOther: o.isOther,
          fieldId: o.fieldId ?? item.fieldId,
        })),
      };
      break;

    case 'TitlePage':
      node.componentProps = {
        ...node.componentProps,
        title: item.title,
        subTitle: item.titlePage.subTitle,
        image: item.titlePage.image,
      };
      break;

    case 'PageBreak':
      node.componentProps = {
        ...node.componentProps,
        title: item.title || 'Page break',
      };
      break;

    case 'LegalDisclaimer':
      node.componentProps = {
        ...node.componentProps,
        header: item.legalDisclaimer?.header ?? '',
        description: item.legalDisclaimer?.description ?? '',
        disclaimer: item.legalDisclaimer?.disclaimer ?? '',
        footer: item.legalDisclaimer?.footer ?? '',
      };
      break;

    case 'Currency':
      node.componentProps = {
        ...node.componentProps,
        isPercentageEditable: item.currency?.isPercentageEditable ?? false,
        percentageBoundFieldId: item.currency?.percentageBoundFieldId ?? '',
      };
      break;

    case 'Numeric':
      node.componentProps = {
        ...node.componentProps,
        hasMin: item.numeric?.hasMin ?? false,
        hasMax: item.numeric?.hasMax ?? false,
        minValue: item.numeric?.minValue ?? 0,
        maxValue: item.numeric?.maxValue ?? 0,
        subType: item.numeric?.kind ?? 'INTEGER',
      };
      break;

    case 'Description':
      break;

    case 'DynamicExplanation':
      node.componentProps = {
        ...node.componentProps,
        links: (item.dynamicExplanation.links || []).map((link) => ({
          choices: link.isAdditional ? undefined : link.choices ?? [],
          nodeId: link.nodeId,
          required: link.requireNote,
          isAdditional: link.isAdditional,
          fieldId: link.fieldId,
        })),
        label: item.dynamicExplanation.textLabel,
      };
      break;

    default:
      console.warn(
        `decodeFormConfigItem(): unsupported form item kind "${item.kind}"`,
        item
      );
  }
  return node;
}

export function decodeFormConfig(
  data: FormConfig,
  annotations: Annotation[] = []
): TreeNodeType {
  return {
    id: data.formId,
    type: 'object',
    component: 'Form',
    componentProps: {},
    children: (
      (data.items ?? []).map((childItem) =>
        decodeFormConfigItem(childItem, annotations)
      ) as SectionNode[]
    ).filter((child) => child.component),
  };
}

export function encodeFormConfigItem(
  node: TreeNode,
  annotations: Annotation[]
): FormConfigItem | null {
  if (!node.componentProps) {
    return null;
  }
  const item: FormConfigItem = {
    id: node.id,
    title: node.componentProps.title || '',
    fieldId: node.componentProps.annotationId,
    description: node.componentProps.description,
    num: node.componentProps.num,
    helpText: node.componentProps.help,
    annotationId: node.componentProps.annotationId,
    outlineExclusive: node.componentProps.outlineExclusive || false,
    legalDescription: node.componentProps.legalDescription,
    optional: node.componentProps.optional || false,
    kind: FormConfigItemKind.UNKNOWN,
  } as FormConfigItem;

  const getFieldId = (annotationId: string) => {
    if (
      !annotations.length ||
      annotations.find(({ fieldId }) => fieldId === annotationId)
    )
      return annotationId;
    const annotation = findAnnotationById(annotations, annotationId);
    return annotation?.fieldId;
  };

  const root = node?.path[0];

  const getNode = (nodeId: string) => {
    return root?.findById(nodeId);
  };

  if (!item.fieldId && node.componentProps.annotationId) {
    item.fieldId = getFieldId(node.componentProps.annotationId) ?? '';
  }

  const nodeData = node as TreeNodeType;

  let singleCheckboxProps: SingleCheckboxNodeData;
  let currencyProps: CurrencyNodeData;
  let numericProps: NumericNodeData;

  switch (nodeData.component) {
    case 'Section':
      item.kind = FormConfigItemKind.SECTION;
      item.num = node.componentProps.num ?? '';
      item.section = {
        items: (nodeData.children ?? []).map((child) =>
          encodeFormConfigItem(child as TreeNode, annotations)
        ),
      };
      break;

    case 'Term':
      item.kind = FormConfigItemKind.TERM;
      item.num = node.componentProps.num ?? '';
      item.term = {
        items: (nodeData.children ?? []).map((child) =>
          encodeFormConfigItem(child as TreeNode, annotations)
        ),
      };
      break;

    case 'ShortAnswer':
    case 'LongAnswer': {
      const stringOption: FormConfigItemStringOption = {
        variant:
          nodeData.component === 'ShortAnswer'
            ? FormConfigItemStringOptionStringVariant.SHORT_ANSWER
            : FormConfigItemStringOptionStringVariant.LONG_ANSWER,
        textLabel: nodeData.componentProps?.label ?? '',
        needAttachment: nodeData.componentProps?.needAttachment ?? false,
      };

      switch (nodeData.componentProps?.subType) {
        case 'DATE':
          item.kind = FormConfigItemKind.DATE;
          item.date = stringOption;
          break;
        case 'TIME':
          item.kind = FormConfigItemKind.TIME;
          item.time = stringOption;
          break;
        case 'ADDRESS':
          item.kind = FormConfigItemKind.ADDRESS;
          item.address = stringOption;
          break;
        case 'PARTY':
          item.kind = FormConfigItemKind.PARTY;
          item.party = stringOption;
          break;
        case 'PHONE_NUMBER':
          item.kind = FormConfigItemKind.PHONE_NUMBER;
          item.phoneNumber = stringOption;
          break;
        case 'TEXT':
        default:
          item.kind = FormConfigItemKind.STRING;
          item.string = stringOption;
          break;
      }

      item.title = nodeData.componentProps?.title ?? '';
      item.annotationId = nodeData.componentProps?.annotationId;

      break;
    }
    case 'SingleCheckbox':
      singleCheckboxProps = nodeData.componentProps;
      item.kind = FormConfigItemKind.BOOLEAN;
      item.annotationId = singleCheckboxProps.annotationId;
      item.boolean = {
        isCheckbox: true,
        yesLabel: singleCheckboxProps.checkedOption,
        yesValue: 'yes',
        noLabel: singleCheckboxProps.uncheckedOption,
        noValue: 'no',
        needAttachment: singleCheckboxProps.needAttachment ?? false,
      };
      break;

    case 'MultiCheckbox':
    case 'RadioGroup':
      const stringChoice: FormConfigItemStringChoice = {
        options: nodeData.componentProps.options.map((o) => ({
          label: o.label,
          value: o.value,
          noteLabel: '',
          requireNote: false,
          triggerNote: false,
          annotationId: o.annotationId,
          textAnnotationId: o.textAnnotationId,
          helpText: o.help,
          isOther: o.isOther,
          fieldId: o.fieldId || getFieldId(o.annotationId),
        })),
      };
      if (nodeData.component === 'MultiCheckbox') {
        item.kind = FormConfigItemKind.MULTIPLE_SELECT;
        item.multiSelect = stringChoice;
      } else {
        item.kind = FormConfigItemKind.STRING_CHOICE;
        item.stringChoice = stringChoice;
      }
      break;

    case 'TitlePage':
      item.kind = FormConfigItemKind.PAGE_TITLE;
      item.titlePage = {
        subTitle: nodeData.componentProps.subTitle,
        image: nodeData.componentProps.image,
      };
      break;

    case 'PageBreak':
      item.kind = FormConfigItemKind.PAGE_BREAK;
      item.title = item.title || 'Page break';
      break;

    case 'Description':
      item.kind = FormConfigItemKind.DESCRIPTION;
      break;

    case 'SmartList':
      break;

    case 'DynamicExplanation':
      item.kind = FormConfigItemKind.DYNAMIC_EXPLANATION;
      item.dynamicExplanation = {
        links: (nodeData.componentProps.links || [])
          .map((link) => {
            const node = getNode(link.nodeId);
            const { annotationId, fieldId } = node?.componentProps || {};
            return {
              choices: link.isAdditional ? undefined : link.choices ?? [],
              isAdditional: link.isAdditional,
              requireNote: link.required,
              nodeId: link.nodeId,
              fieldId: fieldId || getFieldId(annotationId),
            };
          })
          .filter((link) => link),
        textLabel: nodeData.componentProps.label,
      };
      break;

    case 'LegalDisclaimer':
      item.kind = FormConfigItemKind.LEGAL_DISCLAIMER;
      item.legalDisclaimer = {
        header: node.componentProps.header,
        description: node.componentProps.description,
        disclaimer: node.componentProps.disclaimer,
        footer: node.componentProps.footer,
      };
      break;

    case 'Currency':
      currencyProps = nodeData.componentProps;
      item.kind = FormConfigItemKind.CURRENCY;
      item.currency = {
        isPercentageEditable: currencyProps.isPercentageEditable ?? false,
        percentageBoundFieldId: currencyProps.percentageBoundFieldId,
      };
      break;

    case 'Numeric':
      numericProps = nodeData.componentProps;
      item.kind = FormConfigItemKind.NUMERIC;
      item.numeric = {
        hasMin: numericProps.hasMin ?? false,
        hasMax: numericProps.hasMax ?? false,
        minValue: numericProps.minValue ?? 0,
        maxValue: numericProps.maxValue ?? 0,
        kind: numericProps.subType ?? 'INTEGER',
      };
      break;

    default:
      item.kind = FormConfigItemKind.UNKNOWN;
      // eslint-disable-next-line no-console
      console.warn(
        `encodeFormConfigItem(): unsupported TreeNode component type: '${node.component}'`,
        nodeData
      );
  }

  return item;
}

export function encodeFormConfig(
  node: TreeNode,
  formId?: string,
  annotations: Annotation[] = []
): FormConfig {
  if (node.component !== 'Form') {
    throw new TypeError('invalid root node, must be Form');
  }
  return {
    formId: formId ?? node.id,
    items:
      (node.children ?? []).map((child) =>
        encodeFormConfigItem(child, annotations)
      ) ?? [],
  };
}
