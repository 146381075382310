import {
  documentUploadedFromComputer,
  fileRenamed,
} from '@uc/analytics-definitions';
import invariant from 'invariant';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import intersection from 'lodash/intersection';
import isArray from 'lodash/isArray';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import { action, makeObservable, observable, runInAction } from 'mobx';
import MissingFillConditionsModal from 'src/components/transactions/documents/missing-fill-conditions-modal';
import logger from 'src/logger';
import {
  assignTransactionDocumentsToFolderIntent,
  cleanTransactionDocuments,
  deleteFolders,
  renameFolderIntent,
  renameTransactionDocuments,
  restoreDocuments,
  updateTransactionDocumentsNotes,
  uploadNewDocumentsIntent,
} from 'src/models/transactions/intents';
import { productPathFromEmbeddedFeature } from 'src/utils/analytics/product-path';
import chaining from 'src/utils/chaining';
import debounceBatch from 'src/utils/debounce-batch';
import { getFetch } from 'src/utils/get-fetch';
import pickFile from 'src/utils/pick-file';

export default class TransactionDocumentsStore {
  @observable tdvIdFormTags = new Map();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = parent.api.documents;
    this.transactionsApi = parent.api.transactions;
  }

  get transactions() {
    return this.parent.transactions;
  }

  get features() {
    return this.parent.features;
  }

  get uploads() {
    return this.parent.uploads;
  }

  get ui() {
    return this.parent.ui;
  }

  get embeddedApp() {
    return this.parent.embeddedApp;
  }

  cleanTds = async (transactionId, tds) => {
    const tdsToClean = tds.filter((td) => td.isFillable);
    if (!tdsToClean.length) {
      return;
    }
    try {
      await this.transactions.dispatch(
        transactionId,
        cleanTransactionDocuments(tdsToClean.map((td) => td.id))
      );
    } catch (e) {
      logger.error(e);
    }
  };

  rawDocs(transactionId) {
    const { transactions } = this;
    return transactions.getFetchItems.get({
      transactionId,
      kind: 'TRANSACTION_DOCUMENT',
    });
  }

  activeDocs(transactionId) {
    return this.rawDocs(transactionId).filter((d) => !d.archived);
  }

  folders(transactionId) {
    return this.transactions.getItems(transactionId, 'FOLDER');
  }

  foldersById(transactionId) {
    return keyBy(this.folders(transactionId), 'id');
  }

  folderIdByDocId(transactionId) {
    return mapValues(this.folderByDocId(transactionId), (f) => f.id);
  }

  folderByDocId(transactionId) {
    const map = {};
    this.folders(transactionId).forEach((f) =>
      f.documents.forEach((d) => {
        map[d.id] = f;
      })
    );

    return map;
  }

  getGeneralFolder(transactionId) {
    return this.folders(transactionId).find((f) => f.folderKind === 'DEFAULT');
  }

  getTrashFolder(transactionId) {
    return this.folders(transactionId).find((f) => f.folderKind === 'TRASH');
  }

  getFetchAttachmentTds = async (transactionId) => {
    const folders = await this.transactions.getFetchItems.getOrFetch({
      transactionId,
      kind: 'FOLDER',
    });
    await this.transactions.getFetchItems.getOrFetch({
      transactionId,
      kind: 'TRANSACTION_DOCUMENT',
    });
    const attachmentsFolder = folders.find(
      (f) => f.folderKind === 'ATTACHMENTS'
    );
    if (!attachmentsFolder) {
      return [];
    }
    return attachmentsFolder.documents;
  };

  @action
  renameFolder = async (folderId, title) => {
    await this.transactions.dispatch(
      this.transactions.itemsById.get(folderId).transId,
      renameFolderIntent({
        folderId,
        title,
      })
    );
  };

  @action
  uploadNewDocuments = async (transactionId, files, folderId, runUpload) => {
    const run = runUpload || ((f) => f());
    return run(async () => {
      const resp = await this.transactions.dispatch(
        transactionId,
        uploadNewDocumentsIntent({
          folderId,
          files,
        })
      );
      documentUploadedFromComputer({
        product: 'documents',
        sub_product: 'document_files',
        product_path: productPathFromEmbeddedFeature(
          this.embeddedApp.embeddedFeature
        ),
      });

      return Object.values(resp.result)
        .map(({ td_id: tdId }) => this.transactions.itemsById.get(tdId))
        .filter((td) => td);
    });
  };

  @action
  archiveTransactionDocument = async (tid) => {
    const item = this.transactions.itemsById.get(tid);
    await this.transactions.dispatch(
      item.transId,
      assignTransactionDocumentsToFolderIntent([
        {
          tdId: tid,
          folderId: find(
            this.transactions.getItems(item.transId, 'FOLDER'),
            (d) => d.title === 'Trash'
          ).id,
        },
      ])
    );
  };

  @action
  restoreTransactionDocuments = async (
    transactionId,
    restoreTds = undefined,
    restoreToFolderId = undefined
  ) => {
    const tdsIds = (isArray(restoreTds) ? restoreTds : [])
      .map((td) => td.id || td)
      .filter((tdId) => !!tdId);
    await this.transactions.dispatch(
      transactionId,
      restoreDocuments(
        tdsIds.map((tdId) => ({
          tdId,
          restoreToFolderId,
        }))
      )
    );
  };

  // Validates and dispatches intent for renaming specified
  // transaction document.
  // Returns true or false to signal whether the operation
  // was successful.
  validateAndRenameDocument = async (itemId, title) => {
    if (!(title && title.trim())) {
      return false;
    }

    // title is actually a file name. so we validate that the user
    // is not clearing the first part and leaving only the extension
    if (/^\.\w+$/.test(title.trim())) {
      return false;
    }

    // Nothing to do if the value didn't change.
    const transactionDoc = this.transactions.itemsById.get(itemId);
    if (title === transactionDoc.title) {
      return true;
    }

    await this.transactions.dispatch(
      transactionDoc.transId,
      renameTransactionDocuments([
        {
          transactionDocumentId: itemId,
          title,
        },
      ])
    );

    fileRenamed({
      product: 'documents',
      sub_product: 'document_files',
      product_path: productPathFromEmbeddedFeature(
        this.embeddedApp.embeddedFeature
      ),
    });

    return true;
  };

  updateDocumentNotes = async (itemId, notes) => {
    // Nothing to do if the value didn't change.
    const item = this.transactions.itemsById.get(itemId);
    if (item && notes === item.notes) {
      return;
    }

    await this.transactions.dispatch(
      item.transId,
      updateTransactionDocumentsNotes([
        {
          transactionDocumentId: itemId,
          notes,
        },
      ])
    );
  };

  @action
  deleteFolders = async (transactionId, folderIds) => {
    await this.transactions.dispatch(transactionId, deleteFolders(folderIds));
  };

  getSplitSuggestionProgress(item) {
    const PAGE_OCR_STATE = 3;
    const METADATA_STATE = 1;

    const PERCENT_SPENT_ESTIMATING = 5;
    const PERCENT_SPENT_ANALYZING = 92;
    const PERCENT_SPENT_SPLITTING = 3;
    const TIME_SPENT_ESTIMATING = 2000;

    const TIME_SPENT_SPLITTING = 1200;
    const stateByPart = get(item, 'document.analysis.stateByPart');
    const pageCount = get(item, 'document.analysis.documentResult.pageCount');
    const byteSize = get(item, 'document.byteSize');
    if (
      stateByPart &&
      stateByPart[METADATA_STATE] &&
      stateByPart[METADATA_STATE].status === 'PENDING'
    ) {
      return {
        status: 'ESTIMATING',
        percentCompleted: 0,
        percentPending: PERCENT_SPENT_ESTIMATING,
        estimatedDuration: TIME_SPENT_ESTIMATING,
      };
    }
    if (!pageCount) {
      return {
        status: 'FAILED',
      };
    }
    if (
      stateByPart &&
      stateByPart[PAGE_OCR_STATE] &&
      stateByPart[PAGE_OCR_STATE].status === 'FAILED'
    ) {
      return {
        status: 'FAILED',
      };
    }
    if (
      stateByPart &&
      stateByPart[PAGE_OCR_STATE] &&
      stateByPart[PAGE_OCR_STATE].status === 'COMPLETE'
    ) {
      return {
        status: 'SPLITTING',
        percentCompleted: PERCENT_SPENT_ANALYZING + PERCENT_SPENT_ESTIMATING,
        percentPending: PERCENT_SPENT_SPLITTING,
        estimatedDuration: TIME_SPENT_SPLITTING,
      };
    }
    return {
      status: 'ANALYZING',
      percentCompleted: PERCENT_SPENT_ESTIMATING,
      percentPending: PERCENT_SPENT_ANALYZING,
      estimatedDuration:
        (0.0022797 * byteSize + 190.93 * pageCount + 13032) * 1.1,
    };
  }

  @action
  assignTransactionDocumentToFolder = async (
    transactionId,
    folderId,
    transactionDocumentId,
    optimistic = true
  ) => {
    const folder = this.foldersById(transactionId)[folderId];
    await this.transactions.dispatch(
      transactionId,
      assignTransactionDocumentsToFolderIntent(
        [
          {
            folderId,
            tdId: transactionDocumentId,
            // Newly assigned docs go to the bottom of the folder.
            index: folder.documents.length,
          },
        ],
        optimistic
      )
    );
  };

  pickFilesForUpload = async (transactionId, folderId, runUpload) => {
    let files;
    try {
      files = await pickFile({
        maxFiles: 20,
      });
    } catch (err) {
      return null;
    }

    if (files && files.length) {
      return this.uploadNewDocuments(transactionId, files, folderId, runUpload);
    }
    return null;
  };

  fetchFormSuggestion = debounceBatch(async (calls) => {
    const callsByTransactionIdMatchingMode = groupBy(
      calls.map((x, i) => [x, i]),
      (call) => `${call[0][0]}.${call[0][3]}`
    );
    const res = [];
    await Promise.all(
      toPairs(callsByTransactionIdMatchingMode).map(
        async ([transactionIdMatchingMode, transactionCalls]) => {
          const [transactionId, matchingMode] =
            transactionIdMatchingMode.split('.');
          const tdIds = uniq(
            transactionCalls
              .filter((call) => call[0][2] === 'TRANSACTION_DOCUMENT')
              .map((call) => call[0][1])
          );
          const tdvIds = uniq(
            transactionCalls
              .filter((call) => call[0][2] === 'TRANSACTION_DOCUMENT_VERSION')
              .map((call) => call[0][1])
          );
          const { data } = await this.api.getFormSuggestions({
            tdIds,
            tdvIds,
            transactionId,
            mode: matchingMode,
          });
          transactionCalls.forEach(([[, tdId], i]) => {
            res[i] = data[tdId];
          });
        }
      )
    );
    return res;
  }, 200);

  @getFetch({
    getMemoizeKey: ({ item, matchingMode }) => {
      // use analyzedTime so that this will be invalidated whenever
      // analysis is completed
      return `${item.transactionId}:${item.id}:${
        item.latestVersionId || item.id
      }:${matchingMode}:${item.analyzedTime}`;
    },
  })
  async _getFetchFormSuggestion({ item, matchingMode }) {
    return this.fetchFormSuggestion(
      item.transactionId,
      item.id,
      item.kind,
      matchingMode
    );
  }

  getFetchFormSuggestion = (mode, { item, td, tdv, matchingMode }) => {
    /*
      Get/fetch form suggestions (with tabs) for a TD/TDV (item)
      This batches calls made close in time so n+1 calls
      to this are OK
    */
    if ([item, td, tdv].filter((i) => i !== undefined).length !== 1) {
      throw new Error(
        'One and only one of `item`, `td`, `tdv` needs to be set to call `getFetchFormSuggestion`.'
      );
    }
    return this._getFetchFormSuggestion(mode, {
      item: item || td || tdv,
      matchingMode,
    });
  };

  getOrFetchTdvFormSplitSuggestion = (tdv) =>
    this._getFetchFormSuggestion.getOrFetch({
      item: tdv,
      matchingMode: 'splitting',
    });

  suggestionHasTabs(suggestion, includeFormTags, tabRoles, premium) {
    if (!suggestion) {
      return false;
    }
    let matches;
    const hasFormTags = (match) => {
      return (
        includeFormTags &&
        match.form.tags &&
        !!intersection([...match.form.tags], [...includeFormTags]).length
      );
    };
    if (premium) {
      matches = [...suggestion].filter(
        (x) => x.matchMethod === 'OCR' && !hasFormTags(x)
      );
    } else if (premium === false) {
      matches = [...suggestion].filter(
        (x) => x.matchMethod !== 'OCR' || hasFormTags(x)
      );
    } else {
      matches = [...suggestion];
    }

    const suggestedRoles = flatMap(matches, (x) => [
      ...get(x, 'form.fillConfig.annotations', []),
    ]).map((t) => t.recipientRole);
    if (!tabRoles) {
      return suggestedRoles.length > 0;
    }
    return intersection(suggestedRoles, tabRoles || []).length > 0;
  }

  @action
  missingFillConditionsCheck = async (transaction, tds, operation) => {
    if (!transaction.features.missingFillConditions) {
      return true;
    }
    const missingTds =
      tds && tds.filter((td) => !!td?.missingFillConditionsCount);
    if (!missingTds.length) {
      return true;
    }
    return new Promise((resolve) => {
      this.ui.setCustomModal(({ onClose }) => {
        const onOk = chaining(onClose, () => resolve(true));
        const onCancel = chaining(onClose, () => resolve(false));
        return MissingFillConditionsModal({
          tds: missingTds,
          operation,
          onOk,
          onCancel,
        });
      });
    });
  };

  getFetchTdvsFormTags = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId, tdvIds }) =>
      `${transactionId}:${(tdvIds || []).sort().join('.')}`,
    getter: ({ tdvIds }) =>
      tdvIds.reduce(
        (all, tdvId) => ({
          ...all,
          ...(this.tdvIdFormTags.get(tdvId)
            ? {
                [tdvId]: this.tdvIdFormTags.get(tdvId),
              }
            : {}),
        }),
        {}
      ),
    fetcher: async ({ transactionId }) => {
      const { data } = await this.transactionsApi.getFormTags({
        transactionId,
      });

      runInAction(() => {
        data.tdvsFormTags.forEach((tdvFormTags) => {
          this.tdvIdFormTags.set(tdvFormTags.tdvId, tdvFormTags.formTags ?? []);
        });
      });
    },
  });

  getOrFetchTdsFormTags = async (tds) => {
    if (!tds?.length) {
      return [];
    }
    invariant(
      uniq(tds.map((td) => td.transactionId)).length <= 1,
      'Cannot fetch tags from tds of different transactions simultaneously.'
    );
    const tdByTdvId = tds.reduce(
      (all, td) => ({
        ...all,
        [td.latestVersionId]: td,
      }),
      {}
    );
    const pairs = toPairs(
      await this.getFetchTdvsFormTags.getOrFetch({
        transactionId: tds[0].transactionId,
        tdvIds: tds
          .filter((td) => td.isFillable)
          .map((td) => td.latestVersionId),
      })
    );
    return pairs.map(([tdvId, formTags]) => [tdByTdvId[tdvId], formTags]);
  };
}
