import { COMPLETE_TASK, IMPORT_ZIPFORM_DOCS } from '../actions/kinds';
import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';
import { importZipformDocs } from '../actions/import-zipform-docs';

export default {
  actions: [IMPORT_ZIPFORM_DOCS, COMPLETE_TASK],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  actionsVisible: {
    COMPLETE_TASK: 'canCompleteTask',
  },
  label: 'Import Documents from zipForm Plus',
  onClick: importZipformDocs,
  getStatusMeta: (task) => {
    if (task.status === 'OPEN') {
      return {
        message: 'Add any document from the linked zipForm transaction.',
      };
    } else if (task.status === 'CLOSED') {
      return {
        message:
          'You added documents from zipForm. You can add more at any time.',
      };
    }
    return {};
  },
};
