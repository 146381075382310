import { computed, makeObservable } from 'mobx';
import {
  ItemEdgeKind,
  ItemSharePacketAccessStatus,
} from 'src/types/proto/transactions';
import getUrlPrefix from 'src/utils/get-url-prefix';
import Item, { ItemStore, TransactionItemJson } from './item';

export type SharePacketJson = TransactionItemJson<'SHARE_PACKET'>;

export default class SharePacket extends Item<'SHARE_PACKET'> {
  resolvedItems = ['publication', 'coverSheet.brokerAddress'];

  constructor(store: ItemStore, json: SharePacketJson) {
    super(store, json);

    makeObservable(this);
  }

  get status() {
    return this.kindItem.status;
  }

  get publicationId() {
    return this.kindItem.publicationId;
  }

  get coverSheetId() {
    return this.kindItem.coverSheetId;
  }

  get isSharingEnabled() {
    return this.kindItem.accessStatus === ItemSharePacketAccessStatus.ENABLED;
  }

  @computed
  get linkShareMode() {
    return this.kindItem.linkShareMode;
  }

  @computed
  get requestAccessUrl() {
    const urlPrefix = getUrlPrefix();
    return `${urlPrefix}${this.kindItem.requestAccessPath}`;
  }

  @computed
  get coverSheetUrl() {
    return this.publication && this.publication.coverSheetUrl;
  }

  @computed
  get coverSheetName() {
    return this.coverSheetUrl ? 'Coversheet' : null;
  }

  @computed
  get documentsCount() {
    return this.publishedTdvIds.length + +!!this.coverSheetUrl;
  }

  @computed
  get publishedTdvIds() {
    return this.kindItem.publishedTdvIds || [];
  }

  @computed
  get parent() {
    const { parentId } = this;
    return parentId && this.store.itemsById.get(parentId);
  }

  @computed
  get parentId() {
    const packetEdge = this.inEdges.filter(
      (e) => e.kind === ItemEdgeKind.PACKET_HAS_CHILD
    )[0];
    if (!packetEdge) {
      return undefined;
    }

    return packetEdge.item1Id;
  }

  @computed
  get publication() {
    return this.publicationId && this.store.itemsById.get(this.publicationId);
  }

  @computed
  get hasShares() {
    return this.outEdges.some((e) => e.kind === ItemEdgeKind.ITEM_SHARED_AS);
  }

  @computed
  get shareCount() {
    return this.outEdges.filter((e) => e.kind === ItemEdgeKind.ITEM_SHARED_AS)
      .length;
  }

  @computed
  get hasUnpublishedChanges() {
    return this.kindItem.hasUnpublishedChanges;
  }

  /**
   * TODO: Looks these fields are not match with backend,
   * for coverPhoto we already have a CoverPhoto item for it,
   * we may better check it and remove this code.
   *
   * @deprecated may use CoverPhoto item for it: src/models/transactions/items/cover-photo.ts
   */
  @computed
  get coverPhoto() {
    return {
      // eslint-disable-next-line
      // @ts-ignore
      youtubeUrl: this.kindItem.coverSheet?.youtubeUrl,
      // eslint-disable-next-line
      // @ts-ignore
      mapImageUrl: this.kindItem.coverSheet?.mapImageUrl,
      // eslint-disable-next-line
      // @ts-ignore
      customImageUrl: this.kindItem.coverSheet?.customImageUrl,
    };
  }

  @computed
  get folderId() {
    return this.outEdges.find(
      (e) => e.kind === ItemEdgeKind.SHARE_PACKET_HAS_FOLDER
    )?.item2Id;
  }

  @computed
  get folder() {
    return this.store.itemsById.get(this.folderId);
  }

  can() {
    // if (op === 'share') {
    //   return this.folder && !!this.folder.documentCount;
    // }
    return false;
  }
}
