import { computed, makeObservable, toJS } from 'mobx';
import type AccountStore from 'src/stores/account-store';
import type { User } from 'src/types/proto/auth';
import type { ItemTransactionAppOffersClientWorkspace } from 'src/types/proto/transactions';
import type Offer from '../offer';
import TransactionAppDelegate from './delegate';
import type TransactionApp from '.';

export default class OffersAppDelegate extends TransactionAppDelegate<'OFFERS'> {
  constructor(item: TransactionApp<'OFFERS'>) {
    super(item);

    makeObservable(this);
  }

  @computed
  get account(): AccountStore {
    return this.item.store.parent.account;
  }

  @computed
  get data() {
    return this.item.typeItem || {};
  }

  @computed
  get offerPackageId() {
    return this.data.offerPackageId;
  }

  @computed
  get keyTermsConfig() {
    return toJS(this.data.keyTermsConfig?.keyTerms || []);
  }

  @computed
  get offerPackage() {
    const offerPackageId = this.offerPackageId;
    return offerPackageId
      ? this.item.store.getItem(
          this.item.transaction.id,
          'TRANSACTION_PACKAGE',
          offerPackageId
        )
      : null;
  }

  @computed
  get submissionPreferences() {
    return this.data.submissionPreferences || {};
  }

  @computed
  get clientWorkspace(): Partial<ItemTransactionAppOffersClientWorkspace> {
    return this.data.clientWorkspace || {};
  }

  @computed
  get clientWorkspaceEnabled() {
    return Boolean(this.clientWorkspace.enabled);
  }

  @computed
  get clientsSeen() {
    return this.clientWorkspace.clientsSeen || [];
  }

  getUsersClientSeen = (user?: User) => {
    const userId = (user || this.account.user)?.id;
    return this.clientsSeen.find((cs) => cs.userId === userId);
  };

  userSeenOffer = (offer: Offer, user: User) => {
    const clientSeen = this.getUsersClientSeen(user);
    return Boolean(
      clientSeen &&
        clientSeen.seenOfferIds
          .map((o) => o.split(':'))
          .find(([id, vers]) => id === offer.id && +vers >= +offer.vers)
    );
  };

  @computed
  get allBoundSellers() {
    return this.item.transaction.parties.sellers.filter(
      (s) => s.isBound && s.email
    );
  }

  @computed
  get sellersWithAccess() {
    if (!this.clientWorkspaceEnabled) {
      return [];
    }

    return this.allBoundSellers;
  }

  @computed
  get userIdsSeen() {
    return Array.from(new Set(this.clientsSeen.map((cs) => cs.userId)));
  }

  @computed
  get sellersSeen() {
    return this.sellersWithAccess.filter((s) =>
      this.userIdsSeen.includes(s.userId)
    );
  }

  @computed
  get countOfUsersSeenNoLongerSellers() {
    const allBoundSellersUserIds = new Set(
      this.allBoundSellers.map((s) => s.userId)
    );
    return this.userIdsSeen.filter((uid) => !allBoundSellersUserIds.has(uid))
      .length;
  }

  @computed
  get offersSummary() {
    return this.data.offersSummary || [];
  }
}
