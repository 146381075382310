import type EnvelopeStore from 'src/stores/envelope-store';
import type { ValidItem } from 'src/types/api';
import type { Item as EnvelopeItem } from 'src/types/proto/signing';
import EnvelopeDocument from './envelope-document';
import EnvelopeRecipient from './envelope-recipient';
import Item from './item';
import Step from './step';

const ITEM_MODEL_BY_KIND = {
  ENVELOPE_DOCUMENT: EnvelopeDocument,
  ENVELOPE_RECIPIENT: EnvelopeRecipient,
  STEP: Step,
};

export type ItemModelMap = typeof ITEM_MODEL_BY_KIND;
export type ItemModelMapKey = keyof ItemModelMap;
export type ItemModels = InstanceType<
  typeof ITEM_MODEL_BY_KIND[ItemModelMapKey]
>;

export function makeItem<T extends ValidItem<EnvelopeItem>>(
  store: EnvelopeStore,
  json: EnvelopeItem
): T['kind'] extends ItemModelMapKey
  ? ItemModelMap[T['kind']]
  : Item<T['kind']> {
  // @ts-expect-error Other kind is handled by base class
  const ItemModel = ITEM_MODEL_BY_KIND[json.kind] || Item;
  return new ItemModel(store, json);
}
