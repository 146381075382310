import camelCase from 'lodash/camelCase';
import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { action, makeObservable, observable, runInAction } from 'mobx';
import {
  createTasks,
  deleteItems,
  updateTaskMeta,
  updateTdTaskAssignments,
} from 'src/models/transactions/intents';
import { makeItem } from 'src/models/transactions/items';
import getMapDefault from 'src/utils/get-map-default';
import { IDX_KIND } from './transaction-store';

export default class TasksStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  @observable queriedTasksMap = new Map();

  get transactions() {
    return this.parent.transactions;
  }

  _queriedTasksDefault = () => {
    return {
      cursor: null,
      hasMore: null,
      fetching: false,
      ids: [],
    };
  };

  initialize = () => {
    this.transactions.subscribeItemDidChange(
      'reloadTasks',
      'TASK',
      debounce(this.reloadTasks, 1000)
    );
  };

  create = async ({
    name,
    orderIndex,
    parentId,
    clientId,
    transactionId,
    type,
    taskKind,
    typeItem,
  }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      createTasks({
        newTasks: [
          {
            parentId,
            clientId,
            title: name,
            orderIndex: orderIndex === undefined ? 0 : orderIndex,
            task: {
              taskKind: taskKind || 'TASK',
              type,
              ...(typeItem
                ? {
                    [camelCase(type)]: typeItem,
                  }
                : null),
            },
          },
        ],
      })
    );
    return this.transactions.itemsById.get(result.task_ids[0]);
  };

  getKey = ({ query, filters }) =>
    `${query || ''}:${
      filters && Object.keys(filters).length ? JSON.stringify(filters) : ''
    }`;

  getQueriedTasks = (query, filters) => {
    return getMapDefault(
      this.queriedTasksMap,
      this.getKey({
        query,
        filters,
      }),
      this._queriedTasksDefault()
    );
  };

  hasMore(query = '', filters) {
    const queriedTasks = this.getQueriedTasks(query, filters);
    return queriedTasks.hasMore === null || queriedTasks.hasMore;
  }

  @action
  clearQueryData = (query, filters) => {
    this.queriedTasksMap.delete(query, filters);
  };

  @action
  clearCache = () => {
    this.queriedTasksMap.clear();
  };

  @action
  reloadTasks = () => {
    this.clearCache();
  };

  fetchTasksPreview = async (query) => {
    const { data } = await this.transactions.api.fetchTasksPreview(query);
    data.tasks = data.tasks.map((t) => makeItem(this.transactions, t));
    return data;
  };

  getTasks(mode, query = '', filters = {}) {
    const queriedTasks = this.getQueriedTasks(query, filters);
    if (queriedTasks.hasMore === null) {
      return [];
    }
    let tasks;
    if (query || filters) {
      tasks = queriedTasks.ids.reduce((list, id) => {
        const item = this.transactions.itemsById.get(id);
        /* Return only found items, skipping possible deleted ones */
        if (item) {
          list.push(item);
        }
        return list;
      }, []);
    } else {
      tasks = this.transactions.itemsById.indexed(IDX_KIND, {
        kind: 'TASK',
      }).all;
    }
    return sortBy(
      tasks.filter((x) => x.isListVisible(mode)),
      (item) => -1 * item.id
    );
  }

  fetchMore = async (mode, query = '', filters) => {
    const key = this.getKey({
      query,
      filters,
    });
    const queriedTasks = getMapDefault(
      this.queriedTasksMap,
      key,
      this._queriedTasksDefault()
    );
    if (queriedTasks.fetching || queriedTasks.hasMore === false) {
      return;
    }
    runInAction(() => {
      this.queriedTasksMap.set(key, {
        ...queriedTasks,
        fetching: true,
      });
    });
    const { data } = await this.transactions.api.fetchTasks({
      cursor: queriedTasks.cursor,
      ...(query
        ? {
            query,
          }
        : {}),
      ...(mode === 'client'
        ? {
            role: 'client',
          }
        : null),
      ...filters,
    });
    this.updateMyTask(data, query, filters);
  };

  @action
  updateMyTask = (data, query, filters) => {
    const tasks = data.data;
    const tasksById = keyBy(tasks, 'id');
    const transactionsById = keyBy(
      tasks.map((json) => json.thinTrans).filter((json) => json && json.id),
      'id'
    );
    let ids;
    this.transactions.updateThinAggregatesById(transactionsById);
    this.transactions.updateItemsById(tasksById);
    if (query || filters) {
      const queriedTasks = this.getQueriedTasks(query, filters);
      const existingIds = queriedTasks.ids;
      ids = uniq([...existingIds, ...tasks.map((t) => t.id)]);
    } else {
      ids = [];
    }
    this.queriedTasksMap.set(
      this.getKey({
        query,
        filters,
      }),
      {
        ids,
        fetching: false,
        ...pick(data, ['cursor', 'hasMore']),
      }
    );
  };

  changeStatus = async (task, status) => {
    const taskJson = task.toJS();
    taskJson.task.status = status;
    await this.transactions.dispatch(
      task.transId,
      updateTaskMeta({
        updates: [taskJson],
      })
    );
  };

  changeArchiveStatus = async (task, isArchived) => {
    const taskJson = task.toJS();
    taskJson.task.isArchived = isArchived;
    await this.transactions.dispatch(
      task.transId,
      updateTaskMeta({
        updates: [taskJson],
      })
    );
  };

  changeTitle = async (task, title) => {
    const taskJson = task.toJS();
    taskJson.title = title;
    taskJson.task.name = title;
    await this.transactions.dispatch(
      task.transactionId,
      updateTaskMeta({
        updates: [taskJson],
      })
    );
  };

  updateTask = async (task) => {
    const taskJson = task && task.toJS ? task.toJS() : task;
    await this.transactions.dispatch(
      task.transId,
      updateTaskMeta({
        updates: [taskJson],
      })
    );
  };

  archive = async (task) => {
    return this.changeStatus(task, 'ARCHIVED');
  };

  delete = async (task) => {
    return this.transactions.dispatch(
      task.transactionId,
      deleteItems([task.id])
    );
  };

  changeDateComputation = async (task, dateComputation) => {
    const taskJson = task.toJS();
    taskJson.task.dateComputation = dateComputation;
    await this.transactions.dispatch(
      task.transactionId,
      updateTaskMeta({
        updates: [taskJson],
      })
    );
  };

  @action
  assignTds = async (transaction, assignments, optimistic = false) => {
    return this.transactions.dispatch(
      transaction.id,
      updateTdTaskAssignments(assignments, optimistic)
    );
  };

  @action
  clearDocumentAssignment = async (tdId) => {
    const td = this.transactions.itemsById.get(tdId);
    if (!td.tasksIds.length) {
      return;
    }

    await this.transactions.dispatch(
      td.transId,
      updateTdTaskAssignments(
        td.tasksIds.map((taskId) => ({
          removeTdsIds: [td.id],
          taskId,
        }))
      )
    );
  };
}
