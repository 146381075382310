

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import stopPropagation from 'src/utils/stop-propagation';
import { Button, Input } from 'antd';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-copy-input';

@inject('ui')
@observer
export default class CopyInput extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    value: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    copyCallback: PropTypes.func,
    disabled: PropTypes.bool,
    copySuccessMessage: PropTypes.any,
    copyErrorMessage: PropTypes.any,
    withIcon: PropTypes.bool,
  };

  static defaultProps = {
    copySuccessMessage: 'Copied to clipboard',
    copyErrorMessage: 'Could not copy to clipboard',
    size: 'large',
  };

  setRef = (k) => (ref) => {
    this[k] = ref;
  };

  selectText = () => {
    if (!this.input) {
      return;
    }
    const input = this.input.input;
    input.focus();
    input.select();
  };

  handleCopy = () => {
    const { ui, copySuccessMessage, copyErrorMessage } = this.props;
    this.selectText();
    const successful = document.execCommand && document.execCommand('copy');
    if (successful) {
      ui.toast({
        message: copySuccessMessage,
        type: 'success',
      });
    } else {
      ui.toast({
        message: copyErrorMessage,
        type: 'error',
      });
    }

    if (this.props.copyCallback) {
      this.props.copyCallback();
    }
  };

  render() {
    const { className, ...props } = this.props;
    return (
      <Input
        ref={this.setRef('input')}
        {...props}
        readOnly
        className={`${clsPrefix} ${className}`}
        onClick={stopPropagation(this.handleCopy)}
        addonAfter={
          <Button
            type="primary"
            className={`${clsPrefix}__after`}
            disabled={this.props.disabled}
            onClick={stopPropagation(this.handleCopy)}
            size="large"
          >
            {this.props.ui.isMobileSize ? 'Copy' : 'Copy Link'}
          </Button>
        }
      />
    );
  }
}
