import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type LoadSummaryJson = TransactionItemJson<'LOAD_SUMMARY'>;

export default class LoadSummary extends Item<'LOAD_SUMMARY'> {
  constructor(store: ItemStore, json: LoadSummaryJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get page() {
    return this.kindItem.page;
  }

  @computed
  get userId() {
    return this.kindItem.userId;
  }

  @computed
  get badgeCount() {
    return this.kindItem.badgeCount;
  }

  @computed
  get lastLoadTime() {
    return this.kindItem.lastLoadTime;
  }

  @computed
  get previousLoadTime() {
    return this.kindItem.previousLoadTime;
  }
}
