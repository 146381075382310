import Clause from 'src/models/clauses/clause';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { getFetch } from 'src/utils/get-fetch';

export default class ClausesStore {
  @observable clausesByUserId = new Map();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.clauses;
  }

  @computed
  get userId() {
    return this.parent.account.user.id;
  }

  getFetchClauses = getFetch({
    bindTo: this,
    getMemoizeKey: () => this.userId,
    getter: () => this.clausesByUserId.get(this.userId),
    fetcher: async () => {
      const data = await this.fetchClauses();
      runInAction(() => {
        this.clausesByUserId.set(this.userId, data);
      });
    },
  });

  fetchClauses = async () => {
    const { data } = await this.api.list();
    return data.map((c) => new Clause(this, c));
  };

  create = async (values) => {
    const { data } = await this.api.create(values);
    const clause = new Clause(this, data);

    runInAction(() => {
      this.clausesByUserId.set(this.userId, [
        ...this.clausesByUserId.get(this.userId),
        clause,
      ]);
    });

    return clause;
  };

  update = async (id, values) => {
    const { data } = await this.api.update(id, values);
    const clause = new Clause(this, data);

    runInAction(() => {
      this.clausesByUserId.set(this.userId, [
        ...this.clausesByUserId
          .get(this.userId)
          .map((c) => (c.id !== clause.id ? c : clause)),
      ]);
    });
  };

  deleteClause = async (id) => {
    await this.api.deleteClause(id);
    runInAction(() => {
      this.clausesByUserId.set(this.userId, [
        ...this.clausesByUserId.get(this.userId).filter((c) => c.id !== id),
      ]);
    });
  };
}
