
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { action, makeObservable, observable, runInAction } from 'mobx';
import filterChores from 'src/components/transactions/chores/filter-chores';
import { IDX_KIND } from './transaction-store';

export default class ChoreStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  get transactions() {
    return this.parent.transactions;
  }

  @observable total = null;
  @observable cursor = null;
  @observable hasMore = null;
  @observable count = '';
  unreadChores = [];

  getChores = (account, options = {}) => {
    const choreItems = this.transactions.itemsById.indexed(IDX_KIND, {
      kind: 'CHORE',
    }).all;

    const chores = filterChores({
      chores: choreItems,
      priorities: ['HIGH'],
      exceptKinds: get(options, 'exceptKinds', []),
      noHidden: options.noHidden,
    });

    let count = 0;
    count = chores.filter(
      (c) => c.statusUpdatedAt > account.config.lastReadChoresAt
    ).length;
    if (chores.length > 99) {
      count = '99+';
    }

    this.unreadChores = chores
      .filter((c) => c.statusUpdatedAt > account.config.lastReadChoresAt)
      .map((c) => c.id);

    runInAction(() => {
      this.count = count;
    });

    return sortBy(chores, 'statusUpdatedAt').reverse();
  };

  getCursor = () => {
    return this.cursor;
  };

  fetchMore = async () => {
    if (
      this.parent.features.killChoresFlag ||
      this.fetching ||
      this.hasMore === false
    ) {
      return;
    }
    runInAction(() => {
      this.fetching = true;
    });
    const { data } = await this.transactions.api.fetchChores({
      cursor: this.cursor,
    });
    this.updateChores(data);
  };

  @action
  updateChores = (data) => {
    const chores = data.data;
    const choresById = keyBy(chores, 'id');
    const transactionsById = keyBy(
      chores.map((json) => json.thinTrans).filter((json) => json && json.id),
      'id'
    );
    this.transactions.updateThinAggregatesById(transactionsById);
    this.transactions.updateItemsById(choresById);
    runInAction(() => {
      this.cursor = data.cursor;
      this.fetching = false;
      this.hasMore = data.hasMore;
    });
  };

  @action
  markAsRead = async (account) => {
    runInAction(() => {
      account.updateConfig({
        lastReadChoresAt: new Date().getTime(),
      });
      this.count = '';
      this.unreadChores = [];
    });
  };
}
