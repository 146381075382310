import camelCase from 'lodash/camelCase';
import { computed, makeObservable } from 'mobx';
import moment from 'moment';
import { CamelCase } from 'type-fest';
import Item, { ItemStore, TransactionItemJson } from './item';

export type ChoreJson = TransactionItemJson<'CHORE'>;

export default class Chore extends Item<'CHORE'> {
  constructor(store: ItemStore, json: ChoreJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get itemIds() {
    return this.kindItem.itemIds;
  }

  @computed
  get priority() {
    return this.kindItem.priority;
  }

  @computed
  get comment() {
    return this.kindItem.comment;
  }

  @computed
  /** @deprecated never used */
  get markup() {
    return this.kindItem.markup;
  }

  @computed
  get didStart() {
    return this.kindItem.didStart;
  }

  @computed
  get allowedRoles() {
    return this.kindItem.allowedRoles;
  }

  @computed
  get choreKind() {
    return this.kindItem.choreKind;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get statusUpdatedAt() {
    return this.kindItem.statusUpdatedAt;
  }

  @computed
  get isNew() {
    return moment(Number(this.createdAt)) > moment().subtract(2, 'day');
  }

  @computed
  get choreKindItem() {
    return (
      this.kindItem[
        camelCase(this.choreKind) as CamelCase<
          Exclude<ItemChore['choreKind'], 'UNKNOWN'>
        >
      ] || {}
    );
  }

  @computed
  get isHideable() {
    return this.id && this.id !== '0';
  }

  @computed
  get isHidden() {
    const myUser =
      this.store.parent.account.user && this.store.parent.account.user.id;
    if (!myUser) {
      return true;
    }
    return (
      this.kindItem.hiddenForUsers &&
      this.kindItem.hiddenForUsers.includes(myUser)
    );
  }
}
