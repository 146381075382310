import { computed, makeObservable, override } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type TaskReminderJson = TransactionItemJson<'TASK_REMINDER'>;

export default class TaskReminder extends Item<'TASK_REMINDER'> {
  constructor(store: ItemStore, json: TaskReminderJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get reminder() {
    return this.kindItem.reminder;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get isDefault() {
    return this.reminder && this.reminder.isDefault;
  }

  @computed
  get key() {
    return this.reminder && this.reminder.key;
  }

  @computed
  get ccCreator() {
    return this.reminder && this.reminder.ccCreator;
  }

  @computed
  get statusDisplay() {
    const status = this.status;
    const sentDate = this.kindItem.calculatedSendDate;
    if (!status) {
      // eslint-disable-next-line consistent-return
      return `Due on ${sentDate}`;
    }
    if (status === 'SENT') {
      return `Sent on ${sentDate}`;
    }
    if (status === 'SKIPPED') {
      return 'Skipped';
    }
    if (status === 'FAILED') {
      return 'Failed';
    }
    return '';
  }

  @override
  get title() {
    return (this.reminder && this.reminder.title) || '';
  }

  @computed
  get schedule() {
    return this.reminder && this.reminder.schedule;
  }

  @computed
  get notify() {
    return this.reminder && this.reminder.notify;
  }

  @computed
  get triggerKey() {
    return this.reminder && this.reminder.triggerKey;
  }
}
