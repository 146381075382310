import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskAction from './action';
import navigateClick from 'src/utils/navigate-click';
import { inject, observer } from 'mobx-react';

export const viewSharePacket = ({ event, router, task }) => {
  const sharePacket = task.members.find((i) => i.kind === 'SHARE_PACKET');
  navigateClick(event, router, 'transactions.transaction.disclosures.packet', {
    transactionId: sharePacket.transactionId,
    packetId: sharePacket.parentId,
  });
};

@inject('router')
@observer
export default class ViewSharePacket extends Component {
  static propTypes = {
    task: PropTypes.object,
    transactions: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  handleClick = async () => {
    const { task, router } = this.props;
    const sharePacket = task.members.find((i) => i.kind === 'SHARE_PACKET');
    router.navigate('transactions.transaction.disclosures.packet', {
      transactionId: sharePacket.transactionId,
      packetId: sharePacket.parentId,
    });
  };

  render() {
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        View
      </TaskAction>
    );
  }
}
