import get from 'lodash/get';
import moment from 'moment';
import type { Field } from 'src/types/proto/reform';
import type {
  ItemEnvelopeDocument,
  ItemEnvelopeRecipient,
} from 'src/types/proto/signing';

interface Timezone {
  ts: number;
  timezone: string;
}

export default class EnvelopeDocumentDataSource {
  public namespace = window.Glide.CONSTANTS.FORM_NAMESPACE;

  public doc: ItemEnvelopeDocument;
  public timeStampFieldIds?: Field['id'][];
  public recipient: ItemEnvelopeRecipient;
  public ts: number;

  constructor(doc: ItemEnvelopeDocument, recipient: ItemEnvelopeRecipient) {
    this.doc = doc;
    this.recipient = recipient;
    this.ts = moment().valueOf();
    // HACK there must be some more systematic way of adding this kind of
    // functionality to boundField system, but it's unclear to me how so
    // a hack will do for now...
    this.timeStampFieldIds = (
      get(this.doc, 'fillConfig.reformForm.fields', []) as Field[]
    )
      .filter(
        (f) => f.kind === 'timestamp' && f.ownerId === recipient.externalId
      )
      .map((f) => f.id);
  }

  public getFieldValue(fieldId: string): Timezone | unknown | undefined {
    const res = get(this.doc, `formFill.fieldValues.['${fieldId}']`);
    if (!res && this.timeStampFieldIds?.includes(fieldId)) {
      return {
        ts: this.ts,
        timezone: 'US/Pacific',
      };
    }
    return res;
  }

  getFieldIsUnlinked(): boolean {
    return true;
  }
}
