import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';
import { DELETE } from '../actions/kinds';
import { viewSharePacket } from '../actions/view-share-packet';

export default {
  actions: [DELETE],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  label: 'Share documents portal',
  canAssign: false,
  canEditStatus: false,
  onClick: viewSharePacket,
  style: {
    color: '#FC6062',
    background: '#A4D1F8',
    border: '#FC6062',
  },
};
