import type { TmSubProduct } from '@uc/analytics-definitions';
import type { EmbeddedFeature } from 'src/stores/embedded-app-store';

export const getSubProduct = (
  embeddedFeature?: EmbeddedFeature
): TmSubProduct => {
  switch (embeddedFeature) {
    case 'offers':
    case 'offers-sellside':
      return 'offer_management_glide';
    case 'templates-and-clauses':
      return 'transaction_templates';
    case 'signatures':
      return 'esign';
    default:
      return 'document_files';
  }
};
