import range from 'lodash/range';

const MAX_CONCURRENCY = 100;

type PromiseInfo = [any, number];
type GetNext = () => PromiseInfo | undefined;
type Mapper = (param: any) => Promise<any>;
type SetResponse = (idx: number, res: any) => void;
type GetConsumer = (
  getNext: GetNext,
  mapper: Mapper,
  setResponse: SetResponse
) => () => Promise<any>;

const _getConsumer: GetConsumer =
  (getNext, mapper, setResponse) => async () => {
    const nextPromise = getNext();
    if (!nextPromise) {
      return null;
    }
    const [object, idx] = nextPromise;
    const res = await mapper(object);
    setResponse(idx, res);
    return _getConsumer(getNext, mapper, setResponse)();
  };

type Options = {
  concurrency?: number;
};
type BatchPromise = (
  _objects: any[],
  mapper: Mapper,
  options?: Options
) => Promise<any[]>;

const batchPromises: BatchPromise = async (_objects, mapper, options = {}) => {
  const { concurrency } = options || {};

  const objects = (_objects || []).map<PromiseInfo>((o, idx) => [o, idx]);

  const responses: any[] = [];

  const getNext = () => objects.shift();
  const setResponse: SetResponse = (idx, res) => {
    responses[idx] = res;
  };
  const consumersPool = range(
    Math.min(concurrency || MAX_CONCURRENCY, MAX_CONCURRENCY, objects.length)
  ).map(() => _getConsumer(getNext, mapper, setResponse));
  await Promise.all(consumersPool.map((consumer) => consumer()));

  return responses;
};

export default batchPromises;
