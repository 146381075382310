import debounce from 'lodash/debounce';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { getFetch } from 'src/utils/get-fetch';

export default class TransactionUsersStore {
  @observable usersByQueryParams = new Map();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.transactions;
  }

  get transactionsStore() {
    return this.parent.transactions;
  }

  initialize = () => {
    this.transactionsStore.subscribeAggregateReindexed(
      'reset',
      debounce(this.clearData, 1000)
    );
  };

  @action
  clearData = () => {
    this.usersByQueryParams = new Map();
  };

  getFindUsersKey = ({ permissions, teams, ...otherParams }) =>
    `${Array.from(
      new Set((permissions || []).map((p) => p.toUpperCase()))
    ).sort()}:${teams === 'yes' ? 'teams' : ''}:${
      Object.keys(otherParams).length ? JSON.stringify(otherParams) : ''
    }`;
  getFetchFindUsers = getFetch({
    bindTo: this,
    getMemoizeKey: this.getFindUsersKey,
    getter: (params) => this.usersByQueryParams[this.getFindUsersKey(params)],
    fetcher: async (params) => {
      const { data } = await this.api.fetchFindUsers(params);
      runInAction(() => {
        this.usersByQueryParams[this.getFindUsersKey(params)] = data;
      });
      return data;
    },
  });

  fetchTransactionUsers = (params) => this.getFetchFindUsers.fetch(params);
  getTransactionUsers = (params) => this.getFetchFindUsers.get(params);
  getOrFetchTransactionUsers = (params) =>
    this.getFetchFindUsers.getOrFetch(params);
}
