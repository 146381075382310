import { makeAutoObservable, toJS } from 'mobx';
import { SetRequired } from 'type-fest';
import uuid from 'uuid';
import type {
  NodeComponentType,
  TreeNodeType,
  TreeNodeTypeByComponentType,
} from './types';
import { isArray } from './utils';

export interface TreeNodeInterface<T extends NodeComponentType = any> {
  id?: string;
  type: string;
  component?: T;
  componentProps?: TreeNodeTypeByComponentType<T>['componentProps'];
}

export interface TreeNodeLike<T extends TreeNodeLike<T>> {
  children?: T[];
}

export interface NodeMatcher {
  (node: TreeNode): boolean;
}

export type emitChangeType = (
  targetNode: TreeNode,
  type: 'add' | 'remove' | 'move' | 'duplicate' | 'update'
) => void;

export type TreeNodeParent = SetRequired<TreeNode, 'children'>;

/**
 * A node in the form schema tree. Each node references parent and children. It supports deleting itself from the parent node, and inserting new items into the children. Just like the DOM structure.
 *
 * @see https://github.com/alibaba/designable/blob/main/packages/core/src/models/TreeNode.ts
 */
export class TreeNode implements TreeNodeInterface, TreeNodeLike<TreeNode> {
  private static cache = new Map<string, TreeNode>();

  /** Find node by id from global cache */
  static findById(id: string) {
    return TreeNode.cache.get(id);
  }

  private emitChange?: emitChangeType;
  readonly id: string;
  parent?: TreeNodeParent;
  type: string;
  component: NodeComponentType;
  componentProps: SetRequired<TreeNodeType, 'componentProps'>['componentProps'];
  children?: TreeNode[];

  // eslint-disable-next-line no-shadow
  constructor(data: TreeNodeType, parent?: TreeNodeParent) {
    this.id = data?.id ?? uuid.v4();
    this.parent = parent;
    this.type = data?.type ?? 'object';
    this.component = data?.component;
    this.componentProps = data?.componentProps ?? {};
    if (data && 'children' in data && isArray(data.children)) {
      this.children = data.children.map(
        (child: TreeNodeType) => new TreeNode(child, this as TreeNodeParent)
      );
    }

    TreeNode.cache.set(this.id, this);

    makeAutoObservable(this);
  }

  get root(): TreeNode {
    // eslint-disable-next-line no-shadow
    let parent = this.parent;
    if (!parent) {
      return this;
    }

    while (parent.parent) {
      parent = parent.parent;
    }
    return parent;
  }

  get rootEmitChange(): emitChangeType | undefined {
    return this.root.emitChange;
  }

  /** Its own index in the children of parent node */
  get index() {
    if (!this.parent) {
      return -1;
    }
    return this.parent.children?.indexOf(this);
  }

  /** Return the previous sibling of current node */
  get previous(): TreeNode | undefined {
    if (!this.parent) {
      return undefined;
    }

    const { index } = this;

    if (index > 0) {
      return this.parent.children[index - 1];
    }

    return undefined;
  }

  /** Return the next sibling of current node */
  get next(): TreeNode | undefined {
    if (!this.parent) {
      return undefined;
    }
    const { index } = this;

    if (index < this.parent.children.length - 1) {
      return this.parent.children[index + 1];
    }

    return undefined;
  }

  /** Get full path */
  get path(): TreeNode[] {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let node: TreeNode | undefined = this;
    const path: TreeNode[] = [];

    while (node) {
      path.unshift(node);
      node = node.parent;
    }

    return path;
  }

  /** Check if current node contains another node */
  contains(target: TreeNode): boolean {
    let node: TreeNode | undefined = target;
    while (node) {
      if (node === this) {
        return true;
      }
      node = node.parent;
    }

    return false;
  }

  /** Recursively iterate over itself and its children, until it returns no children or stops when false. */
  each(callback: (node: TreeNode) => any) {
    if (callback(this) !== false && isArray(this.children)) {
      this.children.forEach((child) => child.each(callback));
    }
  }

  /** Recursively iterate over itself and its children, and return the first one matches. */
  find(callback: NodeMatcher): TreeNode | undefined {
    if (callback(this)) {
      return this;
    }

    if (isArray(this.children)) {
      for (const child of this.children) {
        const found = child.find(callback);
        if (found) {
          return found;
        }
      }
    }

    return undefined;
  }

  /**
   * Recursively iterate over itself and its children, and return all matched nodes.
   *
   * If callback is not passed, all sub nodes including itself will be returned
   */
  findAll(
    callback: NodeMatcher = () => true,
    list: TreeNode[] = []
  ): TreeNode[] {
    if (callback(this)) {
      list.push(this);
    }

    if (isArray(this.children)) {
      for (const child of this.children) {
        child.findAll(callback, list);
      }
    }

    return list;
  }

  /** Recursively iterate over itself and its children, and return the node with the id. */
  findById(id: string): TreeNode | undefined {
    return this.find((n) => n.id === id);
  }

  onChange(emitChange: emitChangeType) {
    this.emitChange = emitChange;
  }

  /** Adopt a node, modify its parent to self. Skip nodes that already belong to self. Nodes are not yet inserted into children. */
  adopt(...nodes: TreeNode[]) {
    return nodes
      .filter((node) => node.parent !== this)
      .map((node) => {
        node.remove();
        node.parent = this as TreeNodeParent;
        node.each((n) => TreeNode.cache.set(n.id, n));

        return node;
      });
  }

  /** Recursive cloning of nodes and children. */
  // eslint-disable-next-line no-shadow
  clone(parent?: TreeNodeParent) {
    const newNode = new TreeNode(
      {
        id: uuid.v4(),
        type: this.type,
        component: this.component,
        componentProps: toJS(this.componentProps),
      } as TreeNodeType,
      parent
    );

    if (isArray(this.children)) {
      newNode.children = this.children.map((child) =>
        child.clone(newNode as TreeNodeParent)
      );
    }

    return newNode;
  }

  /** Deep cloning and duplicating. */
  duplicate() {
    const newNode = this.clone();
    this.parent?.insert(this.index + 1, newNode);

    this.rootEmitChange?.(this, 'duplicate');
    return newNode;
  }

  /** Move node to the new position of parent */
  move(to: number, parent = this.parent) {
    if (!parent) {
      return this;
    }
    if (parent === this.parent) {
      if (to !== this.index) {
        const { children } = parent;
        children.splice(to, 0, ...children.splice(this.index, 1));
      }
    } else {
      parent?.insert(to, this);
    }

    this.rootEmitChange?.(this, 'move');
    return this;
  }

  /** Remove itself from the parent node. Isolates the node from the tree. You can then insert it into the tree again. */
  remove() {
    if (!this.parent) {
      return this;
    }
    this.rootEmitChange?.(this, 'remove');
    this.parent.children.splice(this.index, 1);
    this.parent = undefined;
    this.each((n) => TreeNode.cache.delete(n.id));

    return this;
  }

  add(start: number, node: TreeNode) {
    this.rootEmitChange?.(node, 'add');
    return this.insert(start, ...[node]);
  }

  updateProps(props: TreeNodeType['componentProps']) {
    this.componentProps = props ?? {};
    this.rootEmitChange?.(this, 'update');
  }

  /** Inserts a set of nodes before the first child. */
  prepend(...nodes: TreeNode[]) {
    const newNodes = this.adopt(...nodes);
    this.children ??= [];
    this.children.unshift(...newNodes);

    return newNodes;
  }

  /** Inserts a set of nodes after the last child. */
  append(...nodes: TreeNode[]) {
    const newNodes = this.adopt(...nodes);
    this.children ??= [];
    this.children.push(...newNodes);

    return newNodes;
  }

  /** Insert a set of nodes at the start index. */
  insert(start: number, ...nodes: TreeNode[]) {
    const newNodes = this.adopt(...nodes);
    this.children ??= [];
    this.children.splice(start, 0, ...newNodes);

    return newNodes;
  }

  toJSON(): object {
    const { id, type, component, componentProps, children } = this;

    const jsonObject = {
      id,
      type,
      component,
      componentProps: toJS(componentProps),
      children: children?.map((child) => child.toJSON()),
    };

    return jsonObject;
  }
}
