import { computed, makeObservable } from 'mobx';
import { ItemNhdSelectionMode } from 'src/types/proto/transactions';
import Item, { ItemStore, TransactionItemJson } from './item';

export type NhdJson = TransactionItemJson<'NHD'>;

export default class Nhd extends Item<'NHD'> {
  constructor(store: ItemStore, json: NhdJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get status() {
    return this.kindItem?.status;
  }

  @computed
  get provider() {
    return this.kindItem?.provider;
  }

  @computed
  get tdvIds() {
    return this.kindItem?.tdvIds;
  }

  @computed
  get vendorCode() {
    return this.kindItem?.vendorCode;
  }

  @computed
  get isSellerSelectionMode() {
    return this.kindItem?.selectionMode === ItemNhdSelectionMode.SELLER;
  }
}
