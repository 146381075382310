/* eslint-disable */

import React from 'react';
import pdfImg from 'src/images/icons/zfx-file.png';

const ZfxIcon = ({ style, ...otherProps }) => (
  <img
    src={pdfImg}
    alt="ZFX Form"
    style={{
      width: 21,
      height: 28,
      ...style,
    }}
    {...otherProps}
  />
);

export default ZfxIcon;
