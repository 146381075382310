import BaseTermDelegate from './base';
import BooleanTermDelegate from './boolean';
import ComboTermDelegate from './combo';
import DatetimeTermDelegate from './datetime';
import MultipleSelectTermDelegate from './multiple-select';
import MutuallyExclusiveTermDelegate from './mutually-exclusive';
import RelativeDateTermDelegate from './relative-date';
import SectionTermDelegate from './section';
import SingleSelectTermDelegate from './single-select';
import StringFormatTermDelegate from './string-format';

const DELEGATES_BY_KIND = {
  BOOLEAN: BooleanTermDelegate,
  COMBO: ComboTermDelegate,
  DATETIME: DatetimeTermDelegate,
  MULTIPLE_SELECT: MultipleSelectTermDelegate,
  MUTUALLY_EXCLUSIVE: MutuallyExclusiveTermDelegate,
  RELATIVE_DATE: RelativeDateTermDelegate,
  SECTION: SectionTermDelegate,
  SINGLE_SELECT: SingleSelectTermDelegate,
  STRING_FORMAT: StringFormatTermDelegate,
};

export default function getDelegate(term) {
  const Cls = DELEGATES_BY_KIND[term.kind] || BaseTermDelegate;
  return new Cls(term);
}
