import { FC, SVGProps } from 'react';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { isObservableArray } from 'mobx';
import { Rule } from 'rc-form';
import { componentConfig, ComponentConfigItemType } from './component-config';
import type { TreeNode } from './tree-node';
import type {
  NodeComponentType,
  ContainerNode,
  FieldNode,
  OtherNode,
  TreeNodeType,
  ComponentWithOptionsData,
  MultiInputOption,
} from './types';
import type { FormBuilderStore } from '.';

type FindNodeAlreadyUsingAnnotationId = {
  /** an annotationId which is going to be used */
  annotationId: string;
  /** the node which is going to use the annotationId */
  currentNode: TreeNode;
  /** the node to be checked if it is using the annotationId */
  node: TreeNode;
  /** the node to be checked if it is using the annotationId */
  optionIndex?: number;
};

type RequiredRule = {
  /** the error message to show when get required error */
  message?: string;
  /** the target to show in `${target}` cannot be blank */
  target?: string;
};

type IsNotCurrentNodeOrCurrentOption = {
  /** the node which is going to use a annotationId */
  currentNode: TreeNode;
  /** the node that is already using a annotationId */
  node: TreeNode;
  /** the index of the node in a children list */
  nodeIndex: number;
  /** the index of the option in a option list */
  optionIndex: number;
};

export function getComponentConfig<T extends NodeComponentType = any>(
  type: T
): ComponentConfigItemType<T> {
  return componentConfig[type];
}

export function isContainerNode(
  node: TreeNodeType | TreeNode
): node is ContainerNode {
  const config = getComponentConfig(node.component);
  return config.category === 'container';
}

export function isFieldNode(node: TreeNodeType | TreeNode): node is FieldNode {
  const config = getComponentConfig(node.component);
  return config.category === 'field';
}

export function isOtherNode(node: TreeNodeType | TreeNode): node is OtherNode {
  const config = getComponentConfig(node.component);
  return config.category === 'other';
}

export function isFormComponentNode(
  node: TreeNodeType | TreeNode
): node is OtherNode {
  const config = getComponentConfig(node.component);
  return config.category === 'field' || config.category === 'other';
}

export function getComponentIcon(
  type: NodeComponentType
): FC<SVGProps<SVGElement>> | string | undefined {
  const config = getComponentConfig(type);
  return config?.icon;
}

export function getComponentTitle(node: TreeNodeType | TreeNode): string {
  if (!node) {
    return '';
  }
  node = node as TreeNodeType;
  const config = getComponentConfig(node.component);
  let { title, num } = node.componentProps;

  if (!title && !num && config.type === 'Term') {
    return `Hidden ${config.name}`;
  }

  title = title || config.name;
  if (num) {
    if (!/\.$/.test(num)) {
      num += '.';
    }
    title = `${num} ${title}`;
  }
  return title;
}

export function getDeleteNodeDialogDescription(node: TreeNode): string {
  let description = '';

  switch (node.component) {
    case 'Section':
      description =
        'Are you sure you want to delete this form section ? Any saved configurations, child elements, and content will be deleted as well.';
      break;
    case 'Term':
      description =
        'Are you sure you want to delete this term? Any saved configurations, child elements, and content will be deleted as well.';
      break;
    default:
      description = 'Are you sure you want to delete this component?';
      break;
  }

  return description;
}

export function findNodeParent(
  node: TreeNode | undefined | null,
  filter: (n: TreeNode) => boolean
): TreeNode | undefined {
  let n = node;
  while (n) {
    if (filter(n)) {
      return n;
    }
    n = n.parent;
  }
  return undefined;
}

export function getComponentTypeLevel(type: NodeComponentType): number {
  const types: NodeComponentType[] = ['Form', 'Section', 'Term'];
  const level = types.indexOf(type);
  return level >= 0 ? level : types.length;
}

/**
 * Loosely array type checking, for mobx legacy mode:
 * ```js
 * configure({ useProxies: 'never' })
 * ```
 * @see https://mobx.js.org/configuration.html#limitations-without-proxy-support
 */
export function isArray<T = any>(arr: any): arr is T[] {
  return Array.isArray(arr) || isObservableArray(arr);
}

const isOptionUsingAnnotationId = (
  option: MultiInputOption,
  annotationId: string
) =>
  option.annotationId === annotationId ||
  option.textAnnotationId === annotationId;

const isNotCurrentNodeOrCurrentOption = ({
  currentNode,
  node,
  nodeIndex,
  optionIndex,
}: IsNotCurrentNodeOrCurrentOption) =>
  !isEqual(currentNode, node) || nodeIndex !== optionIndex;

export const findNodeAlreadyUsingAnnotationId = ({
  annotationId,
  currentNode,
  node,
  optionIndex,
}: FindNodeAlreadyUsingAnnotationId): TreeNode | undefined =>
  node.find(
    (treeNode) =>
      /*
       * Try to find a node that is
       * 1. it is not current node
       * 2. its annotationId is equal to the annotationId that is going to be used
       */
      (!isEqual(currentNode, treeNode) &&
        treeNode.componentProps?.annotationId === annotationId) ||
      (treeNode.componentProps as ComponentWithOptionsData)?.options?.some(
        /*
         * If it cannot find a node above, trying to find
         * a node that is already using the annotationId should meet the following conditions at the same time
         * 1. one of the options of a node is using the annotation id
         * 2. the option using the annotation id belongs to the other node or the option belongs to current node but is the other option
         */
        (option, nodeIndex) =>
          isOptionUsingAnnotationId(option, annotationId) &&
          isNotCurrentNodeOrCurrentOption({
            currentNode,
            node: treeNode,
            nodeIndex,
            optionIndex,
          })
      )
  );

export const getRequiredRules = ({ target, message }: RequiredRule): Rule[] => {
  return [
    {
      required: true,
      message: message || (target ? `${target} can not be blank` : undefined),
    },
  ];
};

export const getNestedObjectKeyPaths = (obj, prefix = ''): string[] =>
  Object.keys(obj).reduce((result, key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      return [
        ...result,
        ...getNestedObjectKeyPaths(obj[key], `${prefix + key}.`),
      ];
    }
    return [...result, prefix + key];
  }, []);

/**
 * the key of `store.annotationsBeingUsed` map is expected to be `${node.id}.${annotationFieldKeyPath}
 * @param {string} nodeId node.id
 * @param {string} path path to annotation field
 * @returns {string} key
 */
export const getAnnotationBeingUsedKey = (nodeId: string, path: string) =>
  `${nodeId}.${path}`;

export function handleFormValuesChange<T = any>({
  changedValue,
  value,
  store,
  node,
}: {
  changedValue: T;
  value: T;
  store: FormBuilderStore;
  node: TreeNode;
}) {
  const changedValueKey = getNestedObjectKeyPaths(changedValue)[0];
  // detect if annotation related field changes
  if (
    changedValueKey?.includes('annotationId') ||
    changedValueKey?.includes('textAnnotationId')
  ) {
    const annotationId = get(value, changedValueKey);
    const annotationKey = getAnnotationBeingUsedKey(node.id, changedValueKey);

    // get usedAnnotationId before `store.addAnnotationBeingUsed` and `store.removeAnnotationBeingUsed`
    const usedAnnotationId = store.annotationsBeingUsed.get(annotationKey);

    if (annotationId) {
      store.addAnnotationBeingUsed(annotationKey, annotationId);
    } else {
      store.removeAnnotationBeingUsed(annotationKey);
    }

    /**
     * Although we disabled being used annotation id in annotation option list,
     * we might still have annotation id being used errors since user can import config
     * to render the form builder,
     * so here when annotation field changes, we run validation
     * for the components which are using the annotation id.
     */
    if (usedAnnotationId) {
      store.runValidationByAnnotationId(usedAnnotationId);
    }
  }
}
