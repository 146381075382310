import { COMPLETE_TASK, OPEN_SHARE_PACKET_MODEL } from '../actions/kinds';
import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';
import { openSharePacketModel } from '../actions/open-share-packet-model';

export default {
  actions: [OPEN_SHARE_PACKET_MODEL, COMPLETE_TASK],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  actionsVisible: {
    COMPLETE_TASK: 'canCompleteTask',
  },
  label: 'Share Disclosure Package',
  onClick: openSharePacketModel,
  getStatusMeta: (task) => {
    if (task.status === 'OPEN') {
      return {
        message:
          'Share your disclosure package with anyone via email or web link.',
      };
    } else if (task.status === 'CLOSED') {
      return {
        message:
          'You already shared your package. Change your sharing settings at any time.',
      };
    }
    return {};
  },
};
