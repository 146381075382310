

import AnchorButton from 'src/components/common/anchor-button';
import AppModal from 'src/components/common/app-modal';
import PromiseButton from 'src/components/common/promise-button';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SingleSelect from 'src/components/forms/single-select';
import api from 'src/api/public-api';
import { CheckOutlined } from '@ant-design/icons';
import { inject, observer } from 'mobx-react';

const clsPrefix = 'app-zipform-all-forms';

@inject('uploads')
@observer
export default class ZipFormAllForms extends Component {
  static propTypes = {
    txnId: PropTypes.any,
    onFormAdd: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      zfLibs: [],
      selectedZfLibId: undefined,
      zfForms: [],
      selectedForms: [],
    };
  }

  showModal = async () => {
    const { txnId } = this.props;
    const { data } = await api.integrations.zipformLibraries(txnId);
    this.setState({
      shouldShowModal: true,
      zfLibs: data,
    });
  };

  hideModal = () => {
    this.setState({
      shouldShowModal: false,
    });
  };

  onLibSelect = async (selectedZfLibId) => {
    const { txnId } = this.props;
    this.setState({
      selectedZfLibId,
    });
    const selectedZfLib = this.state.zfLibs.find(
      (lib) => lib.id === selectedZfLibId
    );
    const { data } = await api.integrations.zipformLibraryForms(
      txnId,
      selectedZfLib.id,
      selectedZfLib.name,
      selectedZfLib.version
    );
    this.setState({
      zfForms: data,
    });
  };

  onFormAdd = async (f) => {
    const { txnId, onFormAdd } = this.props;
    await api.integrations.zipformAddForm(txnId, f.id);
    this.setState({
      selectedForms: this.state.selectedForms.concat(f.id),
    });
    onFormAdd(f);
  };

  render() {
    return (
      <React.Fragment>
        <AppModal
          className={clsPrefix}
          key="zipform-all-forms"
          visible={this.state.shouldShowModal}
          cancelable
          width={640}
          maskClosable={false}
          onCancel={this.hideModal}
          onOk={this.hideModal}
          title="Add other forms"
        >
          <SingleSelect
            className={`${clsPrefix}__lib`}
            size="large"
            placeholder="Select a form library"
            onChange={this.onLibSelect}
          >
            {this.state.zfLibs.map((lib) => (
              <SingleSelect.Option key={lib.id} value={lib.id}>
                {lib.name} - {lib.description}
              </SingleSelect.Option>
            ))}
          </SingleSelect>
          {this.state.selectedZfLibId ? (
            <div className={`${clsPrefix}__form-list`}>
              {this.state.zfForms.map((f) => (
                <div key={f.id} className={`${clsPrefix}__form-wrap`}>
                  <div className={`${clsPrefix}__form-name`}>{f.name}</div>
                  <div className={`${clsPrefix}__form-action`}>
                    {this.state.selectedForms.includes(f.id) > 0 ? (
                      <div className={`${clsPrefix}__form-added`}>
                        <CheckOutlined />
                        <span> Added </span>
                      </div>
                    ) : (
                      <PromiseButton
                        size="small"
                        onClick={() => this.onFormAdd(f)}
                      >
                        Add
                      </PromiseButton>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={`${clsPrefix}__no-lib`}>
              Please select a form library.
            </div>
          )}
        </AppModal>
        <AnchorButton type="primary" onClick={this.showModal}>
          All Forms
        </AnchorButton>
      </React.Fragment>
    );
  }
}
