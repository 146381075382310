/**
 * Check if current user agent is mobile device.
 *
 * iOS is added for webview of Glide mobile app
 */
export default function isMobileUserAgent() {
  return /Android|webOS|iPhone|iPad|iPod|iOS|Opera Mini/i.test(
    navigator.userAgent
  );
}
