import { action, makeObservable, observable } from 'mobx';
import type { AppStore } from './app-store';
import type Documents from 'src/api/public/documents';
import FillConfig from 'src/models/forms/fill-config';
import { getFetch } from 'src/utils/get-fetch';
import type TransactionDocument from 'src/models/transactions/items/transaction-document';

type fillConfigParams = {
  formUuid: string;
  td?: TransactionDocument;
};

export default class FillConfigStore {
  // Do not fetch fill config by autoinc id for security reasons.
  @observable fillConfigByFormUuid = new Map<string, FillConfig>();
  parent: AppStore;
  api: Documents;

  constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.documents;
  }

  @action
  updateModels(fillConfigs: [string, any][]) {
    fillConfigs.forEach(([key, data]) => {
      const m = new FillConfig(this, data);
      const existing = this.fillConfigByFormUuid.get(key);
      if (existing) {
        existing.updateFromJson(m);
      } else {
        this.fillConfigByFormUuid.set(key, m);
      }
    });
  }

  getFillConfigMemoizeKey = ({ formUuid, td }: fillConfigParams) => {
    /*
    The TD's namespace scope needs to be part of the memoize key since the TD changing
    namespace scope after the fill config of its underlying form has already been loaded
    can cause a different equivalencies being computed, without either the form's UUID nor
    the TD's id changing. In essence, including this forces the fill-config to be re-fetched
    on the same session if a fillable TD changes namespaceScope (current case for this exists
    when moving a TD from the property into an offer when starting an offer based on the
    documents previously added directly into the property)
    */
    return [formUuid, td?.id, td?.itemNamespace?.namespaceScopeKey]
      .filter(Boolean)
      .join(':');
  };

  getFetchFillConfigByFormUuid = getFetch({
    bindTo: this,
    getter: (args: fillConfigParams) => {
      return this.fillConfigByFormUuid.get(this.getFillConfigMemoizeKey(args));
    },
    getMemoizeKey: this.getFillConfigMemoizeKey,
    fetcher: async ({ formUuid, td }: fillConfigParams) => {
      const { data } = await this.api.getFillConfigByFormUuid(formUuid, td?.id);
      this.updateModels([
        [
          this.getFillConfigMemoizeKey({
            formUuid,
            td,
          }),
          data,
        ],
      ]);
    },
  });

  @action
  clearCacheForDocument(td: TransactionDocument) {
    if (!td?.latestVersion?.form) {
      return;
    }

    this.fillConfigByFormUuid.delete(
      this.getFillConfigMemoizeKey({
        formUuid: td.latestVersion.form.uuid,
        td,
      })
    );
  }

  @action
  clearAll() {
    this.fillConfigByFormUuid.clear();
  }
}
