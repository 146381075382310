import debounce from 'lodash/debounce';
import zip from 'lodash/zip';

type Resolve<T> = (value?: T) => void;

const debounceBatch = <T extends unknown[], R>(
  processor: (calls: T[]) => Promise<R[] | undefined>,
  wait = 100
) => {
  /*
    This is a util for batching.

    pass in the multi compute function and get
    out a single compute function that automatically
    batches together calls that are close together in
    time

    an example serves best to illustrate

    debounced = debounceBatch(async (x) => {
        await delayPromise(1000)
        return x.map(([y]) => y * 2)
    })
    await Promise.all([
      debounced(3),
      debounced(4),
      debounced(5),
    ])

    This will return [6, 8, 10], but only call the
    processing function passed to debounceBatch once
  */
  const calls: T[] = [];
  const callbacks: Resolve<R>[] = [];
  const runner = debounce(
    async () => {
      const callsToRun = calls.splice(0, calls.length);
      const callbacksToRun = callbacks.splice(0, callbacks.length);
      calls.splice(0, calls.length); // clear
      callbacks.splice(0, callbacks.length);
      const results = await processor(callsToRun);
      if (results === undefined) {
        callbacksToRun.forEach((callback) => callback());
      } else {
        zip(results, callbacksToRun).forEach(([result, callback]) => {
          return callback?.(result);
        });
      }
    },
    wait,
    {
      leading: false,
      trailing: true,
    }
  );
  return (...args: T) => {
    calls.push(args);
    const res = new Promise<R>((resolve) => {
      callbacks.push(resolve as Resolve<R>);
    });
    runner();
    return res;
  };
};

export default debounceBatch;
