export const DATE = 'DATE';
export const DESCRIPTION = 'DESCRIPTION';
export const ATTACHMENTS = 'ATTACHMENTS';
export const ACTIVITIES = 'ACTIVITIES';
export const ASSIGNEE = 'ASSIGNEE';

export const DEFAULT_SECTIONS = [
  ASSIGNEE,
  DATE,
  DESCRIPTION,
  ATTACHMENTS,
  ACTIVITIES,
];

export default [ASSIGNEE, DATE, DESCRIPTION, ATTACHMENTS, ACTIVITIES];
