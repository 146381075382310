import { COMPLETE_TASK, START_SIGNATURE_WIZARD } from '../actions/kinds';
import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';

export default {
  actions: [START_SIGNATURE_WIZARD, COMPLETE_TASK],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  actionsVisible: {
    COMPLETE_TASK: 'canCompleteTask',
  },
  label: 'eSign Your Disclosure Documents',
  onClick: async () => {
    const {
      startSignatureWizard,
    } = await import('../actions/start-signature-wizard');
    startSignatureWizard();
  },
  getStatusMeta: (task) => {
    if (task.status === 'OPEN') {
      return {
        message:
          'Request eSignatures for your disclosure documents via DocuSign.',
      };
    } else if (task.status === 'CLOSED') {
      return {
        message:
          'You requested signatures for disclosure documents. Request more at any time.',
      };
    }
    return {};
  },
};
