import { ReactElement, useEffect, useRef } from 'react';
import { tmLoadModal } from '@uc-tm/modal-loader';

export interface TmModalLoaderProps<TInput = any, TResult = any> {
  uri: string;
  data: TInput;
  visible: boolean;
  onSuccess?: (result: TResult) => void;
  onClose: () => void;
  onError?: (error: any) => void;
}

export function TmModalLoader<I = any, R = any>(
  props: TmModalLoaderProps<I, R>
): ReactElement | null {
  const { uri, data, visible, onSuccess, onClose, onError } = props;
  const modalHandleRef = useRef<ReturnType<typeof tmLoadModal> | null>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const load = async () => {
      try {
        const handler = tmLoadModal(uri, data);
        modalHandleRef.current = handler;
        const result = await handler;
        onSuccess?.(result as any);
      } catch (err) {
        if (err) {
          console.error(err);
          onError?.(err);
        } else {
          onClose();
        }
      }
    };

    if (visible) {
      load();
      // close the remote modal if the parent component is unloaded
      return () => {
        modalHandleRef.current?.close?.();
      };
      // eslint-disable-next-line no-else-return
    } else if (modalHandleRef.current) {
      modalHandleRef.current.close?.();
      modalHandleRef.current = null;
    }
  }, [visible]);

  return null;
}
