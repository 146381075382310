import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import values from 'lodash/values';
import { ObservableMap, makeObservable, observable, runInAction } from 'mobx';
import UserContact from 'src/models/auth/user-contact';

export default class ContactStore {
  @observable userContactsById = new ObservableMap();
  @observable userContactIdByEmail = new ObservableMap();
  @observable _accessedEmails = new ObservableMap();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.auth;
  }

  userContactsByEmail = (fetch, emails) => {
    const data = mapValues(keyBy(emails), (email) => {
      const userContactId = this.userContactIdByEmail.get(email);
      return userContactId && this.userContactsById.get(userContactId);
    });
    const missingEmails = emails.filter((email) => data[email] === undefined);
    if (missingEmails.length > 0 && fetch) {
      const toFetchEmails = missingEmails.filter(
        (email) => !this._accessedEmails.get(email)
      );
      if (toFetchEmails.length > 0) {
        runInAction(() => {
          toFetchEmails.forEach((email) => {
            this._accessedEmails.set(email, true);
          });
        });
        this.api.findUserContactsByEmail(toFetchEmails).then((response) => {
          const userContactByEmail = {};
          response.data.forEach((userContact) => {
            userContactByEmail[userContact.contact.email] = userContact;
          });
          runInAction(() => {
            toFetchEmails.forEach((email) => {
              const userContactJson = userContactByEmail[email];
              if (userContactJson) {
                const userContact = new UserContact(userContactJson);
                this.userContactsById.set(userContact.id, userContact);
                this.userContactIdByEmail.set(email, userContact.id);
              } else {
                this.userContactIdByEmail.set(email, null);
              }
            });
          });
        });
      }
    }
    const complete = !values(data).some(
      (userContact) => userContact === undefined
    );
    return {
      data,
      complete,
    };
  };
}
