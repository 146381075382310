import { makeObservable, observable } from 'mobx';
import type Comms from 'src/api/public/comms';
import type Party from 'src/models/transactions/items/party';
import type { EmailMessage } from 'src/types/proto/emails';
import type { AppStore } from './app-store';

export default class CommsStore {
  @observable emailsById = new Map<string, EmailMessage>();

  parent: AppStore;
  api: Comms;

  constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.comms;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  initialize = () => {};

  fetchEmails = async (
    transactionId: string,
    parties: Party[],
    offset: number,
    limit: number
  ) => {
    const partyIds = (parties || []).map((p) => p.id);
    const { data } = await this.api.fetchEmails(transactionId, {
      parties: partyIds,
      offset,
      limit,
    });
    const emails = data.data;
    this.updateEmails(emails);
    return data;
  };

  // TODO: add a getFetch function, so we don't fetch every time

  updateEmails = (emails: EmailMessage[]) => {
    emails.forEach((email) => {
      this.emailsById.set(email.id, email);
    });
  };
}
