import BaseTermDelegate from './base';
import { computed, makeObservable, override } from 'mobx';

const RELATIVE = 'relative';
const FIXED_DATE = 'fixed_date';
const RADIO = 'radio';

export default class RelativeDateTermDelegate extends BaseTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  @computed
  get radio() {
    return this.term.kindData?.radio?.hasRadio
      ? this.term.kindData?.radio || {}
      : null;
  }

  @computed
  get radioFixedDateValue() {
    return this.radio?.fixedDateValue;
  }

  @computed
  get radioRelativeValue() {
    return this.radio?.relativeValue;
  }

  @computed
  get relativeDescription() {
    return this.term.kindData.relativeDescription || 'Relative';
  }

  get relative() {
    return RELATIVE;
  }

  get fixedDate() {
    return FIXED_DATE;
  }

  @override
  get mainFieldKey() {
    return 'relative';
  }

  @computed
  get relativeBoundOutput() {
    return this.getBoundOutput(this.relative);
  }

  @computed
  get fixedDateBoundOutput() {
    return this.getBoundOutput(this.fixedDate);
  }

  @computed
  get radioBoundOutput() {
    return this.radio ? this.getBoundOutput(RADIO) : undefined;
  }

  getKindBoundOutput(dateKind) {
    if (dateKind === this.relative) {
      return this.relativeBoundOutput;
    } else if (dateKind === this.fixedDate) {
      return this.fixedDateBoundOutput;
    }

    return undefined;
  }

  @override
  get boundOutputs() {
    return [
      this.relativeBoundOutput,
      this.fixedDateBoundOutput,
      this.radio ? this.getBoundOutput('radio') : null,
    ].filter(Boolean);
  }

  getDateKind(v) {
    const value = (v !== undefined ? v : this.term.value) || {};
    return [RELATIVE, FIXED_DATE].includes(value['']) ? value[''] : undefined;
  }

  @computed
  get dateKind() {
    return this.getDateKind();
  }

  getOtherKind(v) {
    if (!v || ![this.relative, this.fixedDate].includes(v)) {
      return v;
    }

    return v !== this.fixedDate ? this.fixedDate : this.relative;
  }

  getActiveBoundOutput(value) {
    const dateKind = this.getDateKind(value);
    return dateKind ? this.getBoundOutput(dateKind) : undefined;
  }

  @computed
  get activeBoundOutput() {
    return this.getActiveBoundOutput();
  }

  renderReadOnlyValue() {
    const { dateKind, activeBoundOutput } = this;
    const value = activeBoundOutput
      ? activeBoundOutput.getFieldValue('this')
      : undefined;
    return value && dateKind === this.relative
      ? `${value} ${this.relativeDescription}`
      : value;
  }

  getValuesByFormFieldId(value_) {
    const value = value_ || {};
    const relative = value[''] === this.relative;
    const fixedDate = value[''] === this.fixedDate;
    let radioValue = null;
    if (relative) {
      radioValue = this.radioRelativeValue;
    } else if (fixedDate) {
      radioValue = this.radioFixedDateValue;
    }

    return {
      [this.term.getFieldId(this.relative)]: relative
        ? value[this.relative]
        : null,
      [this.term.getFieldId(this.fixedDate)]: fixedDate
        ? value[this.fixedDate]
        : null,
      ...(this.radio
        ? {
            [this.term.getFieldId(RADIO)]: radioValue,
          }
        : {}),
    };
  }

  getCurrentValue() {
    const {
      radioBoundOutput,
      radioRelativeValue,
      relativeBoundOutput,
      fixedDateBoundOutput,
      relative,
      fixedDate,
    } = this;

    const radioValue =
      radioBoundOutput && radioBoundOutput.getFieldValue('this');
    const relativeValue = relativeBoundOutput.getFieldValue('this');
    const fixedDateValue = fixedDateBoundOutput.getFieldValue('this');

    let dateKind = null;
    if (relativeValue) {
      dateKind = relative;
    } else if (fixedDateValue) {
      dateKind = fixedDate;
    } else if (radioValue) {
      dateKind = radioValue === radioRelativeValue ? relative : fixedDate;
    }

    return {
      '': dateKind,
      [relative]: dateKind === relative ? relativeValue : null,
      [fixedDate]: dateKind === fixedDate ? fixedDateValue : null,
    };
  }

  getEvalValue(value_) {
    const value = value_ || {};
    return (
      {
        [this.relative]: value[this.relative],
        [this.fixedDate]: value[this.fixedDate],
      }[value['']] ?? null
    );
  }
}
