import debounce from 'lodash/debounce';
import difference from 'lodash/difference';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import {
  action,
  autorun,
  computed,
  makeObservable,
  observable,
  reaction,
  runInAction,
  toJS,
} from 'mobx';
import logger from 'src/logger';
import TransactionTemplate from 'src/models/transaction-templates/transaction-template';
import { create, load } from 'src/models/transactions/intents';
import { makeItem } from 'src/models/transactions/items';
import { getDefaultTdFetchOptions } from 'src/models/transactions/items/transaction-document';
import TransactionSettings from 'src/models/transactions/settings';
import ThinTransaction from 'src/models/transactions/thin-transaction';
import Transaction, {
  CATEGORY_ACTIVE,
  CATEGORY_ARCHIVED,
  CATEGORY_CLOSED,
} from 'src/models/transactions/transaction';
import debounceBatch from 'src/utils/debounce-batch';
import { getFetch } from 'src/utils/get-fetch';
import getLocalStorage from 'src/utils/get-local-storage';
import toArray from 'src/utils/to-array';
import AggregateStore, {
  IDX_ITEM_TRANSACTION_ID_KIND,
  IDX_KIND,
} from './aggregate-store';

const CACHE_INVALIDATION_DEBOUNCE = 100;

export { IDX_ITEM_TRANSACTION_ID_KIND, IDX_KIND };
export const IDX_TASK_TRANSACTION_ID_BOARD_ID = 'tradnsactionId_BoardId';

const LOCAL_STORAGE_TRANSACTIONS_BY_ID_KEY = 'transactionsById';

const FILTERS = {
  TRANSACTION_DOCUMENT: {
    not_trash: (i) => {
      return i.folderType !== 'TRASH';
    },
    trash: (i) => {
      return i.folderType === 'TRASH';
    },
  },
  TRANSACTION_PACKAGE: {
    offer: (i) => {
      return i.packageKind === 'OFFER';
    },
  },
};

export default class TransactionStore extends AggregateStore {
  teamsById = new Map();
  static extraItemsIndices = {
    [IDX_TASK_TRANSACTION_ID_BOARD_ID]: {
      keyBy: ['transactionId', 'boardId'],
      computed: {
        get active() {
          return this.all.filter((i) => !i.archived);
        },
      },
    },
  };
  static itemFilters = FILTERS;
  static ThinAggregate = ThinTransaction;

  @observable dmsSettings = new Map();
  @observable layoutStoreByTransactionId = new Map();
  @observable dispatchResponseByClientId = new Map();
  @observable usersWithAccessByTransactionId = new Map();
  @observable transactionsFormsByTransactionId = new Map();
  @observable overviewByTransactionId = new Map();
  @observable transactionSettingsByTransactionId = new Map();
  // This is used primarily to invalidate various caches
  // that depend on transaction.state, transaction.archived
  // in lists. It is updated in a debounced way.
  @observable
  transactionsInvalidationInfo = {
    archived: null,
    active: null,
    complete: null,
    size: null,
    ts: 0,
  };

  constructor(parent) {
    super(parent);

    makeObservable(this);
  }

  @computed
  get api() {
    return this.parent.api.transactions;
  }

  get transactionsById() {
    return this.aggregatesById;
  }

  get getFetchTransaction() {
    return this.getFetchAggregate;
  }

  @computed
  get activitiesApi() {
    return this.parent.api.activities;
  }

  @computed
  get transactionDocuments() {
    return this.parent.transactionDocuments;
  }
  @computed
  get tasks() {
    return this.parent.tasks;
  }
  @computed
  get chores() {
    return this.parent.chores;
  }

  @computed
  get gfps() {
    return this.parent.gfps;
  }

  @computed
  get transactionForms() {
    return this.parent.transactionForms;
  }
  /* Computed properties for transactions */

  get recentTransactionsList() {
    return this.recentAggregatesList;
  }

  get account() {
    return this.parent.account;
  }

  /* Methods for transactions */
  trackTransactionInvalidationInfo() {
    if (this.stopTrackingTransactionInvalidationInfo) {
      this.stopTrackingTransactionInvalidationInfo();
    }

    this.stopTrackingTransactionInvalidationInfo = autorun(
      this.invalidateTransactionInfo,
      {
        delay: CACHE_INVALIDATION_DEBOUNCE,
      }
    );
  }

  reactAfterToTransactionInvalidation = async (
    asyncFn,
    reactFn,
    setDisposer
  ) => {
    let res;
    let succeeded;
    let prevInfo;

    try {
      res = await asyncFn();
      succeeded = true;
      prevInfo = this.computeInvalidateTransactionInfo();
    } finally {
      const disposer = reaction(
        () => this.transactionsInvalidationInfo,
        (info) => {
          if (
            succeeded &&
            prevInfo &&
            isEqual(prevInfo, omit(toJS(info), 'ts'))
          ) {
            return;
          }
          reactFn(info);
          prevInfo = null;
        }
      );
      setDisposer(disposer);
    }
    return res;
  };

  reactToTransactionInvalidation(fn) {
    return reaction(() => {
      return this.transactionsInvalidationInfo;
    }, fn);
  }

  computeInvalidateTransactionInfo = () => {
    let archived = 0;
    let active = 0;
    let complete = 0;

    this.transactionsById.forEach((transaction) => {
      if (transaction.category === CATEGORY_ARCHIVED) {
        archived += 1;
      } else if (transaction.category === CATEGORY_ACTIVE) {
        active += 1;
      } else if (transaction.category === CATEGORY_CLOSED) {
        complete += 1;
      }
    });

    return {
      size: this.aggregatesById.size,
      active,
      complete,
      archived,
    };
  };

  invalidateTransactionInfo = () => {
    const info = this.computeInvalidateTransactionInfo();
    runInAction(() => {
      this.transactionsInvalidationInfo = {
        ts: Date.now(),
        ...info,
      };
    });
  };

  onItemBackendUpdates = (items) => {
    if (!this.debouncedTaskReload) {
      this.debouncedTaskReload = debounce(this.parent.tasks.reloadTasks, 1000);
    }
    const tasksUpdated = items.filter((i) => i.kind === 'TASK').length > 0;
    if (tasksUpdated) {
      this.debouncedTaskReload();
    }
  };

  reloadTransactionItemBatched = debounceBatch(async (calls) => {
    // transactionId, itemId
    const callsByTransactionId = groupBy(calls, (call) => call[0]);
    await Promise.all(
      toPairs(callsByTransactionId).map(
        async ([transactionId, transactionCalls]) => {
          const itemIds = uniq(transactionCalls.map((call) => call[1]));
          const items = await this.getOrFetchItemMulti(
            transactionId,
            null,
            itemIds,
            {
              force: true,
            }
          );
          this.onItemBackendUpdates(items);
        }
      )
    );
  }, 200);

  reloadTransactionItemsHandler = async ({
    transactionId,
    itemIds: itemIds_,
    deletedItemIds,
  }) => {
    logger.log('reloadTransactionItems', {
      transactionId,
      itemIds: itemIds_,
      deletedItemIds,
    });
    if (deletedItemIds && deletedItemIds.length) {
      this.deleteItemsById(deletedItemIds);
    }

    const itemIds = (itemIds_ || []).filter((itemId) =>
      this.itemsById.has(itemId)
    );
    if (itemIds.length) {
      itemIds.forEach((itemId) =>
        this.reloadTransactionItemBatched(transactionId, itemId)
      );
    }
  };

  initialize = () => {
    if (this.account.isAuthenticated) {
      this.account.subscribeUserEvent(
        'dispatchResponse',
        this.dispatchResponseHandler
      );
      this.account.subscribeUserEvent(
        'asyncDispatchError',
        this.dispatchErrorResponseHandler
      );
      this.account.subscribeUserEvent(
        'aggregateReindex',
        this.aggregateReindexHandler
      );
    }
    this.account.subscribeUserEvent(
      'reloadTransactionItems',
      this.reloadTransactionItemsHandler
    );
  };

  getClass = (json) =>
    ({
      TEMPLATE: TransactionTemplate,
    }[get(json, 'meta.state')] || Transaction);

  makeItem = (item) => {
    return makeItem(this, item);
  };

  updatedItem = (item) => {
    if (
      item.kind === 'PARTY' &&
      item.transaction &&
      !item.removed &&
      !item.unbound
    ) {
      item.transaction.parties.ensurePartyId(item.id);
    }
  };

  @action
  getOrFetchTransaction = async (transactionId, options) => {
    const { force, includeData } = {
      force: false,
      includeData: false,
      ...options,
    };
    let transaction = this.getFetchTransaction.get({
      transactionId,
    });
    if (!transaction || force) {
      transaction = await this.getFetchTransaction.fetch({
        transactionId,
      });
    }

    if (includeData) {
      await this.fetchDataForAggregate(transaction, {
        ...getDefaultTdFetchOptions(this.parent),
        ...(options || {}),
      });
    }

    return transaction;
  };

  fetchDataForTransaction = async (transactionId, options) =>
    this.getOrFetchTransaction(transactionId, {
      ...(options || {}),
      includeData: true,
    });

  fetchDataForAggregate = async (transaction, options) => {
    const options_ = {
      ...getDefaultTdFetchOptions(this.parent),
      ...(options || {}),
    };
    const transactionId = transaction.id;
    if (transaction.isTms) {
      // Load these on the side
      (() => {
        const fetch_ = async (resolve) => {
          // On purchases, ensure all properties are fetched when loading
          // the transaction, and before fetching forms
          if (transaction.isPurchase) {
            await this.getFetchItems.getOrFetch({
              transactionId,
              kind: 'PROPERTY_INFO',
            });
          }
          await this.getFetchTransactionForms.getOrFetch({
            transactionId,
          });
          resolve();
          return null;
        };

        return new Promise(fetch_);
      })();
    }

    // Block on these...
    return Promise.all([
      this.getFetchItems.getOrFetch({
        transactionId,
        kind: 'TRANSACTION_APP',
      }),
      this.getFetchItems.getOrFetch({
        transactionId,
        kind: 'FOLDER',
      }),
      this.getFetchItems.getOrFetch({
        transactionId,
        kind: 'TRANSACTION_DOCUMENT',
        filter: options_.transactionDocumentFilter || undefined,
      }),
      this.getOrFetchTicketTasks(transaction),
    ]);
  };

  getFetchTransactionTeam = getFetch({
    bindTo: this,
    getMemoizeKey: (teamId) => teamId,
    getter: (teamId) => {
      return this.teamsById[teamId];
    },
    fetcher: async (teamId) => {
      const { data } = await this.api.fetchTeam(teamId);

      runInAction(() => {
        this.teamsById[teamId] = data;
      });
    },
  });

  updatePageLoaded = async (transactionId) => {
    await this.dispatch(transactionId, load({}));
  };

  @action
  searchTransactions = async (v, params, pipeline = false) => {
    const { searchFileReviewPipeline, searchTransactions } = this.api;
    const search = pipeline ? searchFileReviewPipeline : searchTransactions;
    const result = await search(v, params);

    return {
      ...result.data,
      data: this.loadRawAggregates(result.data.data),
    };
  };

  @action
  fetchList = async (options) => {
    const withDefaultOptions = {
      category: CATEGORY_ACTIVE,
      ...options,
    };

    const { data: fetchResult } = await this.api.list(withDefaultOptions);
    return {
      ...fetchResult,
      data: this.loadRawAggregates(fetchResult.data),
    };
  };

  createTestTransaction = async () => {
    return this.create(
      create({
        isTest: true,
      })
    );
  };

  @action
  fetchSearchPreview = async ({
    query,
    kind,
    category,
    searchFileReviewPipeline,
    sides,
  }) => {
    const { data } = await this.api.fetchSearchPreview({
      query,
      kind,
      category,
      fileReview: !!searchFileReviewPipeline,
      sides,
    });
    if (data.transactions) {
      data.transactions = this.loadRawAggregates(data.transactions);
    }
    if (data.tasks) {
      this.updateThinAggregatesById(
        fromPairs(data.tasks.map((t) => [t.thinTrans.id, t.thinTrans]))
      );
      data.tasks = data.tasks.map((t) => this.makeItem(t));
    }
    return data;
  };

  @action
  setTransactionLastVisitedDetailsTab = (transactionId, tab) => {
    const transaction = this.transactionsById.get(transactionId);
    if (transaction) {
      transaction.setLastVisitedDetailsTab(tab);
    }
  };

  @action
  setTransactionHeroCollapsedState = (transactionId, isCollapsed) => {
    const transaction = this.transactionsById.get(transactionId);
    if (transaction) {
      transaction.setHeroCollapsedState(isCollapsed);
    }
  };

  /* Tasks ----------------------------------------------------- */
  @action
  fetchTasks = (transactionId, group) => {
    return this.getFetchTasks.fetch({
      transactionId,
      group,
    });
  };

  @action
  getOrFetchTasks = (transactionId, boardId) => {
    return this.getFetchTasks.getOrFetch({
      transactionId,
      boardId,
    });
  };

  @action
  getOrFetchTicketTasks = (transaction) => {
    return this.getOrFetchTasks(transaction.id, transaction.ticketBoardId);
  };

  @action
  getOrFetchChecklistsTasks = (transaction) => {
    return this.getOrFetchTasks(transaction.id, transaction.checklistBoardId);
  };

  getFetchTasks = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId, boardId }) =>
      `${transactionId}:${boardId || ''}`,
    getter: ({ transactionId, boardId }) => {
      return this.itemsById.indexed(IDX_TASK_TRANSACTION_ID_BOARD_ID, {
        transactionId,
        boardId,
      }).all;
    },
    fetcher: async ({ transactionId, boardId }) => {
      const resolved = await Promise.all([
        this.getOrFetchTransaction(transactionId),
        this.api.fetchItems(
          transactionId,
          'TASK',
          boardId
            ? {
                board_ids: [boardId],
              }
            : {}
        ),
      ]);
      const {
        data: { data: tasks },
      } = resolved[1];
      const tasksById = keyBy(tasks, 'id');
      runInAction(() => {
        this.updateItemsById(tasksById);
      });
    },
  });

  /* Users -------------------------------------------------- */
  getOrgPermissionsKeyPart = (orgPermissions) =>
    `orgPerms:${(orgPermissions || []).sort().join(':')}`;

  getFetchUsersWithAccess = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId, orgPermissions }) =>
      `${transactionId}:${this.getOrgPermissionsKeyPart(orgPermissions)}`,
    getter: ({ transactionId, orgPermissions }) => {
      return (
        this.usersWithAccessByTransactionId.get(transactionId) || []
      ).filter(
        (u) =>
          !orgPermissions ||
          !orgPermissions.length ||
          difference(orgPermissions, u.orgPermissions || []).length === 0
      );
    },
    fetcher: async ({ transactionId, orgPermissions }) => {
      const { data } = await this.api.getTransactionUsersWithAccess(
        transactionId,
        {
          orgPermissions,
        }
      );
      runInAction(() => {
        const cachedData = keyBy(
          this.usersWithAccessByTransactionId.get(transactionId) || [],
          'id'
        );
        data.forEach((user) => {
          const cachedUser = cachedData[user.id] || {};
          cachedData[user.id] = {
            ...user,
            orgPermissions: Array.from(
              new Set(
                (cachedUser.orgPermissions || []).concat(orgPermissions || [])
              )
            ),
          };
        });
        this.usersWithAccessByTransactionId.set(
          transactionId,
          Object.values(cachedData)
        );
      });
    },
  });

  /* Overview  ------------------------------------------------- */

  getFetchOverview = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId }) => transactionId,
    getter: ({ transactionId }) => {
      return this.overviewByTransactionId.get(transactionId) || {};
    },
    fetcher: async ({ transactionId }) => {
      const { data } = await this.api.getTransactionOverview({ transactionId });
      runInAction(() => {
        this.overviewByTransactionId.set(transactionId, data);
      });
    },
  });

  @action
  getOrFetchOverview = (transactionId) => {
    return this.getFetchOverview.getOrFetch({
      transactionId,
    });
  };

  /* Local Storage --------------------------------------------- */

  get localStorageTransactionsById() {
    let data = getLocalStorage().getItem(LOCAL_STORAGE_TRANSACTIONS_BY_ID_KEY);
    try {
      data = JSON.parse(data);
    } catch (err) {
      data = {};
    }
    if (!data || !isObject(data)) {
      data = {};
      getLocalStorage().setItem(
        LOCAL_STORAGE_TRANSACTIONS_BY_ID_KEY,
        JSON.stringify(data)
      );
    }

    return data;
  }

  getLocalStorageData = (transactionId) =>
    this.localStorageTransactionsById[transactionId] || {};
  getLocalStorageDataItem = (transactionId, itemKey) =>
    this.getLocalStorageData(transactionId)[itemKey];

  setLocalStorageData = (transactionId, data) => {
    getLocalStorage().setItem(
      LOCAL_STORAGE_TRANSACTIONS_BY_ID_KEY,
      JSON.stringify({
        ...this.localStorageTransactionsById,
        [transactionId]: data,
      })
    );
  };
  setLocalStorageDataItem = (transactionId, itemKey, data) => {
    this.setLocalStorageData(transactionId, {
      ...this.getLocalStorageData(transactionId),
      [itemKey]: data,
    });
  };

  removeLocalStorageData = (transactionId) => {
    const {
      [transactionId]: transactionData, // eslint-disable-line no-unused-vars
      ...remainingData
    } = this.localStorageTransactionsById;
    getLocalStorage().setItem(
      LOCAL_STORAGE_TRANSACTIONS_BY_ID_KEY,
      remainingData
    );
  };

  getFetchTransactionSettings = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId }) => transactionId,
    getter: ({ transactionId }) => {
      return this.transactionSettingsByTransactionId.get(transactionId);
    },
    fetcher: async ({ transactionId }) => {
      const { data } = await this.api.getTransactionSettings(transactionId);
      runInAction(() =>
        this.transactionSettingsByTransactionId.set(
          transactionId,
          new TransactionSettings(data)
        )
      );
    },
  });

  getCompositeKey = (args) => args.filter((a) => a).join('_');

  getFetchTransactionForms = getFetch({
    bindTo: this,
    getMemoizeKey: ({
      transactionId,
      bppId,
      propertyInfoId,
      primaryAgentId,
    }) => {
      // When fetching forms for the entire transaction (ie `!bppId && !propertyInfoId`), in
      // addition to the transactionId being part of the memoizeKey, all propertyInfo ids and
      // their associated addressIds are included so that the cached forms are also invalidated
      // anytime property is added or their address is altered
      let secondaryPropertiesKeys = '';
      if (!bppId && !propertyInfoId) {
        const allPropertyInfos = this.getItems(transactionId, 'PROPERTY_INFO');
        secondaryPropertiesKeys = sortBy(allPropertyInfos, ({ id }) =>
          parseInt(id, 10)
        )
          .map((pi) => `${pi.id}:${pi.addressId}`)
          .join(':');
      }

      // addressId is added to propertyInfoId so the cache is invalidated if address changes
      // on the property
      let fullPropertyInfoKey = '';
      if (propertyInfoId) {
        const propertyInfo = this.getItem(
          transactionId,
          'PROPERTY_INFO',
          propertyInfoId
        );
        fullPropertyInfoKey = [propertyInfoId, propertyInfo?.addressId]
          .filter(Boolean)
          .join(':');
      }

      return this.getCompositeKey([
        transactionId,
        secondaryPropertiesKeys,
        bppId,
        fullPropertyInfoKey,
        primaryAgentId,
      ]);
    },
    getter: ({ transactionId, bppId, propertyInfoId, primaryAgentId }) => {
      return this.transactionsFormsByTransactionId.get(
        this.getCompositeKey([
          transactionId,
          bppId,
          propertyInfoId,
          primaryAgentId,
        ])
      );
    },
    fetcher: async ({
      transactionId,
      bppId,
      propertyInfoId,
      // form libraries are specific to primary agent, caching should take that into account
      primaryAgentId,
    }) => {
      const { data } = await this.api.getTransactionForms({
        transactionId,
        bppId,
        propertyId: propertyInfoId,
      });
      data.formByFormSeriesId = new Map();
      (data.libraries || []).forEach((library) => {
        const entitled =
          !library.private || data.entitledLibraryUuids.includes(library.uuid);
        (library.forms || []).forEach((form) => {
          if (!form.seriesId) {
            return;
          }
          let _form = data.formByFormSeriesId.get(form.seriesId);
          if (!_form) {
            data.formByFormSeriesId.set(form.seriesId, form);
            _form = form;
          }
          _form.entitled = Boolean(_form.entitled) || entitled;
          _form.libraryUuids = _form.libraryUuids || [];
          _form.libraryUuids.push(library.uuid);
        });
      });
      runInAction(() => {
        this.transactionsFormsByTransactionId.set(
          this.getCompositeKey([
            transactionId,
            bppId,
            propertyInfoId,
            primaryAgentId,
          ]),
          data
        );
      });
    },
  });

  emit = (transactionId, events) =>
    this.api.emit(transactionId, toArray(events));
}
