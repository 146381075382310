import camelCase from 'lodash/camelCase';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import orderBy from 'lodash/orderBy';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { computed, makeObservable } from 'mobx';
import getTaskType from 'src/components/transactions/checklists/types';
import type { DateComputation } from 'src/types/proto/dates';
import getDateMoment from 'src/utils/get-date-moment';
import Item, { ItemStore, TransactionItemJson } from './item';
import ChecklistItem from './task-types/checklist-item';

export const TASK_STATUSES = [
  ['OPEN', 'Not Started'],
  ['IN_PROGRESS', 'In Progress'],
  ['CLOSED', 'Complete'],
  ['ARCHIVED', 'Archived'],
  ['SKIPPED', 'Skipped'],
];

export const TASK_STATUSES_NAMES = fromPairs(TASK_STATUSES);

export const KEY_DATE_CHOICES = window.Glide.CONSTANTS.KEY_DATE_CHOICES;

export const KEY_DATE_CHOICES_NAMES = fromPairs(KEY_DATE_CHOICES);

const OVERRIDE_NAMES = {
  'Request Seller Disclosures': 'Invite Sellers to Fill Disclosures',
  'Order NHD report': 'Order Natural Hazard Disclosure',
  'Complete AVID': 'Fill and add AVID',
};

const TASK_DESCRIPTION = {
  'Request Seller Disclosures': 'Select forms and invite Sellers to fill.',
  'Order NHD report': 'Order from top NHD providers right in Glide.',
  'Complete AVID': 'Use our wizard to fill an AVID on the go!',
};

const COMPLETED_OVERRIDE_TASKS = [
  'Request Seller Disclosures',
  'Complete AVID',
];

export type TaskJson = TransactionItemJson<'TASK'>;

export default class Task extends Item<'TASK'> {
  resolvedItems = ['members', 'tds'];

  constructor(store: ItemStore, json: TaskJson) {
    super(store, json);
    makeObservable(this);
  }

  get features() {
    return this.store.parent.features;
  }

  @computed
  get children(): Task[] {
    return orderBy(
      this.childrenIds
        .map((id) => this.store.itemsById.get(id))
        .filter((foundItem) => foundItem)
        .filter((t) => !t.task.isArchived),
      ['orderIndex', 'id'],
      ['asc', 'desc']
    );
  }

  @computed
  get allChildren(): Task[] {
    return orderBy(
      this.childrenIds
        .map((id) => this.store.itemsById.get(id))
        .filter((foundItem) => foundItem),
      ['orderIndex', 'id'],
      ['asc', 'desc']
    );
  }

  @computed
  get childrenIds() {
    return (this.outEdges || [])
      .filter((e) => e.kind === 'TASK_HAS_TASK')
      .map((e) => e.item2Id);
  }

  @computed
  get members() {
    return (this.outEdges || [])
      .filter((e) => e.kind === 'TASK_HAS_MEMBER')
      .map((e) => this.store.itemsById.get(e.item2Id))
      .filter(Boolean);
  }

  @computed
  get membersIds() {
    return this.task.membersIds || [];
  }

  @computed
  get date() {
    return this.kindItem.date || '';
  }

  @computed
  get dateMoment() {
    return getDateMoment(this.date);
  }

  @computed
  get dateTimestamp() {
    return this.dateMoment && +this.dateMoment;
  }

  @computed
  get needsDate() {
    return (
      this.type === 'KEY_DATE' &&
      (this.dateComputation.kind === 'EMPTY' ||
        (this.dateComputation.kind === 'ABSOLUTE' &&
          !this.dateComputation.absolute))
    );
  }

  @computed
  get dateComputation() {
    return this.kindItem.dateComputation || ({} as DateComputation);
  }

  set dateComputation(value) {
    this.kindItem.dateComputation = value;
  }

  @computed
  get labels() {
    if (COMPLETED_OVERRIDE_TASKS.includes(this.kindItem.name)) {
      return this.kindItem.labels.map((label) => {
        if (label.label === 'Completed') {
          return {
            ...label,
            label: 'PDFs Generated',
          };
        }
        return label;
      });
    }
    return this.kindItem.labels || [];
  }

  @computed
  get actions() {
    return get(getTaskType(this.type), 'actions') || [];
  }

  @computed
  get statusMeta() {
    const taskType = getTaskType(this.type);
    if (!!taskType && !!taskType.getStatusMeta) {
      return taskType.getStatusMeta(this);
    }
    return undefined;
  }

  @computed
  get statusDescription() {
    const statusMeta = this.statusMeta;
    if (statusMeta && statusMeta.message) {
      return statusMeta.message;
    }
    const label = this.labels[0];
    return !!label && !!label.label
      ? `${this.name}: ${label.label.toLowerCase()}`
      : 'No description';
  }

  @computed
  get taskKind() {
    return this.kindItem.taskKind;
  }

  @computed
  get reminderTriggers() {
    return this.task.reminderTriggers;
  }

  @computed
  get keyDate() {
    return this.kindItem.keyDate;
  }

  set keyDate(value) {
    this.kindItem.keyDate = value;
  }

  @computed
  get checklistBoard() {
    return (
      this.kindItem.checklistBoard || {
        appliedChecklists: [],
      }
    );
  }

  @computed
  get keyDateShortName() {
    return {
      ACCEPTANCE: 'Acceptance Deadline',
      INITIAL_DEPOSIT: 'Initial Deposit Deadline',
      VERIFICATION_DOWN_PAYMENT: 'Down Payment Deadline',
      SELLER_DISCLOSURES: 'Disclosures Deadline',
      INSPECTION_CONTINGENCY: 'Inspections',
      APPRAISAL_CONTINGENCY: 'Appraisal',
      LOAN_CONTINGENCY: 'Loans Deadline',
      ALL_CONTINGENCIES_REMOVAL: 'Contingencies Deadline',
      VERIFICATION_PROPERTY_CONDITION: 'Property Condition Verification',
      CLOSE_ESCROW: 'Closing',
      CONTRACT_CREATED: 'Contract Creation',
    }[this.keyDate?.type as string];
  }

  @computed
  get parentId() {
    const taskEdge = (this.inEdges || []).find(
      (e) => e.kind === 'TASK_HAS_TASK'
    );

    if (!taskEdge) {
      return null;
    }
    return taskEdge.item1Id;
  }

  @computed
  get parent() {
    const { parentId } = this;
    if (!parentId) {
      return null;
    }
    return this.store.itemsById.get(parentId);
  }

  @computed
  get orderIndex() {
    return this.kindItem.orderIndex;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  set status(value) {
    this.kindItem.status = value;
  }

  @computed
  get isClosed() {
    return this.status === 'CLOSED';
  }

  @computed
  get isOpen() {
    return this.status === 'OPEN';
  }

  @computed
  get isInProgress() {
    return this.status === 'IN_PROGRESS';
  }

  @computed
  get isDormant() {
    return this.status === 'DORMANT';
  }

  @computed
  get isSkipped() {
    return this.status === 'SKIPPED';
  }

  @computed
  get isStartable() {
    return !this.isDormant && this.isNotStarted;
  }

  @computed
  get isVisible() {
    return !['ARCHIVED', 'PRE_EXISTANT'].includes(this.status);
  }

  @computed
  get isNotStarted() {
    return (
      (this.labels[0] &&
        (this.labels[0].label === 'Not Started' ||
          this.labels[0].label === 'Draft (not sent)' ||
          this.labels[0].label === 'Draft' ||
          this.labels[0].label === 'Skipped')) ||
      this.isDormant
    );
  }

  @computed
  get isDraft() {
    // XXX: This should really be a new Task Status.
    return (this.labels || []).some(
      (label) => label.label.indexOf('Draft') !== -1
    );
  }

  @computed
  get isArchived() {
    return this.kindItem.isArchived;
  }

  @computed
  get notes() {
    return this.kindItem.notes;
  }

  set notes(value) {
    this.kindItem.notes = value;
  }

  @computed
  get taskAssignedTdEdgesDesc() {
    return sortBy(
      this.outEdges.filter((e) => e.kind === 'TASK_ASSIGNED_TD'),
      (e) => -Number(e.createdAt),
      (e) => -Number(e.item2Id)
    );
  }

  @computed
  get lastAssignmentEdge() {
    return (
      sortBy(
        this.outEdges.filter((e) => e.kind === 'TASK_ASSIGNED_PARTY'),
        (e) => -Number(e.createdAt),
        (e) => -Number(e.item2Id)
      )[0] || null
    );
  }

  @computed
  get isVisibleItem() {
    return this.kindItem.isVisibleItem;
  }

  @computed
  get isTransactionVisibleItem() {
    return this.kindItem.isTransactionVisibleItem;
  }

  @computed
  get isClientVisibleItem() {
    return this.kindItem.isClientVisibleItem;
  }

  isListVisible = (mode: string) => {
    if (this.can('actAsAdmin')) {
      if (mode === 'client') {
        return this.isClientVisibleItem;
      }
      return this.isVisibleItem;
    }
    if (this.can('actAsClient')) {
      return this.isClientVisibleItem;
    }
    return false;
  };

  @computed
  get assignee() {
    return (
      this.outEdges
        .filter((e) => e.kind === 'TASK_ASSIGNED_PARTY')
        .map((e) => this.store.itemsById.get(e.item2Id))
        .filter((assignee) => assignee)[0] || null
    );
  }

  @computed
  get dependentTaskIds() {
    return this.kindItem.dependentTaskIds;
  }

  @computed
  get tds() {
    return this.outEdges
      .filter((e) => e.kind === 'TASK_ASSIGNED_TD')
      .map((e) => this.store.itemsById.get(e.item2Id))
      .filter((td) => td);
  }

  @computed
  get tdsDesc() {
    return reverse(this.tds.slice());
  }

  @computed
  get typeItem() {
    return (this.kindItem as any)[camelCase(this.type)] || {};
  }

  getBackData = (...args: any) => {
    const taskTypeHandler = getTaskType(this.type);
    return (
      taskTypeHandler &&
      taskTypeHandler.getBackData &&
      taskTypeHandler.getBackData(this, ...args)
    );
  };

  can(op: string): boolean {
    const callWithTask = (v: any) => (isFunction(v) ? v(this) : v);
    const transaction = this.transaction;
    const party = transaction?.parties.me;

    if (op === 'download') {
      return this.outEdges.some((e) => e.kind === 'TASK_ASSIGNED_TD');
    }
    if (op === 'progress') {
      return this.status !== 'CLOSED';
    }
    if (op === 'avidSign') {
      return this.status === 'CLOSED' && this.type === 'AVID';
    }
    if (op === 'assign') {
      return callWithTask(getTaskType(this.type).canAssign) !== false;
    }
    if (op === 'editStatus') {
      return callWithTask(getTaskType(this.type).canEditStatus) !== false;
    }
    if (op === 'rename') {
      return callWithTask(getTaskType(this.type).canRename) !== false;
    }
    if (op === 'actAsAdmin') {
      return (
        this.transactionPermissions &&
        this.transactionPermissions.includes('ACT_AS_ADMIN')
      );
    }
    if (op === 'actAsClient') {
      return (
        !this.store.parent.ui.isGlideMobileApp &&
        this.transactionPermissions &&
        this.transactionPermissions.includes('ACT_AS_CLIENT')
      );
    }
    if (op === 'actAsAdminAndClient') {
      return (
        !this.store.parent.ui.isGlideMobileApp &&
        this.transactionPermissions &&
        this.transactionPermissions.includes('ACT_AS_ADMIN') &&
        this.transactionPermissions.includes('ACT_AS_CLIENT')
      );
    }
    if (op === 'addForms') {
      return Boolean(
        this.type === 'GFP' &&
          this.task.gfp &&
          this.task.gfp.status !== 'LOCKED'
      );
    }
    if (op === 'accessInPerson') {
      return Boolean(
        this.type === 'GFP' &&
          this.task.gfp &&
          this.task.gfp.creationMode === 'AGENT_INPERSON'
      );
    }
    if (op === 'canInviteAvidParty') {
      return Boolean(party && !party.isThisSideAgent);
    }
    if (op === 'canAddReminders') {
      return (
        this.can('actAsAdmin') &&
        this.reminderTriggers &&
        this.reminderTriggers.length > 0
      );
    }
    if (op === 'canDelete') {
      return this.can('actAsAdmin') && this.isArchived;
    }
    if (op === 'canCompleteTask') {
      return !this.isClosed;
    }
    return false;
  }

  @computed
  get type() {
    return this.kindItem.type;
  }

  @computed
  get name() {
    return (
      (OVERRIDE_NAMES as unknown as { [key: string]: string })[
        this.kindItem.name
      ] || this.kindItem.name
    );
  }

  @computed
  get description() {
    return (TASK_DESCRIPTION as unknown as { [key: string]: string })[
      this.kindItem.name
    ];
  }

  @computed
  get statusLabel() {
    if (this.tds.length) {
      if (this.tds.length > 1) {
        const lastTd = this.tdsDesc[0];
        return {
          level: 'SUCCESS',
          text: `${lastTd.title} and ${this.tds.length - 1} more`,
        };
      }
      return {
        level: 'SUCCESS',
        text: `${this.tds[0].title}`,
      };
    }
    return null;
  }

  @computed
  get syncTask() {
    return this.kindItem.syncTask;
  }

  @computed
  get syncChecklist() {
    return this.kindItem.syncChecklist;
  }

  @computed
  get checklistItemTmpl() {
    return this.kindItem.checklistItemTmpl;
  }

  @computed
  get checklistItem() {
    return this.kindItem.checklistItem;
  }

  @computed
  get checklistItemModel() {
    return new ChecklistItem(this as any);
  }

  /**
   * Deprecated! Use properties.
   */
  @computed
  get task() {
    return this.kindItem;
  }
}
