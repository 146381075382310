export default (task) =>
  task.transaction.isListing
    ? {
        routeName: 'transactions.transaction.disclosurePackage',
        routeParams: {
          transactionId: task.transactionId,
        },
        label: 'Disclosure Package',
      }
    : {
        routeName: 'transactions.transaction',
        routeParams: {
          transactionId: task.transactionId,
        },
        label: 'Back',
      };
