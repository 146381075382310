import ItemBase, { ItemJson } from 'src/models/aggregates/item';
import type { ValidEnvelopeItemKind } from 'src/types/api';
import type { Item as EnvelopeItem } from 'src/types/proto/signing';

export type EnvelopeItemJson<K extends ValidEnvelopeItemKind> = ItemJson<
  EnvelopeItem,
  K
>;

export default class Item<K extends ValidEnvelopeItemKind> extends ItemBase<
  EnvelopeItem,
  K
> {}
