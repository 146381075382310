import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type ListingInfoJson = TransactionItemJson<'LISTING_INFO'>;

export default class ListingInfo extends Item<'LISTING_INFO'> {
  constructor(store: ItemStore, json: ListingInfoJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get sellerLegalName() {
    return this.kindItem?.sellerLegalName;
  }

  @computed
  get mlsNumber() {
    return this.kindItem?.mlsNumber;
  }

  @computed
  get listingDate() {
    return this.kindItem?.listingDate;
  }

  @computed
  get expirationDate() {
    return this.kindItem?.expirationDate;
  }

  @computed
  get terms() {
    return this.kindItem?.terms;
  }

  @computed
  get includes() {
    return this.kindItem?.includes;
  }

  @computed
  get excludes() {
    return this.kindItem?.excludes;
  }

  @computed
  get currentPrice() {
    return this.kindItem?.currentPrice;
  }

  @computed
  get association() {
    return this.kindItem?.association;
  }

  @computed
  get trustDeed1() {
    return this.kindItem?.trustDeed1;
  }

  @computed
  get trustDeed2() {
    return this.kindItem?.trustDeed2;
  }

  @computed
  get trustDeed3() {
    return this.kindItem?.trustDeed3;
  }

  @computed
  get otherLiensAmount() {
    return this.kindItem?.otherLiensAmount;
  }

  @computed
  get otherLiensDescription() {
    return this.kindItem?.otherLiensDescription;
  }

  @computed
  get otherEncumberancesAmount() {
    return this.kindItem?.otherEncumberancesAmount;
  }

  @computed
  get otherEncumberancesDescription() {
    return this.kindItem?.otherEncumberancesDescription;
  }

  @computed
  get remarks() {
    return this.kindItem?.remarks;
  }
}
