/*  when the app is embedded, we want to block combo clicks on left click
    events on anchor tags, and also disable context menu clicks in general.
    These are the util functions to be attached to onclick, oncontextmenu,
    mousedown, or etc global handlers.

    TODO 1: Potentially move these into compass-app-bridge in the long run
    TODO 2: add event to bridge to support opening links in new tab or same
        window.
*/
export function disableAnchorClick(e: MouseEvent) {
  const element = e.target as any;
  if (element?.closest('a')) {
    e.preventDefault();
  }
}

export function disableComboAnchorClick(e: MouseEvent) {
  const comboKeyClicked = e.metaKey || e.ctrlKey || e.shiftKey;

  // Safari doesn't recognize aux clicks yet, so we must also check for
  // button === 1, however middle clicks do not trigger click events for
  // Chrome and FireFox
  if (comboKeyClicked || e.button === 1) {
    disableAnchorClick(e);
  }
}
