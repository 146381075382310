import BaseTermDelegate from './base';
import { computed, makeObservable, override } from 'mobx';

export default class MutuallyExclusiveTermDelegate extends BaseTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  @override
  get mainFieldKey() {
    return '1';
  }

  @computed
  get fieldIds() {
    return this.term.fieldIds || {};
  }

  @computed
  get boundOutputsByFieldKey() {
    return Object.keys(this.term.fieldIds).reduce((all, fieldKey) => {
      const fieldId = this.term.getFieldId(fieldKey);
      return {
        ...all,
        [fieldKey]: fieldId ? this.getBoundOutput(fieldKey) : null,
      };
    }, {});
  }

  @computed
  get labels() {
    return this.term.kindData.labels || {};
  }

  getActiveFieldKey(value) {
    return (
      Object.keys(this.fieldIds)
        .sort((fk1, fk2) => (+fk1 > +fk2 ? 1 : -1))
        .find((fieldKey) => value[fieldKey]) || null
    );
  }

  @computed
  get currentActiveFieldKey() {
    return this.getActiveFieldKey(this.getCurrentValue());
  }

  getActiveValue(value) {
    const activeFieldKey = this.getActiveFieldKey(value);
    return activeFieldKey ? value[activeFieldKey] : null;
  }

  @computed
  get currentActiveValue() {
    return this.getActiveValue(this.getCurrentValue());
  }

  @computed
  get currentActiveLabel() {
    const activeFieldKey = this.currentActiveFieldKey;
    return activeFieldKey ? this.labels[activeFieldKey] : null;
  }

  renderReadOnlyValue() {
    const value = this.currentActiveValue;
    return value ? `${value} ${this.currentActiveLabel}` : '';
  }

  getValuesByFormFieldId(value) {
    const activeFieldKey = value.__activeKey;
    const fieldId = activeFieldKey ? this.fieldIds[activeFieldKey] : null;
    return {
      ...Object.values(this.fieldIds).reduce(
        (all, fid) => ({
          ...all,
          [fid]: null,
        }),
        {}
      ),
      ...(fieldId
        ? {
            [fieldId]: value[activeFieldKey],
          }
        : {}),
    };
  }

  getCurrentValue() {
    const val = Object.entries(this.boundOutputsByFieldKey).reduce(
      (all, [fieldKey, boundOutput]) => {
        let value = null;
        if (boundOutput) {
          const topLinkedOutput = boundOutput.topLinkedOutput;
          const bindings = Object.keys(topLinkedOutput?.bindings || {});
          value =
            bindings.length === 1
              ? topLinkedOutput.getFieldValue(bindings[0])
              : bindings.reduce(
                  (v, b) => ({
                    ...v,
                    [b]: topLinkedOutput.getFieldValue(b),
                  }),
                  {}
                );
        }
        return {
          ...all,
          [fieldKey]: value,
        };
      },
      {}
    );
    return {
      ...val,
      __activeKey: this.getActiveFieldKey(val),
    };
  }

  getEvalValue(value) {
    return {
      key: this.getActiveFieldKey(value),
      value: this.getActiveValue(value),
    };
  }
}
