import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import type TemplateChecklistsStore from 'src/stores/template-checklists-store';
import { type TemplateChecklist as TemplateChecklistJson } from 'src/types/proto/template_checklists';

export default class TemplateChecklist extends Model<TemplateChecklistJson> {
  constructor(store: TemplateChecklistsStore, json: TemplateChecklistJson) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  store: TemplateChecklistsStore;

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get itemId() {
    return this.data.itemId;
  }

  @computed
  get title() {
    return this.data.title;
  }

  @computed
  get checklistType() {
    return this.data.checklistType || 'LISTING';
  }

  @computed
  get isListing() {
    return this.checklistType === 'LISTING';
  }

  @computed
  get isArchived() {
    return !!this.data.isArchived;
  }

  @computed
  get allChecklistGroups() {
    return this.data.tree?.children || [];
  }

  @computed
  get allChecklistItems() {
    return this.allChecklistGroups
      .map((g) => (g.children || []).map((ci) => ci))
      .flat();
  }

  @computed
  get allRelatedSeriesIds() {
    return Array.from(
      new Set(
        this.allChecklistItems
          .map((ci) =>
            ((ci.taskTypeItem || {}).relatedSeriesIds || []).map((rf) => rf)
          )
          .flat()
      )
    );
  }
}
