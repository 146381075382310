import cloneDeep from 'lodash/cloneDeep';
import { makeAutoObservable } from 'mobx';
import type { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import type { FormConfig, Annotation, Field } from 'src/types/proto/reform';
import type adminApi from '../../api/admin-api';
import type { AdminAppStore } from '../admin-app-store';
import { componentConfig } from './component-config';
import { decodeFormConfig, encodeFormConfig } from './schema/data-convert';
import {
  FormConfigValidationErrors,
  validateFormConfig,
} from './schema/validator';
import { TreeNode } from './tree-node';
import type { ComponentWithOptionsData, NodeComponentType } from './types';
import {
  findNodeParent,
  getComponentConfig,
  getComponentTitle,
  getAnnotationBeingUsedKey,
} from './utils';

export type SidenavTab = 'outline' | 'warnings';

export type SectionToRender = {
  itemIds: Set<string>;
  onRenderEnd: (id: string) => void;
};

export type NodeRenderListener = (node: TreeNode) => void;

export class FormBuilderStore {
  private static config = componentConfig;
  private static emptyTreeNode = new TreeNode({
    component: 'Form',
    componentProps: {},
    children: [],
  });
  public api?: typeof adminApi.documents;
  public parent?: AdminAppStore;

  enableDebugTool = false;
  treeNode: TreeNode | null = null;
  sidenavTab: SidenavTab = 'outline';
  currentNode: TreeNode | null = null;
  annotations: Annotation[];
  fields: Field[];
  formErrors: Map<string, ValidateErrorEntity>;
  validations: Map<
    string,
    () => Promise<[string, ValidateErrorEntity | undefined]>
  >;
  annotationsBeingUsed: Map<string, string>;
  configErrors: FormConfigValidationErrors = null;
  /** Original form config data */
  configSource: FormConfig | null = null;
  formBuilderIsChanged: boolean;

  visualizedRendering: {
    forceRenderAll: boolean;
    forceResetViewBounding: boolean;
    rendered: number;
    toBeRendered: number;
    onFinish: VoidFunction | null;
    onNodeRender: NodeRenderListener | null;
    sectionToRender: SectionToRender | null;
  };

  ignoreScrollOnce: boolean;

  // eslint-disable-next-line no-shadow
  constructor(parent?: AdminAppStore) {
    this.parent = parent;
    this.api = (parent?.api as unknown as typeof adminApi).documents;
    this.reset();
    this.annotations = [];
    this.fields = [];
    this.validations = new Map();
    this.annotationsBeingUsed = new Map();
    this.formErrors = new Map();
    this.formBuilderIsChanged = false;
    this.visualizedRendering = {
      forceRenderAll: false,
      forceResetViewBounding: false,
      rendered: 0,
      toBeRendered: 0,
      onFinish: null,
      onNodeRender: null,
      sectionToRender: null,
    };
    this.ignoreScrollOnce = false;
    makeAutoObservable(this);
  }

  setEnableDebugTool(flag: boolean) {
    this.enableDebugTool = flag;
  }

  addEventListenerToTreeNode() {
    this.treeNode?.onChange(() => {
      this.setFormBuilderChange(true);
    });
  }

  reset() {
    this.setTreeNode(FormBuilderStore.emptyTreeNode);
  }

  setTreeNode(tree: TreeNode): void {
    this.treeNode = tree;
    this.setCurrentNode(this.treeNode.find((n) => n.component === 'Section'));
    this.addEventListenerToTreeNode();
  }

  setAnnotations(annotations: Annotation[]) {
    this.annotations = annotations;
  }

  setFields(fields: Field[]) {
    this.fields = fields;
  }

  loadFormConfig(config: FormConfig) {
    const rawConfig = cloneDeep(config);
    const errors = validateFormConfig(rawConfig);
    this.configSource = config;
    if (errors) {
      this.configErrors = errors;
      throw new Error('Invalid form configuration');
    }
    const data = decodeFormConfig(config);
    const treeNode = new TreeNode(data);
    this.setTreeNode(treeNode);
    this.setAnnotationsBeingUsed(treeNode);
  }

  setConfigErrors(errors: FormConfigValidationErrors): void {
    this.configErrors = errors;
  }

  get formConfig() {
    if (!this.treeNode) {
      return null;
    }
    return encodeFormConfig(this.treeNode, undefined, this.annotations);
  }

  saveFormConfig(formUuid: string) {
    const data = this.treeNode;
    if (!data) {
      return undefined;
    }
    const { formId, ...payload } = encodeFormConfig(
      data,
      formUuid,
      this.annotations
    );
    return this.api?.saveFormConfig(formId, payload);
  }

  createFlowPreview(formId: string, data: { permissions: string[] }) {
    return this.api?.createFlowPreview(formId, {
      config: this.formConfig,
      ...data,
    });
  }

  setSidenavTab(tab: SidenavTab): void {
    this.sidenavTab = tab;
  }

  setCurrentNode(node?: TreeNode) {
    this.currentNode = node ?? null;
  }

  setAnnotationsBeingUsed(tree: TreeNode) {
    this.annotationsBeingUsed.clear();
    tree.each((n) => {
      if (n.componentProps?.annotationId) {
        const annotationKey = getAnnotationBeingUsedKey(n.id, 'annotationId');
        this.addAnnotationBeingUsed(
          annotationKey,
          n.componentProps?.annotationId
        );
      }
      if ('options' in (n.componentProps ?? {})) {
        (n.componentProps as ComponentWithOptionsData).options.forEach(
          (v, i) => {
            if (v.annotationId) {
              const annotationKey = getAnnotationBeingUsedKey(
                n.id,
                `options.${i}.annotationId`
              );
              this.addAnnotationBeingUsed(annotationKey, v.annotationId);
            }
            if (v.textAnnotationId) {
              const annotationKey = getAnnotationBeingUsedKey(
                n.id,
                `options.${i}.textAnnotationId`
              );
              this.addAnnotationBeingUsed(annotationKey, v.textAnnotationId);
            }
          }
        );
      }
    });
  }

  /**
   * Trigger renaming of a node
   * TODO replace native prompt with dialog
   */
  rename(node: TreeNode) {
    const name = getComponentTitle(node);
    const config = getComponentConfig(node.component);
    const newName = window.prompt(
      `[MOCK editing, to be replaced later]\nInput new name for ${config.name}`,
      name
    );

    if (node.component === 'PageBreak' || newName === null) {
      return;
    }

    node.updateProps({ ...node.componentProps, title: newName });
  }

  get currentSection(): TreeNode | undefined {
    return findNodeParent(this.currentNode, (n) => n.component === 'Section');
  }

  getComponentConfig(componentName: NodeComponentType) {
    return FormBuilderStore.config[componentName];
  }

  addValidation(
    id: string,
    validation: () => Promise<[string, ValidateErrorEntity | undefined]>
  ) {
    this.validations.set(id, validation);
  }

  removeValidation(id: string) {
    this.validations.delete(id);
  }

  addFormError(id: string, err: ValidateErrorEntity) {
    this.formErrors.set(id, err);
  }

  removeFormError(id: string) {
    this.formErrors.delete(id);
  }

  getFormError(id: string): ValidateErrorEntity | undefined {
    return this.formErrors.get(id);
  }

  resetFormErrors() {
    this.formErrors = new Map();
  }
  /**
   * Remove annotation id from being used map when the node which is using it will be deleted.
   *
   * @param {string} id id of the node which is going to be deleted from the form builder;
   */
  removeAnnotationBeingUsedByNodeId(id: string) {
    for (const [key] of this.annotationsBeingUsed) {
      const nodeId = key.split('.')[0];
      if (nodeId === id) {
        this.removeAnnotationBeingUsed(key);
      }
    }
  }

  async runAllValidations() {
    await this.forceRenderAll();

    const validations = [];
    for (const validation of this.validations.values()) {
      validations.push(validation());
    }
    const errorsList = await Promise.all(validations);

    this.clearForceRenderAll();

    errorsList.forEach((idAndError) => {
      const [id, error] = idAndError;
      if (error) {
        this.addFormError(id, error);
      } else {
        this.removeFormError(id);
      }
    });
  }

  /**
   * @param {string} key this key is expected to be `${node.id}.${annotationFieldKeyPath}`
   * @param {string} id annotation id
   */
  addAnnotationBeingUsed(key: string, id: string) {
    this.annotationsBeingUsed.set(key, id);
  }

  /**
   * @param {string} key this key is expected to be `${node.id}.${annotationFieldKeyPath}`
   */
  removeAnnotationBeingUsed(key: string) {
    this.annotationsBeingUsed.delete(key);
  }

  /**
   * if the form builder rendered by user imported config,
   * it might have several annotation fields use the same annotation id.
   * so this function helps to run validations for nodes
   * which are using the same annotation id.
   */
  runValidationByAnnotationId(annotationId: string) {
    for (const [key, id] of this.annotationsBeingUsed) {
      if (id === annotationId) {
        const nodeId = key.split('.')[0];
        this.validations.get(nodeId)?.();
      }
    }
  }

  setFormBuilderChange(formBuilderIsChanged: boolean) {
    this.formBuilderIsChanged = formBuilderIsChanged;
  }

  clearForceRendered() {
    this.visualizedRendering.rendered = 0;
  }

  increaseForceRendered() {
    this.visualizedRendering.rendered += 1;

    if (
      this.visualizedRendering.rendered ===
      this.visualizedRendering.toBeRendered
    ) {
      if (this.visualizedRendering.onFinish) {
        this.visualizedRendering.onFinish();
      }
    }
  }

  increaseTotalComponent() {
    this.visualizedRendering.toBeRendered += 1;
  }

  decreaseTotalComponent() {
    this.visualizedRendering.toBeRendered -= 1;
  }

  forceRender(itemIds: string[]) {
    const idSet = new Set(itemIds);

    return new Promise((resolve) => {
      this.visualizedRendering.sectionToRender = {
        itemIds: idSet,
        onRenderEnd: (id: string) => {
          idSet.delete(id);

          if (idSet.size === 0) {
            resolve(null);
          }
        },
      };
    });
  }

  forceRenderAll() {
    this.clearForceRendered();
    this.visualizedRendering.forceRenderAll = true;

    return new Promise((resolve) => {
      this.visualizedRendering.onFinish = () => resolve(null);
    });
  }

  setNodeRenderListener(callback: NodeRenderListener | null) {
    this.visualizedRendering.onNodeRender = callback;
  }

  clearForceRenderAll() {
    this.visualizedRendering.forceRenderAll = false;
  }

  setForceResetViewBounding(value: boolean) {
    this.visualizedRendering.forceResetViewBounding = value;
  }

  setIgnoreScrollOnce(value: boolean) {
    this.ignoreScrollOnce = value;
  }

  getAutolinkedField(fieldId: string) {
    const field = this.fields.find((f) => f.id === fieldId);
    if (!field?.linkId) {
      return undefined;
    }
    return this.fields.find((f) => f.id === field.linkId.split('!')[0]);
  }
}
