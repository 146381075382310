import React from 'react';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import { TmModalLoader } from 'src/components/tm-modal/tm-modal-loader';

export default function MissingFillConditionsModal({
  tds,
  operation = 'proceed',
  onOk,
  onCancel,
}) {
  const title = `Are you sure you want to ${operation}?`;
  const proceed = `${capitalize(operation)} Anyway`;
  let html = 'The following documents are missing fields:';
  const style = {
    row: 'display: flex;align-items: baseline;margin: 8px 0;',
    bullet: 'padding: 0 5px',
    description:
      'padding: 5px;margin-left: 8px;background-color: #ffebeb;border-radius: 5px;color: #bf2925;',
  };
  tds.forEach(({ titleNoExtension, missingFillConditionsCount }) => {
    html += `<div style="${style.row}"> 
        <div style="${style.bullet}">&bull;</div>
        <div>
          ${titleNoExtension}
          <span style="${style.description}">${missingFillConditionsCount}</span>
        </div>
      </div>`;
  });

  return (
    /**
     * Load form modal from Compass CDN by modal-loader.
     *
     * @see https://github.com/UrbanCompass/uc-frontend/tree/master/workspaces/tm/packages/modal--confirm
     */
    <TmModalLoader
      visible
      uri="tm/confirm/0"
      data={{
        title,
        okText: proceed,
        html,
      }}
      onSuccess={onOk}
      onClose={onCancel}
    />
  );
}
MissingFillConditionsModal.propTypes = {
  tds: PropTypes.array.isRequired,
  operation: PropTypes.string,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
