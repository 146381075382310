

import React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { PropTypes as MobxPropTypes } from 'mobx-react';
import PropTypes from 'prop-types';
import AppIcon from 'src/components/common/app-icon';
import OverflowLabel from 'src/components/common/overflow-label';

const clsPrefix = 'app-transaction-package-submit-errors';

const ERROR_LEVEL_ICON = {
  ERROR: 'xCircle',
  WARNING: 'alertCircle',
};

const ERROR_TYPES = {
  NO_DOCUMENTS: {
    description: 'You must include at least one document',
  },
  UNSIGNED_DOCUMENTS: {
    description: 'The following documents need to be signed',
    renderDetail: (d) => <OverflowLabel>{d}</OverflowLabel>,
  },
  OUTSTANDING_SIGNATURES: (tp) => ({
    description: `This ${tp.userPackageKind} contains signature requests with outstanding signatures`,
  }),
  MISSING_MANDATORY_ROLES: {
    description: 'The following parties are missing',
  },
};

function ModalContent({ transactionPackage, errors }) {
  return (
    <div className={clsPrefix}>
      {errors.map((error) => {
        const { type, level, details } = error;
        const errorTypeDef = isFunction(ERROR_TYPES[type])
          ? ERROR_TYPES[type](transactionPackage, error)
          : ERROR_TYPES[type];
        const { description, renderDetail } = errorTypeDef || {};
        return (
          <div className={`${clsPrefix}__error`} key={`${type}-${level}`}>
            <div className={`${clsPrefix}__error-title flex-align-center`}>
              <AppIcon
                className={classNames(
                  `${clsPrefix}__error-icon`,
                  `${clsPrefix}__error-icon--${level.toLowerCase()}`
                )}
                name={ERROR_LEVEL_ICON[level]}
              />
              <span className={`${clsPrefix}__error-label bold`}>
                {description || type}
                {Boolean(details?.length) && ':'}
              </span>
            </div>
            {Boolean(details?.length) && (
              <ul className={`${clsPrefix}__error-details`}>
                {details.map((d) => (
                  <li key={d}>
                    {renderDetail
                      ? renderDetail(d, error, transactionPackage)
                      : d}
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );
}
ModalContent.propTypes = {
  transactionPackage: PropTypes.object,
  errors: MobxPropTypes.arrayOrObservableArray,
};

export function getErrorModalProps(
  transactionPackage,
  ui,
  errors,
  reDispatchWithSkipWarnings
) {
  const hasErrors = errors.some((e) => e.level === 'ERROR');
  const onOk = hasErrors
    ? () => ui.resetModal()
    : async () => {
        await reDispatchWithSkipWarnings();
        ui.resetModal();
      };
  return {
    title: hasErrors
      ? `You must fix the following errors before delivering ${transactionPackage.userPackageKindWithArticle}`
      : 'Are you sure?',
    onOk,
    okText: hasErrors ? undefined : 'Submit Anyway',
    okButtonProps: hasErrors
      ? {
          type: 'default',
        }
      : undefined,
    showCancelButton: !hasErrors,
    onCancel: () => ui.resetModal(),
    children: (
      <ModalContent transactionPackage={transactionPackage} errors={errors} />
    ),
  };
}

export function getFrontendChecksErrorModalProps(
  _transactionPackage,
  ui,
  error,
  closeOnOk = true
) {
  const isError = error.level === 'ERROR';
  return {
    noTitle: true,
    onOk:
      !isError &&
      (async () => {
        try {
          await error.onOk();
        } catch (err) {
          ui.wentWrong(err);
        } finally {
          if (closeOnOk) {
            ui.resetModal();
          }
        }
      }),
    okText: error.ok || 'Continue',
    okButtonProps: isError
      ? {
          style: {
            display: 'none',
          },
        }
      : undefined,
    showCancelButton: true,
    onCancel: () => ui.resetModal(),
    cancelText: error.cancel || 'Cancel',
    children: (
      <div className={`${clsPrefix}__frontend-checks`}>
        <span className={`${clsPrefix}__frontend-checks-title`}>
          {error.title}
        </span>
        <span className={`${clsPrefix}__frontend-checks-description`}>
          {error.description}
        </span>
      </div>
    ),
  };
}
