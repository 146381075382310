import set from 'lodash/set';
import { action, computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import versionComparable from 'src/utils/version-comparable';

export default class Form extends Model {
  constructor(store, json) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  static FORM_SIDE_AFFINITIES = window.Glide.CONSTANTS.FORM_SIDE_AFFINITIES;
  static FORM_SIDE_AFFINITIES_MAP = {
    UNKNOWN_SIDE: 'Unknown',
    SALE: 'Listing',
    PURCHASE: 'Purchase',
    BOTH_SIDES: 'Both',
  };

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get uuid() {
    return this.data.uuid;
  }

  @computed
  get vers() {
    return this.data.vers;
  }

  @computed
  get isDefault() {
    return this.data.id === this.data.seriesDefaultFormId;
  }

  @computed
  get version() {
    return this.data.version || '0.0.0';
  }

  @computed
  get updatedAt() {
    return this.data.updatedAt || 0;
  }

  @computed
  get lastActiveAt() {
    return this.data.lastActiveAt;
  }

  @computed
  get scheduledAt() {
    return this.data.scheduledAt;
  }

  @computed
  get document() {
    return this.data.document;
  }

  @computed
  get annotations() {
    return this.data?.fillConfig?.annotations ?? [];
  }

  @computed
  get fields() {
    return this.data?.fillConfig?.reformForm?.fields ?? [];
  }

  @computed
  get outputs() {
    return this.data?.fillConfig?.reformForm?.outputs ?? [];
  }

  @computed
  get formConfig() {
    return {
      formId: this.data.uuid,
      items: this.data?.fillConfig?.formConfig?.items ?? [],
    };
  }

  @computed
  get activeFormConfig() {
    return this.data.flowConfigEnabled ?? false;
  }

  @computed
  get formFlowConfigId() {
    return this.data.formFlowConfigId;
  }

  @computed
  get subforms() {
    return this.data?.subforms ?? [];
  }

  set outputs(outputs) {
    return set(this, 'data.fillConfig.reformForm.outputs', outputs);
  }

  @computed
  get title() {
    return this.data.title;
  }

  @computed
  get sideAffinity() {
    return this.data.sideAffinity;
  }

  @computed
  get canApplyTo() {
    return this.data.canApplyTo;
  }

  @computed
  get decodedSideAffinity() {
    return (
      Form.FORM_SIDE_AFFINITIES_MAP[this.sideAffinity] ||
      Form.FORM_SIDE_AFFINITIES_MAP.UNKNOWN_SIDE
    );
  }

  @computed
  get libraries() {
    return this.data.libraries || [];
  }

  @computed
  get active() {
    return this.data.active;
  }

  @computed
  get qaActive() {
    return this.data.qaActive;
  }

  @computed
  get flowConfigState() {
    return (
      this.data.flowConfigState ||
      (this.activeFormConfig ? 'PUBLISHED' : 'DRAFT')
    );
  }

  @computed
  get versionComparable() {
    return versionComparable(this.version);
  }

  @computed
  get majorVersion() {
    return this.version.split('.')[0];
  }

  @computed
  get seriesId() {
    return this.data.seriesId;
  }

  @computed
  get minorVersion() {
    return this.version.split('.').splice(0, 2).join('.');
  }

  @computed
  get tags() {
    return this.data.tags || [];
  }

  @computed
  get glideFillable() {
    return this.data.glideFillable;
  }

  @computed
  get excludeMatching() {
    return this.data.excludeMatching;
  }

  @computed
  get state() {
    return this.data.state || 'DRAFT';
  }

  @action
  setPspdfkitDocumentId(pspdfkitDocumentId) {
    this.data.pspdfkitDocumentId = pspdfkitDocumentId;
  }

  @action
  setFormConfig(formConfig) {
    this.data.fillConfig.formConfig = formConfig;
  }
}
