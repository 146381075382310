import { computed, makeObservable } from 'mobx';
import { ItemTemplateSettingStatus } from 'src/types/proto/transactions';
import Item, { ItemStore, TransactionItemJson } from './item';

export type TemplateSettingJson = TransactionItemJson<'TEMPLATE_SETTING'>;

export default class TemplateSetting extends Item<'TEMPLATE_SETTING'> {
  constructor(store: ItemStore, json: TemplateSettingJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get isActive() {
    return this.kindItem.status === ItemTemplateSettingStatus.ACTIVE;
  }
}
