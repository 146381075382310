import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';
import { DELETE, VIEW_DOCUSIGN } from '../actions/kinds';
import { viewDocusign } from '../actions/view-docusign';

export default {
  actions: [VIEW_DOCUSIGN, DELETE],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  label: 'DocuSign Envelope',
  canAssign: false,
  canEditStatus: false,
  onClick: viewDocusign,
  style: {
    color: '#E33F46',
    background: '#FCD04F',
    border: '#E33F46',
  },
};
