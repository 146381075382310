import { computed, makeObservable } from 'mobx';
import keyBy from 'lodash/keyBy';

export default class BaseTermDelegate {
  constructor(term) {
    makeObservable(this);
    this.term = term;
  }

  @computed
  get mainFieldKey() {
    return '';
  }

  getAnnotations() {
    const allFormFieldNames = Object.values(this.term.fieldIds);
    return (
      this.term.store?.getAnnotationsByFormFieldNames(...allFormFieldNames) ||
      []
    );
  }

  getBoundOutput(fieldKey) {
    const fieldId = this.term.getFieldId(fieldKey);
    return fieldId
      ? this.term.outline.boundForm.getBoundOutputForField(fieldId)
      : undefined;
  }

  @computed
  get mainBoundOutput() {
    return this.getBoundOutput();
  }

  @computed
  get boundOutputs() {
    return [this.mainBoundOutput];
  }

  @computed
  get boundOutputsById() {
    return keyBy(this.boundOutputs, 'id');
  }

  @computed
  get topLinkedBoundOutputs() {
    return this.boundOutputs.map((bo) => bo.topLinkedOutput);
  }

  @computed
  get topLinkedBoundOutputsById() {
    return keyBy(this.topLinkedBoundOutputs, 'id');
  }

  renderReadOnlyValue() {
    return this.mainBoundOutput.readOnlyValue;
  }

  renderEmptyValue(forceNa) {
    return this.isNa() || forceNa ? 'N/A' : 'Add...';
  }

  getValuesByFormFieldId(value) {
    return {
      [this.term.mainFieldId]: value,
    };
  }

  getCurrentValue() {
    const mainBoundOutput = this.mainBoundOutput;
    if (!mainBoundOutput) {
      return null;
    }
    const topLinkedOutput = mainBoundOutput.topLinkedOutput;
    const bindings = Object.keys(topLinkedOutput?.bindings || {});
    return bindings.length === 1
      ? topLinkedOutput.getFieldValue(bindings[0])
      : bindings.reduce(
          (v, b) => ({
            ...v,
            [b]: topLinkedOutput.getFieldValue(b),
          }),
          {}
        );
  }

  getEvalValue(value) {
    return value;
  }

  async validate(_value) {
    return null;
  }

  runValidations(value) {
    return this.term.validations.map((v) => v(this.getEvalValue(value), value));
  }

  hasPermissionOnOutput(boundOutput) {
    const store = this.term.store;
    return store
      ? (boundOutput || this.mainBoundOutput)?.canSetValue(store.permissions) ??
          false
      : true;
  }

  hasPermission() {
    return this.boundOutputs
      .filter(Boolean)
      .some((bo) => this.hasPermissionOnOutput(bo));
  }

  isOutputNa(boundOutput) {
    return (boundOutput || this.mainBoundOutput)?.isNa ?? false;
  }

  isNa() {
    return this.boundOutputs.filter(Boolean).every((bo) => this.isOutputNa(bo));
  }
}
