import { computed, makeObservable } from 'mobx';
import type EnvelopeStore from 'src/stores/envelope-store';
import Item, { EnvelopeItemJson } from './item';

export type EnvelopeDocumentJson = EnvelopeItemJson<'ENVELOPE_DOCUMENT'>;

export default class EnvelopeDocument extends Item<'ENVELOPE_DOCUMENT'> {
  constructor(store: EnvelopeStore, json: EnvelopeDocumentJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get document() {
    return this.kindItem.document;
  }

  @computed
  get url() {
    return this.document?.url;
  }

  @computed
  get index() {
    return this.kindItem.index;
  }
}
