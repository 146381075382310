import { autorun } from 'mobx';

const autorunPromise = (f: () => any) =>
  new Promise((resolve) => {
    autorun(() => {
      const res = f();
      if (res) {
        resolve(res);
      }
    });
  });

export default autorunPromise;
