

import React, { Component } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import api from 'src/api';
import ActionsMenu from 'src/components/common/actions-menu';
import AnchorButton from 'src/components/common/anchor-button';
import AppButton from 'src/components/common/app-button';
import AppModal, { WIDTH_LG } from 'src/components/common/app-modal';
import { AppRadio as Radio } from 'src/components/common/app-radio';
import asyncProps from 'src/components/common/async-props';
import FakeForm from 'src/components/common/fake-form';
import AppRichTextEditor from '../common/app-rich-text-editor';
import RowSpinner from 'src/components/common/row-spinner';
import formatFullTimestamp from 'src/utils/format-full-timestamp';
import { QuickForm } from 'src/components/forms/quick-form';

const clsPrefix = 'app-email-templates';
const NEW_TEMPLATE_ID = '_new';

class TemplateView extends Component {
  static propTypes = {
    item: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    allowRte: PropTypes.bool,
  };

  static defaultProps = {
    onEdit: () => {},
    onDelete: () => {},
  };

  render() {
    const { item, allowRte } = this.props;

    return (
      <div className={`${clsPrefix}__template-view`}>
        <div className={`${clsPrefix}__template-details`}>
          <div className={`${clsPrefix}__template-title`}>{item.name}</div>
          <div className={`${clsPrefix}__template-description`}>
            {allowRte ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: item.value,
                }}
              />
            ) : (
              item.value
            )}
          </div>
          <div className={`${clsPrefix}__template-ts`}>
            Saved at{' '}
            {formatFullTimestamp(
              parseInt(item.updatedAt, 10),
              false,
              'M/d/YY hh:mm A'
            )}
          </div>
        </div>
        <ActionsMenu
          className={`${clsPrefix}__template-actions`}
          dropdownLabel={null}
          dropdownButtonProps={{
            iconSize: 16,
          }}
          size="default"
          menu={{
            Edit: this.props.onEdit,
            Delete: this.props.onDelete,
          }}
        />
      </div>
    );
  }
}

class TemplateForm extends Component {
  static propTypes = {
    item: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    defaultMessageValue: PropTypes.string,
    allowRte: PropTypes.bool,
    newMessageValue: PropTypes.string,
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
  };

  getErrors = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Name is required.';
    } else if (values.name.length > 255) {
      errors.name = 'Name should be limited to 255 characters.';
    }

    if (!values.value) {
      errors.value = 'Message is required.';
    }

    return errors;
  };

  getErrorProps = (errors, attr) => {
    return {
      validateStatus: errors[attr] ? 'error' : null,
    };
  };

  render() {
    const {
      item,
      onSave,
      defaultMessageValue,
      allowRte,
      newMessageValue,
      ...otherProps
    } = this.props;

    let initialValue = item || {
      value: defaultMessageValue,
    };

    if (newMessageValue) {
      initialValue = { ...initialValue, value: newMessageValue };
    }

    return (
      <div>
        <QuickForm
          ref={(ref) => {
            this.formRef = ref;
          }}
          initialValue={initialValue}
          getErrors={this.getErrors}
          hideControls
          onSave={(values) => onSave(item && item.id, values)}
          {...otherProps}
        >
          {({ currentValues, errors, onChange }) => (
            <FakeForm
              layout="horizontal"
              className={`${clsPrefix}__template-edit`}
            >
              <QuickForm.Item
                name="name"
                className={`${clsPrefix}__item`}
                {...this.getErrorProps(errors, 'name')}
              >
                <Input
                  autoFocus
                  placeholder="Title"
                  value={currentValues.name || ''}
                  onChange={({ target }) =>
                    onChange({
                      name: target.value,
                    })
                  }
                />
              </QuickForm.Item>
              <FakeForm.Item
                {...this.getErrorProps(errors, 'value')}
                className={`${clsPrefix}__item`}
              >
                {allowRte ? (
                  <AppRichTextEditor
                    className={`${clsPrefix}__item-rte`}
                    rteRef={(el) => {
                      this.reactQuillRef = el;
                    }}
                    placeholder="Description"
                    value={currentValues.value || ''}
                    onChange={(e) => {
                      onChange({
                        value: e,
                      });
                    }}
                  />
                ) : (
                  <Input.TextArea
                    rows="12"
                    placeholder="Description"
                    value={currentValues.value || ''}
                    onChange={({ target }) =>
                      onChange({
                        value: target.value,
                      })
                    }
                  />
                )}
              </FakeForm.Item>

              <div className={`${clsPrefix}__actions`}>
                <AppButton
                  className="margin-right-sm"
                  type="primary"
                  disabled={Object.keys(errors).length > 0}
                  onClick={async () => {
                    /* eslint-disable no-unused-vars */
                    if (this.formRef) {
                      const [_, e] = await this.formRef.validateAll();
                      if (!isEmpty(e)) {
                        return;
                      }
                    }
                    this.props.onSave(currentValues);
                    /* eslint-enable no-unused-vars */
                  }}
                >
                  Save
                </AppButton>
                <AppButton type="primary" onClick={this.props.onCancel}>
                  Cancel
                </AppButton>
              </div>
            </FakeForm>
          )}
        </QuickForm>
      </div>
    );
  }
}

class TemplateItem extends Component {
  static propTypes = {
    item: PropTypes.object,
    editing: PropTypes.bool,
    onEdit: PropTypes.func,
    onEditCancel: PropTypes.func,
    onDelete: PropTypes.func,
    onSave: PropTypes.func,
    defaultMessageValue: PropTypes.string,
    allowRte: PropTypes.bool,
    newMessageValue: PropTypes.string,
  };

  static defaultProps = {
    editing: false,
    onEdit: () => {},
    onDelete: () => {},
    onSave: () => {},
  };

  render() {
    const { item, editing, defaultMessageValue, allowRte, newMessageValue } =
      this.props;

    return (
      <div
        className={classNames(`${clsPrefix}__listitem`, {
          [`${clsPrefix}__template--editing`]: editing,
        })}
      >
        <Radio value={item ? item.id : '_new'} />
        <div className={`${clsPrefix}__listitem-inner`}>
          {!editing && (
            <TemplateView
              item={item}
              onEdit={() => this.props.onEdit()}
              onDelete={() => this.props.onDelete()}
              allowRte={allowRte}
            />
          )}
          {editing && (
            <TemplateForm
              item={item}
              onSave={this.props.onSave}
              onCancel={this.props.onEditCancel}
              defaultMessageValue={defaultMessageValue}
              allowRte={allowRte}
              newMessageValue={newMessageValue}
            />
          )}
        </div>
      </div>
    );
  }
}

@inject('ui')
@asyncProps(async ({ category }) => {
  const { data } = await api.account.findTemplateByCategory(category);
  return {
    templates: data,
  };
})
@observer
export default class EmailTemplates extends Component {
  static propTypes = {
    templates: PropTypes.object,
    category: PropTypes.string,
    onTemplateSelect: PropTypes.func,
    getMessage: PropTypes.func,
    allowRte: PropTypes.bool,
    ui: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      templates: props.templates,
      visible: false,
      selected: null,
      lastSelected: null,
      editing: false,
    };
  }

  showModal = () => {
    const { templates } = this.state;
    const selected =
      templates && templates.length === 1 ? templates[0].id : null;
    this.setState({
      visible: true,
      editing: false,
      selected,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      selected: false,
    });
  };

  addTemplate = () => {
    this.setState({
      selected: '_new',
      editing: true,
    });
  };

  selectTemplate = (templateId) => {
    const { onTemplateSelect } = this.props;
    this.setState(({ templates }) => {
      const template = templates.find(({ id }) => id === templateId);
      onTemplateSelect(template.value);
      return { lastSelected: template.id };
    });
    this.closeModal();
  };

  startEditing = (template) => {
    this.setState({
      selected: template.id,
      editing: true,
    });
  };

  saveTemplate = async (values, selectOnSave) => {
    const { ui } = this.props;
    try {
      const { data } = await api.account.updateTemplate(values);
      this.setState(({ templates }) => ({
        templates: [data, ...templates.filter(({ id }) => id !== data.id)],
        selected: selectOnSave ? data.id : null,
        editing: false,
      }));
    } catch (error) {
      ui.wentWrong(error);
    }
  };

  cancelEditing = () => {
    this.setState({
      editing: false,
      selected: null,
    });
  };

  deleteTemplate = async (item) => {
    const { ui } = this.props;
    ui.confirm({
      title: `Are you sure you want to delete ${item.name}?`,
      onOk: () => this.confirmDelete(item),
      onCancel() {},
    });
  };

  saveExisting = async () => {
    const { getMessage, ui } = this.props;
    const { lastSelected } = this.state;
    const message = await getMessage();

    if (!message) {
      ui.toast({
        message: 'Please specify a message',
        type: 'error',
      });
      return;
    }

    this.setState({
      visible: true,
      selected: lastSelected || NEW_TEMPLATE_ID,
      editing: true,
      newMessage: message,
    });
  };

  confirmDelete = async (template) => {
    const { ui } = this.props;
    try {
      await api.account.deleteTemplate(template.id);
      this.setState(({ templates }) => ({
        templates: templates.filter(({ id }) => id !== template.id),
        selected: null,
        editing: null,
      }));
    } catch (error) {
      ui.wentWrong(error);
    }
  };

  render() {
    const { allowRte, category } = this.props;

    const { visible, newMessage, editing, selected } = this.state;

    return (
      <React.Fragment>
        <AppModal
          key={`email-template-modal-${visible}`}
          width={WIDTH_LG}
          className={clsPrefix}
          visible={visible}
          title="Custom Message Templates"
          okText="Insert"
          okButtonProps={{
            disabled: editing || !this.state.selected,
          }}
          onOk={() => this.selectTemplate(selected)}
          onCancel={this.closeModal}
          maskClosable={false}
          emptyMsg="No templates exists."
        >
          <div className={`${clsPrefix}__list`}>
            <div>
              <AppButton
                type="primary"
                className={`${clsPrefix}__add`}
                onClick={() => this.addTemplate()}
              >
                <PlusOutlined />
                Add a Template
              </AppButton>
            </div>
            {this.loading && <RowSpinner />}
            {!this.loading && (
              <Radio.Group
                className={`${clsPrefix}__items`}
                value={selected}
                onChange={(ev) => {
                  this.setState({
                    editing: false,
                    selected: ev.target.value,
                  });
                }}
              >
                {selected && selected === '_new' && (
                  <TemplateItem
                    editing={editing}
                    onSave={(values) =>
                      this.saveTemplate(
                        {
                          category,
                          ...values,
                        },
                        true
                      )
                    }
                    onEditCancel={this.cancelEditing}
                    defaultMessageValue={newMessage}
                    allowRte={allowRte}
                  />
                )}
                {this.state.templates.map((template) => (
                  <TemplateItem
                    item={template}
                    onEdit={() => this.startEditing(template)}
                    onDelete={() => this.deleteTemplate(template)}
                    onSave={(values) => this.saveTemplate(values, false)}
                    onEditCancel={this.cancelEditing}
                    editing={editing && selected === template.id}
                    allowRte={allowRte}
                    newMessageValue={newMessage}
                  />
                ))}
              </Radio.Group>
            )}
          </div>
        </AppModal>
        <AnchorButton
          className="margin-right-sm"
          type="primary"
          onClick={this.showModal}
        >
          Apply a template
        </AnchorButton>
        <AnchorButton type="primary" onClick={this.saveExisting}>
          Save this as a template
        </AnchorButton>
      </React.Fragment>
    );
  }
}
