import intersection from 'lodash/intersection';
import { action, computed, makeObservable } from 'mobx';
import { getTransactionDocumentBoundForm } from 'src/components/documents/get-document-data';
import Model from 'src/models/base';
import { slugLower } from 'src/utils/slug';
import noop from 'lodash/noop';
import Term from './term';

export default class FormOutline extends Model {
  constructor(
    json,
    {
      fillConfig,
      transactionDocument,
      transactionReformForms,
      transactionSettings,
      boundForm,
      ocrFields,
      getDiffData,
    }
  ) {
    super(json);
    makeObservable(this);
    this.id = `${Math.round(Math.random() * 10000)}`;
    this.fillConfig = fillConfig;
    this.transactionDocument = transactionDocument;
    this.transactionReformForms = transactionReformForms;
    this.transactionSettings = transactionSettings;
    this._boundForm = boundForm || null;
    this._ocrFields = ocrFields || {};
    this._getDiffData = getDiffData || noop;
  }

  @computed
  get store() {
    return this.fillConfig?.store.parent.annotations;
  }

  @computed
  get boundForm() {
    if (this.store?.boundForm) {
      return this.store.boundForm;
    }

    if (this._boundForm) {
      return this._boundForm;
    }

    this._boundForm = getTransactionDocumentBoundForm({
      transactionDocument: this.transactionDocument,
      transactionReformForms: this.transactionReformForms,
      fillConfig: this.fillConfig,
      transactionSettings: this.transactionSettings,
    });

    return this._boundForm;
  }

  @computed
  get subforms() {
    return this.data.subforms || {};
  }

  @computed
  get terms() {
    let currentSectionTitle;
    return (this.data.terms || []).map((term, idx) => {
      const subformTitle = term.subformId
        ? this.subforms[term.subformId]
        : undefined;
      let withSubformTitle = false;
      if (subformTitle && subformTitle !== currentSectionTitle) {
        currentSectionTitle = subformTitle;
        withSubformTitle = true;
      }
      return new Term(
        this,
        term,
        `${idx + 1}`,
        withSubformTitle
          ? {
              subformTitle,
            }
          : undefined
      );
    });
  }

  @computed
  get flattenedTerms() {
    return this.terms.map((t) => [t, ...t.flattenedTerms]).flat();
  }

  getFilteredTermIds({ fillableOnly = false, search }) {
    // Gets filters and return the full list of targeted termIds
    const termIds = [];

    // Search
    const slugSearch = slugLower((search || '').trim());
    if (slugSearch) {
      termIds.push(
        this.flattenedTerms
          .filter(
            (t) =>
              !t.inCombo &&
              !t.hasFullTermDescendants &&
              slugLower(t.title).includes(slugSearch)
          )
          .map((t) => [t.id, ...t.predecessors.map((p) => p.id)])
          .flat()
      );
    }

    // Fillable Only
    if (fillableOnly) {
      termIds.push(
        this.flattenedTerms
          .filter((t) => !t.inCombo && (!t.isSection || !t.delegate.isEmpty))
          .map((t) => t.id)
      );
    }

    // End Result
    return termIds.length ? intersection(...termIds) : null;
  }

  @computed
  get termsById() {
    return this.flattenedTerms.reduce(
      (allTerms, term) => ({
        ...allTerms,
        [term.id]: term,
      }),
      {}
    );
  }

  getTerm(termId) {
    return this.termsById[termId];
  }

  getOcrValue(term) {
    if (!term) {
      return null;
    }
    const values = Object.values(term.fieldIds)
      .map((fId) => {
        return {
          field: fId,
          value: this._ocrFields[fId],
        };
      })
      .filter((v) => v.value != null);
    return values.length ? values : null;
  }

  getPrevNextTerm(term, excludeSections, filter, prev) {
    const flattenedTerms = this.flattenedTerms;
    const termIndex = flattenedTerms.findIndex((t) => t.id === term.id);

    const slice = flattenedTerms.slice(
      ...(prev ? [0, termIndex] : [termIndex + 1])
    );
    if (prev) {
      slice.reverse();
    }
    return slice.find(
      (t) =>
        !t.inCombo &&
        (!excludeSections || !t.isSection) &&
        (!filter || filter(t))
    );
  }

  getPrevTerm(term, excludeSections, filter) {
    return this.getPrevNextTerm(term, excludeSections, filter, true);
  }

  getNextTerm(term, excludeSections, filter) {
    return this.getPrevNextTerm(term, excludeSections, filter, false);
  }

  @computed
  get isAvailable() {
    return this.terms.length > 0;
  }

  @action
  stageValues(values) {
    const valuesByFieldId = Object.entries(values).reduce(
      (allValues, [termId, value]) => {
        const term = this.getTerm(termId);
        return {
          ...allValues,
          ...term.delegate.getValuesByFormFieldId(value),
        };
      },
      {}
    );
    if (Object.keys(valuesByFieldId).length) {
      Object.entries(valuesByFieldId).forEach(([fieldId, value]) => {
        const { topLinkedOutput } =
          this.boundForm.getBoundOutputForField(fieldId);
        topLinkedOutput.setFields({
          [topLinkedOutput.identityFieldName]: value,
        });
      });
    }
  }

  clearHighlight() {
    return this.store?.clearAllHighlighted();
  }

  @action.bound
  getDiffData(term) {
    return this._getDiffData(term);
  }
}
