import camelCase from 'lodash/camelCase';
import { computed, makeObservable } from 'mobx';
import { CamelCase } from 'type-fest';
import offersIcon from 'src/images/spot-icons/apps/spot-offers.svg';
import sellerDisclosuresIcon from 'src/images/spot-icons/apps/spot-sellerdisc.svg';
import {
  OFFERS_GUID,
  SELLER_DISCLOSURES_GUID,
} from 'src/models/transactions/apps';
import {
  ItemTransactionApp,
  ItemTransactionAppType,
} from 'src/types/proto/transactions';
import type Transaction from '../../transaction';
import Item, { ItemStore, TransactionItemJson } from '../item';
import OffersAppDelegate from './offers';
import SellerDisclosuresAppDelegate from './seller-disclosures';

const _ICONS_BY_GUID = {
  [SELLER_DISCLOSURES_GUID as string]: sellerDisclosuresIcon,
  [OFFERS_GUID as string]: offersIcon,
};

const _DELEGATE_BY_GUID = {
  [SELLER_DISCLOSURES_GUID as string]: SellerDisclosuresAppDelegate,
  [OFFERS_GUID as string]: OffersAppDelegate,
};

export type ValidItemTransactionAppType = Exclude<
  ItemTransactionAppType,
  'UNKNOWN'
>;

export type TransactionAppJson<K extends ValidItemTransactionAppType> = Omit<
  TransactionItemJson<'TRANSACTION_APP'>,
  'transactionApp'
> & {
  transactionApp: Omit<
    ItemTransactionApp,
    CamelCase<ValidItemTransactionAppType>
  > & {
    [key in K]: ItemTransactionApp[CamelCase<K>];
  };
};

export default class TransactionApp<
  K extends ValidItemTransactionAppType
> extends Item<'TRANSACTION_APP'> {
  public delegate:
    | SellerDisclosuresAppDelegate
    | OffersAppDelegate
    | undefined = undefined;

  constructor(store: ItemStore, json: TransactionAppJson<K>) {
    super(store, json);
    makeObservable(this);
    const DelegateCls = _DELEGATE_BY_GUID[this.guid];
    if (DelegateCls) {
      this.delegate = new DelegateCls(this);
    }
  }

  get status() {
    return this.kindItem.status;
  }

  @computed
  get type() {
    return this.kindItem.type;
  }

  @computed
  get guid() {
    return this.kindItem.guid;
  }

  @computed
  get version() {
    return this.kindItem.version;
  }

  get typeItem(): Partial<ItemTransactionApp[CamelCase<K>]> {
    return this.kindItem[camelCase(this.type) as CamelCase<K>] || {};
  }

  @computed
  get icon() {
    return _ICONS_BY_GUID[this.guid];
  }

  getRoute = ({ transaction }: { transaction: Transaction }) => {
    if (this.guid === SELLER_DISCLOSURES_GUID && transaction) {
      return {
        routeName: 'transactions.transaction.disclosurePackage',
        routeParams: {
          transactionId: transaction.id,
        },
      };
    }
    if (this.guid === OFFERS_GUID && transaction) {
      return {
        routeName: 'transactions.transaction.offers',
        routeParams: {
          transactionId: transaction.id,
        },
      };
    }

    return undefined;
  };
}
