import {
  legacyPartyModalSaved,
  transactionTemplateEdited,
} from '@uc/analytics-definitions';
import invariant from 'invariant';
import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';
import isArrayLike from 'lodash/isArrayLike';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import omit from 'lodash/omit';
import remove from 'lodash/remove';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
  toJS,
} from 'mobx';
import uuid from 'uuid/v4';
import analytics, { trackError } from 'src/analytics';
import logger from 'src/logger';
import { IntentKind } from 'src/types/proto/transactions';
import { getSubProduct } from 'src/utils/analytics/segment';
import autorunPromise from 'src/utils/autorun-promise';
import batchPromises from 'src/utils/batch-promises';
import { getFetch } from 'src/utils/get-fetch';
import getKindData from 'src/utils/get-kind-data';
import IndexedObservableMap from 'src/utils/indexed-observable-map';

export const IDX_ITEM_TRANSACTION_ID_KIND = 'tradnsactionId_Kind';
export const IDX_KIND = 'kind';
const MAX_RECENT_AGGREGATES = 10;
const DISPATCHING = 'DISPATCHING';
const DELAYED = 'DELAYED';

const ERROR_KEY = '__error';
const _makeError = (e) => ({
  ...e,
  [ERROR_KEY]: true,
});
const _isError = (e) => Boolean(e) && e[ERROR_KEY] === true;
const _getError = (e) => omit(e, ERROR_KEY);

// A helper for updating `modelsById` based on latest data in `jsonModelsById`.
// `factory` is used to create new instances in `modelsById` for new data.
// Note: Partial updates are allowed. This method doesn't delete any `modelsById`
// entries missing in `jsonModelsById`.
//
// Updates are versioned - a `modelsById` entry is updated only if `jsonModelsById`
// data has a backend `version` (jsonData['vers']) higher than the instance in
// `modelsById` (modelInstance.version). This ensures that stale data arriving out
// of order doesn't overwrite fresher data.
function updateModelsById(modelsById, jsonModelsById, factory, reinstantiate) {
  Object.keys(jsonModelsById).forEach((k) => {
    const json = jsonModelsById[k];
    invariant(json.id === k, 'Model JSON id !== key');
    invariant(json.id, 'Model JSON must have id field.');

    if (modelsById.has(k)) {
      const model = modelsById.get(k);

      // modelVersion can be undefined if the the model instance is
      // not based off backend data. In which case we always want to update it.
      const modelVersion = Number(model.vers || -1);
      const jsonVersion = Number(json.vers || 0);

      // Version data on models must be numbers if present.
      if (Number.isNaN(modelVersion) || Number.isNaN(jsonVersion)) {
        throw Error(`Unexpected model versions found. jsonVersion: ${json.vers},
            modelVersion: ${model.vers}, id: ${json.id}`);
      }

      if (jsonVersion >= modelVersion) {
        if (!reinstantiate || !reinstantiate(model, json)) {
          modelsById.updateValue(k, () => {
            modelsById.get(k).updateFromJson(json);
          });
        } else {
          modelsById.set(k, factory(json));
        }
      }
    } else {
      modelsById.set(k, factory(json));
    }
  });
}

const itemIndices = (filters) => ({
  [IDX_ITEM_TRANSACTION_ID_KIND]: {
    keyBy: ['transactionId', 'kind'],
    computed: {
      get active() {
        return this.all.filter((i) => !i.archived);
      },

      filter(item) {
        return this.all.filter(filters[this.indexValues.kind][item]);
      },
    },
  },
  [IDX_KIND]: {
    keyBy: ['kind'],
    computed: {
      get active() {
        return this.all.filter((i) => !i.archived);
      },

      filter(item) {
        return this.all.filter(filters[this.indexValues.kind][item]);
      },
    },
  },
});

export default class AggregateStore {
  @observable recentAggregatesIdList = null;
  @observable aggregatesById = new IndexedObservableMap();
  @observable thinAggregatesById = new IndexedObservableMap();
  @observable aggregateReindexedCallbacks = [];
  @observable itemDidChangeCallbacks = {};

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.itemsById = new IndexedObservableMap({
      ...itemIndices(this.constructor.itemFilters || {}),
      ...(this.constructor.extraItemsIndices || {}),
    });
  }

  loadRawAggregates(rawAggregates) {
    let aggregateIdList = null;
    runInAction(() => {
      rawAggregates.forEach((a) => {
        this.updateAggregatesById({
          [a.id]: a,
        });
      });
      aggregateIdList = rawAggregates.map((a) => a.id);
    });
    return aggregateIdList.map((id) => this.aggregatesById.get(id));
  }

  getFetchAggregate = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId }) => transactionId,
    getter: ({ transactionId }) => {
      return this.aggregatesById.get(transactionId);
    },
    fetcher: async ({ transactionId }) => {
      const { data } = await this.api.getAggregate(transactionId);
      const aggregate = data;
      runInAction(() => {
        this.updateAggregatesById({
          [aggregate.id]: aggregate,
        });
        this.bumpRecentAggregateById(aggregate.id);
      });
    },
  });

  fetchDataForAggregate = async () => {};

  @action
  getOrFetchAggregate = async (aggregateId, options) => {
    const { force, includeData } = {
      force: false,
      includeData: false,
      ...options,
    };
    let aggregate = this.getFetchAggregate.get({
      transactionId: aggregateId,
    });
    if (!aggregate || force) {
      aggregate = await this.getFetchAggregate.fetch({
        transactionId: aggregateId,
      });
    }

    if (includeData) {
      await this.fetchDataForAggregate(aggregate, options);
    }

    return aggregate;
  };

  @computed
  get recentAggregatesList() {
    if (this.recentAggregatesIdList === null) {
      return null;
    }
    return this.recentAggregatesIdList
      .slice(0, MAX_RECENT_AGGREGATES)
      .map((id) => this.transactionsById.get(id))
      .filter((t) => t.isActive);
  }

  @action
  ensureRecent = async () => {
    if (this.recentAggregatesIdList === null) {
      const { data } = await this.fetchList({
        limit: MAX_RECENT_AGGREGATES,
      });
      runInAction(() => {
        this.recentAggregatesIdList = data.map((t) => t.id);
      });
    }
    return this.recentAggregatesList;
  };

  @action
  bumpRecentAggregateById(aggregateId) {
    if (this.recentAggregatesIdList === null) {
      return;
    }

    const idx = this.recentAggregatesIdList.indexOf(aggregateId);
    if (idx !== -1) {
      this.recentAggregatesIdList.splice(idx, 1);
    }
    this.recentAggregatesIdList.splice(0, 0, aggregateId);
  }

  @action
  updateItemsById(jsonItemsById, ignoreIds = []) {
    const jsonItemsNoIgnored = omit(jsonItemsById, ignoreIds);
    const deletedIds = map(
      filter(jsonItemsNoIgnored, (v) => v.deleted),
      (v) => v.id
    );
    const jsonItemsToUpdate = omit(jsonItemsNoIgnored, deletedIds);
    this.deleteItemsById(deletedIds);

    updateModelsById(this.itemsById, jsonItemsToUpdate, (json) => {
      const item = json;
      return this.makeItem(item);
    });

    keys(jsonItemsToUpdate).forEach((id) => {
      const item = this.itemsById.get(id);
      if (item.resolvedItems && item.resolvedItems.length) {
        const resolvedItemPairs = flatMap(
          item.resolvedItems.map((field) => toJS(item.kindItem[field])),
          (resolvedItem) => {
            if (!resolvedItem) {
              return [];
            }
            if (resolvedItem.id) {
              return [resolvedItem];
            }
            if (isArrayLike(resolvedItem)) {
              return resolvedItem.filter((r) => r && r.id);
            }
            return [];
          }
        ).map((resolvedItem) => [resolvedItem.id, resolvedItem]);

        if (resolvedItemPairs.length) {
          this.updateItemsById(
            fromPairs(resolvedItemPairs),
            keys(jsonItemsToUpdate).concat(ignoreIds)
          );
        }
      }

      this.updatedItem(item);
    });
  }

  updatedItem = () => {};

  @action
  updateThinAggregatesById(jsonTransactionsById) {
    updateModelsById(
      this.thinAggregatesById,
      jsonTransactionsById,
      (json) => new this.constructor.ThinAggregate(this, json)
    );
  }

  @action
  updateAggregatesById(jsonAggregatesById) {
    updateModelsById(
      this.aggregatesById,
      jsonAggregatesById,
      (json) => {
        const Cls = this.getClass(json);
        return new Cls(this, json);
      },
      (oldTrans, newTrans) =>
        !oldTrans || this.getClass(oldTrans) !== this.getClass(newTrans)
    );

    Object.values(jsonAggregatesById).forEach((jsonTrans) => {
      const aggregate = this.aggregatesById.get(jsonTrans.id);
      if (aggregate.resolvedItems) {
        this.updateItemsById(
          keyBy(
            filter(
              map(aggregate.resolvedItems, (resolvedItem) => {
                return get(aggregate.meta, resolvedItem);
              }),
              (v) => !isNil(v)
            ),
            'id'
          )
        );
      }
    });
    if (this.constructor.ThinAggregate) {
      this.updateThinAggregatesById(jsonAggregatesById);
    }
  }

  @action
  create = async (intentObj) => {
    const { account } = this.parent;
    try {
      const resp = await this.api.create(intentObj);
      const { transaction } = resp.data;
      analytics().track(`Dispatch: ${intentObj.kind}`, {
        email: account.user.contact.email,
        name: `${account.user.contact.firstName} ${account.user.contact.lastName}`,
        transactionId: transaction.id,
      });
      runInAction(() => {
        this.updateAggregatesById({
          [transaction.id]: transaction,
        });
        this.bumpRecentAggregateById(transaction.id);
      });
      return this.aggregatesById.get(transaction.id);
    } catch (dispatchErr) {
      trackError(dispatchErr, `DispatchError: ${intentObj.kind}`);
      throw dispatchErr;
    }
  };

  dispatchResponseHandler = async ({ transactionId, vers, clientId }) => {
    // we get and processes dispatch responses that havent
    // been received as a response to a dispatch call from the frontend
    // this is used to resolve async dispatch calls
    // and to keep the store up to date with other users actions
    logger.log('dispatchResponse', {
      transactionId,
      vers,
      clientId,
    });

    if (!this.parent.embeddedApp.isCurrentTransaction(transactionId)) {
      logger.log('dispatchResponse - not current transaction');
      return;
    }

    let existing = null;
    if (clientId) {
      // if we are currently dispatching this intent
      // then we want to wait until we are no longer dispatching it
      // then we see if we have sucessfully processed a response
      // or if it was delayed
      existing = await autorunPromise(() => {
        const res = this.dispatchResponseByClientId.get(clientId);
        if (res === DISPATCHING) {
          return null;
        }
        return res || 'NONE'; // make autrun promise return in this case
      });
      logger.log('dispatchResponse - existing:', existing);
    } else {
      existing = null;
      logger.log('dispatchResponse - noclientid');
    }
    // if existing == delayed then we want to process the response
    if (existing && existing !== DELAYED && existing !== 'NONE') {
      return;
    }
    this.fetchAndProcessDispatchResponse(transactionId, vers);
  };

  dispatchErrorResponseHandler = async ({ status, error, clientId }) => {
    logger.log('asyncDispatchError', {
      status,
      error,
      clientId,
    });
    let existing = null;
    if (clientId) {
      existing = await autorunPromise(() => {
        const res = this.dispatchResponseByClientId.get(clientId);
        if (res === DISPATCHING) {
          return null;
        }
        return res || 'NONE'; // make autorun promise return in this case
      });
      logger.log('asyncDispatchError - existing:', existing);
    } else {
      existing = null;
      logger.log('asyncDispatchError - noclientid');
    }
    if (existing && existing !== DELAYED && existing !== 'NONE') {
      return;
    }
    if (clientId) {
      runInAction(() => {
        this.dispatchResponseByClientId.set(clientId, _makeError(error));
      });
    }
  };

  aggregateReindexHandler = async ({ transactionId }) => {
    const txn = this.transactionsById.get(transactionId);
    if (txn) {
      this.sendAggregateReindexed(txn);
    }
  };

  trackDispatch(tid, intent) {
    const product = getSubProduct(this.parent.embeddedApp.embeddedFeature);
    const kindData = getKindData(intent);
    try {
      switch (intent.kind) {
        case IntentKind.UPDATE_PARTY_CONTACT_DETAILS: {
          legacyPartyModalSaved({
            party: {
              email: kindData.contact?.email,
            },
            selected_roles: kindData.roles,
            product,
            is_edit_party: true,
          });
          break;
        }
        case IntentKind.INVITE_PARTIES: {
          kindData.invites.forEach((invite) => {
            legacyPartyModalSaved({
              party: {
                email: invite.contact?.email,
              },
              selected_roles: invite.roles,
              product,
              is_edit_party: false,
            });
          });
          break;
        }
        default:
          break;
      }
    } catch (e) {
      console.error('error sending analytics tracking for intent', {
        tid,
        intent,
      });
    }
  }

  @action
  baseDispatch = async (tid, intentObj, ...args) => {
    let data;
    const { isTemplate } = await this.getOrFetchAggregate(tid);
    const { optimistic, ...rawIntent } = intentObj;

    if (optimistic && optimistic.stage) {
      optimistic.stage(this.aggregatesById.get(tid));
    }
    const clientId = uuid();
    rawIntent.clientId = clientId;
    runInAction(() =>
      this.dispatchResponseByClientId.set(clientId, DISPATCHING)
    );
    try {
      analytics().track(`Dispatch: ${intentObj.kind}`, {
        transactionId: tid,
      });
      this.trackDispatch(tid, rawIntent);
      const resp = await this.api.dispatch(tid, rawIntent, ...args);
      ({ data } = resp);

      if (isTemplate) {
        transactionTemplateEdited({
          product: 'business_tracker',
          sub_product: 'transaction_templates',
        });
      }
    } catch (dispatchErr) {
      trackError(dispatchErr, `Dispatch Error: ${intentObj.kind}`, {
        transactionId: tid,
      });

      if (optimistic && optimistic.fail) {
        optimistic.fail(dispatchErr, this.aggregatesById.get(tid));
      }
      if (optimistic && optimistic.unstage) {
        optimistic.unstage(this.aggregatesById.get(tid));
      }
      runInAction(() => this.dispatchResponseByClientId.set(clientId, 'ERROR'));
      throw dispatchErr;
    }

    if (data.isDelayed && data.clientId) {
      if (this.dispatchResponseByClientId.get(data.clientId) === DISPATCHING) {
        runInAction(() =>
          this.dispatchResponseByClientId.set(clientId, DELAYED)
        );
      }
      // if delayed
      // we wait until we have a response in the store (which comes from
      // the hook) then return it
      return autorunPromise(() => {
        const res = this.dispatchResponseByClientId.get(data.clientId);
        if (res === DELAYED) {
          return null;
        }
        if (_isError(res)) {
          return Promise.reject(_getError(res));
        }
        return [false, res];
      });
    }
    return [true, [data, optimistic]];
  };

  @action
  dispatch = async (tid, intentObj, ...args) => {
    return this.processBaseDispatchResponse(
      await this.baseDispatch(tid, intentObj, ...args)
    );
  };

  @action
  bulkDispatch = async (dispatches, options = {}) => {
    // TODO backend endpoint that supports bulk dispatch
    if (!dispatches || !dispatches.length) {
      return null;
    }

    const {
      concurrency = 10, // Can set the max number of parallel dispatches with this param (default is 10)
    } = options || {};

    const allResponses = await batchPromises(
      dispatches || [],
      ([tid, intentObj, ...args]) => this.baseDispatch(tid, intentObj, ...args),
      {
        concurrency,
      }
    );
    let res = [];
    runInAction(() => {
      res = allResponses.map((r) => this.processBaseDispatchResponse(r));
    });
    return res;
  };

  processBaseDispatchResponse = (res) => {
    if (res && res.length) {
      const [process, r] = res;
      if (process && r) {
        return this.processDispatchResponse(...r);
      }

      return r;
    }
    return res;
  };

  processDispatchResponse = (dispatchResponse, optimistic) => {
    const { transaction, result, mutatedItemsById, clientId, isReindexed } =
      dispatchResponse;
    runInAction(() => {
      if (optimistic && optimistic.unstage) {
        optimistic.unstage(this.transactionsById.get(transaction.id));
      }
      this.updateAggregatesById({
        [transaction.id]: transaction,
      });

      this.updateItemsById(mutatedItemsById, []);
      this.bumpRecentAggregateById(transaction.id);
    });

    const mutatedItemModelsById = {};
    Object.keys(mutatedItemsById).forEach((id) => {
      mutatedItemModelsById[id] = this.itemsById.get(id);
    });

    const res = {
      // eslint-disable-line
      transaction: this.transactionsById.get(transaction.id),
      mutatedItemsById: mutatedItemModelsById,
      result,
    };
    if (!isEmpty(mutatedItemsById)) {
      this.sendItemDidChange(mutatedItemsById);
    }

    if (isReindexed) {
      this.sendAggregateReindexed(transaction);
    }
    if (clientId) {
      runInAction(() => {
        this.dispatchResponseByClientId.set(clientId, res);
      });
    }
    return res;
  };

  fetchAndProcessDispatchResponse = async (transactionId, vers) => {
    const { data } = await this.api.fetchDispatchResponse(transactionId, vers);
    return this.processDispatchResponse(data);
  };

  @action
  subscribeAggregateReindexed(key, callback) {
    this.aggregateReindexedCallbacks.push({
      key,
      callback,
    });
  }

  @action
  unsubscribeAggregateReindexed(key, callback) {
    remove(
      this.aggregateReindexedCallbacks,
      (x) => x.key === key && (!callback || x.callback === callback)
    );
  }

  @action
  subscribeItemDidChange(key, kind, callback) {
    this.itemDidChangeCallbacks[kind] = this.itemDidChangeCallbacks[kind] || [];
    this.itemDidChangeCallbacks[kind].push({
      key,
      callback,
    });
  }

  @action
  unsubscribeItemDidChange(key, kind, callback) {
    this.itemDidChangeCallbacks[kind] = this.itemDidChangeCallbacks[kind] || [];
    remove(
      this.itemDidChangeCallbacks[kind],
      (x) => x.key === key && (!callback || x.callback === callback)
    );
  }

  @action
  sendItemDidChange(mutatedItemsById) {
    const itemsByKind = {};
    values(mutatedItemsById).forEach((item) => {
      itemsByKind[item.kind] = itemsByKind[item.kind] || [];
      itemsByKind[item.kind].push(item);
    });
    toPairs(itemsByKind).forEach(([kind, items]) => {
      const callbacks = this.itemDidChangeCallbacks[kind];
      if (callbacks) {
        callbacks.forEach(({ callback }) => {
          try {
            callback(items);
          } catch (err) {
            logger.error('Error with itemDidChangeCallback.');
          }
        });
      }
    });
  }

  @action
  sendAggregateReindexed(transaction) {
    this.aggregateReindexedCallbacks.forEach(({ callback }) => {
      try {
        callback(transaction);
      } catch (err) {
        logger.error('Error with itemDidChangeCallback.');
      }
    });
  }

  /* Items  ---------------------------------------------------- */

  getOrFetchItem = async (transactionId, kind, id, options) => {
    const { force } = {
      force: false,
      ...options,
    };
    let item = this.getItem(transactionId, kind, id);
    if (item && !force) {
      return item;
    }

    item = await this.fetchItem(transactionId, kind, id);
    return item;
  };

  getOrFetchItemMulti = async (transactionId, kind, ids, options) => {
    if (ids.length === 0) {
      return [];
    }

    invariant(
      ids.every((x) => x),
      'Undefined item id'
    );

    const { force } = {
      force: false,
      ...options,
    };

    const missingIds = (
      force ? ids : ids.filter((id) => !this.itemsById.get(id))
    ).filter((id) => id);

    if (missingIds.length) {
      const { data } = await this.api.fetchMultiItems({
        transactionId,
        kind,
        data: { ids: missingIds },
      });
      const itemsById = keyBy(data, 'id');
      runInAction(() => {
        this.updateItemsById(itemsById);
      });
    }

    let items = ids.map((id) => this.itemsById.get(id));

    if (kind) {
      items = items.filter((item) => item && item.kind === kind);
      invariant(items.length === ids.length, `Some items not of kind: ${kind}`);
    }

    return items;
  };

  getItem(transactionId, kind, id) {
    return this.getFetchItem.get({
      transactionId,
      kind,
      id,
    });
  }

  /** @type * */
  getItems(transactionId, kind) {
    return this.getFetchItems.get({
      transactionId,
      kind,
    });
  }

  @action
  fetchItem = (transactionId, kind, id, resolve) => {
    return this.getFetchItem.fetch({
      transactionId,
      kind,
      id,
      resolve,
    });
  };

  getFetchItem = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId, kind, id }) =>
      `${transactionId}:${kind}:${id}`,
    getter: ({ transactionId, kind, id }) => {
      const item = this.itemsById.get(id);
      if (
        !item ||
        (kind && item.kind !== kind) ||
        (transactionId && item.transactionId !== transactionId)
      ) {
        return null;
      }
      return item;
    },
    fetcher: async ({ transactionId, kind, id, resolve }) => {
      const resolved = await Promise.all([
        this.getOrFetchAggregate(transactionId),
        this.api.fetchItem(transactionId, kind, id, {
          resolve,
        }),
      ]);

      const { data } = resolved[1];

      runInAction(() => {
        this.updateItemsById({
          [id]: data,
        });
      });
    },
  });

  @action
  fetchItems = (transactionId, kind, item) => {
    return this.getFetchItems.fetch({
      transactionId,
      kind,
      item,
    });
  };

  @action
  deleteItemsById(itemIds) {
    itemIds.forEach((id) => {
      const item = this.itemsById.get(id);
      if (item) {
        item.updateFromJson({
          ...item.data,
          deleted: 1,
        });
      }
      this.itemsById.delete(id);
    });
  }

  getFetchItems = getFetch({
    bindTo: this,
    getMemoizeKey: ({ transactionId, kind, item }) =>
      item ? `${transactionId}:${kind}:${item}` : `${transactionId}:${kind}`,
    getter: ({ transactionId, kind, item }) => {
      if (item) {
        return this.itemsById
          .indexed(IDX_ITEM_TRANSACTION_ID_KIND, {
            transactionId,
            kind,
          })
          .filter(item);
      }
      return this.itemsById.indexed(IDX_ITEM_TRANSACTION_ID_KIND, {
        transactionId,
        kind,
      }).all;
    },
    fetcher: async ({ transactionId, kind, item }) => {
      const resolved = await Promise.all([
        this.getOrFetchAggregate(transactionId),
        this.api.fetchItems(transactionId, kind, {
          item,
        }),
      ]);
      const { data } = resolved[1];
      const items = data.data;
      const itemsById = keyBy(items, 'id');
      runInAction(() => {
        this.updateItemsById(itemsById);
      });
    },
  });
}
