import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type TransactionFieldsJson = TransactionItemJson<'TRANSACTION_FIELDS'>;

export default class TransactionFields extends Item<'TRANSACTION_FIELDS'> {
  constructor(store: ItemStore, json: TransactionFieldsJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get namespace() {
    return this.kindItem.namespace || '';
  }
  getFieldIsUnlinked() {
    return false;
  }
}
