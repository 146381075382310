/* eslint-disable @typescript-eslint/no-empty-function */
export default function getLocalStorage() {
  const isEmbedded = Boolean(window.Glide?.isEmbedded);
  if (isEmbedded) {
    console.warn('Cannot use localStorage on Glide embedded.'); // eslint-disable-line no-console
  }
  return isEmbedded
    ? {
        getItem: () => null,
        setItem() {},
        removeItem() {},
      }
    : window.localStorage;
}
