import { computed, makeObservable } from 'mobx';
import type EnvelopeStore from 'src/stores/envelope-store';
import Item, { EnvelopeItemJson } from './item';

export type StepJson = EnvelopeItemJson<'STEP'>;

export default class Step extends Item<'STEP'> {
  constructor(store: EnvelopeStore, json: StepJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get state() {
    return this.kindItem.state;
  }

  @computed
  get stepKind() {
    return this.kindItem.stepKind;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get ownerId() {
    return this.outEdges
      .filter((e) => e.kind === 'STEP_HAS_OWNER')
      .map((e) => e.item2Id)[0];
  }

  @computed
  get owner() {
    return this.ownerId ? this.store.itemsById.get(this.ownerId) : null;
  }
}
