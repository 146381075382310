import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type ItemLabelJson = TransactionItemJson<'ITEM_LABEL'>;

export default class ItemLabel extends Item<'ITEM_LABEL'> {
  constructor(store: ItemStore, json: ItemLabelJson) {
    super(store, json);
    makeObservable(this);
  }

  @computed
  get color() {
    return this.kindItem.color;
  }

  set color(value) {
    this.kindItem.color = value;
  }

  @computed
  get label() {
    return this.kindItem.label;
  }

  set label(value) {
    this.kindItem.label = value;
  }
}
