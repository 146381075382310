import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type InvoiceJson = TransactionItemJson<'INVOICE'>;

export default class Invoice extends Item<'INVOICE'> {
  resolvedItems = ['td'];

  constructor(store: ItemStore, json: InvoiceJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get mode() {
    return this.kindItem.mode;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get customerName() {
    return this.kindItem.customerName;
  }

  @computed
  get customerFullName() {
    return [this.customerFirstName, this.customerLastName]
      .filter((n) => n)
      .map((n) => n.trim())
      .join(' ');
  }

  @computed
  get customerDisplayName() {
    return this.customerName || this.customerFullName;
  }

  @computed
  get customerFirstName() {
    return this.kindItem.customerFirstName;
  }

  @computed
  get customerLastName() {
    return this.kindItem.customerLastName;
  }

  @computed
  get customerEmail() {
    return this.kindItem.customerEmail;
  }

  @computed
  get externalCustomerId() {
    return this.kindItem.externalCustomerId;
  }

  @computed
  get sentDate() {
    return this.kindItem.sentDate;
  }

  @computed
  get receivedDate() {
    return this.kindItem.receivedDate;
  }

  @computed
  get invoiceNum() {
    return this.kindItem.invoiceNum;
  }

  @computed
  get amount() {
    return this.kindItem.amount;
  }

  @computed
  get td() {
    return this.outEdges
      .filter((e) => e.kind === 'INVOICE_GENERATED_TD')
      .map((e) => this.store.itemsById.get(e.item2Id))
      .filter((td) => td)[0];
  }
}
