
import range from 'lodash/range';
import { action, computed, makeObservable, observable } from 'mobx';

export default class DocumentMissingSignatureStore {
  @observable pspdfkitInstance;
  @observable allSignatures = [];
  @observable dismissedSignatures = [];
  @observable focusedSignature = null;

  constructor(parent) {
    makeObservable(this);

    this.parent = parent;
  }

  @computed
  get dismissedIds() {
    return this.dismissedSignatures.map((s) => s.zone.id);
  }

  isDismissed(signature) {
    return this.dismissedIds.includes(signature.zone.id);
  }

  @action
  setPspdfkitInstance(pspdfkitInstance) {
    this.pspdfkitInstance = pspdfkitInstance;
  }

  @action
  setAllSignatures(signatures) {
    this.allSignatures = signatures;
  }

  @action
  setFocusedSignature(signature, scrollTo = true) {
    this.focusedSignature = signature;
    if (signature && scrollTo) {
      this.scrollTo(
        // Align top of the annotation to roughly 20% of pdf viewer's height
        this.getSignatureOffset(signature) + this.getOffset('-20%'),
        {
          placement: 'top',
          minScrollLength: 100, // Avoid "small" jumps
        }
      );
    }
  }

  @action
  dismissSignature(signature) {
    this.dismissedSignatures.push(signature);
  }

  @action
  focusNextSignature() {
    let currentIndex = -1;
    if (this.focusedSignature) {
      currentIndex = this.allSignatures.findIndex((s) => {
        return s.zone.id === this.focusedSignature?.zone.id;
      });
    }

    let next = this.allSignatures.find((s, index) => {
      return index > currentIndex && !this.dismissedIds.includes(s.zone.id);
    });

    if (next === undefined) {
      // Get next non-dismissed signature
      next = this.allSignatures.find((s) => {
        return !this.dismissedIds.includes(s.zone.id);
      });
    }
    this.setFocusedSignature(next);
  }

  getSignatureOffset = (signature, { position = 'top' } = {}) => {
    // variables to calculate offsetInPage
    const zoom = this.currentZoomLevel;
    const top = signature.zone.vertices[0].y * zoom;
    const bottom = signature.zone.vertices[3].y * zoom;

    // get offset
    const pageIndex = signature.zone.page;
    const offsetInPage = {
      top,
      center: top + (bottom - top) / 2,
      bottom,
    }[position];

    return this.getPageOffset(pageIndex) + offsetInPage;
  };

  // Copied from pdf-annotation-store.js
  get currentZoomLevel() {
    return this.pspdfkitInstance.currentZoomLevel;
  }

  getPageOffset = (pageIndex) => {
    const zoom = this.currentZoomLevel;
    const PAGE_GUTTER = 20 * zoom; // Value got experimentally, not guaranteed to work every time
    let offset = PAGE_GUTTER;
    range(pageIndex).forEach((idx) => {
      offset +=
        this.pspdfkitInstance.pageInfoForIndex(idx).height * zoom + PAGE_GUTTER;
    });
    return offset;
  };

  getOffset(offset) {
    if (!offset) {
      return 0;
    } if (
      typeof offset === 'string' &&
      offset.endsWith('%') &&
      !Number.isNaN(window.parseFloat(offset.slice(0, -1)))
    ) {
      const { psPdfKitScroll } = this;
      return (
        (psPdfKitScroll.clientHeight *
          window.parseFloat(offset.slice(0, -1), 0)) /
        100.0
      );
    }
    const res = window.parseFloat(offset);
    return !Number.isNaN(res) ? res : 0;
  }

  get psPdfKitScroll() {
    // Used to calculate Y offset
    return this.pspdfkitInstance.contentDocument.querySelector(
      '.PSPDFKit-Scroll'
    );
  }

  get psPdfKitZoom() {
    // The Zoom element's width is the visible annotation area
    // Used to calculate X offset
    return this.pspdfkitInstance.contentDocument.querySelector(
      '.PSPDFKit-Zoom'
    );
  }

  scrollTo = (
    y,
    { smooth = true, placement = 'top', minScrollLength = 0, callback } = {}
  ) => {
    const { psPdfKitScroll } = this;
    const extraOffset = {
      top: 0,
      center: -(psPdfKitScroll.clientHeight / 2),
      bottom: -psPdfKitScroll.clientHeight,
    }[placement];
    const top = Math.max(0, y + extraOffset);
    if (
      Math.abs(top - psPdfKitScroll.scrollTop) >=
      Math.max(minScrollLength || 0, 0.1)
    ) {
      if (callback) {
        const cb = () => {
          callback();
          psPdfKitScroll.removeEventListener('scroll', cb);
        };
        psPdfKitScroll.addEventListener('scroll', cb);
      }
      psPdfKitScroll.scrollTo({
        top,
        behavior: smooth ? 'smooth' : undefined,
      });
    } else if (callback) {
      // If scroll is avoided because of minScrollLength, callback still has to be called (immediately)
      callback();
    }
  };
}
