const PACKAGE_EMPTY_EMAIL_TEMPLATE = '<br/>--';
const PACKAGE_EMPTY_EMAIL_TEMPLATE_NO_USER = '';

type DefaultEmailBodyOptions = {
  noUser?: boolean;
};

type Templates = {
  default: string;
  REJECT_OFFER?: string;
};

const TEMPLATES: Templates = {
  default: PACKAGE_EMPTY_EMAIL_TEMPLATE,
};

const NO_USER_TEMPLATES: Templates = {
  default: PACKAGE_EMPTY_EMAIL_TEMPLATE_NO_USER,
};

export function getDefaultEmailBody(
  kind: keyof Templates = 'default',
  options?: DefaultEmailBodyOptions
): string {
  const { noUser } = options ?? {};

  const bodies = noUser ? NO_USER_TEMPLATES : TEMPLATES;

  return bodies[kind] ?? bodies.default;
}
