import get from 'lodash/get';
import intersection from 'lodash/intersection';

const CHORE_DISPLAY_STATUSES = ['NO_ACTION', 'ACTIVE'];

export default function filterChores({
  chores,
  itemId,
  kinds,
  priorities,
  exceptKinds,
  roles,
  noHidden,
}) {
  return chores
    .filter(
      (chore) => !itemId || !chore.itemIds || chore.itemIds.includes(itemId)
    )
    .filter((chore) => CHORE_DISPLAY_STATUSES.includes(chore.status))
    .filter((chore) => !priorities || priorities.includes(chore.priority))
    .filter((chore) => !kinds || kinds.includes(chore.choreKind))
    .filter((chore) => !exceptKinds || !exceptKinds.includes(chore.choreKind))
    .filter(
      (chore) =>
        !chore.allowedRoles ||
        intersection(
          chore.allowedRoles,
          get(chore, 'transactionParties.me.roles')
        ).length
    )
    .filter(
      (chore) =>
        !roles ||
        !chore.allowedRoles ||
        intersection(chore.allowedRoles, roles).length
    )
    .filter((chore) => !noHidden || !chore.isHidden);
}
