import BaseTermDelegate from './base';
import { computed, makeObservable, override } from 'mobx';
import keyBy from 'lodash/keyBy';

export default class MultipleSelectTermDelegate extends BaseTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  @computed
  get placeholder() {
    return this.term.kindData.placeholder || 'Select';
  }

  @computed
  get choices() {
    return this.term.kindData.choices || [];
  }

  @computed
  get choicesByFieldKey() {
    return keyBy(this.term.kindData.choices, 'fieldKey');
  }

  @computed
  get firstChoice() {
    return this.choices[0];
  }

  @computed
  get checkboxes() {
    return Boolean(this.term.kindData.checkboxes);
  }

  @computed
  get sideBySide() {
    return Boolean(this.term.kindData.sideBySide);
  }

  @computed
  get select() {
    return Boolean(this.term.kindData.select) || !this.checkboxes;
  }

  @computed
  get allowClear() {
    return Boolean(this.term.kindData.allowClear);
  }

  @override
  get mainFieldKey() {
    return this.firstChoice ? this.firstChoice.fieldKey : undefined;
  }

  @override
  get boundOutputs() {
    return this.choices.map((c) => this.getBoundOutput(c.fieldKey));
  }

  hasPermission() {
    return this.boundOutputs.some((bo) => this.hasPermissionOnOutput(bo));
  }

  isNa() {
    return this.boundOutputs.every((bo) => this.isOutputNa(bo));
  }

  getSelectedValues(v) {
    const value = (v === undefined ? this.term.value : v) || {};
    return this.choices
      .map(({ fieldKey }) => fieldKey)
      .filter((fieldKey) => value[fieldKey]);
  }

  @computed
  get selectedValues() {
    return this.getSelectedValues();
  }

  getEvalValue(value) {
    return this.getSelectedValues(value);
  }

  getChoices(choiceValues_) {
    const choiceValues = new Set(choiceValues_ || []);
    return this.choices.filter((c) => choiceValues.has(c.fieldKey));
  }

  @computed
  get selectedChoices() {
    return this.getChoices(this.selectedValues);
  }

  getLabels(choiceValues) {
    return this.getChoices(choiceValues).map((c) => c.label) || [];
  }

  @computed
  get selectedLabels() {
    return this.getLabels(this.selectedValues);
  }

  choiceHasExtra(choice) {
    return Boolean(this.choicesByFieldKey[choice?.fieldKey]?.extra?.available);
  }

  getChoiceExtraKey(choice) {
    if (this.choiceHasExtra(choice)) {
      return `${choice.fieldKey}_extra`;
    }

    return null;
  }

  getChoiceExtraBoundOutput(choice) {
    const extraKey = this.getChoiceExtraKey(choice);
    return extraKey ? this.getBoundOutput(extraKey) : null;
  }

  getExtraPlaceholder(choice) {
    return this.choiceHasExtra(choice)
      ? this.choicesByFieldKey[choice?.fieldKey]?.extra?.placeholder ||
          'Explain'
      : '';
  }

  getChoiceExtraValue(choice, value) {
    const extraKey = this.getChoiceExtraKey(choice);
    return (extraKey && (value || this.term.value)[extraKey]) || null;
  }

  renderReadOnlyValue() {
    return this.selectedChoices.map((c) =>
      [c.label, this.getChoiceExtraValue(c)].filter(Boolean).join(': ')
    );
  }

  getCurrentValue() {
    return this.choices.reduce((all, choice) => {
      const boundOutput = this.getBoundOutput(choice.fieldKey);
      if (!boundOutput) {
        return all;
      }
      const fieldValue = boundOutput.getFieldValue('this');
      const extraKey = this.getChoiceExtraKey(choice);
      const extraBoundOutput = this.getBoundOutput(extraKey);
      return {
        ...all,
        [choice.fieldKey]: fieldValue,
        ...(extraKey
          ? {
              [extraKey]:
                (fieldValue &&
                  extraBoundOutput &&
                  extraBoundOutput.getFieldValue('this')) ||
                null,
            }
          : {}),
      };
    }, {});
  }

  getValuesByFormFieldId(value_) {
    const value = value_ || {};
    return this.choices.reduce((allValues, choice) => {
      const fieldValue = Boolean(value[choice.fieldKey]);
      const extraKey = this.getChoiceExtraKey(choice);
      return {
        ...allValues,
        [this.term.getFieldId(choice.fieldKey)]: fieldValue,
        ...(extraKey
          ? {
              [this.term.getFieldId(extraKey)]:
                (fieldValue && value[extraKey]) || null,
            }
          : {}),
      };
    }, {});
  }
}
