

import React, { Component, Fragment, createRef } from 'react';
import { Checkbox, Input, Skeleton } from 'antd';
import classNames from 'classnames';
import intersection from 'lodash/intersection';
import isFunction from 'lodash/isFunction';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AnchorButton from 'src/components/common/anchor-button';
import AppIcon from 'src/components/common/app-icon';
import { AppRadio as Radio } from 'src/components/common/app-radio';
import { slugLower } from 'src/utils/slug';

const clsPrefix = 'app-select-filter';

@inject('ui')
@observer
export default class SelectFilter extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    className: PropTypes.string,
    title: PropTypes.any,
    defaultValue: PropTypes.any,
    options: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    multiple: PropTypes.bool,
    // search enabled if search === true or if search is a number and the number of available options is >= search
    search: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    searchFilter: PropTypes.func,
    fetchingOptions: PropTypes.bool,
    context: PropTypes.object,
    allowClear: PropTypes.bool,
    maxOptionsCollapsed: PropTypes.number,
  };

  static defaultProps = {
    search: 8,
    searchFilter: (q, opt) =>
      !q.trim() || slugLower(opt.label).includes(slugLower(q)),
    allowClear: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.multiple ? [] : null,
      isSearching: false,
      searchQuery: '',
      expanded: false,
    };
  }

  searchInput = createRef();

  componentDidMount() {
    this.removeUnknownOptionsFromValue();
  }

  componentDidUpdate() {
    this.removeUnknownOptionsFromValue();
    this.clearSearch();
  }

  get controlledValue() {
    return this.props.value !== undefined;
  }

  get value() {
    let { defaultValue } = this.props;
    if (defaultValue === undefined) {
      defaultValue = this.props.multiple ? [] : null;
    }
    if (this.controlledValue) {
      return this.props.value || defaultValue;
    }

    return this.state.value || defaultValue;
  }

  get options() {
    const { options, context } = this.props;
    if (isFunction(options)) {
      return options(context, this.value);
    }
    return options;
  }

  get visibleOptions() {
    const { searchFilter } = this.props;
    const { isSearching, searchQuery } = this.state;
    return this.options.filter(
      (o) => !isSearching || searchFilter(searchQuery, o)
    );
  }

  onChange = (value) => {
    const { onChange } = this.props;
    if (this.controlledValue && onChange) {
      onChange(value);
    }
    if (!this.controlledValue) {
      this.setState(
        {
          value,
        },
        onChange ? () => onChange(value) : undefined
      );
    }
  };

  onChangeMultipleBulk = (values, checked) => {
    const newValue = checked
      ? Array.from(new Set(this.value.concat(values)))
      : this.value.filter((v) => !values.includes(v));
    return this.onChange(newValue);
  };
  onChangeMultiple = (value, checked) =>
    this.onChangeMultipleBulk([value], checked);

  onClear = () => this.onChange(this.props.multiple ? [] : null);

  removeUnknownOptionsFromValue = () => {
    const { multiple, fetchingOptions } = this.props;
    if (fetchingOptions) {
      return;
    }
    const options = this.options.map((opt) => opt.value);

    if (
      multiple &&
      intersection(this.value, options).length < this.value.length
    ) {
      this.onChange(this.value.filter((v) => options.includes(v)));
    } else if (!multiple && this.value && !options.includes(this.value)) {
      this.onChange(null);
    }
  };

  clearSearch = () => {
    const { isSearching, searchQuery } = this.state;
    if (!this.searchEnabled && (isSearching || searchQuery)) {
      this.stopSearching();
    }
  };

  get toggleExpandedButton() {
    return (
      this.collapsable && (
        <div className={`${clsPrefix}__toggle-expanded-wrap`}>
          <AnchorButton
            className={`${clsPrefix}__toggle-expanded`}
            type="default"
            onClick={() => this.toggleExpanded()}
          >
            <AppIcon name={`chevron${this.expanded ? 'Up' : 'Down'}`} />
            {this.expanded ? 'Hide' : 'Show more'}
          </AnchorButton>
        </div>
      )
    );
  }

  renderOptions = () => {
    const { multiple, fetchingOptions } = this.props;
    const { searchQuery } = this.state;

    if (fetchingOptions) {
      return (
        <Skeleton
          title={false}
          paragraph={{
            rows: 5,
          }}
          active
        />
      );
    }

    const visibleOptions = this.visibleOptions.slice(
      0,
      (this.collapsed && this.maxOptionsCollapsed) || undefined
    );

    if (!visibleOptions.length) {
      return (
        <div className={`${clsPrefix}__no-options`}>
          {searchQuery ? 'No items found' : 'No items'}
        </div>
      );
    }

    let options;
    if (multiple) {
      options = visibleOptions.map(({ value, label }) => (
        <Checkbox
          key={value}
          className={`${clsPrefix}__option ${clsPrefix}__option--checkbox`}
          checked={this.value.includes(value)}
          onChange={(event) =>
            this.onChangeMultiple(value, event.target.checked)
          }
        >
          {label}
        </Checkbox>
      ));
    } else {
      options = (
        <Radio.Group
          className={`${clsPrefix}__radio-group`}
          value={this.value}
          onChange={(event) => this.onChange(event.target.value)}
        >
          {visibleOptions.map(({ value, label }) => (
            <Radio
              key={value}
              value={value}
              className={`${clsPrefix}__option ${clsPrefix}__option--radio`}
            >
              {label}
            </Radio>
          ))}
        </Radio.Group>
      );
    }

    return multiple
      ? options.concat(this.toggleExpandedButton)
      : [options, this.toggleExpandedButton];
  };

  renderClear = () => {
    const { multiple, allowClear } = this.props;
    if (
      !allowClear ||
      (!multiple && !this.value) ||
      (multiple && !this.value.length)
    ) {
      return null;
    }

    const label = `Clear${multiple ? ` (${this.value.length})` : ''}`;
    return (
      <AnchorButton
        className={`${clsPrefix}__clear`}
        onClick={this.onClear}
        type="primary"
      >
        {label}
      </AnchorButton>
    );
  };

  get searchEnabled() {
    return (
      Boolean(this.props.search) && this.options.length >= this.props.search
    );
  }

  toggleSearch = (isSearching_, callback) => {
    const explicitlySet = isSearching_ !== undefined;
    this.setState((prevState) => {
      const isSearching = explicitlySet
        ? Boolean(isSearching_)
        : !prevState.isSearching;
      return {
        ...prevState,
        isSearching,
        searchQuery: isSearching ? prevState.searchQuery : '',
      };
    }, callback);
  };
  startSearching = (callback) => this.toggleSearch(true, callback);
  stopSearching = (callback) => this.toggleSearch(false, callback);

  renderSearch = () => {
    const { searchQuery, isSearching } = this.state;

    if (!this.searchEnabled) {
      return null;
    }

    return (
      <Fragment>
        <Input
          ref={this.searchInput}
          className={classNames(`${clsPrefix}__search-input`, {
            [`${clsPrefix}__search-input--visible`]: isSearching,
            [`${clsPrefix}__search-input--hidden`]: !isSearching,
          })}
          placeholder="Search"
          value={searchQuery}
          onChange={(event) =>
            this.setState({
              searchQuery: event.target.value,
            })
          }
          onKeyDown={(event) => {
            if (event.key === 'Enter' && searchQuery) {
              this.toggleAllVisibleOptions();
            } else if (event.key === 'Escape') {
              this.stopSearching();
            }
          }}
        />
        <AnchorButton
          className={classNames(`${clsPrefix}__search-close-button`, {
            [`${clsPrefix}__search-close-button--visible`]: isSearching,
          })}
          onClick={() => this.stopSearching()}
          type="danger"
        >
          <AppIcon type="feather" name="x" size={14} />
        </AnchorButton>
      </Fragment>
    );
  };

  toggleAllVisibleOptions = () => {
    const { multiple } = this.props;
    const value = this.value;
    const visibleOptionsValues = this.visibleOptions.map((opt) => opt.value);

    if (multiple) {
      // For multiple selection if all visible options are selected, then deselect all.
      // Select all in any other case.
      const check = visibleOptionsValues.find((val) => !value.includes(val));
      this.onChangeMultipleBulk(visibleOptionsValues, check);
    } else if (
      visibleOptionsValues.length === 1 &&
      value !== visibleOptionsValues[0]
    ) {
      // For single selection, select only if there is one visible value and it is not
      // already selected.
      this.onChange(visibleOptionsValues[0]);
    }
  };

  get maxOptionsCollapsed() {
    // Dynamic default: max 4 in collapsed view for mobile, non collapsable for other screen sizes
    const { maxOptionsCollapsed, ui } = this.props;
    if (maxOptionsCollapsed !== undefined) {
      return maxOptionsCollapsed;
    }
    return ui.isMobileSize ? 4 : maxOptionsCollapsed;
  }

  get collapsable() {
    const { maxOptionsCollapsed, visibleOptions } = this;
    return (
      Boolean(maxOptionsCollapsed) &&
      maxOptionsCollapsed < visibleOptions.length
    );
  }

  get expanded() {
    return !this.collapsable || this.state.expanded;
  }

  get collapsed() {
    return !this.expanded;
  }

  toggleExpanded = (expanded) => {
    const explicitlySet = expanded !== undefined;
    this.setState((prevState) => {
      const newExpanded = explicitlySet
        ? Boolean(expanded)
        : !prevState.expanded;
      return {
        ...prevState,
        expanded: newExpanded,
      };
    });
  };
  expand = () => this.toggleExpanded(true);
  collapse = () => this.toggleExpanded(false);

  render() {
    const { className, title } = this.props;

    return (
      <div className={classNames(clsPrefix, className)}>
        <div className={`${clsPrefix}__title-wrap`}>
          {title}
          <div className={`${clsPrefix}__title-actions-wrap`}>
            {this.renderClear()}
            {this.searchEnabled && (
              <AnchorButton
                className={`${clsPrefix}__search-button`}
                onClick={() =>
                  this.startSearching(() => {
                    if (this.searchInput && this.searchInput.current) {
                      this.searchInput.current.focus();
                    }
                  })
                }
              >
                <AppIcon type="feather" name="search" />
              </AnchorButton>
            )}
          </div>
        </div>
        <div className={`${clsPrefix}__search`}>{this.renderSearch()}</div>
        <div className={`${clsPrefix}__options`}>{this.renderOptions()}</div>
      </div>
    );
  }
}
