import { computed, makeObservable, override } from 'mobx';
import { AnalysisAnalysisStatus } from 'src/types/proto/documents';
import Item, { ItemStore, TransactionItemJson } from './item';

export const PAGE_OCR_STATE = 3;

export type TransactionDocumentVersionJson =
  TransactionItemJson<'TRANSACTION_DOCUMENT_VERSION'>;

export default class TransactionDocumentVersion extends Item<'TRANSACTION_DOCUMENT_VERSION'> {
  constructor(store: ItemStore, json: TransactionDocumentVersionJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get tdId() {
    return this.inEdges
      .filter((e) => e.kind === 'TD_HAS_TDV')
      .map((e) => e.item1Id)[0];
  }

  @computed
  get url() {
    return this.kindItem.url || this.document.url;
  }

  @computed
  get version() {
    return this.kindItem.version;
  }

  @computed
  get documentId() {
    return this.kindItem.documentId;
  }

  @computed
  get document() {
    return this.kindItem.document!;
  }

  @computed
  get source() {
    return this.kindItem.source;
  }

  @computed
  get isFillable() {
    return !!this.formId;
  }

  @computed
  get formId() {
    return this.kindItem.formFill?.formId;
  }

  @computed
  get formOutlineFlowId() {
    return this.kindItem.formFill?.formOutlineFlowId;
  }

  @computed
  get formSeriesId() {
    return this.kindItem.formFill?.formSeriesId;
  }

  @computed
  get form() {
    return this.kindItem.formFill?.form;
  }

  @override
  get fieldValues() {
    return this.kindItem.formFill?.fieldValues ?? {};
  }

  @computed
  get unlinkedFieldIds() {
    return this.kindItem.formFill?.unlinkedFieldIds || [];
  }

  @computed
  get isAnalyzing(): boolean {
    if (this.isFillable) {
      return false;
    }
    return (
      !this.document.analysis?.stateByPart?.[PAGE_OCR_STATE] ||
      this.document.analysis?.stateByPart?.[PAGE_OCR_STATE].status ===
        AnalysisAnalysisStatus.PENDING
    );
  }

  @computed
  get analyzedTime(): number {
    return (
      this.document.analysis?.stateByPart?.[PAGE_OCR_STATE]?.finishedAt || 0
    );
  }

  @computed
  get pdfAnnotateConfigId() {
    return this.kindItem.pdfAnnotateConfigId;
  }

  @computed
  get missingFillConditionsCount() {
    return this.kindItem.missingFillConditionsCount;
  }
}
