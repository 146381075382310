
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { computed, makeObservable } from 'mobx';
import {
  cancelFileReview,
  finishFileReview,
  updateFileReview,
} from 'src/models/transactions/intents';
import { IDX_KIND } from './transaction-store';

export default class ChecklistsStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  get transactions() {
    return this.parent.transactions;
  }

  get account() {
    return this.parent.account;
  }

  get userId() {
    return get(this.account, 'user.id');
  }

  updateStatus = async ({
    transactionId,
    clis,
    fileReviewId = undefined,
    finishReview = false,
    materializeChanges = true,
  }) =>
    this.updateFileReview(transactionId, {
      fileReviewId,
      finishReview,
      materializeChanges,
      reviewedChecklistItems: clis.map(([cliId, filingStatus]) => ({
        checklistItemId: cliId,
        op: {
          kind: 'CHANGE_STATUS',
          filingStatus,
        },
      })),
    });

  fetchFileReviews = (transactionId) =>
    this.transactions.getFetchItems.getOrFetch({
      transactionId,
      kind: 'FILE_REVIEW',
    });

  @computed
  get fileReviews() {
    return sortBy(
      this.transactions.itemsById.indexed(IDX_KIND, {
        kind: 'FILE_REVIEW',
      }).all,
      (item) => -1 * item.id
    );
  }

  getTransactionFileReviews = (transactionId) =>
    this.fileReviews.filter((fr) => fr.transactionId === transactionId);

  getMyFileReviews = (transactionId) =>
    this.userId
      ? this.getTransactionFileReviews(transactionId).filter(
          (fr) => fr.userId === this.userId
        )
      : [];

  getCurrentFileReview = (transactionId) =>
    this.getMyFileReviews(transactionId).find((fr) => fr.inProgress);

  updateFileReview = async (transactionId, params) =>
    this.transactions.dispatch(transactionId, updateFileReview(params));

  reviewCLIs = ({
    transactionId,
    clis,
    fileReviewId = undefined,
    finishReview = false,
    materializeChanges = true,
  }) =>
    this.updateFileReview(transactionId, {
      fileReviewId,
      finishReview,
      materializeChanges,
      reviewedChecklistItems: clis.map(([cliId, status, message, label]) => ({
        checklistItemId: cliId,
        op: {
          kind: 'REVIEW',
          status,
          message,
          label,
        },
      })),
    });

  reviewCLI = ({
    transactionId,
    cliId,
    status,
    fileReviewId,
    message,
    label,
    finishReview,
    materializeChanges,
  }) =>
    this.reviewCLIs({
      transactionId,
      clis: [[cliId, status, message, label]],
      fileReviewId,
      message,
      finishReview,
      materializeChanges,
    });

  finishFileReview = (
    fileReview,
    setFullyApprovedReviewState = false,
    fileReviewComment = ''
  ) =>
    this.transactions.dispatch(
      fileReview.transactionId,
      finishFileReview(
        fileReview.id,
        !!setFullyApprovedReviewState,
        fileReviewComment
      )
    );

  cancelFileReview = (fileReview, revertChanges = true) =>
    this.transactions.dispatch(
      fileReview.transactionId,
      cancelFileReview(fileReview.id, !!revertChanges)
    );
}
