import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import Form from 'src/models/forms/form';
import versionComparable from 'src/utils/version-comparable';

export default class FormSeries extends Model {
  constructor(store, json) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get uuid() {
    return this.data.uuid;
  }

  @computed
  get title() {
    return this.data.title;
  }

  @computed
  get forms() {
    const forms = this.data.forms
      ? this.data.forms.map((f) => new Form(this.store, f))
      : this.data.formIds.map((formId) => {
          return this.store.formsById.get(formId);
        });
    const res = sortBy(forms, 'versionComparable');
    return res;
  }

  @computed
  get reversedForms() {
    return reverse(this.forms.slice());
  }

  @computed
  get defaultForm() {
    const activeForm = this.reversedForms.find((f) => f.active);
    if (activeForm) {
      return activeForm;
    }
    return this.reversedForms[0] || null;
  }

  @computed
  get latestForm() {
    return this.reversedForms[0] || null;
  }

  @computed
  get excludeMatching() {
    return this.data.excludeMatching;
  }

  get majorVersions() {
    return uniq(this.forms.map((f) => f.majorVersion));
  }

  getNextMajorVersionNumber() {
    const { majorVersions } = this;
    if (majorVersions.length === 0) {
      return '0.0.0';
    }
    return `${Number(majorVersions[majorVersions.length - 1]) + 1}.0.0`;
  }

  getNextMinorVersionNumber(majorVersion) {
    const forms = this.getFormsByMajorVersion(majorVersion);
    if (!forms.length) {
      return `${majorVersion}.0.0`;
    }
    const latestForm = forms[forms.length - 1];
    const nextMinor = Number(latestForm.minorVersion.split('.')[1]) + 1;
    return `${majorVersion}.${nextMinor}.0`;
  }

  getFormsByMajorVersion(majorVersion) {
    return this.forms.filter((f) => f.majorVersion === majorVersion);
  }

  get emptyMajorVersion() {
    const lastMajorVersion = this.majorVersions[this.majorVersions.length - 1];
    if (!lastMajorVersion) {
      return null;
    }
    const forms = this.getFormsByMajorVersion(lastMajorVersion);
    if (forms.every((form) => !form.active)) {
      return forms[forms.length - 1];
    }
    return null;
  }

  getEmptyMinorVersion(majorVersion) {
    const forms = this.getFormsByMajorVersion(majorVersion);
    if (!forms.length) {
      return null;
    }
    const latest = forms[forms.length - 1];
    if (latest.active) {
      return null;
    }
    return latest;
  }

  getPreviousForm(version) {
    const comparable = versionComparable(version);
    return this.reversedForms.find((f) => f.versionComparable < comparable);
  }
}
