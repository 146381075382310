import Item from './item';
import formatTimestamp from 'src/utils/format-timestamp';
import { computed, makeObservable } from 'mobx';

export default class SyncRecord extends Item {
  constructor(store, json) {
    super(store, json);

    makeObservable(this);
  }

  meta(syncKind) {
    if (syncKind === 'PUSH') {
      return this.kindItem.pushMeta;
    }
    return this.kindItem.pullMeta;
  }

  lastRequestId(syncKind) {
    const meta = this.meta(syncKind);
    return meta.lastRequestId;
  }

  lastSyncStatus(syncKind) {
    const meta = this.meta(syncKind);
    return meta.statusByRequestId[meta.lastRequestId];
  }

  lastSyncFinishTime(syncKind) {
    const meta = this.meta(syncKind);
    if (!meta.responseTimeByRequestId) {
      return;
    }
    const finishTime = meta.responseTimeByRequestId[meta.lastRequestId];
    // eslint-disable-next-line consistent-return
    return finishTime ? formatTimestamp(finishTime, 'MM/DD/YY h:mm a') : '';
  }

  lastSuccessfulSyncFinishTime(syncKind) {
    const meta = this.meta(syncKind);
    if (!meta.responseTimeByRequestId) {
      return;
    }

    const finishTime =
      meta.responseTimeByRequestId[meta.lastSuccessfulResponseId];
    // eslint-disable-next-line consistent-return
    return finishTime ? formatTimestamp(finishTime, 'MM/DD/YY h:mm a') : '';
  }

  failedCount(syncKind) {
    const meta = this.meta(syncKind);
    return meta.failedCount;
  }

  isComplete(syncKind) {
    return this.lastSyncStatus(syncKind) === 'SUCCESS';
  }

  didFail(syncKind) {
    return this.lastSyncStatus(syncKind) === 'FAIL';
  }

  @computed
  get transactionList() {
    return this.kindItem.transactionList
      ? this.kindItem.transactionList.data
      : [];
  }

  @computed
  get checklist() {
    return this.kindItem.checklist ? this.kindItem.checklist.data : [];
  }
}
