import debounce from 'lodash/debounce';
import get from 'lodash/get';
import omit from 'lodash/omit';
import sortBy from 'lodash/sortBy';
import { action, computed, makeObservable, observable } from 'mobx';
import Form from 'src/models/forms/form';
import FormFlowConfig from 'src/models/forms/form-flow-config';
import FormSeries from 'src/models/forms/form-series';
import { getFetch } from 'src/utils/get-fetch';

export default class AdminFormStore {
  @observable formsSeriesById = new Map();
  @observable formsById = new Map();
  @observable formFlowConfigsById = new Map();
  @observable formFlowConfigsByFormSeriesId = new Map();

  @computed
  get formSeries() {
    return sortBy(
      this.formsSeriesById.values(),
      (v) => v.title || (v.document && v.document.filename)
    );
  }

  constructor(parentStore) {
    makeObservable(this);
    this.parent = parentStore;
    this.api = this.parent.api.documents;
  }

  @action
  updateFormModels(datas, replace) {
    const forms = datas.map((data) => {
      const existing = this.formsById.get(data.id);
      if (existing && existing.data.fillConfig && !data.fillConfig) {
        data.fillConfig = existing.data.fillConfig;
      }
      const fs = new Form(this, data);
      if (existing && replace) {
        existing.updateFromJson(data);
      } else {
        this.formsById.set(fs.id, fs);
      }
      return fs;
    });
    return forms;
  }

  @action
  updateFormSeriesModels(datas, replaceForms) {
    datas.forEach((data) => {
      const formsData = get(data, 'forms', []);
      const formSeriesData = omit(data, ['forms']);
      formSeriesData.formIds = formsData.map((f) => f.id);
      const existing = this.formsSeriesById.get(formSeriesData.id);
      if (existing) {
        existing.updateFromJson(formSeriesData);
      } else {
        const fs = new FormSeries(this, formSeriesData);
        this.formsSeriesById.set(fs.id, fs);
      }
      this.updateFormModels(formsData, replaceForms);
    });
  }

  @action
  updateFormFlowConfigModels(datas) {
    datas.forEach((data) => {
      const fs = new FormFlowConfig(this, data);
      const existing = this.formFlowConfigsById.get(fs.id);
      if (existing) {
        existing.updateFromJson(data);
      } else {
        this.formFlowConfigsById.set(fs.id, fs);
      }
    });
    this.updateFormModels(datas.map((d) => d.form));
    this.updateFormModels(
      datas.map((d) => d.qaGenerationForm).filter((x) => x)
    );
  }

  createFormFlowConfig = async (formFlowConfigData) => {
    const { data } = await this.api.createFormFlowConfig(formFlowConfigData);
    await this.getFetchFormFlowConfig.fetch(data.id);
    return this.formFlowConfigsById.get(data.id);
  };

  saveFormFlowConfig = async (formFlowConfigId, formId) => {
    await this.api.saveFormFlowConfig(formFlowConfigId, formId);
    await this.getFetchFormFlowConfig.fetch(formFlowConfigId);
  };

  setFillConfigQaGenerationForm = async (formFlowConfigId, formId) => {
    await this.api.setFillConfigQaGenerationForm(formFlowConfigId, formId);
    await this.getFetchFormFlowConfig.fetch(formFlowConfigId);
  };

  promoteFillConfigQaGenerationForm = async (formFlowConfigId) => {
    await this.api.promoteFillConfigQaGenerationForm(formFlowConfigId);
    await this.getFetchFormFlowConfig.fetch(formFlowConfigId);
  };

  setFillConfigPubStatus = async (formFlowConfigId, newStatus) => {
    await this.api.setFillConfigPubStatus(formFlowConfigId, newStatus);
    await this.getFetchFormFlowConfig.fetch(formFlowConfigId);
  };

  getFetchFormFlowConfig = getFetch({
    bindTo: this,
    getter: (formFlowConfigId) => {
      return this.formFlowConfigsById.get(formFlowConfigId);
    },
    getMemoizeKey: (x) => x,
    fetcher: async (formFlowConfigId) => {
      const { data } = await this.api.getFormFlowConfig(formFlowConfigId);
      this.updateFormFlowConfigModels([data]);
    },
  });

  getFetchFormFlowConfigBySeriesId = getFetch({
    bindTo: this,
    getter: (formSeriesId) => {
      return this.formFlowConfigsByFormSeriesId.get(formSeriesId);
    },
    getMemoizeKey: (x) => x,
    fetcher: async (formSeriesId) => {
      const { data } = await this.api.listFormFlowConfig({
        formSeriesIds: [formSeriesId],
      });
      this.formFlowConfigsByFormSeriesId.set(formSeriesId, data);
      this.updateFormFlowConfigModels(data);
    },
  });

  getFetchAllFormFlowConfig = getFetch({
    bindTo: this,
    getter: () => {
      return [...this.formFlowConfigsById.values()];
    },
    fetcher: async () => {
      const { data } = await this.api.listFormFlowConfig();
      this.updateFormFlowConfigModels(data);
    },
  });

  getFetchFormSeries = getFetch({
    bindTo: this,
    getter: (formSeriesId) => {
      return this.formsSeriesById.get(formSeriesId);
    },
    getMemoizeKey: (x) => x,
    fetcher: async (formSeriesId) => {
      const { data } = await this.api.getFormSeries(formSeriesId);
      this.updateFormSeriesModels([data], true);
    },
  });

  searchFormSeries = getFetch({
    bindTo: this,
    getter: () => {
      return [...this.formsSeriesById.values()];
    },
    fetcher: async (query) => {
      const { data } = await this.api.listFormSeriesFast({ query });
      this.updateFormSeriesModels(data);
    },
  });

  fetchFormBySeriesVersion = async (formSeriesId, version) => {
    const { data } = await this.api.getFormBySeriesVersion(
      formSeriesId,
      version
    );
    this.updateFormModels([data], true);
    return this.formsById.get(data.id);
  };

  fetchFormBySeriesVersionWithPspdfkitDocument = async (
    formSeriesId,
    version,
    isEditing,
    loadSync
  ) => {
    const { data } = await this.api.getFormBySeriesVersionEditMode(
      formSeriesId,
      version,
      isEditing,
      loadSync
    );
    const { form: serializedForm, ...rest } = data;
    const form = this.updateFormModels([serializedForm], true)[0];
    return { form, ...rest };
  };

  reloadFormSeriesForForm = async (formId) => {
    const form = this.formsById.get(formId);
    if (form) {
      await Promise.all([
        this.getFetchFormSeries.fetch(form.seriesId),
        this.fetchFormBySeriesVersion(form.seriesId, form.version),
      ]);
    }
  };

  updateForm = async (formId, formData, pspdfkitLayer) => {
    await this.api.saveForm(formId, {
      form: formData,
      pspdfkitLayer,
    });
    await this.reloadFormSeriesForForm(formId);
    const form = this.formsById.get(formId);
    return form;
  };

  activateForm = async (
    formId,
    active = true,
    toggleDraftPublishedState = false
  ) => {
    await this.api.activateForm(formId, active, toggleDraftPublishedState);
    await this.reloadFormSeriesForForm(formId);
    const form = this.formsById.get(formId);
    return form;
  };

  deleteForm = async (formId) => {
    await this.api.deleteForm(formId);
    this.formsById.delete(formId);
  };

  createForm = async (formData) => {
    const { data } = await this.api.createForm(formData);

    await this.getFetchFormSeries.fetch(data.seriesId);
    return this.formsById.get(data.id);
  };

  cloneForm = async (id, options) => {
    const { data } = await this.api.cloneForm(id, options);
    await this.getFetchFormSeries.fetch(data.seriesId);
    return this.formsById.get(data.id);
  };

  getFormLastEdit = async (id) => {
    const { data } = await this.api.getFormLastEdit(id);
    return data;
  };

  notifyFormEdit = debounce(
    (id, data) => this.api.notifyFormEdit(id, data),
    10 * 1000
  );

  addForm = async (formId, libraryId) => {
    await this.api.addForm(formId, libraryId);
    await this.reloadFormSeriesForForm(formId);
  };

  removeForm = async (formId, libraryId) => {
    await this.api.removeForm(formId, libraryId);
    await this.reloadFormSeriesForForm(formId);
  };

  renderForm = async (formId, data, generate) => {
    const response = await this.api.renderForm(formId, {
      fieldValues: data,
      generate,
    });
    return response.data;
  };

  async setFormConfigState(formId, state) {
    const { data } = await this.api.transitionFormConfigToState(formId, state);
    await this.reloadFormSeriesForForm(formId);
    const form = this.formsById.get(formId);
    await this.getFetchFormFlowConfig.fetch(form.formFlowConfigId);
    return data;
  }
}
