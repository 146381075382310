import type { ValidItemTransactionAppType } from '.';
import type TransactionApp from '.';

export default class TransactionAppDelegate<
  K extends ValidItemTransactionAppType
> {
  constructor(public item: TransactionApp<K>) {
    this.item = item;
  }
}
