type Options = {
  locale?: string | string[];
  precision?: number;
  empty?: string;
};

export default function formatPercentage(
  value: string | number,
  options: Options = {}
) {
  const { locale = 'en-US', precision = 1, empty = '-' } = options;
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  return !Number.isNaN(parseFloat(value as string))
    ? `${formatter.format(value as number)}%`
    : empty;
}
