import BaseTermDelegate from './base';
import { computed, makeObservable } from 'mobx';

const TRUE_VALUE = '1';
const FALSE_VALUE = null;

export default class BooleanTermDelegate extends BaseTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  @computed
  get radio() {
    return Boolean(this.term.kindData.radio);
  }

  @computed
  get checkbox() {
    return !this.radio && Boolean(this.term.kindData.checkbox);
  }

  @computed
  get select() {
    return !this.radio && !this.checkbox;
  }

  @computed
  get breakLines() {
    return Boolean(this.term.kindData.breakLines);
  }

  @computed
  get yes() {
    return this.term.kindData.yes || {};
  }

  @computed
  get yesLabel() {
    return this.yes?.label || 'Yes';
  }

  @computed
  get no() {
    return this.term.kindData.no || {};
  }

  @computed
  get noLabel() {
    return this.no?.label || 'No';
  }

  @computed
  get negated() {
    return Boolean(this.term.kindData.negated);
  }

  getNegated(v) {
    return Boolean(this.negated ? !v : v);
  }

  @computed
  get falseLabel() {
    return this.negated ? this.yesLabel : this.noLabel;
  }

  @computed
  get trueLabel() {
    return this.negated ? this.noLabel : this.yesLabel;
  }

  @computed
  get trueValue() {
    return TRUE_VALUE;
  }

  @computed
  get falseValue() {
    return FALSE_VALUE;
  }

  @computed
  get yesValue() {
    return !this.negated ? this.trueValue : this.falseValue;
  }

  @computed
  get noValue() {
    return !this.negated ? this.falseValue : this.trueValue;
  }

  @computed
  get oppositeCheckbox() {
    return Boolean(this.term.kindData.oppositeCheckbox);
  }

  getExtraBoundOutputForValue(value_) {
    const value = this.getNegated((value_ || this.getCurrentValue() || {})['']);
    const fieldKey = value ? 'yes' : 'no';
    const fieldId = this.term.getFieldId(fieldKey);
    return fieldId ? this.getBoundOutput(fieldKey) : null;
  }

  getExtraLabel(value_) {
    const value = value_ || this.getCurrentValue() || {};
    const extra = this.getNegated(value['']) ? this.yes : this.no;
    return extra?.extra ? extra.extraLabel : undefined;
  }

  get extraLabel() {
    return this.getExtraLabel();
  }

  getExtraValue(value_) {
    const value = value_ || this.getCurrentValue() || {};
    return this.getNegated(value['']) ? value.yes : value.no;
  }

  get extraValue() {
    return this.getExtraValue();
  }

  renderReadOnlyValue() {
    const mainValue = this.mainBoundOutput.readOnlyValue
      ? this.trueLabel
      : this.falseLabel;

    const extraValue = this.extraValue;
    const formattedExtraValue = extraValue
      ? [this.extraLabel, extraValue].filter(Boolean).join(': ')
      : '';

    return [mainValue, formattedExtraValue];
  }

  getValuesByFormFieldId(value_) {
    const value = value_ || {};
    const boolVal = Boolean(value['']);
    return {
      [this.term.mainFieldId]: boolVal,
      ...Object.entries({
        [this.term.getFieldId('opposite_checkbox') || '']: !boolVal,
        [this.term.getFieldId('yes') || '']: this.getNegated(boolVal)
          ? value.yes
          : null,
        [this.term.getFieldId('no') || '']: !this.getNegated(boolVal)
          ? value.no
          : null,
      })
        .filter(([k]) => k)
        .reduce(
          (all, [k, v]) => ({
            ...all,
            [k]: v,
          }),
          {}
        ),
    };
  }

  getCurrentValue() {
    const value = this.mainBoundOutput.getFieldValue('this');
    const yesBoundOutput = this.getBoundOutput('yes');
    const noBoundOutput = this.getBoundOutput('no');
    return {
      '': value,
      yes:
        this.getNegated(value) && yesBoundOutput
          ? yesBoundOutput.getFieldValue('this')
          : null,
      no:
        !this.getNegated(value) && noBoundOutput
          ? noBoundOutput.getFieldValue('this')
          : null,
    };
  }

  getEvalValue(value) {
    return Boolean((value || {})['']);
  }

  runValidations(_value) {
    return null;
  }
}
