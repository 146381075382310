import Offer from 'src/models/transactions/items/offer';
import { getFetch } from 'src/utils/get-fetch';
import { makeObservable, observable, runInAction } from 'mobx';

export default class PackagesStore {
  @observable prevOfferTermsByPackageId = new Map();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.packages;
  }

  getPackageIdMemoizeKey = ({ tpId, tpVers }) => `${tpId}:${tpVers}`;

  // Fetches the previous offer terms
  getFetchPreviousOfferTerms = getFetch({
    bindTo: this,
    getMemoizeKey: this.getPackageIdMemoizeKey,
    getter: ({ tpId, tpVers }) =>
      this.prevOfferTermsByPackageId.get(
        this.getPackageIdMemoizeKey({
          tpId,
          tpVers,
        })
      ),
    fetcher: async ({ tpId, tpVers }) => {
      const { data } = await this.api.getPreviousOfferData(tpId);

      let offerItem = null;
      if (data) {
        offerItem = new Offer(this.parent.transactions, data);
      }

      runInAction(() => {
        this.prevOfferTermsByPackageId.set(
          this.getPackageIdMemoizeKey({
            tpId,
            tpVers,
          }),
          offerItem
        );
      });

      return offerItem;
    },
  });
}
