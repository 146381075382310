
import get from 'lodash/get';
import { getColor } from 'src/utils/get-color';
import {
  ARCHIVE,
  AVID_INVITE,
  DELETE,
  DOWNLOAD_AVID,
  EDIT_AVID_FLOW,
  EDIT_REMINDERS,
  GENERATE_AVID,
  SIGN_AVID,
  VIEW_AVID,
} from '../actions/kinds';
import { viewTask } from '../actions/view-avid';
import { DEFAULT_SECTIONS } from '../sections/kinds';
import getDisclosuresTaskBackData from '../utils/get-disclosures-task-back-data';
import { DEFAULT_VITALS } from '../vitals/kinds';

export default {
  actions: (task) => {
    if (!get(task, 'task.avid.avidId')) {
      return [VIEW_AVID, DELETE];
    }
    const avid = task.members.find((m) => m.kind === 'AVID') || {
      status: 'AVID_DRAFT',
    };

    if (avid.status === 'AVID_UPLOADED') {
      return [DOWNLOAD_AVID, SIGN_AVID, ARCHIVE, DELETE];
    }
    return [
      EDIT_AVID_FLOW,
      GENERATE_AVID,
      AVID_INVITE,
      DOWNLOAD_AVID,
      SIGN_AVID,
      EDIT_REMINDERS,
      ARCHIVE,
      DELETE,
    ];
  },
  getStartLabel: () => {
    return 'Fill AVID';
  },
  getBackData: getDisclosuresTaskBackData,
  actionsVisible: {
    AVID_INVITE: 'canInviteAvidParty',
    EDIT_REMINDERS: 'canAddReminders',
    DELETE: 'canDelete',
  },
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  mainActions: [EDIT_AVID_FLOW, AVID_INVITE],
  listOnlyActions: [VIEW_AVID],
  label: 'AVID',
  canAssign: false,
  canEditStatus: false,
  onClick: viewTask,
  style: {
    color: getColor('red-10'),
    backgroundColor: '#F3FEF8',
    border: getColor('red-10'),
  },
  spotIcon: () => import('src/images/spot-icons/workflows/spot-avid.svg'),
  getStatusMeta: (task) => {
    const avid = task.members.find((m) => m.kind === 'AVID') || {
      status: 'AVID_DRAFT',
      flows: [],
      hasMulti: false,
    };

    const transaction = task.transaction;
    const party = transaction.parties.me;
    let fillParty;

    if (avid.fillRole) {
      const parties = transaction.parties.filterByRoles([avid.fillRole]);
      if (parties && parties.length) {
        fillParty = parties[0];
      }
    }

    const hasMulti = avid.hasMulti;

    const words = {
      AVID: hasMulti ? 'AVIDs' : 'AVID',
      form: hasMulti ? 'forms' : 'form',
      PDF: hasMulti ? 'PDFs' : 'PDF',
      is: hasMulti ? 'are' : 'is',
      has: hasMulti ? 'have' : 'has',
      was: hasMulti ? 'were' : 'was',
      Form: hasMulti ? 'Forms' : 'Form',
    };

    const isFiller = party && fillParty && party.id === fillParty.id;

    if (avid.status === 'AVID_UPLOADED') {
      return {
        message: `You uploaded ${words.AVID} for this transaction.`,
        actions: [SIGN_AVID, DOWNLOAD_AVID],
        primaryActions: [SIGN_AVID, DOWNLOAD_AVID],
      };
    }
    if (avid.status === 'AVID_DRAFT') {
      return {
        message: 'Start or request AVIDs for this transaction.',
        actions: [EDIT_AVID_FLOW],
        primaryActions: [EDIT_AVID_FLOW],
      };
    }
    if (avid.status === 'AVID_CREATED') {
      return {
        message: 'Not started yet',
        actions: isFiller ? [EDIT_AVID_FLOW] : [AVID_INVITE, EDIT_AVID_FLOW],
        primaryActions: isFiller
          ? [EDIT_AVID_FLOW]
          : [AVID_INVITE, EDIT_AVID_FLOW],
      };
    }
    if (avid.status === 'AVID_STARTED') {
      const fillerMessage = `You’ve started but haven’t completed the ${words.form} yet.`;
      const inviteMessage = `Agent has started filling out the ${words.form}.`;

      return {
        message: isFiller ? fillerMessage : inviteMessage,
        actions: isFiller
          ? [EDIT_AVID_FLOW, GENERATE_AVID]
          : [AVID_INVITE, EDIT_AVID_FLOW, GENERATE_AVID],
        primaryActions: isFiller ? [EDIT_AVID_FLOW] : [AVID_INVITE],
      };
    }
    if (avid.status === 'AVID_COMPLETED') {
      return {
        message: `Ready to generate ${words.PDF}.`,
        actions: isFiller
          ? [GENERATE_AVID, EDIT_AVID_FLOW]
          : [GENERATE_AVID, EDIT_AVID_FLOW, AVID_INVITE],
        primaryActions: isFiller
          ? [GENERATE_AVID, EDIT_AVID_FLOW]
          : [GENERATE_AVID],
      };
    }
    if (avid.status === 'AVID_GENERATED') {
      return {
        message: `${words.PDF} ${words.has} been generated.`,
        actions: [SIGN_AVID, DOWNLOAD_AVID, GENERATE_AVID, EDIT_AVID_FLOW],
        primaryActions: [SIGN_AVID, DOWNLOAD_AVID],
      };
    }
    return {};
  },
};
