
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { makeObservable, observable, runInAction } from 'mobx';
import getMapDefault from 'src/utils/get-map-default';

export default class TransactionsListPageStore {
  @observable queriedTxnsMap = new Map();
  @observable queriedPipelineMap = new Map();

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  initialize = () => {
    this.parent.transactions.subscribeAggregateReindexed(
      'refreshTransactions',
      debounce(this.refreshTransactions, 1000)
    );
  };

  getQMap(pipeline) {
    return pipeline ? this.queriedPipelineMap : this.queriedTxnsMap;
  }

  getQuery = (q, params) => {
    const fullParams = {
      q,
      ...omit(params, 'view'),
    };
    return JSON.stringify(fullParams);
  };

  _queriedTxnsDefault = () => {
    return {
      fetching: false,
      data: [],
      total: null,
    };
  };

  getTransactions = (q, params, pipeline = false) => {
    const query = this.getQuery(q, params);
    const qMap = this.getQMap(pipeline);
    const queriedTxns = getMapDefault(qMap, query, this._queriedTxnsDefault());
    if (queriedTxns.total !== null || queriedTxns.fetching) {
      return queriedTxns;
    }
    runInAction(() => {
      qMap.set(query, queriedTxns);
    });
    this.searchTransactions(q, params, pipeline);
    return qMap.get(query);
  };

  searchTransactions = async (q, params, pipeline = false) => {
    const query = this.getQuery(q, params);
    const qMap = this.getQMap(pipeline);
    const queriedTxns = qMap.get(query) || {};

    if (queriedTxns.fetching) {
      return;
    }

    runInAction(() => {
      qMap.set(query, {
        ...queriedTxns,
        fetching: true,
      });
    });

    const data = await this.parent.transactions.searchTransactions(
      q,
      params,
      pipeline
    );

    runInAction(() => {
      qMap.set(query, {
        data: data.data,
        total: data.total,
        fetching: false,
      });
    });
  };

  refreshTransactions = async () => {
    runInAction(() => {
      this.queriedTxnsMap.clear();
    });
  };
}
