import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import type TransactionTemplates from 'src/api/public/transaction-templates';
import type TransactionTemplate from 'src/models/transaction-templates/transaction-template';
import { load } from 'src/models/transactions/intents';
import type { CreateTemplateRequest } from 'src/types/proto/services/template_public_service';
import { getFetch } from 'src/utils/get-fetch';
import getMapDefault from 'src/utils/get-map-default';
import type { AppStore } from './app-store';

type CachedItem = {
  data: TransactionTemplate[];
  total: null | number;
  fetching: boolean;
  dirty?: boolean;
};

type QueryFn<TResponse = void> = (
  _q: string,
  _params: Record<string, any>
) => TResponse;

type AsyncQueryFn<TResponse = void> = QueryFn<Promise<TResponse>>;

type Team = {
  id: string;
  kind: string;
  permissions: string[];
};

const CACHED_QUERY_DEFAULT: CachedItem = {
  fetching: false,
  data: [],
  total: null,
};

export default class TransactionTemplateStore {
  @observable queriedTemplatesMap = new Map<string, CachedItem>();
  @observable teams = new Map<string, Team[]>();

  parent: AppStore;
  api: TransactionTemplates;

  constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.transactionTemplates;
  }

  @computed
  get userId() {
    const user = this.parent.account.user;
    return user ? user.id : '';
  }

  get transactionsStore() {
    return this.parent.transactions;
  }

  get account() {
    return this.parent.account;
  }

  initialize = () => {
    this.transactionsStore.subscribeAggregateReindexed(
      'refreshTransactions',
      debounce(this.refreshTransactions, 1000)
    );
  };

  getQuery: QueryFn<string> = (q, params) =>
    JSON.stringify({
      q,
      ...omit(params, 'view'),
    });

  _getTransactions: QueryFn<[string, CachedItem | null]> = (q, params) => {
    const query = this.getQuery(q, params);
    const cachedResults = getMapDefault(
      this.queriedTemplatesMap,
      query,
      CACHED_QUERY_DEFAULT
    );
    if (cachedResults.total !== null || cachedResults.fetching) {
      return [query, cachedResults];
    }

    return [query, null];
  };

  getTransactions: AsyncQueryFn<CachedItem | undefined> = async (q, params) => {
    const [query, cachedResults] = this._getTransactions(q, params);
    if (cachedResults && !cachedResults.dirty) {
      return cachedResults;
    }

    await this.searchTransactions(q, params);
    return this.queriedTemplatesMap.get(query);
  };

  getTransactionsData: AsyncQueryFn<TransactionTemplate[]> = async (
    q,
    params
  ) => {
    const [query, cachedResults] = this._getTransactions(q, params);
    if (cachedResults && !cachedResults.dirty) {
      return cachedResults.data;
    }
    await this.searchTransactions(q, params);
    return this.queriedTemplatesMap.get(query).data;
  };

  searchTransactions: AsyncQueryFn = async (q, _params) => {
    const params = _params || {};
    const query = this.getQuery(q, params);
    const cachedResults = getMapDefault(
      this.queriedTemplatesMap,
      query,
      CACHED_QUERY_DEFAULT
    );

    if (cachedResults.fetching) {
      return;
    }

    runInAction(() => {
      this.queriedTemplatesMap.set(query, {
        ...cachedResults,
        fetching: true,
      });
    });

    const result = await this.api.list({
      q,
      cursor: params.cursor || 0,
      limit: params.limit,
      side: params.side,
      isLease: params.isLease,
      hasOffer: params.hasOffer,
    });

    const data = {
      ...result.data,
      data: this.transactionsStore.loadRawAggregates(result.data.data),
    };

    runInAction(() => {
      this.queriedTemplatesMap.set(query, {
        data: data.data,
        total: data.total,
        fetching: false,
      });
    });
  };

  refreshTransactions = async (): Promise<void> => {
    runInAction(() => {
      this.queriedTemplatesMap.forEach((value, key) => {
        this.queriedTemplatesMap.set(key, {
          ...value,
          dirty: true,
        });
      });
    });
  };

  getFetchTemplate = getFetch({
    bindTo: this,
    getMemoizeKey: (templateId) => templateId,
    getter: (templateId) =>
      this.transactionsStore.transactionsById.get(templateId),
    fetcher: (templateId) =>
      this.transactionsStore.getFetchTransaction.fetch({
        transactionId: templateId,
      }),
  });

  loadTemplate = async (templateId: string): Promise<TransactionTemplate> => {
    await this.transactionsStore.dispatch(templateId, load({}));
    const template = await this.transactionsStore.getOrFetchTransaction(
      templateId,
      {
        includeData: true,
      }
    );
    return template;
  };

  getFetchTeams = getFetch({
    bindTo: this,
    getMemoizeKey: () => this.userId,
    getter: () => this.teams.get(this.userId),
    fetcher: async () => {
      const { data } = await this.api.teams();
      this.teams.set(this.userId, data);
      return data;
    },
  });

  getTeams: () => Team[] = () => this.getFetchTeams.get();

  teamsByType = () => {
    const allTeams = this.getTeams() || [];
    return {
      offices: allTeams.filter((t) => t.kind === 'OFFICE'),
      groups: allTeams.filter((t) => t.kind === 'GROUP'),
    };
  };

  get allTeamIds() {
    const teams = this.teamsByType();
    const allTeams = teams.offices.concat(teams.groups);
    return allTeams.map((t) => t.id);
  }

  teamsCanManage = (): {
    offices: Team[];
    groups: Team[];
  } => {
    const teams = this.teamsByType();
    return {
      offices: teams.offices.filter((t) =>
        t.permissions?.includes('MANAGE_TEAM_TEMPLATES')
      ),
      groups: teams.groups.filter((t) =>
        t.permissions?.includes('VIEW_TEAM_TEMPLATES')
      ),
    };
  };

  teamsCanView = (): {
    offices: Team[];
    groups: Team[];
  } => {
    const teams = this.teamsByType();
    return {
      offices: teams.offices.filter((t) =>
        t.permissions?.includes('VIEW_TEAM_TEMPLATES')
      ),
      groups: teams.groups.filter((t) =>
        t.permissions?.includes('VIEW_TEAM_TEMPLATES')
      ),
    };
  };

  create = async (
    data: { clone?: string } & Partial<CreateTemplateRequest>
  ) => {
    if (data.clone) {
      return this.clone(data.clone, omit(data, 'clone'));
    }

    const {
      data: { transaction: template },
    } = await this.api.create(data);
    this.transactionsStore.loadRawAggregates([template]);
    this.refreshTransactions();
    return template;
  };

  clone = async (
    cloneId: string,
    data: Partial<CreateTemplateRequest>
  ): Promise<TransactionTemplate> => {
    const {
      data: {
        transaction: { id: templateId },
      },
    } = await this.api.clone(cloneId, data);
    const templateCreationRes = await new Promise<{
      transactionId: string;
      vers: string;
    }>((resolve, reject) => {
      this.account.subscribeUserEvent(`template-created-${templateId}`, (r) =>
        resolve(r)
      );
      this.account.subscribeUserEvent(
        `template-create-error-${templateId}`,
        (err) => reject(err.error || err)
      );
    });
    this.account.unsubscribeUserEvent(`template-created-${templateId}`);
    this.account.unsubscribeUserEvent(`template-create-error-${templateId}`);
    this.refreshTransactions();
    return (
      await this.transactionsStore.fetchAndProcessDispatchResponse(
        templateCreationRes.transactionId,
        templateCreationRes.vers
      )
    ).transaction;
  };

  edit = async (templateId: string, data: Partial<CreateTemplateRequest>) => {
    const {
      data: { transaction: template },
    } = await this.api.edit(templateId, data);
    this.transactionsStore.loadRawAggregates([template]);
    this.refreshTransactions();
    return template;
  };

  delete = async (templateId: string) => {
    const {
      data: { transaction: template },
    } = await this.api.deleteTemplate(templateId);
    this.transactionsStore.loadRawAggregates([template]);
    this.refreshTransactions();
    return template;
  };
}
