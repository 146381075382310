import get from 'lodash/get';
import intersection from 'lodash/intersection';
import keyBy from 'lodash/keyBy';
import { computed, makeObservable } from 'mobx';
import type TransactionTemplateOriginStore from 'src/stores/transaction-template-store';
import formatFullTimestamp from 'src/utils/format-full-timestamp';
import Transaction, {
  type TransactionStore,
  type TransactionJson,
} from '../transactions/transaction';

export const OPS = {
  VIEW: 'VIEW',
  MANAGE: 'MANAGE',
};

const opPermissions = {
  [OPS.VIEW]: 'VIEW_TEAM_TEMPLATES',
  [OPS.MANAGE]: 'MANAGE_TEAM_TEMPLATES',
};

const VISIBILITY_VALUES = {
  PERSONAL: 'Just me',
  TEAMS: 'Offices & Groups',
  BROKERAGE: 'Everyone',
};

export type TransactionTemplateStore = TransactionTemplateOriginStore &
  Omit<TransactionStore, 'parent'>;

export default class TransactionTemplate extends Transaction<TransactionTemplateStore> {
  constructor(store: TransactionTemplateStore, json: TransactionJson) {
    super(store, json);
    makeObservable(this);
  }

  @computed
  get templateTeams() {
    return this.store.parent.transactionTemplates.getTeams() || [];
  }

  get currentTemplateTeams() {
    return this.templateTeams.filter((t) => this.teamIds.includes(t.id));
  }

  @computed
  get templateTeamsById() {
    return keyBy(this.templateTeams, 'id');
  }

  @computed
  get owned() {
    return this.creatorId === this.userId;
  }

  canTemplate(op: string) {
    if (!OPS[op as keyof typeof OPS]) {
      throw new Error(`Unknown transaction template op: "${op}".`);
    }

    return (
      this.owned ||
      this.templateTeams
        .filter((t) => this.teamIds.includes(t.id))
        .map((t) => [...t.permissions])
        .flat()
        .includes(opPermissions[op])
    );
  }

  @computed
  get canView() {
    return this.canTemplate(OPS.VIEW);
  }

  @computed
  get canManage() {
    if (!this.canTemplate(OPS.VIEW)) {
      return false;
    }
    if (this.owned) {
      return true;
    }
    const templateTeamsIds = this.currentTemplateTeams.map((team) => team.id);
    const teamsCanManage =
      this.store.parent.transactionTemplates.teamsCanManage();
    const managedTeamsIds = teamsCanManage.offices
      .concat(teamsCanManage.groups)
      .map((t) => t.id);
    return intersection(templateTeamsIds, managedTeamsIds).length > 0;
  }

  @computed
  get templateVisibility() {
    const visibility = this.meta.templateVisibility;
    if (!VISIBILITY_VALUES[visibility]) {
      return 'PERSONAL';
    }
    return visibility;
  }

  @computed
  get templateVisibilityDescription() {
    return VISIBILITY_VALUES[this.templateVisibility];
  }

  @computed
  get teamTitlesList() {
    return this.teamIds
      .map((id_) => get(this.templateTeamsById[id_], 'title'))
      .filter((teamTitle) => teamTitle)
      .sort();
  }

  @computed
  get creator() {
    return this.toJS().meta.creator;
  }

  @computed
  get creatorName() {
    const { contact } = this.creator || {};
    return `${contact?.firstName ?? ''} ${contact?.lastName ?? ''}`.trim();
  }

  @computed
  get formattedCreatedAt() {
    return formatFullTimestamp(+this.createdAt);
  }
}
