import TemplateChecklist from 'src/models/template-checklists/template-checklist';
import {
  copyChecklistTemplates,
  deleteItems,
  updateTaskMeta,
} from 'src/models/transactions/intents';
import { getFetch } from 'src/utils/get-fetch';

export default class TemplateChecklistsStore {
  constructor(parent) {
    this.parent = parent;
    this.api = this.parent.api.templateChecklists;
  }

  getFetchTemplateChecklists = getFetch({
    bindTo: this,
    getMemoizeKey: ({ tree = false, transactionId } = {}) =>
      `${!!tree}-${transactionId}`,
    fetcher: async (params) => {
      const { data } = await this.api.list(params);
      return data.map((tclData) => new TemplateChecklist(this, tclData));
    },
  });

  get transactions() {
    return this.parent.transactions;
  }

  toggleTemplatesArchivedState = async (transactionId, tasks, isArchived) => {
    const tasksJson = tasks.map((t) => {
      const tj = t.toJS();
      tj.task.isArchived = isArchived;
      return tj;
    });

    await this.transactions.dispatch(
      transactionId,
      updateTaskMeta({
        updates: tasksJson,
      })
    );
  };

  archiveTemplates = async (transactionId, tasks) => {
    await this.toggleTemplatesArchivedState(transactionId, tasks, true);
  };

  unarchiveTemplates = async (transactionId, tasks) => {
    await this.toggleTemplatesArchivedState(transactionId, tasks, false);
  };

  copyTemplates = async ({ transactionId, ids }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      copyChecklistTemplates(ids)
    );
    return result;
  };

  deleteTemplates = async ({ transactionId, ids }) => {
    const { result } = await this.transactions.dispatch(
      transactionId,
      deleteItems(ids)
    );
    return result;
  };
}
