import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskAction from './action';
import { inject, observer } from 'mobx-react';
import { showSharePacketModal } from 'src/components/transactions/packets/share-packet-share-action';

export const openSharePacketModel = async ({ store, task }) => {
  if (task.isDormant) {
    store.ui.toast({
      message:
        'Please add at least one document to share your Disclosure Package.',
      type: 'info',
    });
    return;
  }
  const { ui, transactions } = store;
  const transaction = task.transaction;
  const sharePacket = await transactions.getOrFetchItem(
    transaction.id,
    'SHARE_PACKET',
    transaction.discPacketId
  );
  showSharePacketModal({
    ui,
    sharePacket,
  });
};

@inject('store')
@observer
export default class OpenSharePacketModelAction extends Component {
  static propTypes = {
    task: PropTypes.object,
    store: PropTypes.object.isRequired,
  };

  handleClick = async () => {
    const { store, task } = this.props;
    openSharePacketModel({
      store,
      task,
    });
  };

  render() {
    const { task } = this.props;
    return (
      <TaskAction
        disabled={task.isDormant}
        {...this.props}
        onClick={this.handleClick}
      >
        {task.isNotStarted ? 'Start' : 'View'}
      </TaskAction>
    );
  }
}
