import { computed, makeObservable } from 'mobx';

export default class LayoutStore {
  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.transactionIndexById = new Map();
    this.packetsById = new Map();
  }

  @computed
  get root() {
    return this.parent;
  }
}
