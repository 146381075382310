import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import { TransactionSettings as TransactionSettingsJson } from 'src/types/proto/transaction_settings';

export default class TransactionSettings extends Model<TransactionSettingsJson> {
  constructor(json: TransactionSettingsJson) {
    super(json);

    makeObservable(this);
  }

  @computed
  get renderOptions() {
    return {
      legalNameFormat: this.data.legalNameFormat,
      joinedLegalNameFormat: this.data.joinedLegalNameFormat,
    };
  }
}
