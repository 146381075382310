import AnchorButton from 'src/components/common/anchor-button';
import ExternalDocumentsModal from 'src/components/common/uploader/external-documents-modal';
import React from 'react';
import api from 'src/api';
import chaining from 'src/utils/chaining';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import uuid from 'uuid/v4';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { createLinkZfFlow } from 'src/models/transactions/intents';
import { getCookieValue, setCookie } from 'src/utils/cookies';
import { observer } from 'mobx-react';

const UPLOAD_TYPE = 'zipForm';

export default class ZipformUploadStore {
  @observable documents = [];
  @observable loading = false;

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  @action
  initialize() {}

  getBreadCrumb = (transaction, onClose) => {
    return (
      <div key="link-zf">
        <span key="link-zf-txn-title"> {transaction.zfTxnName} </span>
        <AnchorButton
          key="link-zf-edit"
          type="primary"
          className="margin-left-sm"
          onClick={() => {
            onClose();
            this.linkZfTxn(transaction);
          }}
        >
          edit
        </AnchorButton>
      </div>
    );
  };

  didLinkTransaction = async (transaction, folderId, taskId) => {
    const { transactions } = this.parent;
    const val = getCookieValue('uploadKey');
    const [txnId, accountType] = val.split('-');

    if (accountType === UPLOAD_TYPE && txnId && txnId === transaction.id) {
      setCookie('uploadKey', '', 0);
      const txn = await transactions.getOrFetchTransaction(transaction.id, {
        force: true,
      });
      const isZfLinked = txn.isZfLinked;
      if (isZfLinked) {
        this.onStartUpload(txn, folderId, taskId, true);
      }
    }
  };

  linkZfTxn = async (transaction) => {
    const { transactions, router } = this.parent;

    setCookie('uploadKey', `${transaction.id}-${UPLOAD_TYPE}`, 90);
    const resp = await transactions.dispatch(
      transaction.id,
      createLinkZfFlow({
        back: {
          name: router.route.name,
          params: {
            ...router.route.params,
          },
        },
      })
    );
    navigateToFlow(router, resp.result.flow_id);
  };

  onStartUpload = async (
    transaction,
    folderId,
    taskId,
    didLinkTransaction = false
  ) => {
    const { ui, uploads } = this.parent;
    const txnId = transaction.id;

    if (!didLinkTransaction && !transaction.isZfLinked) {
      await this.linkZfTxn(transaction);
      return;
    }

    const importId = uuid();

    const onSuccess = async ({ documents }) => {
      await api.integrations.generateZfDocs(txnId, {
        channel: uploads.channelKey,
        folderId,
        taskId,
        documents,
        importId,
      });
    };

    runInAction(() => {
      this.loading = true;
      this.documents = [];
    });

    const WrappedExternalDocumentsModal = observer(({ onClose }) => (
      <ExternalDocumentsModal
        title={
          <strong>
            zipForm<sup>&reg;</sup> Plus Documents
          </strong>
        }
        breadcrumb={this.getBreadCrumb(transaction, onClose)}
        searchPlaceHolder="search in transaction"
        documents={this.documents}
        loading={this.loading}
        emptyMsg={`${transaction.zfTxnName} has no documents in zipForm.`}
        onSuccess={chaining(onSuccess, onClose)}
        onCancel={onClose}
        visible
        transaction={transaction}
        showAllForms
        importId={importId}
        defaultExpandAll={false}
      />
    ));

    ui.setCustomModal(({ onClose }) => (
      <WrappedExternalDocumentsModal onClose={onClose} />
    ));

    try {
      const { data: result } = await api.integrations.zfDocs(txnId);

      if (result.connectionFailure) {
        if (!didLinkTransaction) {
          await this.linkZfTxn(transaction);
          ui.setCustomModal(null);
        }
        return;
      }
      runInAction(() => {
        this.loading = false;
        this.documents.replace(result.documents);
      });
    } catch (e) {
      ui.wentWrong(e);
      runInAction(() => {
        this.documents = [];
        this.loading = false;
      });
    }
  };
}
