import { DEFAULT_SECTIONS } from '../sections/kinds';
import { DEFAULT_VITALS } from '../vitals/kinds';

export default {
  actions: [],
  sections: [...DEFAULT_SECTIONS],
  vitals: [...DEFAULT_VITALS],
  canEditTitle: true,
  getStartLabel: () => 'Start',
};
