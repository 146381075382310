import {
  ItemSignatureRequestStatus,
  ItemEdgeKind,
} from 'src/types/proto/transactions';
import Item, { TransactionItemJson } from './item';

export const RECIPIENT_ROLE_SIGN = 'SIGN';
export const RECIPIENT_ROLE_CC = 'CC';

export type SignatureRequestAction =
  | 'view'
  | 'view-in-ds'
  | 'refresh'
  | 'resend'
  | 'continue'
  | 'revise'
  | 'continue-revising'
  | 'cancel-revision'
  | 'duplicate'
  | 'void'
  | 'delete';

export interface SignatureRequestStatusConfig {
  label: string;
  clsSuffix: string;
  iconName: string;
  actions: SignatureRequestAction[];
}

export const REQUEST_STATUSES: Partial<
  Record<ItemSignatureRequestStatus, SignatureRequestStatusConfig>
> = {
  SENT: {
    // Envelope has been sent to recipients
    label: 'Waiting for Others',
    clsSuffix: 'waiting',
    iconName: 'clock',
    actions: [
      'view',
      'view-in-ds',
      'refresh',
      'resend',
      'revise',
      'duplicate',
      'void',
    ],
  },
  IN_PROGRESS: {
    // Envelope has been sent to recipients
    label: 'Waiting for Others',
    clsSuffix: 'waiting',
    iconName: 'clock',
    actions: [
      'view',
      'view-in-ds',
      'refresh',
      'resend',
      'revise',
      'duplicate',
      'void',
    ],
  },
  REVISING: {
    label: 'Revising',
    clsSuffix: 'revising',
    iconName: 'alertTriangle',
    actions: [
      'view',
      'view-in-ds',
      'refresh',
      'duplicate',
      'continue-revising',
      'cancel-revision',
    ],
  },
  CORRECT: {
    label: 'Correcting',
    clsSuffix: 'revising',
    iconName: 'alertTriangle',
    actions: [
      'view',
      'view-in-ds',
      'refresh',
      'resend',
      'revise',
      'duplicate',
      'void',
    ],
  },
  DELIVERED: {
    // Envelope has been opened/interacted with but not completed
    label: 'Waiting for Others',
    clsSuffix: 'waiting',
    iconName: 'clock',
    actions: ['view', 'view-in-ds', 'refresh', 'resend', 'duplicate', 'void'],
  },
  DECLINED: {
    // Envelope has been decliend by recipients
    label: 'Declined',
    clsSuffix: 'void',
    iconName: 'alertTriangle',
    actions: ['view', 'view-in-ds', 'duplicate', 'delete'],
  },
  COMPLETED: {
    // Envelope has been fully signed
    label: 'Complete',
    clsSuffix: 'completed',
    iconName: 'check',
    actions: ['view', 'view-in-ds', 'duplicate', 'delete'],
  },
  VOIDED: {
    // User cancelled signature request
    label: 'Void',
    clsSuffix: 'void',
    iconName: 'alertTriangle',
    actions: ['view', 'view-in-ds', 'duplicate', 'delete'],
  },
  PREPARING: {
    // User cancelled signature request
    label: 'Preparing',
    clsSuffix: 'preparing',
    iconName: 'alertTriangle',
    actions: ['continue', 'delete'],
  },
  DRAFT: {
    label: 'Draft',
    clsSuffix: 'draft',
    iconName: 'alertTriangle',
    actions: ['view', 'view-in-ds', 'delete'],
  },
};

export const IN_PROGRESS_REQUEST_STATUSES: ItemSignatureRequestStatus[] = [
  'SENT',
  'DELIVERED',
  'IN_PROGRESS',
];

export type SignatureRequestJson = TransactionItemJson<'SIGNATURE_REQUEST'>;

export default class SignatureRequest extends Item<'SIGNATURE_REQUEST'> {
  get status() {
    return this.kindItem.status;
  }

  get envelopeId() {
    return this.kindItem.envelopeId;
  }

  get envelopeIntId() {
    return this.kindItem.envelopeIntId;
  }

  get provider() {
    return this.kindItem.provider;
  }

  get revisionFlowId() {
    return this.kindItem.revisionFlowId;
  }

  get dsSub() {
    return this.kindItem.dsSub;
  }

  get archived() {
    return this.kindItem.archived;
  }

  get dsDocs() {
    return this.kindItem.dsDocs;
  }

  get dsRecipients() {
    return this.kindItem.dsRecipients;
  }

  get sentAt() {
    return this.kindItem.wasPreparingStatus
      ? this.kindItem.sentAt
      : this.createdAt;
  }

  get isCompleted() {
    return this.kindItem.status === ItemSignatureRequestStatus.COMPLETED;
  }

  get isRevising() {
    return this.kindItem.status === ItemSignatureRequestStatus.REVISING;
  }

  get tdIds() {
    return this.inEdges
      .filter(({ kind }) => kind === ItemEdgeKind.TD_HAS_SIGNATURE_REQUEST)
      .map(({ item1Id }) => item1Id);
  }

  get tdvIds() {
    return this.inEdges
      .filter(({ kind }) => kind === ItemEdgeKind.TDV_HAS_SIGNATURE_REQUEST)
      .map(({ item1Id }) => item1Id);
  }

  get generatedTdIds() {
    if (!this.isCompleted) {
      return [];
    }

    return this.outEdges
      .filter(
        ({ kind }) => kind === ItemEdgeKind.SIGNATURE_REQUEST_GENERATED_TD
      )
      .map(({ item2Id }) => item2Id);
  }

  get generatedTdvIds() {
    if (!this.isCompleted) {
      return [];
    }

    return this.outEdges
      .filter(
        ({ kind }) => kind === ItemEdgeKind.SIGNATURE_REQUEST_GENERATED_TDV
      )
      .map(({ item2Id }) => item2Id);
  }

  can(op: string) {
    if (op === 'archive') {
      return !this.archived;
    }
    if (op === 'unarchive') {
      return !!this.archived;
    }
    return super.can(op);
  }
}
