import BaseTermDelegate from './base';
import { computed, makeObservable } from 'mobx';

export default class StringFormatTermDelegate extends BaseTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  @computed
  get placeholder() {
    return this.term.kindData.placeholder;
  }

  @computed
  get multiline() {
    return this.isString &&
      (this.term.kindData.multiline?.min || this.term.kindData.multiline?.max)
      ? this.term.kindData.multiline
      : undefined;
  }

  @computed
  get disableDropdown() {
    return (
      (this.isDropdown && Boolean(this.term.kindData.disableDropdown)) ||
      undefined
    );
  }

  @computed
  get kind() {
    return this.mainBoundOutput.topLinkedOutput.getField('this')?.kind;
  }

  @computed
  get isString() {
    return new Set(['string', 'text']).has(this.kind);
  }

  @computed
  get isDate() {
    return this.kind === 'date';
  }

  @computed
  get isContact() {
    return this.kind === 'contact';
  }

  @computed
  get isDropdown() {
    return this.term.mainAnnotation?.customData.type === 'dropdown';
  }
}
