import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskAction from './action';
import { inject, observer } from 'mobx-react';

export const viewDocusign = (item) => {
  let signatureRequest;
  if (item.kind === 'SIGNATURE_REQUEST') {
    signatureRequest = item;
  } else {
    const { task } = item;
    signatureRequest = task.members.find((i) => i.kind === 'SIGNATURE_REQUEST');
  }
  const sessionToken = window.Glide?.authBearer ?? '';
  const viewType = signatureRequest.status === 'DRAFT' ? 'sender' : 'console';
  const next = `/static/docusign_close_popup.html?transactionId=${signatureRequest.transactionId}`;
  window.open(
    '/integrations/docusign/envelopes/' +
      `${signatureRequest.envelopeId}/view/?sub=${
        signatureRequest.dsSub
      }&next=${encodeURIComponent(
        next
      )}&type=${viewType}&embeddedSessionToken=${sessionToken}`
  );
};

@inject('features')
@observer
export default class ViewDocusign extends Component {
  static propTypes = {
    features: PropTypes.object.isRequired,
    task: PropTypes.object,
    transactions: PropTypes.object.isRequired,
  };

  handleClick = () => {
    const { task } = this.props;
    return viewDocusign({
      task,
    });
  };

  render() {
    const { features } = this.props;
    if (features.variation('kill_ds_extras')) {
      return null;
    }
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        View in DocuSign
      </TaskAction>
    );
  }
}
