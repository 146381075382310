import Model from 'src/models/base';
import { computed, makeObservable } from 'mobx';

export default class FormFlowConfig extends Model {
  constructor(store, json) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get title() {
    return this.data.label;
  }

  @computed
  get pubStatus() {
    return this.data.pubStatus;
  }

  @computed
  get formId() {
    return this.data.formId;
  }

  @computed
  get form() {
    return this.store.formsById.get(this.data.formId);
  }

  @computed
  get qaGenerationForm() {
    return this.store.formsById.get(this.data.qaGenerationFormId);
  }
  @computed
  get tags() {
    return this.data.tags;
  }
  @computed
  get handlerUrl() {
    return this.data.handlerUrl;
  }
  @computed
  get params() {
    return this.data.params;
  }
  @computed
  get updatedAt() {
    return this.data.updatedAt;
  }
}
