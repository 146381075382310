

import React, { Component } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import classNames from 'classnames';
import get from 'lodash/get';
import pull from 'lodash/pull';
import uniqBy from 'lodash/uniqBy';
import { toJS } from 'mobx';
import { PropTypes as MobxPropTypes, inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AnchorButton from 'src/components/common/anchor-button';
import AppButton from 'src/components/common/app-button';
import AppFiltersPanel from 'src/components/common/app-filters-panel';
import AppModal from 'src/components/common/app-modal';
import ExternalDocumentsList, {
  filterSelectedDocuments,
} from 'src/components/common/uploader/external-documents-list';
import ZipFormAllForms from 'src/components/common/uploader/zipform-all-form';

const clsPrefix = 'app-external-documents-modal';

const filterSideWidth = 340;

@inject('features', 'ui', 'uploads')
@observer
export default class ExternalDocumentsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDocuments: props.initialValue ?? [],
      documents: props.documents,
      isLoading: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      documents: nextProps.documents,
    });
  }

  getSelectedDocuments = () => {
    const { selectedDocuments, documents } = this.state;
    return filterSelectedDocuments({
      documents,
      selectedDocuments,
    });
  };

  onChoose = async () => {
    const { onSuccess, uploads, transaction, importId, batchUpload } =
      this.props;
    const selectedDocuments = uniqBy(this.getSelectedDocuments(), 'id');

    if (!batchUpload) {
      this.setState({
        isLoading: true,
      });
    }
    try {
      await onSuccess({
        documents: selectedDocuments,
      });
    } catch (err) {
      // err
    } finally {
      if (!batchUpload) {
        this.setState({
          isLoading: false,
        });
      }
    }

    if (batchUpload) {
      selectedDocuments.forEach((d) => {
        uploads.channel.bind(`${importId}${d.id}`, (res) => {
          if (res.status === 'complete') {
            uploads.documentCreated(d.name, {
              transId: transaction.id,
              version: res.version,
            });
          } else {
            uploads.uploadFailed(d.name, get(res, 'errorcode'));
          }
        });
        uploads.uploadStarted(d.name);
      });
    }
  };

  deselectAllDocuments = () => {
    this.setState({
      selectedDocuments: [],
    });
  };

  deselectDocuments = (deselected) => {
    const { selectedDocuments } = this.state;
    this.setState({
      selectedDocuments: pull(selectedDocuments, deselected),
    });
  };

  renderFooter = () => {
    const { ui, allowNoneSelected, confirmCopy } = this.props;
    const { isLoading } = this.state;
    const selectedDocuments = this.getSelectedDocuments();
    const count = selectedDocuments.length;

    return (
      <div className={`${clsPrefix}__actions`}>
        <div className={`${clsPrefix}__file-actions`}>
          {count ? (
            <Popover
              placement="topLeft"
              content={
                <div className={`${clsPrefix}__selected-popover`}>
                  <div className={`${clsPrefix}__selected-list`}>
                    {selectedDocuments.map((d) => {
                      return (
                        <div
                          key={d.id}
                          className={`${clsPrefix}__selected-item`}
                        >
                          <div className={`${clsPrefix}__selected-title`}>
                            {d.icon}
                            {d.name}
                          </div>
                          <div className={`${clsPrefix}__selected-action`}>
                            <AnchorButton
                              onClick={() => this.deselectDocuments(d.id)}
                            >
                              <CloseOutlined className="margin-right-sm" />
                            </AnchorButton>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={`${clsPrefix}__deselect`}>
                    <AnchorButton
                      onClick={this.deselectAllDocuments}
                      disabled={isLoading}
                      type="primary"
                    >
                      Unselect all
                    </AnchorButton>
                  </div>
                </div>
              }
              trigger="click"
            >
              <AnchorButton
                onClick={this.onSelectedList}
                type="primary"
                className="margin-right"
                disabled={isLoading}
              >
                <CheckOutlined className="margin-right-sm" />
                {count} selected
              </AnchorButton>
            </Popover>
          ) : (
            <AnchorButton
              className={`${clsPrefix}__deselect`}
              disabled={isLoading}
            >
              <CheckOutlined className="margin-right-sm" />
              {count} selected
            </AnchorButton>
          )}
        </div>
        <div className={`${clsPrefix}__modal-actions`}>
          {!ui.isMobileSize && (
            <AppButton onClick={this.props.onCancel} disabled={isLoading}>
              Cancel
            </AppButton>
          )}
          <AppButton
            type="primary"
            onClick={this.onChoose}
            loading={isLoading}
            disabled={(!allowNoneSelected && !count) || isLoading}
          >
            {confirmCopy ? confirmCopy(count) : `Add Documents`}
          </AppButton>
        </div>
      </div>
    );
  };

  onDocumentChange = (selectedDocuments) => {
    this.setState({
      selectedDocuments,
    });
  };

  renderAddOtherForm = () => {
    if (!this.props.showAllForms) {
      return null;
    }
    const { transaction } = this.props;
    return (
      <ZipFormAllForms txnId={transaction.id} onFormAdd={this.onFormAdd} />
    );
  };

  onFormAdd = (form) => {
    const documents = toJS(this.state.documents);
    let folder = documents.find((a) => a.id === 'Other Forms');
    const otherDocuments = documents.filter((b) => b.id !== 'Other Forms');
    if (!folder) {
      folder = {
        contentType: 'FOLDER',
        id: 'Other Forms',
        meta: {},
        name: 'Other Forms',
        signed: 0,
        version: '',
        children: [],
      };
    }
    folder.children.push(form);
    this.setState((prevState) => ({
      documents: otherDocuments.concat(folder),
      selectedDocuments: prevState.selectedDocuments.concat(form.id),
    }));
  };

  get externalDocsModal() {
    const {
      ui,
      breadcrumb,
      searchPlaceHolder,
      loading,
      getFolderProps,
      getDocumentProps,
      defaultExpandAll,
      applyFilter,
      filterProps,
      emptyMsg,
      loadForms,
      hideAssociations,
    } = this.props;

    const isFilterSideVisible = !!filterProps && !ui.isSmallerThanLgSize;

    return (
      <React.Fragment>
        {isFilterSideVisible && (
          <div
            className={`${clsPrefix}__filter-side`}
            style={{
              width: filterSideWidth,
            }}
          >
            <AppFiltersPanel
              {...filterProps}
              mode="inline"
              hideAssociations={hideAssociations}
            />
          </div>
        )}
        <ExternalDocumentsList
          documents={this.state?.documents || []}
          selectedDocuments={this.state?.selectedDocuments || []}
          disabledDocuments={this.state?.disabledDocuments || []}
          onChange={this.onDocumentChange}
          breadcrumb={breadcrumb}
          searchPlaceHolder={searchPlaceHolder}
          loading={loading}
          addOtherForms={this.renderAddOtherForm()}
          getFolderProps={getFolderProps}
          getDocumentProps={getDocumentProps}
          defaultExpandAll={defaultExpandAll}
          className={`${clsPrefix}__list`}
          applyFilter={applyFilter}
          filterProps={isFilterSideVisible ? null : filterProps}
          emptyMsg={emptyMsg}
          loadForms={loadForms}
          showAssociationsModal={this.showAssociationsModal}
          hideAssociations={hideAssociations}
        />
      </React.Fragment>
    );
  }

  showAssociationsModal = (visible = true) => {
    this.props.ui?.setAssociationSearchModalProps({ visible });
  };

  handleModalCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { ui, title, visible, filterProps, ...modalProps } = this.props;

    const isFilterSideVisible =
      !!filterProps && !ui.isSmallerThanLgSize && !ui.isEmbedded;
    const largeHeader = typeof this.state.modalTitle !== 'string';

    return (
      <>
        <AppModal
          {...modalProps}
          className={classNames(clsPrefix, {
            [`${clsPrefix}--large-header`]: largeHeader,
          })}
          key={`extern-document-${visible}`}
          visible={visible}
          title={title}
          cancelable
          width={isFilterSideVisible ? 640 + filterSideWidth : 640}
          footer={this.renderFooter()}
          maskClosable={false}
          onCancel={this.handleModalCancel}
        >
          {this.externalDocsModal}
        </AppModal>
      </>
    );
  }
}

const propTypes = {
  ui: PropTypes.object.isRequired,
  documents: PropTypes.any.isRequired,
  title: PropTypes.any.isRequired,
  breadcrumb: PropTypes.any,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  searchPlaceHolder: PropTypes.string,
  emptyMsg: PropTypes.any,
  visible: PropTypes.bool,
  loading: PropTypes.bool,
  uploads: PropTypes.object,
  transaction: PropTypes.object,
  showAllForms: PropTypes.bool,
  importId: PropTypes.string,
  batchUpload: PropTypes.bool,
  getFolderProps: PropTypes.func,
  getDocumentProps: PropTypes.func,
  defaultExpandAll: PropTypes.bool,
  applyFilter: PropTypes.func,
  filterProps: PropTypes.object,
  initialValue: MobxPropTypes.arrayOrObservableArray,
  allowNoneSelected: PropTypes.bool,
  confirmCopy: PropTypes.func,
  loadForms: PropTypes.func,
};

const defaultProps = {
  importId: '',
  batchUpload: true,
  getFolderProps: () => {},
  getDocumentProps: () => {},
  allowNoneSelected: false,
};

ExternalDocumentsModal.propTypes = propTypes;
ExternalDocumentsModal.defaultProps = defaultProps;
