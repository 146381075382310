import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import remove from 'lodash/remove';
import { makeObservable, observable, runInAction } from 'mobx';
import api from 'src/api';
import logger from 'src/logger';
import getLocalStorage from 'src/utils/get-local-storage';
import querySerialize from 'src/utils/query-serialize';
import { setToken } from 'src/utils/token';

const GOOGLE_CLIENT_ID = window.Glide.CONSTANTS.GOOGLE_CLIENT_ID;

const GRANT_PERMISSIONS_ERRORS = {
  CANNOT_REGISTER: 'No account found for this email.',
};

const GRANT_PERMISSIONS_DEFAULT_ERROR =
  'Please grant all permissions and try again.';

const GOOGLE_BASIC_SCOPES = ['email', 'profile'];

export const GOOGLE_CONTACTS_SCOPE =
  'https://www.googleapis.com/auth/contacts.readonly';

export const GOOGLE_CALENDAR_SCOPE =
  'https://www.googleapis.com/auth/calendar.events';

export const GOOGLE_GMAIL_SENDEMAIL_SCOPE =
  'https://www.googleapis.com/auth/gmail.send';

const OUTLOOK_CLIENT_ID = window.Glide.CONSTANTS.OUTLOOK_CLIENT_ID;

const OUTLOOK_BASIC_SCOPES = ['openid', 'profile', 'offline_access'];

export const OUTLOOK_SENDEMAIL_SCOPE = 'https://graph.microsoft.com/Mail.Send';

export const OUTLOOK_READUSER_SCOPE = 'https://graph.microsoft.com/User.Read';

export const APPS = {
  GOOGLE: 'google',
  DOCUSIGN: 'docusign',
  QUICKBOOKS: 'quickbooks',
  OUTLOOK: 'outlook',
};

function oauthHasScopes(oauth, scopes) {
  if (!oauth.scopes) {
    return false;
  }

  const oauthScopes = oauth.scopes.split(' ');
  return intersection(scopes, oauthScopes).length === scopes.length;
}

class Docusign {
  constructor({ store }) {
    this.api = store.api.integrations;
    this.documentsApi = store.api.documents;
  }

  getFormSuggestions({ tdIds, transactionId }) {
    return this.documentsApi.getFormSuggestions({
      tdIds,
      transactionId,
      mode: 'tabbing',
    });
  }

  getEnvelopes({ sub }) {
    return this.api.getDocusignEnvelopes({
      sub,
    });
  }

  getEnvelopeDocuments({ sub, envelopeId }) {
    return this.api.getDocusignEnvelopeDocuments({
      sub,
      envelopeId,
    });
  }

  generate(transactionId, data) {
    return this.api.generateDocuSignDocs(transactionId, data);
  }
}

class Google {
  scriptLoadingPromise = null;

  constructor({ store, delegate }) {
    this.store = store;
    this.delegate = delegate;
    this.api = this.store.api.integrations;
    this.loadScript();
  }

  loadScript() {
    if (this.scriptLoadingPromise) {
      return this.scriptLoadingPromise;
    }
    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://accounts.google.com/gsi/client';
    scriptTag.async = true;
    scriptTag.defer = true;
    document.body.appendChild(scriptTag);
    this.scriptLoadingPromise = new Promise((resolve, reject) => {
      scriptTag.onload = resolve;
      scriptTag.onerror = reject;
    });
    return this.scriptLoadingPromise;
  }

  requestCode(scope) {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const client = google.accounts.oauth2.initCodeClient({
        client_id: GOOGLE_CLIENT_ID,
        scope: scope || GOOGLE_BASIC_SCOPES.join(' '),
        ux_mode: 'popup',
        callback: (response) => {
          resolve(response.code);
        },
        error_callback: () => {
          resolve(null);
        },
      });
      client.requestCode();
    });
  }

  getAccount() {
    return this.store.account;
  }

  getUi() {
    return this.store.ui;
  }

  handleOauthCallbackAndReturnRedirect = async (params) => {
    const nextRouteString = getLocalStorage().getItem(
      `googleRedirect-${params.state}`
    );
    const loginOptionsString = getLocalStorage().getItem(
      `googleLoginOptions-${params.state}`
    );
    getLocalStorage().removeItem(`googleRedirect-${params.state}`);
    getLocalStorage().removeItem(`googleOfflineAccessOptions-${params.state}`);
    const nextRoute = nextRouteString || {
      path: 'root',
    };
    const loginOptions = loginOptionsString
      ? JSON.parse(loginOptionsString)
      : {};
    const authResponse = await this.doLoginWithCode({
      code: params.code,
      ...loginOptions,
    });
    // true means we've already set a redirect
    return authResponse ? nextRoute : null;
  };

  redirectToGoogle = (params, loginOptions) => {
    const state = Math.random().toString(36).substring(2);
    getLocalStorage().setItem(`googleRedirect-${state}`, window.location.href);
    const redirectUri = `${window.location.origin}${'/auth/google-callback/'}`;
    getLocalStorage().setItem(
      `googleLoginOptions-${state}`,
      JSON.stringify({
        ...loginOptions,
        redirectUri,
      })
    );
    const url = `https://accounts.google.com/o/oauth2/v2/auth?${querySerialize({
      client_id: params.client_id,
      state,
      redirect_uri: redirectUri,
      response_type: 'code',
      include_granted_scopes: true,
      access_type: 'offline',
      scope: params.scope,
      prompt: params.prompt,
    })}`;
    window.location.replace(url);
  };
  grantPermissionsError(statusCode) {
    const message =
      GRANT_PERMISSIONS_ERRORS[statusCode] || GRANT_PERMISSIONS_DEFAULT_ERROR;
    this.getUi().toast({
      message,
      type: 'error',
    });
  }

  handleError(err) {
    if (err.error === 'popup_closed_by_user') {
      /* I don't think we should display any error since probably the user
       intentionally closed it */
    } else if (err.error === 'popup_blocked_by_browser') {
      const message = `It looks like your browser prevented us from displaying
the Google authentication popup. Please allow popups from Glide and try again.`;
      this.getUi().toast({
        message,
        type: 'error',
      });
    } else {
      logger.error(err);
      this.getUi().wentWrong();
    }
  }

  hasGrantedScopes(scopes) {
    const oauth = this.delegate.findOauthByApp(APPS.GOOGLE);
    return oauth && oauthHasScopes(oauth, scopes);
  }

  getAccountEmail() {
    const oauth = this.delegate.findOauthByApp(APPS.GOOGLE);
    return oauth && oauth.email;
  }

  isAuthCodeResponseAnotherUser(resp) {
    const account = this.getAccount();
    return account.user && resp.userId && account.user.id !== resp.userId;
  }

  // eslint-disable-next-line consistent-return
  grantOfflineAccess = async (options) => {
    try {
      const loginOptions = pick(options, [
        'registerCode',
        'inviteUuid',
        'loginRedirect',
      ]);

      try {
        await this.loadScript();
      } catch (e) {
        this.redirectToGoogle(
          {
            scope: options.scope || GOOGLE_BASIC_SCOPES.join(' '),
            client_id: GOOGLE_CLIENT_ID,
            prompt: options.prompt,
          },
          loginOptions
        );
        return null;
      }

      const code = await this.requestCode(options.scope);
      if (!code) {
        return null;
      }

      try {
        const data = await this.doLoginWithCode({
          code,
          ...loginOptions,
        });
        return data;
      } catch (err) {
        if (
          options.prompt === 'select_account' &&
          err.statusCode === 'MISSING_REFRESH_TOKEN'
        ) {
          this.getUi().toast({
            message:
              'Something went wrong and we need you to authorize Glide one more time',
            type: 'error',
          });
          return this.grantOfflineAccess({
            ...options,
            prompt: 'consent',
          });
        }
        if (err.code === api.INVALID_ARGUMENT) {
          this.store.router.navigate('external-signup-redirect');
          return true;
        }
        this.grantPermissionsError(err.statusCode);
        return true;
      }
    } catch (err) {
      this.handleError(err);
      return null;
    }
  };

  doLoginWithCode = async ({
    code,
    registerCode,
    inviteUuid,
    loginRedirect,
    redirectUri,
  }) => {
    const resp = await this.api.exchangeAuthCodeForLogin(APPS.GOOGLE, {
      code,
      registerCode,
      inviteUuid,
      redirectUri,
    });

    const { data } = resp;

    if (data.statusCode === 'EXTERNAL_SIGN_IN_REQUIRED') {
      this.store.router.navigate('external-redirect', {
        email: data.email,
      });
      return;
    }

    if (data.statusCode !== 'OK') {
      throw data;
    }
    if (loginRedirect && data.loginToken) {
      const isFeBeSplitOpen = window.Glide?.IS_FE_BE_SPLIT;
      const authLogin = isFeBeSplitOpen ? api.auth.jwtLogin : api.auth.login;
      const loginResp = await authLogin.call(api.auth, {
        token: data.loginToken,
        next: loginRedirect,
      });
      if (isFeBeSplitOpen) {
        setToken(loginResp.data.token, true);
      }

      this.store.router.redirectAfterLogin(loginResp.data.next);
      // never resolving promise makes sure we redirect here
      await new Promise(() => null);
    }
    // eslint-disable-next-line consistent-return
    return data;
  };

  ensureOfflineAccess = async (scopes, skipPrompt) => {
    const authResp = await this.grantOfflineAccess({
      scope: [...GOOGLE_BASIC_SCOPES, ...scopes].join(' '),
      prompt: !skipPrompt && 'consent',
    });

    let oauth;

    if (authResp) {
      const apps = await this.delegate.fetchAll();
      oauth = apps.find((a) => a.uuid === authResp.tokenUuid);
      this.delegate.replaceOauthByApp(oauth);
      return oauth;
    }

    if (!oauth) {
      logger.error('Expected oauth object');
    }
    return null;
  };
}

class Outlook {
  constructor({ store, delegate }) {
    this.store = store;
    this.delegate = delegate;
    this.api = this.store.api.integrations;
  }

  getAccount() {
    return this.store.account;
  }

  getUi() {
    return this.store.ui;
  }

  ensureOfflineAccess = async (scopes, loginOptions) => {
    const embeddedApp = this.store.embeddedApp;
    const isEmbedded = Boolean(embeddedApp?.isEmbedded);
    const state = {
      hash: Math.random().toString(36).substring(2),
      isEmbedded,
    };
    const redirectUri = `${window.location.origin}${'/auth/outlook-callback/'}`;
    const fullLoginOptions = {
      ...loginOptions,
      redirectUri,
    };
    if (!isEmbedded) {
      getLocalStorage().setItem(
        `outlookRedirect-${state.hash}`,
        window.location.href
      );
      getLocalStorage().setItem(
        `outlookLoginOptions-${state.hash}`,
        JSON.stringify(fullLoginOptions)
      );
    } else {
      state.loginOptions = fullLoginOptions;
      state.nextRouteString = await embeddedApp.getParentUrl();
    }
    const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?${querySerialize(
      {
        client_id: OUTLOOK_CLIENT_ID,
        state: JSON.stringify(state),
        redirect_uri: redirectUri,
        response_type: 'code',
        prompt: 'consent',
        scope: OUTLOOK_BASIC_SCOPES.concat(scopes).join(' '),
      }
    )}`;
    if (isEmbedded) {
      embeddedApp.navigateToUrl(url, 'parent');
    } else {
      window.location.replace(url);
    }
  };

  handleOauthCallbackAndReturnRedirect = async (params) => {
    const state = JSON.parse(params.state);
    let nextRouteString;
    let loginOptions;
    if (!state.isEmbedded) {
      nextRouteString = getLocalStorage().getItem(
        `outlookRedirect-${state.hash}`
      );
      const loginOptionsString = getLocalStorage().getItem(
        `outlookLoginOptions-${state.hash}`
      );
      getLocalStorage().removeItem(`outlookRedirect-${state.hash}`);
      getLocalStorage().removeItem(`outlookLoginOptions-${state.hash}`);
      loginOptions = loginOptionsString ? JSON.parse(loginOptionsString) : {};
    } else {
      nextRouteString = state.nextRouteString;
      loginOptions = state.loginOptions || {};
    }

    const authResponse = await this.doLoginWithCode({
      code: params.code,
      ...loginOptions,
    });

    if (authResponse) {
      if (!state.isEmbedded) {
        return `${nextRouteString}?app=${APPS.OUTLOOK}&tokenUUid=${authResponse.tokenUuid}`;
      }

      return nextRouteString;
    }
    // For embedded, always return the next route so that the product always navigates back to the parent app,
    // it would otherwise e stuck on Glide as top level and eventually redirected to Glide's login page.
    // TODO add some sort of error indication for those cases
    // eslint-disable-next-line consistent-return
    return !state.isEmbedded ? null : nextRouteString;
  };

  doLoginWithCode = async ({
    code,
    registerCode,
    inviteUuid,
    loginRedirect,
    redirectUri,
  }) => {
    try {
      const { data } = await this.api.exchangeAuthCodeForLogin(APPS.OUTLOOK, {
        code,
        registerCode,
        inviteUuid,
        redirectUri,
      });
      if (data.statusCode !== 'OK') {
        throw data;
      }
      if (loginRedirect && data.loginToken) {
        const isFeBeSplitOpen = window.Glide?.IS_FE_BE_SPLIT;
        const authLogin = isFeBeSplitOpen ? api.auth.jwtLogin : api.auth.login;
        const loginResp = await authLogin.call(api.auth, {
          token: data.loginToken,
          next: loginRedirect,
        });
        if (isFeBeSplitOpen) {
          setToken(loginResp.data.token, true);
        }

        this.store.router.redirectAfterLogin(loginResp.data.next);
        // never resolving promise makes sure we redirect here
        await new Promise(() => null);
      }
      return data;
    } catch (err) {
      if (err.statusCode === 'OUTLOOK_ADMIN_ACCESS_ERROR') {
        window.location.replace('/auth/outlook-error/');
        // never resolving promise makes sure we redirect here
        await new Promise(() => null);
      } else {
        throw err;
      }
    }

    return null;
  };
}

export default class IntegrationStore {
  @observable all = null;
  @observable allUsers = new Map();

  constructor(store) {
    makeObservable(this);
    this.store = store;
    this.api = this.store.api.integrations;
    this.google = new Google({
      store: this.store,
      delegate: this.getDelegate(),
    });
    this.outlook = new Outlook({
      store: this.store,
      delegate: this.getDelegate(),
    });
    this.docusign = new Docusign({
      store: this.store,
    });
  }

  getDelegate = () => {
    // TODO: limit the methods we expose
    return this;
  };

  hasApp = (app) => {
    return (
      this.all &&
      this.all.some((oauth) => {
        return oauth.app === app;
      })
    );
  };

  findOauthByApp = (app) => {
    return this.all ? this.all.find((oauth) => oauth.app === app) : undefined;
  };

  refreshByApp = async (app) => {
    const oauth = this.findOauthByApp(app);
    const { data } = await this.api.refresh(oauth.id);
    this.replaceOauthByApp(data);
    return data;
  };

  replaceOauthByApp = (oauth) => {
    const olds = this.all.filter((o) => o.uuid !== oauth.uuid);
    runInAction(() => {
      this.all = [oauth, ...olds];
    });
  };

  ensureAll = async () => {
    if (this.all === null) {
      await this.fetchAll();
    }
    return this.all;
  };

  setOauthByUser = (data, userId) => {
    const uid = userId || (data.length ? data[0].userId : null);
    if (uid) {
      this.allUsers.set(uid, data);
    }
  };

  fetchByUser = async (userId) => {
    const { data } = await this.api.fetchAll(userId);
    runInAction(() => {
      this.setOauthByUser(data, userId);
    });
    return this.allUsers.get(userId);
  };

  fetchAll = async () => {
    const { data } = await this.api.fetchAll();
    runInAction(() => {
      this.all = data;
      this.setOauthByUser(data);
    });
    return this.all;
  };

  filterByAppScopes = (app, scopes) => {
    return (
      this.all &&
      this.all
        .filter((i) => !!i)
        .filter((a) => a.app === app)
        .filter((b) => {
          return oauthHasScopes(b, scopes);
        })
    );
  };

  removeToken = async (oauthTokenId) => {
    await this.api.deleteOauthAccount(oauthTokenId);
    runInAction(() => {
      remove(this.all, (a) => a?.id === oauthTokenId);
    });
  };
}
