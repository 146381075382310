

import React, { Component } from 'react';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import analytics from 'src/analytics';
import AppButton, {
  type AppButtonProps,
} from 'src/components/common/app-button';
import SharePacket from 'src/models/transactions/items/share-packet';
import type ActivityStore from 'src/stores/activity-store';
import type SharedPacketsStore from 'src/stores/shared-packets-store';
import type TransactionStore from 'src/stores/transaction-store';
import UiStore from 'src/stores/ui-store';
import SharePacketInviteEmailModal from './share-packet-invite-email-modal';

const clsPrefix = 'app-share-packet-share-action';

interface SharePacketModalOptions {
  ui: UiStore;
  sharePacket: SharePacket;
  defaultRole?: string;
  defaultTab?: string;
  onOK?: () => void;
  linkShare?: boolean;
}

export function showSharePacketModal({
  ui,
  sharePacket,
  defaultRole = 'BUYER_AGENT',
  defaultTab = 'email',
  onOK,
  linkShare = true,
}: SharePacketModalOptions) {
  analytics().track('Share Packet Modal - Opened', {
    compass: ui.isEmbedded,
  });
  ui.setCustomModal(({ onClose }) => (
    <SharePacketInviteEmailModal
      visible
      defaultTab={defaultTab}
      linkShare={linkShare}
      onClose={onClose}
      onOK={onOK}
      defaultRole={defaultRole}
      transaction={sharePacket.transaction}
      sharePacket={sharePacket}
    />
  ));
}

interface SharePacketShareActionProps {
  className?: string;
  sharePacket: SharePacket;
  transactions: TransactionStore;
  sharedPackets: SharedPacketsStore;
  ui: UiStore;
  buttonProps?: AppButtonProps;
  children?: any;
  defaultRole?: string;
  defaultTab?: string;
  activities: ActivityStore;
}

@inject('transactions', 'sharedPackets', 'ui', 'activities')
@observer
export default class SharePacketShareAction extends Component<SharePacketShareActionProps> {
  static defaultProps = {
    children: 'Share',
    defaultRole: 'BUYER_AGENT',
    defaultTab: 'email',
  };

  onShowModal = () => {
    const { ui, sharePacket, defaultRole, defaultTab } = this.props;
    showSharePacketModal({
      ui,
      sharePacket,
      defaultRole,
      defaultTab,
    });
  };

  render() {
    const { sharePacket, className, buttonProps, children } = this.props;

    return sharePacket ? (
      <AppButton
        {...buttonProps}
        onClick={this.onShowModal}
        className={classNames(className, clsPrefix, 'test__share-share-packet')}
      >
        <span>{children}</span>
      </AppButton>
    ) : null;
  }
}
