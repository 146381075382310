import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskAction from './action';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import { getOrCreateImportZfDocFlow } from 'src/models/transactions/intents';
import { inject, observer } from 'mobx-react';

export const importZipformDocs = async (props) => {
  const { store, task } = props;
  const { result } = await store.transactions.dispatch(
    task.transactionId,
    getOrCreateImportZfDocFlow({
      taskId: task.id,
    })
  );
  navigateToFlow(store.router, result.flow_id);
};

@inject('store')
@observer
export default class ImportZipformDocs extends Component {
  static propTypes = {
    task: PropTypes.object,
    store: PropTypes.object.isRequired,
  };

  handleClick = async () => {
    const { task, store } = this.props;
    importZipformDocs({
      store,
      task,
    });
  };

  render() {
    const { task } = this.props;
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        {task.isNotStarted ? 'Start' : 'View'}
      </TaskAction>
    );
  }
}
