import { Modal } from 'antd';
import {
  gfpRemoveGuidedFormPacketFlow,
  previewFlowPdf,
} from 'src/models/transactions/intents';

export default class GuidedFormPacketsStore {
  constructor(parent) {
    this.parent = parent;
  }

  get transactions() {
    return this.parent.transactions;
  }

  get ui() {
    return this.parent.ui;
  }

  get account() {
    return this.parent.account;
  }

  get features() {
    return this.parent.features;
  }

  onModalClose = () => {
    return this.account.updateConfig({
      seenPdfPreviewInfo: true,
    });
  };

  removeGfpf = async (transactionId, gfpfId) => {
    const { transactions } = this;
    await transactions.dispatch(
      transactionId,
      gfpRemoveGuidedFormPacketFlow({
        gfpfId,
      })
    );
  };

  previewPDF = async (transactionId, flowId) => {
    const { transactions, ui } = this;

    try {
      const data = await transactions.dispatch(
        transactionId,
        previewFlowPdf(flowId)
      );
      // eslint-disable-next-line consistent-return
      const doc = {
        id: data.result.pdf_preview_url,
        url: data.result.pdf_preview_url,
        pspdfkitDoc: data.result.pdf_pspdfkit_document_data,
        filename: `Preview: ${data.result.pdf_preview_name}`,
        docToken: data.result.doc_token,
      };

      ui.setDisplayDocument(doc);
      if (
        this.account.accessMode === 'AGENT' &&
        !this.account.config.seenPdfPreviewInfo
      ) {
        Modal.info({
          title: 'This is just a preview.',
          content: "Click 'Generate PDF' to get the final document.",
          zIndex: 5000,
          onOk: this.onModalClose,
        });
      }
    } catch (e) {
      ui.wentWrong(e);
    }
  };
}
