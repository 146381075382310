import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type AvidJson = TransactionItemJson<'AVID'>;

export default class Avid extends Item<'AVID'> {
  constructor(store: ItemStore, json: AvidJson) {
    super(store, json);
    makeObservable(this);
  }

  @computed
  get flows() {
    return this.kindItem.avidFlows;
  }

  @computed
  get status() {
    return this.kindItem.status;
  }

  @computed
  get hasMulti() {
    return this.flows && this.flows.length > 1;
  }

  @computed
  get fillRole() {
    return this.kindItem.fillRole;
  }

  @computed
  get agentInviteMessage() {
    return this.kindItem.agentInviteMessage;
  }

  @computed
  get attachmentTdvIds() {
    return this.kindItem.attachmentTdvIds || [];
  }

  @computed
  get attachmentTdvs() {
    return this.attachmentTdvIds
      .map((tdvId) => this.store.itemsById.get(tdvId))
      .filter((x) => x);
  }
}
