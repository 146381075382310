import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { action, computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import { TRANSACTION_NAMESPACE } from 'src/stores/pdf-annotations-store';
import FormConfig from './form-config';
import FormOutline from './form-outline';

export default class FillConfig extends Model {
  constructor(store, json) {
    super(json);
    makeObservable(this);
    this.store = store;
  }

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get annotations() {
    return this.data.annotations || [];
  }

  @computed
  get reformForm() {
    return this.data.reformForm;
  }

  @computed
  get reformFormPrepared() {
    return this.data.reformFormPrepared;
  }

  @action
  getFormOutline(documentData) {
    return new FormOutline(this.data.formOutline || {}, {
      fillConfig: this,
      ...documentData,
    });
  }

  @computed
  get formConfig() {
    return new FormConfig(this.store, this.data.formConfig, {
      fillConfig: this,
    });
  }

  @computed
  get requiredNamespaces() {
    return uniq(
      get(this, 'reformFormPrepared.fields', [])
        .filter((f) => f.linkId && f.linkNamespace)
        .map((f) => f.linkNamespace)
    );
  }

  getRequiredTransactionNamespaces = (td) => {
    const res = [];
    if (!td) {
      this.requiredNamespaces.forEach((key) => {
        const source = key.split('/')[0];
        if (source === TRANSACTION_NAMESPACE) {
          res.push(key);
        }
      });
    } else {
      const { namespace, itemId } = td.itemNamespace.namespaceScope;
      res.push(itemId ? [namespace, itemId] : namespace);
    }
    return res;
  };
}
