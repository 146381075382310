import { makeObservable, observable, runInAction } from 'mobx';
import api from 'src/api';
import { getFetch } from 'src/utils/get-fetch';
import pusher from 'src/utils/pusher';

export default class FlowsStore {
  @observable reflectionsById = new Map();
  @observable channelsById = new Map();
  @observable hasComment = false;

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
  }

  get account() {
    return this.parent.account;
  }

  initialize = () => {};

  reloadFlowReflection = ({ flowId, userId }) => {
    if (this.account.user && this.account.user.id === userId) {
      return;
    }
    this.getFetchReflection(true, {
      flowId,
    });
  };

  setHasComment = (hasComment) => {
    this.hasComment = hasComment;
  };

  getFetchReflection = getFetch({
    bindTo: this,
    getMemoizeKey: ({ flowId }) => flowId,
    getter: ({ flowId }) => {
      return this.reflectionsById.get(flowId);
    },
    fetcher: async ({ flowId }) => {
      if (!this.channelsById.get(flowId)) {
        const channel = pusher.subscribe(flowId);
        channel.bind('reloadFlow', this.reloadFlowReflection);
        runInAction(() => {
          this.channelsById.set(flowId, channel);
        });
      }
      const { data: reflection } = await api.flows.getReflection(flowId, {});
      runInAction(() => {
        this.reflectionsById.set(flowId, reflection);
      });
    },
  });

  setReflectionFlag = (flowId, fieldId, value, flagType) => {
    const reflection = this.reflectionsById.get(flowId);
    if (!reflection) {
      return;
    }
    reflection.exports.forEach((item) => {
      if (item.id === fieldId) {
        runInAction(() => {
          item.isFlagged = value;
          item.flagType = flagType;
        });
      }
    });
  };
}
