import { computed, makeObservable } from 'mobx';
import Item, { ItemStore, TransactionItemJson } from './item';

export type CoverPhotoJson = TransactionItemJson<'COVER_PHOTO'>;

export default class CoverPhoto extends Item<'COVER_PHOTO'> {
  constructor(store: ItemStore, json: CoverPhotoJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get content() {
    return this.kindItem.content;
  }
}
