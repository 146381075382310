import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TaskAction from './action';
import navigateClick from 'src/utils/navigate-click';
import navigateToFlow from 'src/components/flows/navigate-to-flow';
import { getOrCreateOrderNhdFlow } from 'src/models/transactions/intents';
import { inject, observer } from 'mobx-react';

export const startOrderNhdWizard = async ({
  event,
  router,
  task,
  transactions,
}) => {
  let flowId = task.task.nhd && task.task.nhd.flowId;
  const nhd = task.members.find((i) => i.kind === 'NHD');

  if (!flowId) {
    const resp = await transactions.dispatch(
      task.transactionId,
      getOrCreateOrderNhdFlow({
        taskId: task.id,
      })
    );
    flowId = resp.result.flow_id;
  }
  if (
    !nhd.status ||
    nhd.status === 'NOT_STARTED' ||
    nhd.status === 'NOT_INTERESTED'
  ) {
    navigateToFlow(router, flowId);
  } else {
    navigateClick(event, router, 'transactions.transaction.items.item', {
      transactionId: task.transactionId,
      taskId: task.id,
    });
  }
};

@inject('router', 'ui')
@observer
export default class StartOrderNhdWizard extends Component {
  static propTypes = {
    task: PropTypes.object,
    transactions: PropTypes.object.isRequired,
    route: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
  };

  handleClick = async () => {
    const { task, router, transactions } = this.props;
    startOrderNhdWizard({
      router,
      transactions,
      task,
    });
  };

  render() {
    const { task } = this.props;
    return (
      <TaskAction {...this.props} onClick={this.handleClick}>
        {task.isNotStarted ? 'Start' : 'Open'}
      </TaskAction>
    );
  }
}
