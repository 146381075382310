import LayoutTermDelegate from './layout';
import { computed, makeObservable } from 'mobx';

export default class SectionTermDelegate extends LayoutTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  getAnnotations() {
    const allFieldNames = Object.values(this.term.fieldIds);
    return this.term.store?.getAnnotationsByNames(...allFieldNames) || [];
  }

  @computed
  get isEmpty() {
    return !this.term.terms?.length;
  }

  getIsSectionActive(activeTermId) {
    return Boolean(
      activeTermId &&
        (this.term.id === activeTermId ||
          this.term.flattenedTerms.some((t) => t.id === activeTermId))
    );
  }

  renderReadOnlyValue() {
    return '';
  }

  renderEmptyValue(_forceNa) {
    return '';
  }

  getValuesByFormFieldId(_value) {
    return {};
  }

  getCurrentValue() {
    return undefined;
  }

  runValidations(_value) {
    return null;
  }
}
