import camelCase from 'lodash/camelCase';
import orderBy from 'lodash/orderBy';
import { computed, makeObservable } from 'mobx';
import Model from 'src/models/base';
import getDelegate from './term-delegates';
import VALIDATIONS from './validations';

export const SECTION = 'SECTION';
export const COMBO = 'COMBO';
export const LAYOUT_TERM_KINDS = new Set([SECTION, COMBO]);
export const STRING_FORMAT = 'STRING_FORMAT';

export default class Term extends Model {
  constructor(outline, json, termId, subformTitle) {
    super(json);
    makeObservable(this);
    this.id = termId;
    this.delegate = getDelegate(this);
    this.outline = outline;
    this.subformTitle = subformTitle;
  }

  @computed
  get depth() {
    return this.id.match(/(\.)/g)?.length || 0;
  }

  @computed
  get fillConfig() {
    return this.outline.fillConfig;
  }

  @computed
  get store() {
    return this.fillConfig?.store.parent.annotations;
  }

  @computed
  get DOMNodeId() {
    return `form-outline-${this.outline.id}--term-${this.id.replace(
      /\./g,
      '-'
    )}`;
  }

  @computed
  get number() {
    return this.data.number || '';
  }

  @computed
  get title() {
    return this.data.title || '';
  }

  @computed
  get description() {
    return this.data.description || '';
  }

  @computed
  get kind() {
    return this.data.kind;
  }

  @computed
  get validations() {
    return (this.data.validations || [])
      .map((v) => VALIDATIONS[v])
      .filter(Boolean);
  }

  @computed
  get fieldIds() {
    if (this.isCombo) {
      return this.delegate.termsWithData.reduce(
        (all, [{ key }, term]) => ({
          ...all,
          ...Object.entries(term.fieldIds).reduce(
            (a, [k, v]) => ({
              ...a,
              [[key, k].filter(Boolean).join('.')]: v,
            }),
            {}
          ),
        }),
        {}
      );
    }
    return this.data.fieldIds || {};
  }

  @computed
  get kindData() {
    return this.data[camelCase(this.kind)] || {};
  }

  @computed
  get isSection() {
    return this.kind === SECTION;
  }

  @computed
  get isCombo() {
    return this.kind === COMBO;
  }

  @computed
  get isLayout() {
    return LAYOUT_TERM_KINDS.has(this.kind);
  }

  @computed
  get isStringFormat() {
    return this.kind === STRING_FORMAT;
  }

  @computed
  get inCombo() {
    return this.parentTerm?.isCombo || false;
  }

  getIsFirstInCombo() {
    if (!this.inCombo) {
      return false;
    }
    return this.parentTerm.flattenedTerms[0]?.id === this.id;
  }

  @computed
  get isLastInCombo() {
    if (!this.inCombo) {
      return false;
    }
    return this.parentTerm.flattenedTerms.slice(-1)[0]?.id === this.id;
  }

  @computed
  get terms() {
    const _getTerm = (term, idx) =>
      new Term(this.outline, term, `${this.id}.${idx + 1}`);

    const terms = this.kindData.terms || [];

    if (this.isSection) {
      return terms.map(_getTerm);
    } if (this.isCombo) {
      return terms.map(({ term }, idx) => _getTerm(term, idx));
    }

    return null;
  }

  @computed
  get flattenedTerms() {
    return (this.terms || []).map((t) => [t, ...t.flattenedTerms]).flat();
  }

  @computed
  get currentLevelId() {
    const idParts = this.id.split('.');
    return +idParts[idParts.length - 1];
  }

  @computed
  get parentId() {
    return this.id
      .split('.')
      .slice(0, -1)
      .join('.');
  }

  @computed
  get parentTerm() {
    return this.outline.getTerm(this.parentId);
  }

  @computed
  get parentTermOrOutline() {
    return this.parentTerm || this.outline;
  }

  @computed
  get siblings() {
    return this.parentTermOrOutline.terms.filter((t) => t.id !== this.id);
  }

  @computed
  get prevSiblings() {
    return orderBy(
      this.parentTermOrOutline.terms.filter(
        (t) => t.currentLevelId < this.currentLevelId
      ),
      ['currentLevelId'],
      ['desc']
    );
  }

  @computed
  get prevSibling() {
    return this.prevSiblings[0];
  }

  @computed
  get nextSiblings() {
    return orderBy(
      this.parentTermOrOutline.terms.filter(
        (t) => t.currentLevelId > this.currentLevelId
      ),
      ['currentLevelId'],
      ['asc']
    );
  }

  @computed
  get nextSibling() {
    return this.nextSiblings[0];
  }

  @computed
  get predecessors() {
    const parentTerm = this.parentTerm;
    return parentTerm ? [parentTerm, ...parentTerm.predecessors] : [];
  }

  getHasDescendants(includeComboSubTerms = true) {
    return includeComboSubTerms || !this.isCombo
      ? Boolean(this.terms?.length)
      : false;
  }

  @computed
  get hasDescendants() {
    return this.getHasDescendants();
  }

  @computed
  get hasFullTermDescendants() {
    return this.getHasDescendants(false);
  }

  @computed
  get descendants() {
    return this.hasDescendants
      ? this.terms.map((t) => [t, ...t.descendants]).flat()
      : [];
  }

  @computed
  get section() {
    return this.isSection ? this : this.predecessors.slice(-1)[0];
  }

  @computed
  get sectionId() {
    return this.section?.id;
  }

  getFieldId(fieldKey) {
    return this.fieldIds[fieldKey ?? this.delegate.mainFieldKey];
  }

  getAnnotationsForFieldKey(fieldKey) {
    const fieldId = this.getFieldId(fieldKey);
    const methodName = this.isSection
      ? 'getAnnotationsByNames'
      : 'getAnnotationsByFormFieldNames';
    return fieldId && this.store ? this.store[methodName](fieldId) : [];
  }

  get annotations() {
    return this.delegate.getAnnotations();
  }

  get mainFieldId() {
    return this.getFieldId();
  }

  get mainField() {
    return this.fillConfig.reformForm.fields.find(
      (f) => f.id === this.mainFieldId
    );
  }

  get mainAnnotation() {
    return this.getAnnotationsForFieldKey()[0];
  }

  jumpToAnnotations = (highlight, blocking = false) => {
    let annotations = this.annotations;
    if (!annotations?.length) {
      const term = this.terms ? this.terms[0] : null;
      annotations = term?.annotations;
    }
    return this.store?.jumpToAnnotations(annotations, {
      blocking,
      placement: 'top',
      offset: '-20%', // Align top of the annotation to roughly 20% of pdf viewer's height
      minScrollLength: 100, // Avoid "small" jumps
      callback:
        highlight && !this.isSection ? this.highlight.bind(this) : undefined,
    });
  };

  renderReadOnlyValue() {
    return this.delegate.renderReadOnlyValue();
  }

  renderEmptyValue(forceNa) {
    return this.delegate.renderEmptyValue(forceNa);
  }

  getPrev(excludeSections, filter) {
    return this.outline.getPrevTerm(this, excludeSections, filter);
  }

  getNext(excludeSections, filter) {
    return this.outline.getNextTerm(this, excludeSections, filter);
  }

  async validate(value) {
    const allErrors = await Promise.all([
      ...(this.delegate.runValidations(value) ?? []),
      await this.delegate.validate(value),
    ]);
    return allErrors.find(Boolean);
  }

  validateCurrentValue() {
    return this.validate(this.value);
  }

  stageValue(value) {
    return this.outline.stageValues({
      [this.id]: value,
    });
  }

  stageCurrentValue() {
    return this.stageValue(this.value);
  }

  get value() {
    return this.delegate.getCurrentValue();
  }

  get hasPermission() {
    return this.delegate.hasPermission();
  }

  get isNa() {
    return !this.isSection && this.delegate.isNa();
  }

  get canSetValue() {
    return !this.isSection && this.hasPermission && !this.isNa;
  }

  highlight() {
    return !this.isSection
      ? this.store?.setHighlighted(this.annotations, true)
      : undefined;
  }
}
