import get from 'lodash/get';
import remove from 'lodash/remove';
import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import { getFetch } from 'src/utils/get-fetch';

export default class OrgsStore {
  @observable org = null;
  @observable defaultOrg = null;
  @observable groups = [];
  @observable offices = [];
  @observable members = [];

  constructor(parent) {
    makeObservable(this);
    this.parent = parent;
    this.orgs = this.parent.api.orgs;
    this.memoizeKey = String(Math.random());
  }

  @computed
  get root() {
    return this.parent;
  }

  @action
  initialize(options) {
    this.org = get(options, 'user.orgInfo.org');
    this.defaultOrg = this.org;
  }

  @action
  resetOrgToDefault() {
    this.org = this.defaultOrg;
  }

  get orgId() {
    return this.org.id;
  }

  fetchAndSetOrg = async (orgId) => {
    const { data: org } = await this.orgs.fetchOrg(orgId);
    runInAction(() => {
      this.org = org;
      this.groups = [];
      this.offices = [];
      this.members = [];
      this.memoizeKey = String(Math.random());
    });
    return org;
  };

  @action
  fetchMembers = async (teamId, params) => {
    const { data } = await this.orgs.listMembers(teamId, params);
    runInAction(() => {
      this.members = data || [];
    });
    return this.members;
  };

  @action
  fetchMember = async (teamId, memberId) => {
    const { data } = await this.orgs.fetchMember(teamId, memberId);
    this.updateMember(data);
    return data;
  };

  updateMember = (member) => {
    runInAction(() => {
      this.members.data = this.members.data.map((m) => {
        if (m.id === member.id) {
          return member;
        }
        return m;
      });
    });
  };

  removeMember = (memberId) => {
    runInAction(() => {
      remove(this.members.data, (m) => m.id === memberId);
    });
  };

  @action
  saveOrg = async (orgRequest) => {
    const { data } = await this.orgs.saveOrg(this.orgId, orgRequest);
    runInAction(() => {
      this.org = data;
    });
    return data;
  };

  // following members are keyed by org on purpose to check auth
  @action
  saveOrgMember = async (memberId, values) => {
    const { data: member } = await this.orgs.saveOrgMember(
      this.orgId,
      memberId,
      values
    );
    this.updateMember(member);
    return member;
  };

  @action
  removeMemberAccount = async (memberId) => {
    await this.orgs.removeMemberAccount(this.orgId, memberId);
    this.removeMember(memberId);
  };

  @action
  enableMemberAccount = async (memberId) => {
    const { data } = await this.orgs.enableMemberAccount(this.orgId, memberId);
    this.updateMember(data);
  };

  @action
  changeMemberAccountStatus = async (memberId, values) => {
    const { data } = await this.orgs.changeMemberAccountStatus(
      this.orgId,
      memberId,
      values
    );
    this.updateMember(data);
  };

  // TODO Deprecate after TMS launch
  @action
  toggleMemberTms = async (memberId, enable = true) => {
    const { data } = await this.orgs.toggleMemberTms(
      this.orgId,
      memberId,
      enable
    );
    this.updateMember(data);
  };

  @action
  resendMemberInviteEmail = async (memberId) => {
    const { data } = await this.orgs.resendMemberInviteEmail(
      this.orgId,
      memberId
    );
    return data;
  };

  @action
  inviteMember = async (members) => {
    await this.orgs.inviteMember(this.orgId, members);
  };

  // Groups
  getFetchGroups = getFetch({
    bindTo: this,
    getMemoizeKey: () => {
      return this.memoizeKey;
    },
    getter: () => {
      return this.groups;
    },
    fetcher: async () => {
      const { data } = await this.orgs.listGroups({ orgId: this.orgId });
      runInAction(() => {
        this.groups = data || [];
      });
      return data;
    },
  });

  @action
  createGroup = async (groupRequest) => {
    const { data } = await this.orgs.createGroup(this.orgId, groupRequest);
    runInAction(() => {
      this.groups = this.groups.concat(data);
    });
  };

  @action
  saveGroup = async (groupRequest) => {
    const { data } = await this.orgs.saveGroup(
      this.orgId,
      groupRequest.id,
      groupRequest
    );
    runInAction(() => {
      const filterGroups = this.groups.filter((group) => group.id !== data.id);
      this.groups = filterGroups.concat(data);
    });
  };

  @action
  deleteGroup = async (groupId) => {
    await this.orgs.deleteGroup(this.orgId, groupId);
    runInAction(() => {
      remove(this.groups, (group) => group.id === groupId);
    });
  };

  @action
  listUserGroups = async (userEmail) => {
    return this.orgs.listUserGroups(userEmail);
  };

  // offices
  getFetchOffices = getFetch({
    bindTo: this,
    getMemoizeKey: () => {
      return this.memoizeKey;
    },
    getter: () => {
      return this.offices;
    },
    fetcher: async () => {
      const { data } = await this.orgs.listOffices({ orgId: this.orgId });
      runInAction(() => {
        this.offices = data || [];
      });
      return data;
    },
  });

  @action
  createOffice = async (officeRequest) => {
    const { data } = await this.orgs.createOffice(this.orgId, officeRequest);
    runInAction(() => {
      this.offices = this.offices.concat(data);
    });
  };

  @action
  saveOffice = async (officeRequest) => {
    const { data } = await this.orgs.saveOffice(
      this.orgId,
      officeRequest.id,
      officeRequest
    );
    runInAction(() => {
      const filterOffices = this.offices.filter((o) => o.id !== data.id);
      this.offices = filterOffices.concat(data);
    });
  };

  @action
  deleteOffice = async (officeId) => {
    await this.orgs.deleteOffice(this.orgId, officeId);
    runInAction(() => {
      remove(this.offices, (o) => o.id === officeId);
    });
  };

  // Templates
  getTemplate = () => {
    return this.parent.templates.transactionsById.get(this.org.templateId);
  };
}
