import BoundForm from 'src/models/fields/bound-form';
import EnvelopeDocumentDataSource from 'src/models/signing/envelope-document-data-source';
import Form from 'src/models/fields/form';
import Namespace from 'src/models/fields/namespace';
import PDFAnnotationsStore from './pdf-annotations-store';
import {
  ALL_WIDGETS,
  SIGNATURE_TABS,
} from 'src/components/documents/pspdfkit/annotations/constants';
import {
  action,
  computed,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx';

const getAnnotationFieldId = (a) => {
  return a?.customData?.formFieldName ?? a.field ?? a.name;
};

export default class PDFEsignStore extends PDFAnnotationsStore {
  @observable boundForms = new Map();

  @observable documents = [];

  @observable completedDocuments = [];

  @observable documentEdId = null;

  @observable signatureModalVisible = false;

  @observable signingAnnotationId = null;

  @observable skippedFieldsChanged = false;

  @observable skipped = new Map();

  @observable focusedAnnotation = null;

  constructor(parent) {
    super(parent);

    makeObservable(this);

    this.disableToa = true;
  }

  @override
  async setPspdfkitInstance({ pspdfkitInstance, doc }) {
    this.annotationsLoaded = false;
    this.pspdfkitInstance = pspdfkitInstance;

    this.documentEdId = doc.edId;

    this.setDesignMode(false);
    this.setSignMode(true);
    this.setFillMode(true);
    this.setAllowedTypes(
      Object.keys({
        ...ALL_WIDGETS,
        ...SIGNATURE_TABS,
      }).map((t) => {
        return t.replace(/-/g, '_').toUpperCase();
      })
    );
    this.clearFocusedAnnotation();
    await this.getFetchAnnotations(true);
  }

  @action
  async setDocuments(documents, update = false) {
    documents.forEach((doc) => {
      this.setBoundForm(doc, update);
      this.setSkippedFields(doc.edId, doc.skipped);
    });
  }

  @action
  async updateDocuments(documents) {
    this.setDocuments(documents, true);
  }

  @override
  setRecipient(recipient) {
    this.recipient = recipient;
  }

  @override
  setBoundForm(doc, update = false) {
    if (!this.boundForms.has(doc.edId)) {
      const namespace = new Namespace(
        new EnvelopeDocumentDataSource(doc, this.recipient)
      );
      const form = new Form(doc.fillConfig.reformFormPrepared);
      this.boundForms.set(doc.edId, new BoundForm(namespace, form));
    } else if (update) {
      const source = new EnvelopeDocumentDataSource(doc, this.recipient);
      const boundForm = this.boundForms.get(doc.edId);
      boundForm.namespace.updateSource(source);
    }
  }

  get boundForm() {
    return this.boundForms.get(this.documentEdId);
  }

  @computed
  get staged() {
    return this.boundForm.namespace.getStaged();
  }

  @computed
  get allStaged() {
    const res = {};
    Array.from(this.boundForms.keys()).forEach((documentEdId) => {
      res[documentEdId] = this.boundForms
        .get(documentEdId)
        .namespace.getStaged();
    });
    return res;
  }

  @override
  get isDirty() {
    return (
      this.skippedFieldsChanged ||
      Array.from(this.boundForms.values())
        .map((b) => b.isDirty)
        .filter(Boolean).length > 0
    );
  }

  @action
  resetSkippedFieldsChanged() {
    this.skippedFieldsChanged = false;
  }

  @action
  setSignatureModalVisible(v = false) {
    this.signatureModalVisible = Boolean(v);
  }

  @action
  setSigningAnnotationId(v = null) {
    this.signingAnnotationId = v;
  }

  @computed
  get ownedFields() {
    return this.boundForm.form?.fields?.filter(
      (f) => f.ownerId === this.recipient.externalId
    );
  }

  get ownedAnnotations() {
    return this.allAnnotations.filter((annotation) => {
      const boundField = this.boundForm.getBoundFieldById(
        getAnnotationFieldId(annotation)
      );

      return boundField?.field?.ownerId === this.recipient.externalId;
    });
  }

  @computed
  get fillableAnnotations() {
    return this.ownedAnnotations.filter(
      (t) => t.customData?.type !== 'date-tab'
    );
  }

  @override
  get hasAnnotations() {
    return this.annotationsLoaded && this.fillableAnnotations.length > 0;
  }

  @computed
  get annotationCount() {
    return this.fillableAnnotations.length;
  }

  @computed
  get fieldCount() {
    const value = new Set(this.fillableAnnotations.map(getAnnotationFieldId))
      .size;

    return value;
  }

  @computed
  get skippedFields() {
    const value = this.skipped.has(this.documentEdId)
      ? this.skipped.get(this.documentEdId)
      : [];

    return value;
  }

  @computed
  get allSkippedFields() {
    const value = [].concat(...Array.from(this.skipped.values()).map(toJS));
    return value;
  }

  @computed
  get completedFields() {
    const visited = [];
    const value = this.fillableAnnotations
      .map(getAnnotationFieldId)
      .filter((k) => {
        const r = this.fieldHasValue(k) && !visited.includes(k);
        visited.push(k);
        return r;
      });

    return value;
  }

  // Skipped fields filtered to this recipient only.
  @computed
  get ownedSkippedFields() {
    const ownedFieldIds = new Set((this.ownedFields ?? []).map((field) => field.id))

    return this.skippedFields.filter((sk) => ownedFieldIds.has(sk))
  }

  @computed
  get currentDocumentCompleted() {
    // Don't double count a field that was skipped and then later filled.
    const completedOrSkipped = new Set([
      ...this.completedFields,
      ...this.ownedSkippedFields
    ]);

    return this.fieldCount <= completedOrSkipped.size;
  }

  @computed
  get allFilled() {
    const value = Array.from(this.boundForms.values()).reduce(
      (m, boundForm) => {
        const recipientFieldIds = (boundForm?.form?.fields ?? [])
          .filter((f) => f.ownerId === this.recipient.externalId)
          .filter((f) => f.kind !== 'timestamp')
          .map(({ id }) => id);
        return (
          m &&
          recipientFieldIds.reduce((n, id) => {
            return (
              n &&
              (this.fieldHasValue(id, boundForm) ||
                this.allSkippedFields.includes(id))
            );
          }, true)
        );
      },
      true
    );
    return value;
  }

  getFieldValue(fieldId, boundForm = this.boundForm) {
    return boundForm.namespace.get(fieldId);
  }

  fieldHasValue(fieldId, boundForm = this.boundForm) {
    return (this.getFieldValue(fieldId, boundForm) ?? null) !== null;
  }

  isSigned(annotationId) {
    const a = this.getAnnotationById(annotationId);
    return Boolean(a) && this.fieldHasValue(getAnnotationFieldId(a));
  }

  @action
  clearFocusedAnnotation() {
    this.focusedAnnotation = null;
  }

  @action
  focusNextAnnotation() {
    this.focusedAnnotation = this.fillableAnnotations.find((t) => {
      const k = getAnnotationFieldId(t);
      return !this.allSkippedFields.includes(k) && !this.fieldHasValue(k);
    });
  }

  @action
  setFieldValues(values, documentEdId = null) {
    const form = this.boundForms.get(documentEdId || this.documentEdId);
    Object.keys(values).forEach((outputId) => {
      const value = values[outputId];
      form.getBoundOutput(outputId).setFields({
        this: value,
      });
    });
  }

  @action
  setDocumentAsCompleted(edId) {
    this.completedDocuments = Array.from(
      new Set([...this.completedDocuments, edId])
    );
  }

  getSignatureImageUrl() {
    return this.recipient?.signatureImage?.document?.url;
  }

  getInitialsImageUrl() {
    return this.recipient?.initialsImage?.document?.url;
  }

  getValue(annotationOrAnnotationId) {
    let annotation = annotationOrAnnotationId;
    if (typeof annotation === 'string') {
      annotation = this.getAnnotationById(annotationOrAnnotationId);
    }
    if (!annotation) {
      return undefined;
    }
    return this.getValueByFieldId(annotation.customData?.formFieldName);
  }

  getValueByFieldId(outputId) {
    try {
      const { pdfValue } = this.boundForm.getBoundOutput(outputId);
      return pdfValue;
    } catch (err) {
      return undefined;
    }
  }

  @action
  unstageAll() {
    this.boundForms.forEach((form) => {
      form.namespace.unstage();
    });
  }

  @action
  signAnnotation(annotationId) {
    const annotation = this.getAnnotationById(annotationId);
    if (annotation) {
      this.signField(getAnnotationFieldId(annotation));
    }
    this.focusNextAnnotation();
  }

  @action
  signField(fieldId, documentEdId = null) {
    const annotation = this.getAnnotationByFieldId(fieldId);

    if (!this.recipient?.acceptedSignature) {
      this.setSignatureModalVisible(true);
      this.setSigningAnnotationId(annotation.id);
      return;
    }

    this.setFieldValues(
      {
        [fieldId]: '1',
      },
      documentEdId || this.documentEdId
    );
  }

  @action
  removeSignature(annotationId) {
    const annotation = this.getAnnotationById(annotationId);
    const fieldId = getAnnotationFieldId(annotation);
    this.setFieldValues({ [fieldId]: null }, this.documentEdId);
  }

  @action
  skipAnnotation(annotationId) {
    const annotation = this.getAnnotationById(annotationId);
    const fieldId = getAnnotationFieldId(annotation);

    this.addSkippedFields(this.documentEdId, [fieldId]);
    this.focusNextAnnotation();
  }

  // Finds and focuses the next required annotation that has yet
  // to be filled. Skips any optional annotations
  // along the way.
  @action
  skipToNextRequiredAnnotation() {
    this.focusedAnnotation = this.fillableAnnotations.find((t) => {
      const optional = t.customData?.optional;
      const fieldId = getAnnotationFieldId(t);
      if (!optional && !this.fieldHasValue(fieldId)) {
        return true;
      }

      // Skip over any following optional fields until we reach a required one.
      if (!this.allSkippedFields.includes(fieldId)) {
        this.addSkippedFields(this.documentEdId, [fieldId]);
      }

      return false;
    });
  }

  @action
  addSkippedFields(edId, skipped) {
    if (!skipped) {
      return;
    }
    const prev = this.skipped.has(edId) ? this.skipped.get(edId) : [];
    this.skipped.set(edId, [...new Set(prev.concat(...skipped))]);
    this.skippedFieldsChanged = true;
  }

  @action
  setSkippedFields(edId, skipped) {
    if (!skipped) {
      return;
    }
    this.skipped.set(edId, [...new Set(skipped)]);
    this.resetSkippedFieldsChanged();
  }

  getAnnotationById(id) {
    return this.allAnnotations.find((a) => a.id === id);
  }

  getAnnotationByFieldId(fieldId = '') {
    return this.allAnnotations.find((annotation) => {
      return (
        annotation.customData?.formFieldName === fieldId ||
        annotation.fieldId === fieldId ||
        annotation.name === fieldId
      );
    });
  }

  isFillableAnnotation(annotation) {
    const type = annotation?.customData?.type;
    if (!type) {
      return false;
    }
    return Object.keys(SIGNATURE_TABS)
      .map((t) => t.toLowerCase())
      .includes(type && type.toLowerCase());
  }

  isFillableField(field) {
    const type = field?.customData?.type;
    if (!type) {
      return false;
    }

    return Object.keys(ALL_WIDGETS)
      .map((t) => t.toLowerCase())
      .includes(type && type.toLowerCase());
  }

  isReadOnly(annotation) {
    const boundField = this.boundForm.getBoundFieldById(
      getAnnotationFieldId(annotation)
    );

    return (
      !boundField ||
      !boundField.field?.ownerId ||
      boundField.field?.ownerId !== this.recipient.externalId
    );
  }

  getMissingFieldsFillConditions() {
    // missing field fill conditions are not enabled on esign
    return [];
  }
}
