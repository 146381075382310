import BaseTermDelegate from './base';
import { computed, makeObservable } from 'mobx';

export default class SingleSelectTermDelegate extends BaseTermDelegate {
  constructor(term) {
    super(term);

    makeObservable(this);
  }

  @computed
  get radio() {
    return Boolean(this.term.kindData.radio);
  }

  @computed
  get allowClear() {
    return Boolean(this.term.kindData.allowClear);
  }

  @computed
  get ignore() {
    return new Set(this.term.kindData.ignore || []);
  }

  @computed
  get extra() {
    return this.term.kindData.extra || {};
  }

  @computed
  get noneChoice() {
    const none = this.term.kindData.none;
    return none?.allow
      ? {
          value: null,
          label: none.label || 'None',
        }
      : undefined;
  }

  @computed
  get choices() {
    const labels = this.labels;
    return [this.noneChoice].filter(Boolean).concat(
      (
        this.mainBoundOutput.topLinkedOutput.getField('this').field?.params
          ?.choices || []
      )
        .filter(({ value }) => !this.ignore.has(value))
        .map(({ value, label, ...other }) => ({
          ...other,
          value,
          label: labels[value] || label || value,
        }))
    );
  }

  getSelectedValue(v) {
    const value = (v === undefined ? this.term.value : v) || {};
    return value[''];
  }

  @computed
  get selectedValue() {
    return this.getSelectedValue();
  }

  getEvalValue(value) {
    return this.getSelectedValue(value);
  }

  getChoice(choiceValue) {
    return this.choices.find((c) => c.value === choiceValue);
  }

  @computed
  get selectedChoice() {
    return this.getChoice(this.selectedValue);
  }

  @computed
  get labels() {
    return this.term.kindData.labels || {};
  }

  getLabel(choiceValue) {
    return (
      this.labels[choiceValue] ||
      this.getChoice(choiceValue)?.label ||
      choiceValue
    );
  }

  @computed
  get selectedLabel() {
    return this.getLabel(this.selectedValue);
  }

  choiceHasExtra(choiceValue) {
    return Boolean(choiceValue && this.extra[choiceValue]?.available);
  }

  @computed
  get hasExtra() {
    return this.choiceHasExtra(this.selectedValue);
  }

  getValueExtraBoundOutput(choiceValue) {
    return this.choiceHasExtra(choiceValue)
      ? this.getBoundOutput(choiceValue)
      : undefined;
  }

  @computed
  get choicesWithExtra() {
    return this.choices.filter((c) => this.choiceHasExtra(c.value));
  }

  @computed
  get extraValue() {
    if (!this.hasExtra || !this.selectedChoice?.value) {
      return '';
    }
    const extraBoundOutput = this.getBoundOutput(this.selectedChoice.value);
    return extraBoundOutput?.readOnlyValue || '';
  }

  getExtraPlaceholder(choiceValue) {
    if (!this.choiceHasExtra(choiceValue)) {
      return '';
    }
    return this.extra[choiceValue].placeholder || 'Explain';
  }

  @computed
  get extraPlaceholder() {
    return this.getExtraPlaceholder(this.selectedValue);
  }

  renderReadOnlyValue() {
    return [this.selectedLabel, this.extraValue]
      .filter((v) => v && v.trim && v.trim())
      .join(': ');
  }

  getCurrentValue() {
    let mainValue = super.getCurrentValue();
    if (!mainValue && this.noneChoice) {
      mainValue = this.noneChoice.value;
    }
    return {
      '': mainValue,
      ...this.choicesWithExtra.reduce(
        (all, c) => ({
          ...all,
          [c.value]:
            mainValue === c.value
              ? this.getBoundOutput(c.value).getFieldValue('this')
              : null,
        }),
        {}
      ),
    };
  }

  getValuesByFormFieldId(value_) {
    const value = value_ || {};
    const mainValue = value[''] || null;
    return {
      [this.term.mainFieldId]: mainValue,
      ...this.choicesWithExtra.reduce(
        (all, c) => ({
          ...all,
          [this.term.getFieldId(c.value)]:
            mainValue === c.value ? value[c.value] : null,
        }),
        {}
      ),
    };
  }
}
