import { computed, makeObservable, override } from 'mobx';
import Model from 'src/models/base';
import type TransactionStore from 'src/stores/transaction-store';
import type { Transaction } from 'src/types/proto/transactions';
import Parties from './parties';

export default class ThinTransaction extends Model<Transaction> {
  constructor(store: TransactionStore, json: Transaction) {
    super();
    makeObservable(this);
    this.store = store;
    this.updateFromJson(json);
  }

  store: TransactionStore;

  parties?: Parties;

  @computed
  get id() {
    return this.data.id;
  }

  @computed
  get title() {
    return this.data.meta && this.data.meta.title;
  }

  @computed
  get side() {
    return this.data.meta && this.data.meta.side;
  }

  @computed
  get state() {
    return this.data.meta && this.data.meta.state;
  }

  @computed
  get permissions() {
    return (this.data && this.data.permissions) || [];
  }

  @computed
  get isLease() {
    return this.data.meta && Boolean(this.data.meta.isLease);
  }

  @override
  updateFromJson(data: Transaction) {
    this.data = data;

    if (!this.parties) {
      this.parties = new Parties(this.store, this);
    } else {
      this.parties.updateFromJson(this);
    }
  }

  // Data store version for the associated transaction. Gets updated by the backend
  // on changes in data.
  // Can be `undefined` if this instance is not based on backend data.
  @computed
  get vers() {
    return this.data.vers;
  }

  @computed
  get isSale() {
    return this.side === 'SALE';
  }

  @computed
  get isPurchase() {
    return this.side === 'PURCHASE';
  }
}
